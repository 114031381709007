import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  InputGroup,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"

const bookings = [
  {
    operator: "Sri Jagannath Travels",
    busName: "Konark Express",
    route: "Bhubaneswar to Konark",
    conductorName: "Manoj Swain",
    driverName: "Suresh Mohapatra",
  },
  {
    operator: "Royal Cruiser",
    busName: "Odia Express",
    route: "Cuttack to Sambalpur",
    conductorName: "Rakesh Sahu",
    driverName: "Rajendra Prasad",
  },
  {
    operator: "Sri Laxmi Travels",
    busName: "Puri Express",
    route: "Bhubaneswar to Puri",
    conductorName: "Sudhir Behera",
    driverName: "Pradeep Nayak",
  },
  {
    operator: "Maa Tarini Travels",
    busName: "Kalinga Express",
    route: "Bhubaneswar to Rourkela",
    conductorName: "Lalit Mohanty",
    driverName: "Bikram Sahoo",
  },
  {
    operator: "Neelachal Travels",
    busName: "Neelachal Express",
    route: "Puri to Cuttack",
    conductorName: "Amit Mohapatra",
    driverName: "Dibya Sahu",
  },
  {
    operator: "Maa Ambika Travels",
    busName: "Ambika Express",
    route: "Bhubaneswar to Bhadrak",
    conductorName: "Suman Pradhan",
    driverName: "Prakash Patra",
  },
  {
    operator: "Sri Sai Travels",
    busName: "Sai Express",
    route: "Bhubaneswar to Berhampur",
    conductorName: "Ganesh Prusty",
    driverName: "Rajendra Kumar",
  },
  {
    operator: "Ravi Travels",
    busName: "Ravi Express",
    route: "Cuttack to Baripada",
    conductorName: "Dilip Das",
    driverName: "Niranjan Behera",
  },
  {
    operator: "Citylink Travels",
    busName: "Citylink Express",
    route: "Bhubaneswar to Jeypore",
    conductorName: "Anil Patnaik",
    driverName: "Sanjay Behera",
  },
  {
    operator: "Sri Ram Travels",
    busName: "Ram Express",
    route: "Puri to Sambalpur",
    conductorName: "Debashis Mohapatra",
    driverName: "Rajib Sahu",
  },
  {
    operator: "Sri Ganesh Travels",
    busName: "Ganesh Express",
    route: "Bhubaneswar to Rourkela",
    conductorName: "Sushant Panda",
    driverName: "Sudhir Behera",
  },
]

const AssignBusCrew = () => {
  const [data_attr, setdata_attr] = useState(0)
  const [token, setToken] = useState(null)
  document.title = "MyBooking | OneTKT"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="OneTKT" breadcrumbItem="Staffs" />
          <Row className="mt-4">
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col"> Operator</th>
                          <th scope="col">Bus</th>
                          <th scope="col">Route</th>
                          <th scope="col">Driver</th>
                          <th scope="col">Conductor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookings.map((bus, i) => {
                          return (
                            <tr key={i}>
                              <td>{bus.operator}</td>
                              <td>{bus.busName}</td>
                              <td>{bus.route}</td>

                              <td>
                                <Button
                                  onClick={() => {
                                    setAssignModal(!assignModal)
                                  }}
                                >
                                  {bus.conductorName}
                                </Button>
                              </td>
                              <td>
                                <Button
                                  onClick={() => {
                                    setAssignModal(!assignModal)
                                  }}
                                >
                                  {bus.driverName}{" "}
                                </Button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AssignBusCrew
