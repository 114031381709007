import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap';
import PropTypes from "prop-types"

import { callApi } from 'helpers/api_helper';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const LiveBuses = (props) => {
    const [occupancy, setOccupancy] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { liveBusList } = props;

    const handleOccupancy = async (id, index) => {
        setIsLoading(true);
        const newOccupancy = [...occupancy];
        newOccupancy[index] = id;
        const config = {
            method: 'post',
            url: 'bus/getBusRoutes/findOccupancy',
            data: {
                id: id
            }
        };
        const response = await callApi(config);
        if (response.data.code === 200) {
            newOccupancy[index] = response.data.data
            setOccupancy(newOccupancy);
            setIsLoading(false);
        }
    }

    useEffect(() => {

    }, [liveBusList])

    return (
        <div>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">{props.t("Today's Live Buses")}</h4>
                    <div className="table-responsive">
                        {/* <div className="float-end pt-1">
                            <span className="text-muted text-small mr-1">{`${startIndex}-${endIndex} of ${totalItemCount} `}</span>
                            <UncontrolledDropdown className="d-inline-block">
                                <DropdownToggle caret color="outline-dark" size="md">
                                    {selectedPageSize}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {pageSizes.map((size, index) => {
                                        return (
                                            <DropdownItem
                                                key={index}
                                                onClick={() => changePageSize(size)}
                                            >
                                                {size}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div> */}
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                                <tr className="text-center">
                                    <th scope="col">{props.t('Partner Name')}</th>
                                    {/* <th scope="col">route id</th> */}
                                    <th scope="col">{props.t('Bus Redg. No.')}</th>
                                    <th scope="col">{props.t('Route')}</th>
                                    <th scope="col">{props.t('Occupancy')} (in %)</th>
                                    <th scope="col">{props.t('Tickets Booked')}</th>
                                    <th scope="col">{props.t('Last Booked')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {liveBusList?.map((item, i) => {
                                    return (
                                        <tr className="text-center" key={item.bus[0]._id + i}>
                                            <th scope="row">{item?.bus[0]?.busName}</th>
                                            {/* <th scope="row">{item?.route[0]?._id}</th> */}
                                            <td>
                                                <div>{item?.bus[0]?.busRegistrationNumber}</div>
                                            </td>
                                            <td>
                                                {
                                                    item?.route.length > 0 ? `${item?.route[0].startPoint?.station} - ${item?.route[0].endPoint?.station}` : '-'
                                                }

                                            </td>
                                            <td>
                                                {item?.occupancy}
                                                {/* <Button onClick={() => handleOccupancy(item?.bus[0]._id, i)}>
                                                    {
                                                        isLoading ? 
                                                        <Spinner className="" color="primary" style={{ height : '17px', width : '17px', fontSize : '10px'}}/> 
                                                        : 
                                                        occupancy[i] ? occupancy[i] : 'View Occupancy'
                                                    }
                                                </Button> */}
                                            </td>
                                            <td>{item?.ticketBooked}</td>
                                            <td>{moment(item?.bookingRequests[0]?.createdAt).format('hh:mm A')}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {/* <Pagination
                            currentPage={currentPage}
                            totalPage={totalPage}
                            onChangePage={(i) => setCurrentPage(i)}
                        /> */}
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

LiveBuses.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any
}
export default withTranslation()(LiveBuses)