import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
import UserProfile from "pages/Authentication/user-profile"
// import LineApexChart from "../AllCharts/apex/chartapex"
import Select from "react-select"

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input,
  CardTitle,
} from "reactstrap"
import { formatDate } from "helpers/Utils/formatTime";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import DownloadPdf from "pages/Utility/DownloadPdf";
import { withTranslation } from "react-i18next";

const options = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "Today",
    value: 1,
  },
  {
    label: "Last 7 Days",
    value: 7,
  },
  {
    label: "Last 30 Days",
    value: 30,
  },
  {
    label: "Last 1 Year",
    value: 366,
  },
]

const DashboardModal = ({ modalOpen, toggleModal, savings, getAllFilteredBookingRequests, t }) => {
  const [pdfContent, setPdfContent] = useState();
  const [selectedDifference, setSelectedDifference] = useState();

  const generatePDFContent = (jsonData = []) => (
    <Document>
      <Page style={styles.page} size="A2">
        <View style={styles.container}>
          <View style={styles.tableRow}>
            <Text style={styles.headingCell}>Bus Redg. No.</Text>
            <Text style={styles.headingCell}>Amount Saved</Text>
            <Text style={styles.headingCell}>Date</Text>
          </View>
          {jsonData?.map((payment, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.valueCell}>
                  {payment.busRegistrationNumber}
                </Text>
                <Text style={styles.valueCell}>{payment?.savings ? payment?.savings : 0} </Text>

                <Text style={styles.valueCell}>{formatDate(payment?.date)}</Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  );

  const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })

  const handleSelect = async e => {
    try {
      setSelectedDifference(e)
      await getAllFilteredBookingRequests(e.value)
    } catch (error) {
      console.log({ error })
    }
  }
  useEffect(() => {
    setPdfContent(generatePDFContent(savings))
  }, [savings]);

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggleModal} centered size="lg">
        <ModalHeader toggle={toggleModal}>
          <Label>{t('Savings Details')}</Label>
        </ModalHeader>
        <ModalBody>
          <div className="container" style={{ height: '350px', overflowY: 'scroll' }}>
            <div className="text-center">
              <Row
              // style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col xs={0} md={6}></Col>
                <Col xs={6} md={3} >
                  {/* <label>Sort By</label> */}
                  <Select
                    // className="form-control"
                    placeholder="Select to sort"
                    classNamePrefix="select2-selection"
                    options={options}
                    value={selectedDifference}
                    onChange={e => handleSelect(e)}
                    isSearchable={false}
                  />
                </Col>

                <Col xs={6} md={3} className="mb-3">
                  <Button color="success">
                    <DownloadPdf
                      className={`mdi mdi-download `}
                      pdfContent={pdfContent}
                      fileName={"savings"}
                    />
                  </Button>
                </Col>
              </Row>
              <div className="table-responsive mb-4">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">{t('Bus Redg. No.')}</th>
                      <th scope="col">{t('Amount Saved')}</th>
                      <th scope="col">{t('Date')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      savings?.map((item) => {
                        return (
                          <tr key={item._id}>
                            <th scope="row">{item?.busRegistrationNumber}</th>
                            <td>{item?.savings ? item?.savings : 0}</td>
                            <td>{formatDate(item?.date)}</td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </table>
              </div>
            </div>
            {/* <Card>
              <CardBody>
                <h4 className="card-title mb-4">Savings Chart</h4>
                <LineApexChart />
              </CardBody>
            </Card> */}
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}
DashboardModal.propType = {
  t: PropTypes.any
}
export default withTranslation()(DashboardModal)