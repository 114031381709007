import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Select from 'react-select';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './DocumentModal.css';
import { withTranslation } from 'react-i18next';

const DocumentModal = ({ modalOpen, toggleModal, busOptions, t }) => {
    const [selectedBus, setSelectedBus] = useState();
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    const handleChangeBus = async (e) => {
        try {
            setSelectedBus(e);
            setSelectedDocuments(e?.data.uploadDocuments);
        } catch (error) {
            console.log({ error })
        }
    }
    var settings = {
        // dots: true,
        centerMode: true,

        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                toggle={toggleModal}
                size='md'
                className='custom-modal'
                centered
            >
                <ModalHeader toggle={toggleModal}>
                    <Label>{t('Documents')}</Label>
                </ModalHeader>
                <ModalBody>
                    <Select
                        isSearchable={false}
                        options={busOptions}
                        value={selectedBus}
                        onChange={handleChangeBus}
                    />

                    <div className='container'>
                        {
                            selectedDocuments?.length > 0 ?
                                <div className='px-2'>
                                    <Slider {...settings}>
                                        {
                                            selectedDocuments?.map((item, i) => {
                                                return (
                                                    <div key={item.name + i} className=''>
                                                        <Label className='my-2'>{item?.name}</Label>
                                                        {/* <a href={item?.url} target='_blank'> */}
                                                        <img className='px-2' src={item?.url} alt={item?.name} style={{ height: 'auto', width: '100%', maxHeight: '800px' }} />
                                                        {/* </a> */}
                                                    </div>

                                                )
                                            })

                                        }
                                    </Slider>

                                </div>
                                :
                                <div>{t('No documents found')}</div>
                        }
                    </div>
                    <div className='my-2 d-flex justify-content-end align-items-center'>
                        <Button color='danger' outline onClick={toggleModal}>{t('Close')}</Button>
                    </div>
                </ModalBody>

            </Modal>
        </div>
    )
}

DocumentModal.propTypes = {
    t: PropTypes.any
}
export default withTranslation()(DocumentModal);