import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { useNavigate } from "react-router-dom"

import Breadcrumb from "../../components/Common/Breadcrumb"
import { callApi } from "helpers/api_helper"
import { formatDate } from "helpers/Utils/formatTime"
import PieChart from "./PieChart"
import * as XLSX from "xlsx"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import DownloadPdf from "pages/Utility/DownloadPdf"
import { withTranslation } from "react-i18next";

const options = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "Last 7 Days",
    value: 7,
  },
  {
    label: "Last 15 Days",
    value: 15,
  },
  {
    label: "Last 30 Days",
    value: 30,
  },
  {
    label: "More than 30 Days",
    value: 30,
  },
]
const IdlePartner = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [userList, setUserList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [selectedDifference, setSelectedDifference] = useState()
  const [data, setData] = useState([])
  const [labels, setLabels] = useState([])
  const [pdfContent, setPdfContent] = useState()

  const pageSizes = [1, 2, 4, 8, 10, 20, 50, 100, 500, 1000]

  const startIndex = (currentPage - 1) * selectedPageSize
  const endIndex = currentPage * selectedPageSize

  const navigate = useNavigate()

  const changePageSize = async size => {
    setCurrentPage(1)
    setSelectedPageSize(size)
    await fetchUsers(size, 1)
  }

  const fetchUsers = async (size, tCurrentPage) => {
    try {
      const config = {
        method: "post",
        url: "user/getUserRoutes/exhaustingCoins",
        data: {
          pageSize: size,
          currentPage: tCurrentPage,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        // const today = moment();
        // const tempArr = response.data.data.map((item) => {
        //     const tempDate = moment(item?.bookingRequests[0]?.createdAt,"DD/MM/YYYY");
        //     const differenceInDays = tempDate.diff(today, 'days');

        // })
        setUserList(response.data.data)
        setIsLoading(false)
        setTotalItemCount(response.data.totalCount)
        setTotalPage(Math.ceil(response.data.totalCount / size))
      }
    } catch (error) {
      console.log({ errorMessage: error })
    }
  }

  const fetchFilteredUsers = async (size, tCurrentPage, value) => {
    try {
      const config = {
        method: "post",
        url: "user/getUserRoutes/filterUserByBookingDays",
        data: {
          pageSize: size,
          currentPage: tCurrentPage,
          difference: value,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        // const today = moment();
        // const tempArr = response.data.data.map((item) => {
        //     const tempDate = moment(item?.bookingRequests[0]?.createdAt,"DD/MM/YYYY");
        //     const differenceInDays = tempDate.diff(today, 'days');

        // })
        setUserList(response.data.data)
        setIsLoading(false)
        setTotalItemCount(response.data.totalCount)
        setTotalPage(Math.ceil(response.data.totalCount / size))
      }
    } catch (error) {
      console.log({ errorMessage: error })
    }
  }
  const handleSelect = async e => {
    try {
      setSelectedDifference(e)
      await fetchFilteredUsers(selectedPageSize, currentPage, e.value)
    } catch (error) {
      console.log({ error })
    }
  }

  const getAllPieChartDetails = async (size, tCurrentPage) => {
    const config = {
      method: "post",
      url: "user/getUserRoutes/idlePartnerDataForPieChart",
      data: {
        pageSize: size,
        currentPage: tCurrentPage,
      },
    }
    const response = await callApi(config)
    if (response.data.code === 200) {
      // setIsLoading(false);
      const tempVar = response?.data?.data
      setData([
        tempVar.idle7Days,
        tempVar.idle15Days,
        tempVar.idle30Days,
        tempVar.idleMore30Days,
      ])
      setLabels([
        props.t("Idle for 7 Days"),
        props.t("Idle for 15Days"),
        props.t("Idle for 30 Days"),
        props.t("Idle for More than 30 Days"),
      ])
    } else {
      navigate("/login")
    }
  }

  useEffect(() => {
    getAllPieChartDetails()
    fetchUsers(selectedPageSize, currentPage)
  }, [])

  const handleDownload = async () => {
    try {
      if (userList.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(userList)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "userList.xlsx")
      }
    } catch (error) {
      console.log("error => ", error)
    }
  }

  const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })
  const generatePDFContent = jsonData => (
    <Document>
      <Page style={styles.page} size="A2">
        <View style={styles.container}>
          <View style={styles.tableRow}>
            <Text style={styles.headingCell}>User ID</Text>
            <Text style={styles.headingCell}>Business Name</Text>
            <Text style={styles.headingCell}>Partner Phone Number</Text>
            <Text style={styles.headingCell}>Last Booking Date</Text>
            <Text style={styles.headingCell}>Coins Left </Text>
          </View>
          {jsonData.map((item, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.valueCell}>{item?.user[0]?.userId}</Text>
                <Text style={styles.valueCell}>{item?.busId[0]?.busName} </Text>
                <Text style={styles.valueCell}>
                  {item?.user[0]?.phoneNumber}{" "}
                </Text>
                <Text style={styles.valueCell}>
                  {formatDate(item?.bookingRequests[0].createdAt)}
                </Text>
                <Text style={styles.valueCell}>
                  {item?.user[0]?.userTokens}{" "}
                </Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )
  useEffect(() => {

    setPdfContent(generatePDFContent(userList))
  }, [userList])

  document.title = "Idle Partner | OneTKT"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="OneTKT" breadcrumbItem="IdlePartner" />

          <Card>
            <CardBody>
              <div className="mb-4">
                <Row>
                  <Col
                    xs={12}
                    md={4}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <h4 className="card-title mb-4">{props.t('Idle Partners')}</h4>
                  </Col>
                  <Col xs={12} md={4} className="mb-2"></Col>
                  <Col xs={12} md={4} className="mb-2">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Label>{props.t('Sort By')}</Label>
                        <Select
                          isSearchable={false}
                          options={options}
                          value={selectedDifference}
                          onChange={e => handleSelect(e)}
                        />
                      </div>
                      <div
                        className="mx-1"
                        style={{ marginTop: 24, marginLeft: 8 }}
                      >
                        <Button color="success">
                          <DownloadPdf
                            className={`mdi mdi-download me-2`}
                            pdfContent={pdfContent}
                            fileName={"IdlePartner"}
                          />
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr className="text-center">
                      <th scope="col">{props.t('User ID')}</th>
                      <th scope="col">{props.t('Business Name')}</th>
                      <th scope="col">{props.t('Partner Phone Number')}</th>
                      {/* <th scope="col">BF (Last 15 Days )</th>
                                            <th scope="col">Selected Range BF</th> */}
                      <th scope="col">{props.t('Last Booking Date')}</th>

                      <th scope="col">{props.t('Coins Left')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList?.map(item => {
                      return (
                        <tr className="text-center" key={item?._id}>
                          <th scope="row">{item?.user[0]?.userId}</th>
                          <td>
                            <div>{item?.user[0]?.businessName}</div>
                          </td>
                          <td>{item?.user[0]?.phoneNumber}</td>
                          {/* <td>94</td>
                                                        <td>94</td> */}
                          <td>
                            {formatDate(item?.bookingRequests[0].createdAt)}
                          </td>
                          <td>{item?.user[0]?.userTokens}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <PieChart data={data} labels={labels} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
IdlePartner.propType = {
  t: PropTypes.any
}
export default withTranslation()(IdlePartner);
