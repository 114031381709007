import React, { useState, useEffect } from "react"
import * as XLSX from "xlsx"
import PropTypes from "prop-types"
import { sentenceCase } from "change-case"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Form,
  FormFeedback,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import DownloadPdf from "pages/Utility/DownloadPdf"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// actions
import { callApi } from "helpers/api_helper"
import Select from "react-select"
import ViewUserDetails from "pages/Modals/ViewUserDetails"
import NewStaffModal from "pages/Modals/NewStaffModal"
import Pagination from "helpers/Utils/Pagination"
import { useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"

const AddStaff = props => {
  //   const dispatch = useDispatch();

  const [name, setname] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [role, setRole] = useState("")
  const [idx, setidx] = useState(1)
  const [data, setData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [roleOptions, setRoleOptions] = useState([])
  const [selectedRole, setSelectedRole] = useState()
  const [selectedUser, setSelectedUser] = useState()

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [pdfContent, setPdfContent] = useState()
  const [userRole, setUserRole] = useState("")

  const pageSizes = [2, 4, 8, 10, 20, 50, 100, 500, 1000]

  const startIndex = (currentPage - 1) * selectedPageSize
  const endIndex = currentPage * selectedPageSize

  const navigate = useNavigate()

  const changePageSize = async size => {
    setCurrentPage(1)
    setSelectedPageSize(size)
    await getAllUsers(size, 1)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userName: name || "",
      phoneNumber: phoneNumber || "",
      role: role || "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter Your User Name"),
      phoneNumber: Yup.string().required("Please Enter Your Phone Number"),
      // role: Yup.string().required("Please Enter Your Role"),
    }),
    onSubmit: (values, { resetForm }) => {
      //   dispatch(editProfile(values));
      if (isEditModalOpen) {
        handleEditSubmit(values)
        resetForm()
      } else {
        handleSubmit(values)
        resetForm()
      }
    },
  })

  const handleSubmit = async values => {
    try {
      const config = {
        method: "post",
        url: "user/getUserRoutes/createUser",
        data: {
          userName: values.userName,
          phoneNumber: values.phoneNumber,
          role: selectedRole.value,
        },
      }

      const response = await callApi(config)
      if (response.data.code === 200) {
        setIsModalOpen(false)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setSuccessMessage(response.data.message)
        await getAllUsers(selectedPageSize, currentPage)
      } else {
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  useEffect(() => {
    const func = async () => {
      const a = JSON.parse(await localStorage.getItem("user"))?.user?.role
      setUserRole(a)


      getAllRoles(a)
    }
    func()
  }, [])

  const handleEditSubmit = async values => {
    try {
      const config = {
        method: "post",
        url: "user/getUserRoutes/updateUser",
        data: {
          userName: values.userName,
          phoneNumber: values.phoneNumber,
          role: selectedRole?.value,
          userId: selectedUser?._id,
        },
      }

      const response = await callApi(config)
      if (response.data.code === 200) {
        setIsEditModalOpen(false)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setSuccessMessage(response.data.message)
        await getAllUsers(selectedPageSize, currentPage)
      } else {
        setIsEditModalOpen(false)
        setTimeout(() => {
          setErrorMessage(null)
        }, 2000)
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      setIsEditModalOpen(false)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      setErrorMessage(error.message)
    }
  }

  const getAllRoles = async role => {
    const config = {
      method: "get",
      url: "roles/getRoleRoutes/getAllUserRoles",
    }
    const response = await callApi(config)

    if (response.data.code === 201) {
      const tempArr = response.data.data.map(item => {
        return {
          label: sentenceCase(item.roleName),
          value: item.roleName,
          data: item,
        }
      })

      setRoleOptions(
        tempArr.filter(
          item =>
            item.value === "SITE_MANAGER" ||
            item.value === "BUSINESS_DEVELOPMENT_MANAGER"
        )
      )
    }
  }

  const getAllUsers = async (size, tCurrentPage) => {
    const config = {
      method: "post",
      url: "user/getUserRoutes/getAll",
      data: {
        pageSize: size,
        currentPage: tCurrentPage,
      },
    }
    const response = await callApi(config)
    if (response.data.code === 200) {
      const tempArr = response.data.data.filter(
        item =>
          item.role === "SITE_MANAGER" ||
          item.role === "BUSINESS_DEVELOPMENT_MANAGER"
      )


      setIsLoading(false)
      setData(tempArr)
      setTotalItemCount(response.data.totalCount)
      setTotalPage(Math.ceil(response.data.totalCount / size))
    } else {
      navigate("/login")
    }
  }

  const handleDownload = async () => {
    try {
      // const data = bookedTicketsList?.map((item) => {
      //   return {
      //     "Partner Name": item?.bus[0]?.busName,
      //     "Bus Registration Number": item?.bus[0]?.busRegistrationNumber,
      //     "Tickets Sold": item?.ticketsBooked,
      //   }
      // })
      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "StaffList.xlsx")
      }
    } catch (error) {
      console.log("error => ", error)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getAllUsers(selectedPageSize, currentPage)
  }, [currentPage])

  const setUser = user => {

    setPhoneNumber(user?.phoneNumber)
    setname(user?.userName)
    setRole(roleOptions?.filter(item => item.value === user?.role))
    setSelectedRole(roleOptions?.filter(item => item.value === user?.role))
    setSelectedUser(user)
  }

  const handleDelete = async phoneNumber => {
    try {
      const config = {
        method: "post",
        url: "user/getUserRoutes/deleteUserByPhoneNumber",
        data: {
          phoneNumber,
        },
      }

      const response = await callApi(config)
      if (response.data.code === 200) {
        setIsModalOpen(false)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setSuccessMessage(response.data.message)
        await getAllUsers(selectedPageSize, currentPage)
      } else {
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })
  const generatePDFContent = jsonData => (
    <Document>
      <Page style={styles.page} size="A2">
        <View style={styles.container}>
          <View style={styles.tableRow}>
            <Text style={styles.headingCell}>User ID</Text>
            <Text style={styles.headingCell}>User Name</Text>
            <Text style={styles.headingCell}>Role</Text>
            <Text style={styles.headingCell}>Phone Number</Text>
            <Text style={styles.headingCell}>Profile Status</Text>
            <Text style={styles.headingCell}>MPIN Status</Text>
          </View>
          {jsonData.map((user, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.valueCell}>{user.userId}</Text>
                <Text style={styles.valueCell}>{user.userName} </Text>
                <Text style={styles.valueCell}>{sentenceCase(user.role)}</Text>
                <Text style={styles.valueCell}>{user.phoneNumber} </Text>
                <Text style={styles.valueCell}>
                  {user.isProfileOk ? "Updated" : "Not Updated"}{" "}
                </Text>
                <Text style={styles.valueCell}>
                  {user.isMPINOk ? "Updated" : "Not Updated"}{" "}
                </Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )

  useEffect(() => {

    setPdfContent(generatePDFContent(data))
  }, [data])

  document.title = "Add Staff | OneTKT"

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="" color="danger" />
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Dashboard" breadcrumbItem="Staffs" />

            <Row>
              <Col lg="12">
                {errorMessage && errorMessage ? (
                  <Alert color="danger">{errorMessage}</Alert>
                ) : null}
                {successMessage ? (
                  <Alert color="success">{successMessage}</Alert>
                ) : null}

                <ViewUserDetails
                  modalOpen={isViewModalOpen}
                  toggleModal={() => setIsViewModalOpen(!isViewModalOpen)}
                  selectedUser={selectedUser}
                />
                <Card>
                  <CardBody>
                    <div className="text-end mb-3 d-flex justify-content-center align-items-center">
                      <div className="mx-1">
                        <Button
                          color="primary"
                          onClick={() => setIsModalOpen(true)}
                        >
                          <i className={`ion ion-ios-person-add me-2`}></i>
                          {props.t('Add New Staff')}
                        </Button>
                      </div>

                      <div className="mx-1">
                        <Button color="success">
                          <DownloadPdf
                            className={`mdi mdi-download me-2`}
                            pdfContent={pdfContent}
                            fileName={"Teams"}
                          />
                        </Button>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <Table>
                        <thead>
                          <tr>
                            <th>{props.t('User ID')}</th>
                            <th>{props.t('User Name')}</th>
                            <th>{props.t('Role')}</th>
                            <th>{props.t('Phone Number')}</th>
                            <th>{props.t('Profile Status')}</th>
                            <th>{props.t('MPIN Status')}</th>
                            <th>{props.t('Actions')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map(user => {
                            return (
                              <tr key={user._id}>
                                <td>{user?.userId}</td>
                                <td>{user?.userName}</td>
                                <td>{sentenceCase(user?.role)}</td>
                                <td>{user?.phoneNumber}</td>
                                <td>
                                  {user?.isProfileOk
                                    ? "Updated"
                                    : "Not Updated"}
                                </td>
                                <td>
                                  {user?.isMPINOk ? "Updated" : "Not Updated"}
                                </td>
                                <td>
                                  <ButtonGroup>
                                    <Button
                                      outline
                                      color="primary"
                                      style={{ pointerEvents: "all" }}
                                      onClick={() => {
                                        setUser(user), setIsEditModalOpen(true)
                                      }}
                                    >
                                      {props.t('Edit')}
                                    </Button>
                                    <Button
                                      outline
                                      color="primary"
                                      style={{ pointerEvents: "all" }}
                                      onClick={() => {
                                        setUser(user),
                                          setSelectedUser(user),
                                          setIsViewModalOpen(true)
                                      }}
                                    >
                                      {props.t('View Details')}
                                    </Button>
                                    <Button
                                      outline
                                      color="danger"
                                      style={{ pointerEvents: "all" }}
                                      onClick={() =>
                                        handleDelete(user?.phoneNumber)
                                      }
                                    >
                                      {props.t('Delete')}
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                    {/* <MDBDataTable responsive striped bordered data={data} /> */}
                    <Pagination
                      currentPage={currentPage}
                      totalPage={totalPage}
                      onChangePage={i => setCurrentPage(i)}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <h4 className="card-title mb-4">Add New User</h4> */}

            {/* Add Staff Modal Start*/}
            <NewStaffModal
              modalOpen={isModalOpen}
              toggleModal={() => setIsModalOpen(!isModalOpen)}
              getAllUsers={getAllUsers}
              roleOptions={roleOptions}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
            />
            {/* Add Staff Modal End*/}
            <Modal
              isOpen={isEditModalOpen}
              toggle={() => setIsEditModalOpen(!isEditModalOpen)}
              backdrop="static"
              size="lg"
              centered={true}
            >
              <ModalHeader toggle={() => setIsEditModalOpen(!isEditModalOpen)}>
                {props.t('Update User')}
              </ModalHeader>
              <ModalBody>
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <div className="form-group my-3">
                    <Label className="form-label">{props.t('User Name')}</Label>
                    <Input
                      name="userName"
                      className="form-control"
                      placeholder="Enter User Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.userName || ""}
                      invalid={
                        validation.touched.userName &&
                          validation.errors.userName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.userName &&
                      validation.errors.userName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.userName}
                      </FormFeedback>
                    ) : null}
                    <Input name="idx" value={idx} type="hidden" />
                  </div>
                  <div className="form-group my-3">
                    <Label className="form-label">{props.t('Phone Number')}</Label>
                    <Input
                      name="phoneNumber"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      type="tel"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phoneNumber || ""}
                      invalid={
                        validation.touched.phoneNumber &&
                          validation.errors.phoneNumber
                          ? true
                          : false
                      }
                    />
                    {validation.touched.phoneNumber &&
                      validation.errors.phoneNumber ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phoneNumber}
                      </FormFeedback>
                    ) : null}
                    <Input name="idx" value={idx} type="hidden" />
                  </div>
                  <div className="my-2">
                    <Label>{props.t('Role')}</Label>
                    <Select
                      isSearchable={false}
                      onChange={e => {
                        setSelectedRole(e)
                      }}
                      options={roleOptions}
                      classNamePrefix="select2-selection"
                      onBlur={validation.handleBlur}
                      value={selectedRole}
                    />
                  </div>
                  <div className="d-flex justify-content-end align-items-center mt-4">
                    <Button
                      outline
                      color="danger"
                      className="mx-2"
                      onClick={() => setIsEditModalOpen(!isEditModalOpen)}
                    >
                      {props.t('Cancel')}
                    </Button>
                    <Button type="submit" color="success">
                      {props.t('Update User')}
                    </Button>
                  </div>
                </Form>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </Modal>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

AddStaff.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any,

}

export default withTranslation()(AddStaff);
