import usFlag from "../assets/images/flags/us.jpg"
import spain from "../assets/images/flags/spain.jpg"
import germany from "../assets/images/flags/germany.jpg"
import italy from "../assets/images/flags/italy.jpg"
import russia from "../assets/images/flags/russia.jpg"
import Bharat from "../assets/images/flags/india.png"


const languages = [
  {
    label: (
      <span>
        <img src={spain} width="20px" style={{ marginRight: "2px" }} /> SPAIN
      </span>
    ),
    value: "SPAIN",
  },
  {
    label: (
      <span>
        <img src={Bharat} width="20px" style={{ marginRight: "2px" }} /> ODIA
      </span>
    ),
    value: "ODIA",
  },
  {
    label: (
      <span>
        <img src={germany} width="20px" style={{ marginRight: "2px" }} />
        GERMAN
      </span>
    ),
    value: "GERMAN",
  },
  {
    label: (
      <span>
        <img src={italy} width="20px" style={{ marginRight: "2px" }} /> ITALY
      </span>
    ),
    value: "ITALY",
  },
  {
    label: (
      <span>
        <img src={russia} width="20px" style={{ marginRight: "2px" }} /> RUSSIAN
      </span>
    ),
    value: "RUSSIAN",
  },

  {
    label: (
      <span>
        <img src={usFlag} width="20px" style={{ marginRight: "1px" }} /> ENGLISH
      </span>
    ),
    value: "ENGLISH",
  },
];

const languagesArray = {
  // sp: {
  //   label: "Spanish",
  //   flag: spain,
  // },
  odia: {
    label: "Odia",
    flag: Bharat,
  },
  // gr: {
  //   label: "German",
  //   flag: germany,
  // },
  // it: {
  //   label: "Italian",
  //   flag: italy,
  // },
  // rs: {
  //   label: "Russian",
  //   flag: russia,
  // },
  en: {
    label: "English",
    flag: usFlag,
  },
}


export default languagesArray;
