import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Button, Label, Input, Modal, ModalBody, ModalHeader } from "reactstrap"

import { callApi } from "helpers/api_helper"
import Select from "react-select"
import { withTranslation } from "react-i18next"

const EditPartnerStaffModal = ({
  modalOpen,
  toggleModal,
  user,
  getAllUsers,
  setSuccessMessage,
  setErrorMessage,
  roleOptions,
  partnerOptions,
  selectedPageSize,
  t
}) => {
  const [name, setName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [role, setRole] = useState("")
  const [selectedRole, setSelectedRole] = useState()
  const [selectedPartner, setSelectedPartner] = useState()
  const [errors, setErrors] = useState({})
  const [LoggedInuser, setLoggedInUser] = useState({})

  const validate = () => {
    try {
      const error = {}

      if (!name) {
        error.name = "User Name is required!"
      }
      if (typeof name !== "string") {
        error.name = "Please enter a valid name!"
      }
      if (!phoneNumber) {
        error.phoneNumber = "Phone Number is required!"
      }
      if (phoneNumber.length !== 10) {
        error.phoneNumber = "Enter a valid Phone Number!"
      }
      setErrors(error)
      return error
    } catch (err) {
      console.log(err)
    }
  }
  const handleSubmit = async () => {
    try {
      const error = await validate()
      if (Object.keys(error).length > 0) {
        return false
      }
      const config = {
        method: "post",
        url: "user/getUserRoutes/updateUser",
        data: {
          userName: name,
          phoneNumber: Number(phoneNumber),
          role: selectedRole.value ? selectedRole.value : user.role,
          partner:
            LoggedInuser.user?.role === "PARTNER"
              ? LoggedInuser?.user?._id
              : LoggedInuser.user?.role === "MANAGER"
                ? LoggedInuser?.user?.partner
                : selectedPartner?.value,
          userId: user?._id,
        },
      }

      const response = await callApi(config)
      if (response.data.code === 200) {
        toggleModal()
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        await getAllUsers(selectedPageSize, 1)
      } else {
        toggleModal()
        setErrorMessage(response.data.message)
        setTimeout(() => {
          setErrorMessage(null)
        }, 2000)
      }
    } catch (error) {
      toggleModal()
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
    }
  }

  useEffect(() => {
    if (user) {

      setName(user?.userName)
      setPhoneNumber(String(user?.phoneNumber))
      setSelectedRole(roleOptions?.filter(item => item.value === user.role))
      setSelectedPartner(
        partnerOptions?.filter(item => item.value === user?.partner?._id)
      )
    }
  }, [user])
  useEffect(() => {
    const fun = async () => {
      setLoggedInUser(JSON.parse(await localStorage.getItem("user")))
    }
    fun()
  }, [])

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        centered={true}
      >
        <ModalHeader toggle={toggleModal}>{t('Update Partner Staff')}</ModalHeader>
        <ModalBody>
          {!(
            LoggedInuser.user?.role === "PARTNER" ||
            LoggedInuser.user?.role === "MANAGER"
          ) && (
              <div className="my-2">
                <Label>{t('Select Partner')}</Label>
                <Select
                  isSearchable={false}
                  onChange={e => {
                    setSelectedPartner(e)
                  }}
                  options={partnerOptions}
                  classNamePrefix="select2-selection"
                  value={selectedPartner}
                />
                {errors.selectedPartner && (
                  <p className="text-danger">{errors.selectedPartner}</p>
                )}
              </div>
            )}
          <div className="form-group my-3">
            <Label className="form-label">{t('User Name')}</Label>
            <Input
              name="userName"
              className="form-control"
              placeholder="Enter User Name"
              type="text"
              onChange={e => setName(e.target.value)}
              value={name}
            />
            {errors.name && <p className="text-danger">{errors.name}</p>}
          </div>
          <div className="form-group my-3">
            <Label className="form-label">{t('Phone Number')}</Label>
            <Input
              name="phoneNumber"
              className="form-control"
              placeholder="Enter Phone Number"
              type="tel"
              onChange={e => setPhoneNumber(e.target.value)}
              value={phoneNumber}
            />
            {errors.phoneNumber && (
              <p className="text-danger">{errors.phoneNumber}</p>
            )}
          </div>
          <div className="my-2">
            <Label>{t('Role')}</Label>
            <Select
              isSearchable={false}
              onChange={e => {
                setSelectedRole(e)
              }}
              options={roleOptions}
              classNamePrefix="select2-selection"
              value={selectedRole}
              name="role"
            />
            {errors.selectedRole && (
              <p className="text-danger">{errors.selectedRole}</p>
            )}
          </div>
          <div className="d-flex justify-content-end align-items-center mt-4">
            <Button
              outline
              color="danger"
              className="mx-2"
              onClick={toggleModal}
            >
              {t('Cancel')}
            </Button>
            <Button onClick={handleSubmit} color="success">
              {t('Update User')}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}


EditPartnerStaffModal.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(EditPartnerStaffModal);
