import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';

class PieChart extends Component {

    generateColorCodes = (arrayLength) => {
        const colorCodes = [];
        const colorStep = 360 / arrayLength;

        for (let i = 0; i < arrayLength; i++) {
            const hue = Math.round(i * colorStep);
            const colorCode = `hsl(${hue}, 100%, 50%)`;
            colorCodes.push(colorCode);
        }

        return colorCodes;
    };

    render() {
        const data = {
            labels: this.props.labels,
            // [
            //     "Desktops",
            //     "Tablets"
            // ],
            datasets: [
                {
                    data: this.props.data,//[300, 180],
                    backgroundColor: //this.generateColorCodes(this.props.data.length),
                        [
                            "hsl(0, 100%, 50%)",
                            "hsl(45, 100%, 50%)",
                            "hsl(225, 100%, 50%)",
                            "hsl(315, 100%, 50%)"
                        ],

                    hoverBackgroundColor: //this.generateColorCodes(this.props.data.length),
                        [
                            "hsl(0, 100%, 50%)",
                            "hsl(45, 100%, 50%)",
                            "hsl(225, 100%, 50%)",
                            "hsl(315, 100%, 50%)"
                        ],
                    hoverBorderColor: "#fff"
                }]
        };

        const option = {
            maintainAspectRatio: false,
            responsive: true,
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                        var total = meta.total;
                        var currentValue = dataset.data[tooltipItem.index];
                        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                        return currentValue + ' (' + percentage + '%)';
                    },
                    title: function (tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    }
                }
            },
            legend: {

                display: true,
                position: "bottom",
                labels: {
                    font: {
                        size: '10px'
                    }
                }
            }
        }

        return (
            <React.Fragment>
                <Pie width={600} height={215} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default PieChart;   