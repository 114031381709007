import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SleeperIcon from 'pages/SeatIcons/SleeperIcon';
import SeaterIcon from 'pages/SeatIcons/SeaterIcon';
import { Button, CardHeader, Col, InputGroup, Label, Row } from 'reactstrap';
import Switch from "react-switch";
import { withTranslation } from 'react-i18next';

let grid = {
    // "_0_1": {
    //     "seatType": "seater",
    //     "seatNumber": "1"
    // },
    // "_1_1": {
    //     "seatType": "sleeper",
    //     "seatNumber": "2"
    // },
    // "_2_1": {
    //     "seatType": "sleeper",
    //     "seatNumber": "3"
    // },
    // "_3_1": {
    //     "seatType": "sleeper",
    //     "seatNumber": "4"
    // },
    // "_3_2": {
    //     "seatType": "sleeper",
    //     "seatNumber": "5"
    // },
    // "_2_2": {
    //     "seatType": "sleeper",
    //     "seatNumber": "6"
    // },
    // "_1_2": {
    //     "seatType": "sleeper",
    //     "seatNumber": "7"
    // },
    // "_0_2": {
    //     "seatType": "seater",
    //     "seatNumber": "8"
    // },
    // "_0_4": {
    //     "seatType": "seater",
    //     "seatNumber": "9"
    // },
    // "_1_4": {
    //     "seatType": "seater",
    //     "seatNumber": "10"
    // },
    // "_2_4": {
    //     "seatType": "seater",
    //     "seatNumber": "11"
    // },
    // "_3_4": {
    //     "seatType": "seater",
    //     "seatNumber": "12"
    // },
    // "_3_5": {
    //     "seatType": "seater",
    //     "seatNumber": "13"
    // },
    // "_2_5": {
    //     "seatType": "seater",
    //     "seatNumber": "14"
    // },
    // "_1_5": {
    //     "seatType": "seater",
    //     "seatNumber": "15"
    // },
    // "_0_5": {
    //     "seatType": "seater",
    //     "seatNumber": "16"
    // },
    // "_0_6": {
    //     "seatType": "seater",
    //     "seatNumber": "17"
    // },
    // "_1_6": {
    //     "seatType": "seater",
    //     "seatNumber": "18"
    // },
    // "_2_6": {
    //     "seatType": "seater",
    //     "seatNumber": "19"
    // },
    // "_3_6": {
    //     "seatType": "seater",
    //     "seatNumber": "20"
    // },
    // "_4_6": {
    //     "seatType": "seater",
    //     "seatNumber": "21"
    // },
    // "_4_5": {
    //     "seatType": "seater",
    //     "seatNumber": "22"
    // },
    // "_4_4": {
    //     "seatType": "seater",
    //     "seatNumber": "23"
    // },
    // "_4_2": {
    //     "seatType": "seater",
    //     "seatNumber": "24"
    // },
    // "_4_1": {
    //     "seatType": "seater",
    //     "seatNumber": "25"
    // },
    // "_4_3": {
    //     "seatType": "seater",
    //     "seatNumber": "26"
    // },
    // "_5_1": {
    //     "seatType": "seater",
    //     "seatNumber": "27"
    // }
}

let alreadyBooked_ = {

    // "_3_2": {
    //     "seatType": "sleeper",
    //     "seatNumber": "5"
    // },
    // "_2_2": {
    //     "seatType": "sleeper",
    //     "seatNumber": "6"
    // },
    // "_1_2": {
    //     "seatType": "sleeper",
    //     "seatNumber": "7"
    // },
    // "_0_2": {
    //     "seatType": "seater",
    //     "seatNumber": "8"
    // },
    // "_0_4": {
    //     "seatType": "seater",
    //     "seatNumber": "9"
    // },
    // "_4_2": {
    //     "seatType": "seater",
    //     "seatNumber": "24"
    // },
    // "_4_1": {
    //     "seatType": "seater",
    //     "seatNumber": "25"
    // },
    // "_4_3": {
    //     "seatType": "seater",
    //     "seatNumber": "26"
    // },
    // "_5_1": {
    //     "seatType": "seater",
    //     "seatNumber": "27"
    // }
}

const BusLayout = ({ t, getBusById, layerGrid, isSwitch = false, alreadyBooked = {}, switch1 = false, setSwitch1, data_attr = 0, setdata_attr, setSelectedSeats = null }) => {
    // setSelectedSeatIndex
    const seaterIcon = () => <i className='mdi mdi-seat-outline' />
    const [rows, setRows] = useState([])
    const [gridContent, setGridContent] = useState(layerGrid || {});

    const Seatersymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 11,
                    color: "#fff",
                    paddingLeft: 8
                }}
            >
                {" "}
                {t('Layer')} 1
            </div>
        );
    };

    const SleeperSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 11,
                    color: "#fff",
                    paddingRight: 8,
                }}
            >
                {" "}
                {t('Layer')} 2
            </div>
        );
    };

    useEffect(() => {
        if (layerGrid) {
            setGridContent(layerGrid)
        }
    }, [layerGrid])

    function handleSeatSelection(key) {



        if (gridContent[key].selected) {
            setGridContent({ ...gridContent, [key]: { seatType: gridContent[key].seatType, seatNumber: gridContent[key].seatNumber, selected: false } });
        } else {

            if (alreadyBooked[key]) { } else {

                setGridContent({ ...gridContent, [key]: { seatType: gridContent[key].seatType, seatNumber: gridContent[key].seatNumber, selected: true } });
            }

        }

    }

    useEffect(() => {

        if (gridContent && Object.keys(gridContent).length > 0) {

            let selSet = {}
            let eys = Object.keys(gridContent)
            for (let index = 0; index < eys.length; index++) {
                const key = eys[index];
                if (gridContent[key].selected) {
                    selSet[key] = gridContent[key]
                }

            }
            if (setSelectedSeats) {
                setSelectedSeats(selSet)
            }
        }
    }, [gridContent])

    function findLargestSmallest(arr) {
        if (arr.length === 0) {
            return null; // Return null for an empty array
        }

        let largest = arr[0];
        let smallest = arr[0];

        for (let i = 1; i < arr.length; i++) {
            if (arr[i] > largest) {
                largest = arr[i];
            }
            if (arr[i] < smallest) {
                smallest = arr[i];
            }
        }

        return {
            largest: largest || 0,
            smallest: smallest || 0
        };
    }
    function getLastDigitArray(obj) {
        let keys = Object.keys(obj);
        let lastDigitsArray = [];

        keys?.forEach(key => {
            let lastDigit = key.split("_")

            if (lastDigit[2]) {

                lastDigitsArray.push(parseInt(lastDigit[2]));
            }
        });

        return lastDigitsArray;
    }

    function getFirstDigitArray(obj) {
        let keys = Object.keys(obj);
        let lastDigitsArray = [];

        keys?.forEach(key => {
            let lastDigit = key.split("_")

            if (lastDigit[1]) {

                lastDigitsArray.push(parseInt(lastDigit[1]));
            }
        });

        return lastDigitsArray;
    }

    function setRowsFunction() {
        const rows_ = [];


        let allJs = getLastDigitArray({ ...gridContent })


        let bordersJs = findLargestSmallest(allJs)


        let allIs = getFirstDigitArray({ ...gridContent })



        for (let i = 0; i < 20; i++) {
            const cells = [];

            for (let j = 0; j < 10; j++) {
                const key = `_${i}_${j}`;
                let cellContent = null;

                if (gridContent[key] && gridContent[key]?.seatType == "seater") {
                    cellContent = gridContent[key] ? (
                        <SeaterIcon seatNo={gridContent[key]?.seatNumber} isBooked={alreadyBooked[key] ? true : false} isSelected={gridContent[key].selected ? true : false} />

                    ) : null;
                }
                if (gridContent[key] && gridContent[key]?.seatType == "sleeper") {

                    cellContent = gridContent[key] ? (

                        <SleeperIcon seatNo={gridContent[key]?.seatNumber} isBooked={alreadyBooked[key] ? true : false} isSelected={gridContent[key].selected ? true : false} />


                    ) : null;
                }
                if (bordersJs?.largest >= j && bordersJs?.smallest <= j && !cellContent && allIs.includes(i)) {
                    cellContent = <div className='m-4'></div>
                }

                // style={{ width: '65px', height: '65px', border: '0px solid black', padding: 0 }}
                cells.push(<td onClick={() => { handleSeatSelection(key) }} key={key} >
                    {cellContent}</td>);
            }
            rows_.push(<tr style={{ "border": "none", display: 'flex', justifyContent: 'center', alignItems: "center", width: '100%' }} key={i}>{cells}</tr>);
        }
        setRows(rows_)

    }

    useEffect(() => {
        if (gridContent) {
            setRowsFunction()
        }
    }, [gridContent])

    useEffect(() => {
        if (getBusById) {
            if (switch1) {
                getBusById(1)
            } else {
                getBusById(2)
            }

        }
    }, [switch1])

    return (
        <div className='w-100 m-0 p-0'  >
            <div className='card w-100' style={{ border: '1px solid black', borderRadius: '16px' }}>
                <div className='d-flex justify-content-between align-items-center card-header' style={{ width: "100%", height: "45px", borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
                    <div className='d-flex justify-content-center w-100 align-items-center'>
                        {
                            isSwitch &&
                            <Switch
                                borderRadius={10}
                                uncheckedIcon={<SleeperSymbol />}
                                checkedIcon={<Seatersymbol />}
                                onColor="#626ed4"
                                offColor='#02a499'
                                height={30}
                                width={85}
                                onChange={() => {
                                    setSwitch1(!switch1)
                                }}
                                checked={switch1}
                            />

                        }

                    </div>
                    <div className='text-end'>
                        <i className='mdi mdi-steering mdi-24px' />
                    </div>

                </div>
                <Row className='py-2'>
                    <Col xs={12}>

                        <div className='m-0 d-flex justify-content-center align-items-center'>
                            {/* <Table> */}
                            <table className='text-center' style={{ "border": "none" }}>
                                <tbody>{rows}</tbody>
                            </table>
                            {/* </Table> */}
                        </div>


                    </Col>
                    {
                        isSwitch &&
                        <Col xs={12}>
                            <div className='mx-5 d-flex justify-content-start align-items-start flex-column'>
                                <InputGroup>
                                    <div
                                        className="input-group-append"
                                        onClick={() => {
                                            if (data_attr === 0) {
                                                setdata_attr(0);
                                            }
                                            else {
                                                setdata_attr(data_attr - 1);
                                            }
                                        }}
                                    >
                                        <Button type="button" color="primary">
                                            <i className="mdi mdi-minus" />
                                        </Button>
                                    </div>
                                    <input
                                        type="number"
                                        className="form-control"

                                        value={data_attr}
                                        placeholder="number"
                                        readOnly
                                    />
                                    <div
                                        className="input-group-append"
                                        onClick={() => {
                                            setdata_attr(data_attr + 1);
                                        }}
                                    >
                                        <Button type="button" color="primary">
                                            <i className="mdi mdi-plus" />
                                        </Button>
                                    </div>
                                </InputGroup>

                            </div>
                            <div className='text-center'>
                                <Label size='sm'>{t('Extra Tickets')}</Label>
                            </div>
                        </Col>
                    }

                </Row>

            </div>
        </div>

    )
}

BusLayout.propType = {
    t: PropTypes.any
}
export default withTranslation()(BusLayout);