import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types';
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/tokenization.png"
import servicesIcon4 from "../../assets/images/services-icon/requirement.png"

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Alert,
  Spinner,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import BuyTokenModal from "./BuyTokenModal"
import { callApi } from "helpers/api_helper"
import { formatDate } from "helpers/Utils/formatTime"
import { withTranslation } from "react-i18next"

const RechargeTickets = (props) => {
  const [buyTokenModalOpen, setBuyTokenModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [subscriptionData, setSubscriptionData] = useState({})
  const [bookingReqList, setBookingReqList] = useState([])
  const [averageToken, setAverageToken] = useState("")

  const navigate = useNavigate()

  const getSubscription = async () => {
    try {
      const config = {
        method: "post",
        url: "subscription/getSubscriptionRoutes/getSubscriptionByUserId",
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setSubscriptionData(response.data.data)
        setIsLoading(false)
      } else {
        navigate("/login")
      }
    } catch (error) {
      console.log({ error })
    }
  }
  const getAllBookingRequests = async () => {
    try {
      const config = {
        method: "post",
        url: "subscription/getSubscriptionRoutes/getAllSubscriptionTxn",
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setBookingReqList(response.data.data)
      }
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    const func = async () => {
      setIsLoading(true)
      await getSubscription()
      await getAllBookingRequests()
      const average = JSON.parse(await localStorage.getItem("average"))?.average
      setAverageToken(average)
    }
    func()
  }, [])

  useEffect(() => {

  }, [errorMessage, successMessage])

  document.title = `${props.t("Tokens")} | OneTKT`

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="" color="danger" />
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="OneTKT" breadcrumbItem="Recharge Tickets" />

            <Row className="mt-4">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                {errorMessage && errorMessage ? (
                  <Alert color="danger">{errorMessage}</Alert>
                ) : null}
                {successMessage ? (
                  <Alert color="success">{successMessage}</Alert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Card className="mini-stat bg-primary text-white">
                  <CardBody>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon3} alt="" />
                      </div>
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                        {props.t('Tokens Remaining')}
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {/* {subscriptionData?.tokens || 0} */}
                        {subscriptionData?.tokens
                          ? subscriptionData?.tokens
                          : 0}
                        <i className="mdi mdi-arrow-up text-success ms-2"></i>
                      </h4>
                      {/* <div className="mini-stat-label bg-success">
                          <p className="mb-0">+ 12%</p>
                        </div> */}
                    </div>
                    <div className="mb-4">
                      <div className="float-start mini-stat-img me-4">
                        <img src={servicesIcon4} alt="" />
                      </div>
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                        {props.t('Avg Daily Requirement')}
                      </h5>
                      <h4 className="fw-medium font-size-24">
                        {averageToken}{" "}
                        <i className="mdi mdi-arrow-up text-success ms-2"></i>
                      </h4>
                      {/* <div className="mini-stat-label bg-success">
                          <p className="mb-0">+ 12%</p>
                        </div> */}
                    </div>
                    {/* <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={servicesIcon2} alt="" />
                        </div>
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Token Expires In
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          {subscriptionData?.expiresIn}{" "}
                          <i className="mdi mdi-arrow-up text-success ms-2"></i>
                        </h4>
                        <div className="mini-stat-label bg-success">
                          <p className="mb-0">+ 12%</p>
                        </div>
                      </div> */}
                  </CardBody>
                  <Button
                    className="btn btn-primary btn-lg waves-effect waves-light"
                    onClick={() => setBuyTokenModalOpen(true)}
                    color="success"
                  >
                    <strong>{props.t('Buy Tokens')}</strong>
                  </Button>
                </Card>
              </Col>

              <Col xs={12} md={8}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">{props.t('Latest Transaction')}</h4>
                    <div className="table-responsive">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">{props.t('Subscription Id')}</th>
                            <th scope="col">{props.t('Token')}</th>
                            <th scope="col">{props.t('Date')}</th>
                            <th scope="col">{props.t('Amount')}</th>
                            <th scope="col">{props.t('Status')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr></tr>
                          {bookingReqList.map(data => (
                            <tr key={data._id}>
                              <td>{data.subscriptionRequestId}</td>
                              <td>{data.tokens}</td>
                              <td>{formatDate(data.createdAt)}</td>
                              <td>{data.amount}</td>
                              <td>
                                <span
                                  className={
                                    data.status === "SUCCESS"
                                      ? "badge bg-success"
                                      : data.status === "pending"
                                        ? "badge bg-warning"
                                        : data.status === "cancel"
                                          ? "badge bg-danger"
                                          : ""
                                  }
                                >
                                  {data.status}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      <BuyTokenModal
        modalOpen={buyTokenModalOpen}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
        getSubscription={getSubscription}
        toggleModal={() => setBuyTokenModalOpen(!buyTokenModalOpen)}
      />
    </React.Fragment>
  )
}
RechargeTickets.propType = {
  t: PropTypes.any
}
export default withTranslation()(RechargeTickets);
