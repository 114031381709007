import { callApi } from 'helpers/api_helper';
import React, { useState, useEffect } from 'react';
import './CountDownTimer.css'

const CountdownTimer = ({ targetDate, userId }) => {
    const [timeDifference, setTimeDifference] = useState(0);
    // const [message, setMessage] = useState('');

    useEffect(() => {

        const startDate = new Date(targetDate);
        startDate.setHours(startDate.getHours() + 48);
        if (targetDate) {
            const interval = setInterval(() => {
                const currentDate = new Date();
                const diff = startDate - currentDate;
                if (diff <= 0) {
                    clearInterval(interval);
                    setTimeDifference(0);
                } else {
                    setTimeDifference(diff / 1000);
                }
            }, 1000);

            return () => clearInterval(interval); // Clean up interval on component unmount
        }
    }, []);

    useEffect(() => {
        const func = async () => {
            if (timeDifference <= 0) {
                const config = {
                    method: 'post',
                    url: 'subscription/getSubscriptionRoutes/activeSubscription',
                    data: {
                        userId
                    }
                }
                const response = await callApi(config);
                if (response.data.code === 200) {
                    // setMessage('Your Subscription has been activated. Enjoy booking.')
                    // setTimeout(()=>{
                    //     setMessage('')
                    // },2000)
                }
            }
        }
        func();
    }, [timeDifference])

    const hours = Math.floor(timeDifference / 3600);
    const minutes = Math.floor((timeDifference % 3600) / 60);
    const seconds = Math.floor(timeDifference % 60);

    return (
        <div>
            {
                timeDifference !== 0 &&
                <div className='text-center fw-bold mb-4'>
                    Your subscription is about to be activated! In just <h3 className='text-danger'>{hours.toString().padStart(2, '0')} : {minutes.toString().padStart(2, '0')} : {seconds.toString().padStart(2, '0')} Hr</h3>
                </div>
                // :
                // <p>{message}</p>
            }
        </div>
    );
};

export default CountdownTimer;
