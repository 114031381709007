import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useSearchParams } from "react-router-dom";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

import onedeta from "../../assets/images/onedeata-dark.png"
import "./BookingDetails.css";
import { callApi } from 'helpers/api_helper';
import { formatDate } from 'helpers/Utils/formatTime';
import { withTranslation } from "react-i18next";

const BookingDetails = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    let [bookingRequest, setBookingRequest] = useState();
    let [seatNumber, setSeatNumber] = useState('');

    useEffect(() => {
        const func = async () => {
            const bookingRequestId = searchParams.get('bookingRequestId');
            const config = {
                url: 'bus/getBookingRequestRoutes/getAllBookingRequestById',
                method: 'post',
                data: {
                    id: bookingRequestId
                }
            }
            const response = await callApi(config);
            if (response.data.code === 200) {
                setBookingRequest(response.data.data);
                const seats = Object.values(response.data.data.selectedSeats);
                const seatNumbers = seats.map(
                    (item, i) =>
                        `${item.seatNumber} ${i !== seats.length - 1 ? ", " : ""}`
                );
                setSeatNumber(seatNumbers);
            }
        }
        func();
    }, [])

    return (
        <div className="page-content">
            <Container fluid>
                <Card className="mt-2 ">
                    <img
                        src={onedeta}
                        alt="logo is not present"
                        className="img-fluid m-2"
                        style={{ maxWidth: "175px" }}
                    />
                    <div className="mt-3 ms-3">
                        <dt>GST : 21AAJCt2090FIZM</dt>
                        <dt>CIN : U729000R2022PTC039033 </dt>
                        <dt>PAN : AAJCT2090F</dt>
                        <dt>TAN : BBNT02192B </dt>
                        <dt>{props.t('Legal Name')} : TicketCo Solutions Private Limited</dt>
                    </div>
                    <div className="divider"></div>

                    <CardBody className="mt-2">

                        <Row>

                            <Col xs={6} md={4} className='my-2'>
                                {props.t('From')}
                                <div style={{ fontWeight: "bold" }}>{bookingRequest?.startPoint}</div>
                            </Col>
                            <Col xs={6} md={4} className='my-2'>
                                {props.t('To')}
                                <div style={{ fontWeight: "bold" }}>{bookingRequest?.endPoint}</div>
                            </Col>
                            <Col xs={6} md={4} className='my-2'>
                                {props.t('Date')}
                                <div style={{ fontWeight: "bold" }}>{formatDate(bookingRequest?.date)}</div>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="row">
                            <Col xs={6} md={4} className='my-2'>
                                {props.t('Bus Name')}
                                <div style={{ fontWeight: "bold" }}>{bookingRequest?.busName}</div>
                            </Col>
                            <Col xs={6} md={4} className='my-2'>
                                {props.t('Bus Phone Number')}
                                <div style={{ fontWeight: "bold" }}>{bookingRequest?.bus?.phoneNumber}</div>
                            </Col>
                            <Col xs={6} md={4} className='my-2'>
                                {props.t('Bus Registration No.')}
                                <div style={{ fontWeight: "bold" }}>{bookingRequest?.busRegistrationNumber}</div>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="row">
                            <Col xs={6} md={4} className='my-2'>
                                {props.t('Passenger Phone No.')}
                                <div style={{ fontWeight: "bold" }}>{bookingRequest?.phoneNumber}</div>
                            </Col>
                            <Col xs={6} md={4} className='my-2'>
                                {props.t('Seat No.')}
                                <div style={{ fontWeight: "bold" }}>{seatNumber}</div>
                            </Col>

                        </Row>
                        <hr />

                    </CardBody>

                    <div className="tc-heading">T&amp;C</div>
                    <ol>
                        <li>
                            {props.t('Valid Ticket')}:
                            <ul>
                                <li>{props.t('Your M-ticket is Valid for booked date and route')}</li>
                            </ul>
                        </li>
                        <li>
                            {props.t('Cancellation and Refunds')}:
                            <ul>
                                <li>{props.t('check company policies for Cancellation and refund')}</li>
                            </ul>
                        </li>
                        <li>
                            {props.t('Responsibility')}:
                            <ul>
                                <li>{props.t('Keep Your mobile charged and guard your m-ticket')}</li>
                            </ul>
                        </li>
                        <li>
                            {props.t('Fare and ID')}:
                            <ul>
                                <li>{props.t('Ensure correct fare; bring valid ID for discounts')}</li>
                            </ul>
                        </li>
                        <li>
                            {props.t('Conduct and Security')}:
                            <ul>
                                <li>{props.t('Obey rules; disruptive behavior can lead to removal')}</li>
                            </ul>
                        </li>
                    </ol>
                </Card>
            </Container>
        </div>
    )
}

BookingDetails.propType = {
    t: PropTypes.any
}
export default withTranslation()(BookingDetails);
