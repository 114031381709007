// import React, { useEffect, useState } from "react"
// import {
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
// } from "reactstrap"
// import { get, map } from "lodash"
// import { withTranslation } from "react-i18next"
// import Select from "react-select"
// //i18n
// import i18n from "../../../i18n"
// import languages from "common/languages"

// const LanguageDropdown = () => {
//   // Declare a new state variable, which we'll call "menu"
//   // const [selectedLang, setSelectedLang] = useState("")
//   // const [menu, setMenu] = useState(false)
//   const [selectedLanguage, setSelectedLanguage] = useState()

//   // useEffect(() => {
//   //   const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
//   //   setSelectedLang(currentLanguage)
//   // }, [])

//   useEffect(() => {
//     const fun = async () => {
//       const user = JSON.parse(await localStorage.getItem("user"))?.user

//       const language = languages.filter((item) => {
//         return item?.value?.toLowerCase() === user?.language?.toLowerCase()
//       })

//       setSelectedLanguage(language)
//     }
//     fun()

//   }, [])
//   // const languagesArray = languages.map(lang => ({
//   //   label: lang.label,
//   //   value: lang.value,
//   // }))

//   // const changeLanguageAction = lang => {
//   //   //set language as i18n
//   //   i18n.changeLanguage(lang)
//   //   localStorage.setItem("I18N_LANGUAGE", lang)
//   //   setSelectedLang(lang)
//   // }
//   // Log the language value here to check if it's as expected
//   // const selectedLanguageObject = languagesArray.find(
//   //   lang => lang.value === Language?.user?.language
//   // )
//   // console.log(selectedLanguageObject)

//   // const toggle = () => {
//   //   setMenu(!menu)
//   // }
//   const handleLanguage = (e) => {
//     console.log(e)
//     setSelectedLanguage(e)
//   }

//   return (
//     // <>
//     //   {/* <Dropdown
//     //     isOpen={menu}
//     //     toggle={toggle}
//     //     className="d-md-block ms-2"
//     //     value={Language?.user?.language}
//     //   >
//     //     <DropdownToggle className="btn header-item waves-effect" tag="button">
//     //       <img
//     //         src={get(languages, `${selectedLang}.flag`)}
//     //         alt="Veltrix"
//     //         height="16"
//     //         className="me-2"
//     //       />{" "}
//     //       {get(languages, `${selectedLang}.label`)}{" "}
//     //       <span className="mdi mdi-chevron-down"></span>
//     //     </DropdownToggle>
//     //     <DropdownMenu className="language-switch dropdown-menu-end">
//     //       {map(Object.keys(languages), key => (
//     //         <DropdownItem
//     //           key={key}
//     //           onClick={() => changeLanguageAction(key)}
//     //           className={`notify-item ${
//     //             selectedLang === key ? "active" : "none"
//     //           }`}
//     //         >
//     //           <img
//     //             src={get(languages, `${key}.flag`)}
//     //             alt="Veltrix"
//     //             className="me-2"
//     //             height="12"
//     //           />
//     //           <span className="align-middle">
//     //             {get(languages, `${key}.label`).toUpperCase()}
//     //           </span>
//     //         </DropdownItem>
//     //       ))}onChange={selectedOption => {
//     // onChange={selectedOption => {
//     //   changeLanguageAction(selectedOption.value)
//     // }}
//     //     </DropdownMenu>
//     //   </Dropdown> */}
//     // </>
//     <Select
//       isSearchable={false}
//       className="mt-3"
//       options={languages}
//       value={selectedLanguage}
//       onChange={selectedOption => {
//         // changeLanguageAction(selectedOption.value)
//         handleLanguage(selectedOption)
//       }}
//     />
//   )
// }

// export default LanguageDropdown;

import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import { withTranslation } from "react-i18next"

//i18n
import i18n from "../../../i18n"
import languages from "common/languages"

const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("")
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    setSelectedLang(currentLanguage)
  }, [])

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
    setSelectedLang(lang)
  }

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-none d-md-block ms-2">
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={get(languages, `${selectedLang}.flag`)}
            alt="Veltrix"
            height="16"
            className="me-2"
          />
          {" "}{get(languages, `${selectedLang}.label`)}
          {" "}<span className="mdi mdi-chevron-down"></span>
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"
                }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Veltrix"
                className="me-2"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(LanguageDropdown)