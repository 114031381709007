import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import Select from "react-select"
import PropTypes from 'prop-types';
import { callApi } from "helpers/api_helper"
import Upload from "helpers/AWS_upload"
import { withTranslation } from "react-i18next";

const EditBusModal = ({
  modalOpen,
  toggleModal,
  selectedBus,
  setErrorMessage,
  setSuccessMessage,
  busTypeOptions,
  getAllBuses,
  options,
  t
}) => {
  const [acOrNonAc, setAcOrNonAc] = useState(null)
  const [busName, setBusName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [alternateNumber, setAlternateNumber] = useState("")
  const [busRegistrationNumber, setBusRegistrationNumber] = useState("")
  const [errors, setErrors] = useState({})
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [partnerOptions, setPartnerOptions] = useState([])

  const [selectedPartner, setSelectedPartner] = useState()
  const [LoggedInuser, setLoggedInUser] = useState({})

  const validate = () => {
    let error = {}
    if (LoggedInuser.user?.role !== "PARTNER") {
      // if (!selectedPartner || !selectedPartner.value) {
      //   error.selectedPartner = "Partner Name Required!"
      // }
    }
    if (!busName) {
      error.busName = "Bus Name Required!"
    }
    if (!busRegistrationNumber) {
      error.busRegistrationNumber = "Bus Registration Number Required!"
    }
    if (!phoneNumber) {
      error.phoneNumber = "Phone Number Required!"
    }
    if (!alternateNumber) {
      error.alternateNumber = "Alternate Number Required!"
    }
    // if (!acOrNonAc.value) {
    //   error.acOrNonAc = "Please choose one option!"
    // }
    setErrors(error)
    return error
  }

  const getAllUsers = async () => {
    const config = {
      method: "post",
      url: "user/getUserRoutes/getAll",
    }
    const response = await callApi(config)
    if (response.data.code === 200) {
      // setUsers(
      //   response.data.data.filter(
      //     item =>
      //       item.role === "BUSINESS_DEVELOPMENT_MANAGER" ||
      //       item.role === "SITE_MANAGER" ||
      //       item.role === "PARTNER"
      //   )
      // )
      const tempArr = response.data.data.map(item => {
        if (item?.status === "ASSIGNED") {
          return item?._id
        }
      })
      const userArr = response.data.data.map(item => {
        return {
          label: item.userName,
          value: item._id,
          data: item,
        }
      })

      setPartnerOptions(userArr.filter(item => item.data.role === "PARTNER"))
    }
  }

  const handleSubmit = async () => {
    try {
      const error = await validate()
      if (Object.keys(error).length > 0) {
        return false
      }

      const config = {
        method: "post",
        url: "bus/getBusRoutes/updateBus",
        data: {
          id: selectedBus._id,
          busName,
          busRegistrationNumber,
          acOrNonAc: acOrNonAc?.value
            ? acOrNonAc?.value
            : selectedBus.acOrNonAc,
          phoneNumber,
          alternateNumber,
          uploadDocuments: uploadedFiles,
          partner:
            LoggedInuser.user?.role === "PARTNER"
              ? LoggedInuser?.user?._id
              : selectedPartner?.value,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setErrorMessage(null)

        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        await getAllBuses()
        await getAllUsers()

        toggleModal()
      } else {
        setSuccessMessage(null)
        setErrorMessage(response.data.message)
        setTimeout(() => {
          setErrorMessage(null)
        }, 2000)

        toggleModal()
      }
    } catch (error) {
      console.log(error)
      setSuccessMessage(null)
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      toggleModal()
    }
  }

  const handleChange = async e => {

    const files = e.target.files
    const newUploads = [...uploadedFiles]
    for (let index = 0; index < files.length; index++) {
      const element = files[index]
      const url = await Upload(element)

      newUploads.push({
        url: url,
        name: element.name,
      })
    }

    // setPartnerOptions(userArr.filter(item => item.data.role === "PARTNER"))
    setUploadedFiles(newUploads)

    // setUploadedFiles(files.map((item) => {
    //     return URL.createObjectURL(item);
    // }))
  }

  const handleDeleteDocument = async index => {

    const docs = [...uploadedFiles]
    const newDocs = docs.filter((item, i) => i !== index)

    setUploadedFiles(newDocs)
  }
  // useEffect(async () => {
  //   await getAllUsers()
  // }, [])
  // const resetFormFields = () => {
  //   setSelectedPartner(null)
  //   setBusName("")
  //   setBusRegistrationNumber("")
  //   setPhoneNumber("")
  //   setAlternateNumber("")
  //   setAcOrNonAc(null)
  //   setUploadedFiles([])
  // }
  useEffect(() => {
    if (Object.keys(selectedBus).length > 0) {
      setAcOrNonAc(
        busTypeOptions.filter(item => selectedBus?.acOrNonAc === item.value)
      )
      setBusName(selectedBus?.busName)
      setPhoneNumber(selectedBus?.phoneNumber)
      setAlternateNumber(selectedBus?.alternateNumber)
      setBusRegistrationNumber(selectedBus?.busRegistrationNumber)

      setUploadedFiles(selectedBus?.uploadDocuments)
      setSelectedPartner(
        options.filter(item => item.value === selectedBus?.partner[0]?._id)
      )
    }


  }, [selectedBus])
  useEffect(() => {
    const fun = async () => {
      setLoggedInUser(JSON.parse(await localStorage.getItem("user")))
    }
    fun()
  }, [])

  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} centered={true} size="lg">
      <ModalHeader toggle={toggleModal}>
        <Label>{t('Update A Bus')}</Label>
      </ModalHeader>
      <ModalBody>
        {LoggedInuser.user?.role !== "PARTNER" && (
          <div className="my-2">
            <Label>{t('Select Partner')}</Label>
            <Select
              isSearchable={false}
              onChange={e => {
                setSelectedPartner(e)
              }}
              options={options}
              classNamePrefix="select2-selection"
              value={selectedPartner}
            />

            {errors.selectedPartner && (
              <p className="text-danger">{errors?.selectedPartner}</p>
            )}
          </div>
        )}

        <div className="my-2">
          <Label>{t('Bus Name')}</Label>
          <Input
            placeholder="Bus Name"
            value={busName}
            onChange={e => setBusName(e.target.value)}
          />
          {errors.busName && <p className="text-primary">{errors?.busName}</p>}
        </div>
        <div className="my-2">
          <Label>{t('Bus Registration Number')}</Label>
          <Input
            placeholder="Bus Registration Number"
            value={busRegistrationNumber}
            onChange={e => setBusRegistrationNumber(e.target.value)}
          />
          {errors.busRegistrationNumber && (
            <p className="text-primary">{errors?.busRegistrationNumber}</p>
          )}
        </div>
        <div className="my-2">
          <Label>{t('Bus Contact Number')}</Label>
          <Input
            type="tel"
            placeholder="Bus Contact Number"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
          />
          {errors.phoneNumber && (
            <p className="text-primary">{errors?.phoneNumber}</p>
          )}
        </div>
        <div className="my-2">
          <Label>{t('Alternate Contact Number')}</Label>
          <Input
            placeholder="Alternate Contact Number"
            type="tel"
            value={alternateNumber}
            onChange={e => setAlternateNumber(e.target.value)}
          />
          {errors.alternateNumber && (
            <p className="text-primary">{errors?.alternateNumber}</p>
          )}
        </div>
        <div className="my-2">
          <Label>{t('Upload Documents')}</Label>
          <Input
            type="file"
            multiple
            accept="image/*"
            placeholder="Upload Documents"
            onChange={handleChange}
          />
          <Row>
            {uploadedFiles.length > 0
              ? uploadedFiles.map((item, index) => {

                return (
                  <Col xs={3} className="my-2" key={item?.url}>
                    <Row>
                      <Col xs={8}>
                        <div
                          // href={item?.url}
                          // target="_blank"
                          key={`file${index}`}
                          className="d-flex justify-content-start align-items-center flex-column"
                        >
                          <img
                            src={item?.url}
                            alt={`file${index}`}
                            style={{
                              height: "80px",
                              width: "80px",
                              cursor: "pointer",
                            }}
                          />
                          <span>{item?.name}</span>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <Button
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => handleDeleteDocument(index)}
                        >
                          <i className="mdi mdi-delete" />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )
              })
              : null}
          </Row>
        </div>
        <div className="my-2">
          <Label>{t('AC / Non-AC')}</Label>
          <Select
            isSearchable={false}
            options={busTypeOptions}
            placeholder="Choose Bus Type"
            value={acOrNonAc}
            onChange={e => setAcOrNonAc(e)}
          />

          {errors.acOrNonAc && (
            <p className="text-danger">{errors?.acOrNonAc}</p>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center align-items-center">
          <Button color="danger" outline className="mx-1" onClick={toggleModal}>
            {t('Close')}
          </Button>
          <Button
            color="primary"
            outline
            className="mx-1"
            onClick={handleSubmit}
          >
            {t('Submit')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
EditBusModal.propType = {
  t: PropTypes.any
}
export default withTranslation()(EditBusModal);
