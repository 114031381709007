import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  Spinner,
  Alert,
} from "reactstrap"
import Select from "react-select"
import BusLayout from "./BusLayout"
import { callApi } from "helpers/api_helper"
import { capitalCase } from "change-case"
import { useNavigate } from "react-router-dom"
import { currentTime } from "helpers/Utils/formatTime"
import moment from "moment"
import ViewBookingByBusModal from "pages/Modals/ViewBookingByBusModal"
import { withTranslation } from "react-i18next"

const Booking = (props) => {
  const navigate = useNavigate();

  const [busList, setBusList] = useState([]);
  const [errors, setErrors] = useState({});
  const [routeList, setRouteList] = useState([]);
  const [selectedBus, setSelectedBus] = useState();
  const [selectedRoute, setSelectedRoute] = useState();
  const [activeLayer, setActiveLayer] = useState("Seater");
  const [allRoutes, setAllRoutes] = useState([]);
  const [selectedStartStation, setSelectedStartStation] = useState();
  const [selectedEndPoint, setSelectedEndPoint] = useState();
  const [startPointList, setStartPointList] = useState([]);
  const [endPointList, setEndPointList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [data_attr, setdata_attr] = useState(0);
  const [switch1, setSwitch1] = useState(true);
  const [sleeperGrid, setSleeperGrid] = useState(true);
  const [seaterGrid, setSeaterGrid] = useState(true);
  const [selectedSeats, setSelectedSeats] = useState({});
  const [totalAmount, setTotalAmount] = useState("");
  const [totalRcvAmount, setTotalRcvAmount] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [date, setDate] = useState(moment(new Date())?.format("YYYY-MM-DD"));
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [alreadyBooked, setAlreadyBooked] = useState({});
  const [bookingReqList, setBookingReqList] = useState([]);
  const [viewBookingModal, setViewBookingModalOpen] = useState(false);
  const [layerLevel, setLayerLevel] = useState(1);

  const getBusById = async (level) => {
    try {
      setSeaterGrid({})
      setSleeperGrid({})
      setLayerLevel(level);
      const endPointIndex = endPointList.indexOf(selectedEndPoint?.value)
      const startPointIndex = endPointList.indexOf(selectedStartStation?.value)
      let busData = {
        method: "post",
        url: "bus/getBusRoutes/getBusSeats",
        data: {
          id: selectedBus?.value,
          date: date,
          startPoint: selectedStartStation?.value,
          endPoint: selectedEndPoint?.value,
          startPointList: startPointList.slice(
            startPointList.indexOf(selectedStartStation?.value)
          ),
          endPointList: endPointList.slice(startPointIndex + 1),
          layerLevel: level
        },
      }
      let response = await callApi(busData)
      if (response.data.code === 200) {
        // setAllRoutes(response?.data?.data?.routeId)
        // let a = response?.data?.data?.routeId
        // const temp = a?.map((x, i) => {
        //   return {
        //     label: `${x?.startPoint?.station}(${currentTime(x?.startPoint?.time)}) - ${x?.endPoint?.station}(${currentTime(x?.endPoint?.time)})`,
        //     value: x?._id,
        //     key: x,
        //   }
        // })

        // setRouteList(temp);


        setAlreadyBooked(response.data.bookedSeats)
        if (level === 1) {
          setSeaterGrid(
            response.data.data.layerId[level - 1].availableSeats
              ? response.data.data.layerId[level - 1].availableSeats
              : {}
          )
        } else if (level === 2) {
          setSleeperGrid(
            response.data.data.layerId[level - 1].availableSeats
              ? response.data.data.layerId[level - 1].availableSeats
              : {}
          )
        } else {
          setSeaterGrid({})
          setSleeperGrid({})
        }
      }

    } catch (err) {
      console?.log(err)
    }
  }
  const [busLayoutFunctionState, setBusLayoutFunctionState] = useState(
    <BusLayout getBusById={getBusById} />
  )
  const getAllBuses = async () => {
    try {
      const config = {
        method: "post",
        url: "bus/getBusRoutes/getAllActiveBuses",
      }
      const response = await callApi(config)
      if (response?.data?.code === 200) {

        const temp = response?.data?.data?.map((x, i) => {
          return {
            label: x?.busRegistrationNumber,
            value: x?._id,
            key: x,
          }
        })
        setBusList(temp)
      } else {
        navigate("/login")
      }
    } catch (error) {
      console?.log(error)
    }
  }

  const getAllRoutes = async () => {
    try {
      const config = {
        method: "post",
        url: "busRoutes/getBus_Routes/getAllRoutes",
      }
      const response = await callApi(config)
      if (response?.data?.code === 200) {

        setAllRoutes(response?.data?.data)
        let a = response?.data?.data
        const temp = a?.map((x, i) => {
          return {
            label: `${x?.startPoint?.station}(${currentTime(
              x?.startPoint?.time
            )}) - ${x?.endPoint?.station}(${currentTime(x?.endPoint?.time)})`,
            value: x?._id,
            key: x,
          }
        })

        setRouteList(temp)
      } else {
        navigate("/login")
      }
    } catch (error) {
      console?.log(error)
    }
  }

  //Date Format Funtion

  const formatDate = value => {
    return moment(value)?.format("DD/MM/YYYY")
  }

  const validate = () => {
    const error = {}
    if (!selectedBus) {
      error.bus = "Please select a bus!"
    }
    if (!selectedRoute) {
      error.route = "Please select a Route!"
    } else if (selectedSeats && Object.keys(selectedSeats).length === 0) {

      error.seatNo = "Please choose a seat to Book!"
    }
    if (!selectedStartStation) {
      error.selectedStartStation = "Please select a start point!"
    }
    if (!selectedEndPoint) {
      error.selectedEndPoint = "Please select a end point!"
    }
    setErrors(error)
    return error
  }

  const validate2 = () => {
    const error = {}

    if (totalAmount === 0) {
      error.totalAmount = "Enter a valid amount!"
    } else if (phoneNumber === "" || !phoneNumber) {
      error.phoneNumber = "Enter your Phone Number!"
    } else if (isNaN(phoneNumber)) {
      error.phoneNumber = "Enter a valid Phone Number!"
    }
    setErrors(error)
    return error
  }

  const handleBook = async () => {

    try {
      const err = await validate()

      if (Object.keys(err).length > 0) {
        return false
      }
      setIsBookingModalOpen(!isBookingModalOpen)
    } catch (error) {
      console.log({ error })
    }
  }

  const handleBookTicket = async () => {

    try {
      setIsLoading(true);
      const err = await validate2();

      if (Object.keys(err).length > 0) {
        return false
      }
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/createBookingRequest",
        data: {
          phoneNumber,
          extraSeats: data_attr,
          bus: selectedBus?.value,
          route: selectedRoute?.value,
          startPoint: selectedStartStation.value,
          endPoint: selectedEndPoint.value,
          busRegistrationNumber: selectedBus.key.busRegistrationNumber,
          busName: selectedBus.key.busName,
          amountDue: totalAmount - totalRcvAmount,
          totalAmount,
          date: date,
          totalReceivedAmount: totalRcvAmount,
          selectedSeats,
          layerLevel
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setIsBookingModalOpen(!isBookingModalOpen);
        await resetFields();;
        setIsLoading(false);
      } else {
        setSuccessMessage(null)
        setErrorMessage(response.data.message)
        setTimeout(() => {
          setErrorMessage(null)
        }, 2000)
        setIsBookingModalOpen(!isBookingModalOpen)
      }
      setSelectedStartStation(null)
      setSelectedEndPoint(null);
      setIsLoading(false);
    } catch (error) {
      console.log({ error })
      setSuccessMessage(null)
      setErrorMessage("Something Went wrong!")
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      setIsBookingModalOpen(!isBookingModalOpen);
      setIsLoading(false);
    }
  }

  const getAllBookingRequests = async () => {
    try {
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/getAllBookingRequestByBus",
        data: {
          bus_id: selectedBus?.value,
          date: date,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setBookingReqList(response.data.data)
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const handleStartAndEndPoints = route => {

    let start = route.label.split(" - ")[0]
    let end = route.label.split(" - ")[1]

    // let specifiedRoute = allRoutes.filter(
    //   x => x.startPoint.station === start
    // )[0]
    let startWayRoutes = [start, ...route?.key?.viaRoute.map(x => x.station)]
    let endWayRoutes = [...route?.key?.viaRoute.map(x => x.station), end]

    setSelectedRoute(route)
    // getBusById(route?.value)
    setStartPointList(startWayRoutes)
    setEndPointList(endWayRoutes)
  }

  const handleBusChange = async e => {
    setAllRoutes(e.key.routeId)
    const temp = e.key.routeId?.map((x, i) => {
      return {
        label: `${x?.startPoint?.station} - ${x?.endPoint?.station}`,
        value: x?._id,
        key: x,
      }
    })
    setRouteList(temp)
  }

  const resetFields = () => {
    try {
      setTotalAmount('')
      setTotalRcvAmount('')
      setPhoneNumber('')
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {

    getBusById(1)
  }, [selectedEndPoint])

  useEffect(() => {
    // fetchBusList();
    const func = async () => {
      const user = JSON.parse(await localStorage.getItem("user"))

      setPhoneNumber(phoneNumber)
    }
    func()
    getAllBuses()
    // getAllRoutes()
    // getBusById();
  }, [])

  useEffect(() => {
    let gridToPass = {}

    if (activeLayer == "Sleeper") {
      gridToPass = sleeperGrid
    }
    if (activeLayer == "Seater") {
      gridToPass = seaterGrid
    }

    setBusLayoutFunctionState(
      <BusLayout
        switch1={switch1}
        setSwitch1={setSwitch1}
        layerGrid={gridToPass}
        isSwitch={true}
        data_attr={data_attr}
        setdata_attr={setdata_attr}
        setSelectedSeats={setSelectedSeats}
        alreadyBooked={alreadyBooked}
        getBusById={getBusById}
      />
    )
  }, [activeLayer, data_attr, seaterGrid, sleeperGrid])

  useEffect(() => {
    if (switch1) {
      setActiveLayer("Seater")
    } else {
      setActiveLayer("Sleeper")
    }
  }, [switch1])

  useEffect(() => {

  }, [selectedSeats])

  useEffect(() => {
    getAllBookingRequests()

    getBusById(1)
  }, [selectedBus])

  document.title = `${props.t("Booking")} | OneTKT`
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {errorMessage && errorMessage ? (
            <Alert color="danger">{errorMessage}</Alert>
          ) : null}
          {successMessage ? (
            <Alert color="success">{successMessage}</Alert>
          ) : null}

          <Row>
            <Col xs={12} md={5}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12} className="mb-1">
                      <div className="col-md-12">
                        <input
                          className="form-control"
                          type="date"
                          // defaultValue={date}
                          value={date}
                          onChange={e => setDate(e.target.value)}
                          id="example-date-input"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className="mb-1">
                      <Label>{props.t('Select Bus')}</Label>
                      <Select
                        isSearchable={false}
                        // placeholder="Bus"
                        onChange={e => {
                          setSelectedBus(e), handleBusChange(e)
                        }}
                        options={busList}
                        classNamePrefix="select2-selection"
                        value={selectedBus}
                      />
                      {errors.bus && (
                        <p className="text-danger">{errors.bus}</p>
                      )}
                    </Col>

                    <Col xs={6} className="mb-1">
                      <Label>{props.t('Select Route')}</Label>

                      <Select
                        isSearchable={false}
                        // isDisabled={!selectedBus}
                        // placeholder="Route"
                        onChange={e => {
                          handleStartAndEndPoints(e)
                        }}
                        options={routeList}
                        classNamePrefix="select2-selection"
                        value={selectedRoute}
                      />
                      {errors.route && (
                        <p className="text-danger">{errors.route}</p>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} className="mb-1">
                      <Label>{props.t('Start Point')}</Label>
                      <Select
                        isSearchable={false}
                        // placeholder="Start Point"
                        onChange={e => {
                          setSelectedStartStation(e)
                        }}
                        options={startPointList.map(x => {
                          return { label: x, key: x, value: x }
                        })}
                        classNamePrefirouteListx="select2-selection"
                        // value={selectedRoute?.key?.startPoint?.station}
                        value={selectedStartStation}
                      />
                      {errors.selectedStartStation && (
                        <p className="text-danger">
                          {errors.selectedStartStation}
                        </p>
                      )}
                    </Col>

                    <Col xs={6} className="mb-1">
                      <Label>{props.t('End Point')}</Label>
                      <Select
                        isSearchable={false}
                        // placeholder="End Point"
                        onChange={e => {
                          setSelectedEndPoint(e)
                        }}
                        options={endPointList.map(x => {
                          return { label: x, key: x, value: x }
                        })}
                        classNamePrefix="select2-selection"
                        // disabled
                        value={selectedEndPoint}
                      />
                      {errors.selectedEndPoint && (
                        <p className="text-danger">{errors.selectedEndPoint}</p>
                      )}
                    </Col>
                  </Row>

                  {errors.seatNo && (
                    <p className="text-danger text-center">{errors.seatNo}</p>
                  )}

                  <Row className="m-0 p-0 w-100">
                    <Col xs={12} sm={12}>{busLayoutFunctionState}</Col>
                  </Row>

                  <div className="mt-0 d-flex justify-content-center align-items-center">
                    <div className="mx-1">
                      <Button
                        color="success"
                        onClick={() => setViewBookingModalOpen(true)}
                      >
                        {props.t('View Booking')}
                      </Button>
                    </div>
                    <div className="mx-1">
                      <Button color="success" onClick={handleBook}>
                        {props.t('Book')}
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={isBookingModalOpen}
            toggle={() => setIsBookingModalOpen(!isBookingModalOpen)}
            size="lg"
            centered={true}
          >
            <ModalBody>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12} lg={8} className={"my-2"}>
                      <Label>{props.t('Seat Details')}</Label>
                      <div
                        className="rounded p-2 "
                        style={{
                          width: "100%",
                          border: "1px solid black",
                          fontSize: "0.6rem",
                        }}
                      >
                        <Row>
                          <Col xs={5}>
                            <span>{props.t('Seats')} :</span>
                          </Col>
                          <Col xs={7} className='text-start'>
                            <span>
                              {selectedSeats &&
                                Object.values(selectedSeats).map((item, i) => {
                                  return i !==
                                    Object.values(selectedSeats).length - 1
                                    ? `${item.seatNumber} , `
                                    : `${item.seatNumber}`
                                })}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5}>
                            <span>{props.t('Extra Seats')} :</span>
                          </Col>
                          <Col xs={7} className='text-start'>
                            <span> {data_attr}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4}>
                            <span>{props.t('Route')} :</span>
                          </Col>
                          <Col xs={8}>
                            <span> {selectedRoute?.label}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={5}>
                            <span>{props.t('Date')} :</span>
                          </Col>
                          <Col xs={7}>
                            <span>{formatDate(new Date())}</span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <hr />

                    <Col xs={6} className={"mt-3"}>
                      <Label>{props.t('Total Amt.')}</Label>
                      <div className="col-md-12">
                        <InputGroup>
                          <span className="input-group-append">
                            <span className="input-group-text">₹</span>
                          </span>
                          <input
                            className="form-control"
                            // defaultValue={200}
                            // placeholder="Total Amount"
                            value={totalAmount}
                            type="tel"
                            onChange={e => setTotalAmount(e.target.value)}
                          />
                        </InputGroup>
                        {errors.totalAmount && (
                          <p className="text-danger">{errors.totalAmount}</p>
                        )}
                      </div>
                    </Col>
                    <Col xs={6} className={"mt-3"}>
                      <Label>{props.t('Changed Amt.')}</Label>
                      <div className="col-md-12">
                        <InputGroup>
                          <span className="input-group-append">
                            <span className="input-group-text">₹</span>
                          </span>
                          <input
                            className="form-control"
                            type="tel"
                            // placeholder="Total Received Amount"
                            value={totalRcvAmount}
                            onChange={e => setTotalRcvAmount(e.target.value)}
                          />
                        </InputGroup>
                        {errors.totalRcvAmount && (
                          <p className="text-danger">{errors.totalRcvAmount}</p>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} className={"mt-3"}>
                      <Label>{props.t('Phone Number')}</Label>
                      <div className="col-md-12">
                        <InputGroup>
                          <span className="input-group-append">
                            <span className="input-group-text">+91</span>
                          </span>
                          <input
                            className="form-control"
                            // placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={e => setPhoneNumber(e.target.value)}
                            type="tel"
                          />
                        </InputGroup>
                        {errors.phoneNumber && (
                          <p className="text-danger">{errors.phoneNumber}</p>
                        )}
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      className={
                        "d-flex justify-content-end align-items-end mt-4"
                      }
                    >
                      <div
                        className="rounded p-3"
                        style={{
                          width: "15rem",
                          border: "1px solid black",
                          fontSize: "0.73rem",
                        }}
                      >
                        <Row>
                          <Col>
                            <span>{props.t('Amount Price')} </span>
                          </Col>
                          <Col>
                            <span>:</span>
                            <span> Rs. {totalAmount}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <span>{props.t('Amount Received')} </span>
                          </Col>
                          <Col>
                            <span>:</span>
                            <span> Rs. {totalRcvAmount}</span>
                          </Col>
                        </Row>
                        <hr />

                        <Row>
                          <Col>
                            <span>{props.t('Amount Due')} </span>
                          </Col>
                          <Col>
                            <span>:</span>
                            <span> Rs. {totalAmount - totalRcvAmount}</span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <div className="mx-2">
                      <Button
                        color="primary"
                        outline
                        onClick={() =>
                          setIsBookingModalOpen(!isBookingModalOpen)
                        }
                      >
                        {props.t('Back')}{" "}
                      </Button>
                    </div>
                    <div className="mx-2">
                      <Button color="success" onClick={handleBookTicket}>
                        {isLoading ? (
                          <Spinner
                            size="sm"
                            className="m-0 p-0"
                            color="secondary"
                          />
                        ) : (
                          "Book"
                        )}
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>

          <ViewBookingByBusModal
            toggleModal={() => setViewBookingModalOpen(!viewBookingModal)}
            modalOpen={viewBookingModal}
            bookingReqList={bookingReqList}
            getTickets={getAllBookingRequests}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
Booking.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any
}
export default withTranslation()(Booking);
