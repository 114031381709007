import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Form,
  FormFeedback,
  Label,
  Input,
  FormGroup,
  InputGroup,
} from "reactstrap";
// import "react-datepicker/dist/react-datepicker.css";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// import avatar from "../../assets/images/users/user-4.jpg";
// actions
import Select from "react-select"
import { callApi } from "helpers/api_helper"
import Upload from "../../helpers/AWS_upload"
import { Buffer } from "buffer"
import { sentenceCase } from "change-case"
import { withTranslation } from "react-i18next"

Buffer.from("anything", "base64")
window.Buffer = window.Buffer || require("buffer").Buffer

function formatToDisplayDate(date) {
  if (date) {
    let temp = date.split("T")
    let newDate = temp[0]
    let newTime = temp[1]

    newDate = newDate.split("-").reverse().join("/")

    return newDate
  } else {
    return ""
  }
}

const options = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Others",
    value: "Others",
  },
]

const UserProfile = props => {
  const navigate = useNavigate();

  const [email, setemail] = useState("")
  const [isProfile, setIsProfile] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [name, setname] = useState("")
  const [businessName, setBusinessName] = useState("")
  const [altPhoneNumber, setAltPhoneNumber] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [birthDate, setBirthDate] = useState("")
  const [user, setUser] = useState({})
  const [gender, setGender] = useState('')
  const [selectedGender, setSelectedGender] = useState()
  const [line1, setLine1] = useState("")
  const [line2, setLine2] = useState("")
  const [state, setState] = useState("")
  const [gstNo, setGstNo] = useState("")
  const [pincode, setPincode] = useState("")
  const [addrline1, setAddrLine1] = useState("")
  const [addrline2, setAddrLine2] = useState("")
  const [addrstate, setAddrState] = useState("")
  const [addrpincode, setAddrPincode] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [profile, setProfile] = useState("")
  const [fileDataAadharURL, setFileDataAadharURL] = useState("")
  const [fileDataPanURL, setFileDataPanURL] = useState("")
  const [fileDataGSTURL, setFileDataGSTURL] = useState("")
  const [profileUrl, setProfileUrl] = useState("")
  const [emailId, setEmailId] = useState("")

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || "",
      phoneNumber: phoneNumber || "",
      altPhoneNumber: altPhoneNumber || "",
      gstNo: gstNo || "",
      birthDate: birthDate || "",
      businessName: businessName || "",
      gender: gender || "",
      line1: line1 || "",
      line2: line2 || "",
      state: state || "",
      pincode: pincode || "",
      addrline1: addrline1 || "",
      addrline2: addrline2 || "",
      addrstate: addrstate || "",
      addrpincode: addrpincode || "",
      profile: profile || "",
      gstNo: gstNo || "",
      emailId: emailId || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your User Name"),
      altPhoneNumber: Yup.string().required(
        "Please Enter Your Alternate Phone Number"
      ),
      gstNo: Yup.string("Please Enter a valid GST Number"),
      // birthDate: Yup.string().required("Please Enter Your Birth date"),
      businessName: Yup.string("Please Enter a valid Business Name"),
      gender: Yup.string().required("Please Choose your gender"),
      phoneNumber: Yup.string().required("Please Enter Your Phone Number"),
      line1: Yup.string().required("This Field is required"),
      line2: Yup.string().required("This Field is required"),
      state: Yup.string().required("Please Enter your state"),
      pincode: Yup.string().required("Please Enter Your Pincode"),
      addrline1: Yup.string("This Field is required"),
      addrline2: Yup.string("This Field is required"),
      addrstate: Yup.string("Please Enter your state"),
      addrpincode: Yup.string("Please Enter Your Pincode"),
      // emailId: Yup.string().required("Please Enter Your Email"),
      // profile: Yup.string().required("Please Upload your Profile Picture."),
    }),
    onSubmit: values => {

      handleUpdateProfile(values)
      // dispatch(editProfile(values));
    },
  })

  const handleUpdateProfile = async values => {

    // return false
    try {
      let config = {}
      if (isProfile) {
        config = {
          method: "post",
          url: "profile/getProfileRoutes/updateProfile",
          data: {
            user: user._id,
            name: values.username,
            phoneNumber: values.phoneNumber,
            gender: values?.gender,
            birthDate: values.birthDate,
            businessName: values.businessName,
            alternatePhoneNumber: values.altPhoneNumber,
            profilePicture: profileUrl,
            pan: fileDataPanURL,
            aadhaar: fileDataAadharURL,
            GSTCertification: fileDataGSTURL,
            GSTNumber: values.gstNo,
            emailId: values.emailId,
            address1: {
              line1: values.line1,
              line2: values.line2,
              state: values.state,
              pincode: values.pincode,
            },
            address2: {
              line1: values.addrline1,
              line2: values.addrline2,
              state: values.addrstate,
              pincode: values.addrpincode,
            },
          },
        }
      } else {
        config = {
          method: "post",
          url: "profile/getProfileRoutes/createProfile",
          data: {
            name: values.username,
            phoneNumber: values.phoneNumber,
            gender: values.gender,
            birthDate: values.birthDate,
            businessName: values.businessName,
            alternatePhoneNumber: values.altPhoneNumber,
            profilePicture: profileUrl,
            pan: fileDataPanURL,
            aadhaar: fileDataAadharURL,
            GSTCertification: fileDataGSTURL,
            emailId: values.emailId,
            GSTNumber: values.gstNo,
            address1: {
              line1: values.line1,
              line2: values.line2,
              state: values.state,
              pincode: values.pincode,
            },
            address2: {
              line1: values.addrline1,
              line2: values.addrline2,
              state: values.addrstate,
              pincode: values.addrpincode,
            },
          },
        }
      }
      const response = await callApi(config)

      if (response.data.code === 200) {
        setErrorMessage(null)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setSuccessMessage(response.data.message)
        await getUser(values.phoneNumber)
      } else {
        setErrorMessage(response.data.message)
        setSuccessMessage(null)
      }
    } catch (error) {
      console.log(error)
      setErrorMessage(error.message)
    }
  }

  const handleParentFileSelection = async event => {

    let img = {
      name: `${Date.now()}-${Math.random()}.${event.target.files[0].name.split(".")[
        event.target.files[0].name.split(".").length - 1
      ]
        }`,
    }
    img = event.target.files[0]
    // img.name = `${Date.now()}-${Math.random()}.${event.target.files[0].name.split('.')[event.target.files[0].name.split('.').length - 1]}`
    // setParentImage(img);
    const uploadUrl = await Upload(event.target.files[0]);

    return uploadUrl
  }

  const getUser = async phoneNumber => {
    try {
      const userConfig = {
        method: "post",
        url: "user/getUserRoutes/getUserByPhoneNumber",
        data: {
          phoneNumber,
        },
      }
      const userResponse = await callApi(userConfig)

      if (userResponse.data.code === 200) {
        // const existingUser =JSON.parse( await localStorage.getItem('user'));
        // await localStorage.setItem('user', JSON.stringify({ token: existingUser.token, user: userResponse.data.data }));

        const { userName, avatar, role, profile, isProfileOk, ...data } = userResponse.data.data;
        setUser(userResponse.data.data)
        setname(userName)
        setAltPhoneNumber(profile?.alternatePhoneNumber)
        setLine1(profile?.address1?.line1)
        setLine2(profile?.address1?.line2)
        setState(profile?.address1?.state)
        setPincode(profile?.address1?.pincode)
        setAddrLine1(profile?.address2?.line1)
        setAddrLine2(profile?.address2?.line2)
        setAddrState(profile?.address2?.state)
        setAddrPincode(profile?.address2?.pincode)
        setBirthDate(new Date(profile?.birthDate))
        setBusinessName(profile?.businessName)
        setSelectedGender(options.filter(item => item.value === profile?.gender))
        setGender(profile?.gender)
        setIsProfile(isProfileOk)
        setProfile(profile?.profilePicture)
        setProfileUrl(profile?.profilePicture)
        setGstNo(profile?.GSTNumber)
        setFileDataAadharURL(profile?.aadhaar)
        setFileDataGSTURL(profile?.GSTCertification)
        setFileDataPanURL(profile?.pan)
        setEmailId(profile?.emailId)
        isProfileOk ? setIsEdit(false) : setIsEdit(true)
      } else {
        navigate("/login")
      }
    } catch (error) {
      console.log(error)
    }
  }
  const updateProfile = async url => {

    let updateProfilePicture = {
      method: "post",
      url: "profile/getProfileRoutes/updateProfilePicture",
      data: {
        _id: user._id,
        profilePicture: url,
      },
    }
    let response = await callApi(updateProfilePicture)

    if (response.data.code === 200) {
      await getUser(phoneNumber)
    }
  }
  const changeHandler = async e => {
    const { name } = e.target
    const url = await handleParentFileSelection(e)
    if (name === "profile") {

      setProfileUrl(url)
      await updateProfile(url)
    } else if (name === "aadhar") {
      setFileDataAadharURL(url)
    }
    if (name === "aadhar") {
      setFileDataAadharURL(url)
    } else if (name === "pan") {
      setFileDataPanURL(url)
    } else if (name === "GST") {
      setFileDataGSTURL(url)
    }
  }

  const fieldsToRender = (role) => {
    try {
      switch (role) {
        case 'SUPER_ADMIN':
          return (
            <div id="SUPER_ADMIN">
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Name')}</Label>
                <Input
                  disabled={!isEdit}
                  name="username"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  invalid={
                    validation.touched.username && validation.errors.username
                      ? true
                      : false
                  }
                />
                {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                ) : null}

              </div>

              <div className="my-4">
                <Label>{props.t('Gender')}</Label>
                <Select
                  isSearchable={false}
                  disabled={!isEdit}
                  onChange={e => {
                    setGender(e.value), setSelectedGender(e), validation.setFieldValue("gender", e.value)
                  }}
                  options={options}
                  classNamePrefix="select2-selection"
                  onBlur={validation.handleBlur}
                  value={selectedGender}
                />
              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="tel"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber || ""}
                  invalid={
                    validation.touched.phoneNumber &&
                      validation.errors.phoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.phoneNumber &&
                  validation.errors.phoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Alternate Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="altPhoneNumber"
                  className="form-control"
                  placeholder="Enter Alternate Phone Number"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.altPhoneNumber || ""}
                  invalid={
                    validation.touched.altPhoneNumber &&
                      validation.errors.altPhoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.altPhoneNumber &&
                  validation.errors.altPhoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.altPhoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Email')}</Label>
                <Input
                  disabled={!isEdit}
                  name="emailId"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.emailId || ""}
                  invalid={
                    validation.touched.emailId && validation.errors.emailId
                      ? true
                      : false
                  }
                />
                {validation.touched.emailId && validation.errors.emailId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.emailId}
                  </FormFeedback>
                ) : null}
              </div>
              <FormGroup className="my-4">
                <Label>{props.t('Birth Date')}</Label>
                <InputGroup>
                  <Flatpickr
                    // disabled={!isEdit}

                    className="form-control d-block"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                    name="birthDate"
                    onChange={date => {
                      setBirthDate(new Date(date).toISOString()),
                        validation.setFieldValue(
                          "birthDate",
                          new Date(date).toISOString()
                        ),
                        console.log(new Date(date).toISOString())
                    }}
                    onBlur={validation.handleBlur}
                    value={birthDate || ""}
                  />
                </InputGroup>
              </FormGroup>
              <div className="my-4">
                <Label>{props.t('Present Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="line1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line1 || ""}
                        invalid={
                          validation.touched.line1 && validation.errors.line1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line1 && validation.errors.line1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="line2"
                        className="form-control"
                        placeholder="Line2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line2 || ""}
                        invalid={
                          validation.touched.line2 && validation.errors.line2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line2 && validation.errors.line2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="state"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.state || ""}
                        invalid={
                          validation.touched.state && validation.errors.state
                            ? true
                            : false
                        }
                      />
                      {validation.touched.state && validation.errors.state ? (
                        <FormFeedback type="invalid">
                          {validation.errors.state}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="pincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pincode || ""}
                        invalid={
                          validation.touched.pincode &&
                            validation.errors.pincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.pincode &&
                        validation.errors.pincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="my-4">
                <Label>{props.t('Permanent Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrline1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrline1 || ""}
                        invalid={
                          validation.touched.addrline1 &&
                            validation.errors.addrline1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrline1 &&
                        validation.errors.addrline1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrline1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrline2"
                        className="form-control"
                        placeholder="Line 2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrline2 || ""}
                        invalid={
                          validation.touched.addrline2 &&
                            validation.errors.addrline2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrline2 &&
                        validation.errors.addrline2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrline2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrstate"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrstate || ""}
                        invalid={
                          validation.touched.addrstate &&
                            validation.errors.addrstate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrstate &&
                        validation.errors.addrstate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrstate}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrpincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrpincode || ""}
                        invalid={
                          validation.touched.addrpincode &&
                            validation.errors.addrpincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrpincode &&
                        validation.errors.addrpincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrpincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>

              <div className=" my-5">
                <Row>
                  <Col lg={4} className="">
                    <Label>{props.t('Upload Aadhar')}</Label>
                    <Input
                      type="file"
                      disabled={!isEdit}
                      id="aadhar"
                      name="aadhar"
                      accept="image/*"
                      onChange={changeHandler}
                    />
                  </Col>
                  <Col lg={2}>
                    <div className="">
                      {fileDataAadharURL ? (
                        // <a href={fileDataAadharURL} target="_blank">
                        <img
                          src={fileDataAadharURL}
                          alt="fileAadhar"
                          style={{ height: "80px", width: "80px" }}
                        />
                        // </a>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg={4} className="">
                    <Label>{props.t('Upload Pan Card')}</Label>
                    <Input
                      type="file"
                      disabled={!isEdit}
                      name="pan"
                      id="Pan"
                      accept="image/*"
                      onChange={changeHandler}
                    />
                  </Col>
                  <Col lg={2}>
                    <div className="">
                      {fileDataPanURL ? (
                        // <a href={fileDataPanURL} target="_blank">
                        <img
                          src={fileDataPanURL}
                          alt="filePan"
                          style={{ height: "80px", width: "80px" }}
                        />
                        // </a>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>

            </div>
          )
        case 'ADMIN':
          return (
            <div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Name')}</Label>
                <Input
                  disabled={!isEdit}
                  name="username"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  invalid={
                    validation.touched.username && validation.errors.username
                      ? true
                      : false
                  }
                />
                {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                ) : null}

              </div>

              <div className="my-4">
                <Label>{props.t('Gender')}</Label>
                <Select
                  isSearchable={false}
                  disabled={!isEdit}
                  onChange={e => {
                    setGender(e.value), setSelectedGender(e), validation.setFieldValue("gender", e.value)
                  }}
                  options={options}
                  classNamePrefix="select2-selection"
                  onBlur={validation.handleBlur}
                  value={selectedGender}
                />
              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="tel"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber || ""}
                  invalid={
                    validation.touched.phoneNumber &&
                      validation.errors.phoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.phoneNumber &&
                  validation.errors.phoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Alternate Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="altPhoneNumber"
                  className="form-control"
                  placeholder="Enter Alternate Phone Number"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.altPhoneNumber || ""}
                  invalid={
                    validation.touched.altPhoneNumber &&
                      validation.errors.altPhoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.altPhoneNumber &&
                  validation.errors.altPhoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.altPhoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Email')}</Label>
                <Input
                  disabled={!isEdit}
                  name="emailId"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.emailId || ""}
                  invalid={
                    validation.touched.emailId && validation.errors.emailId
                      ? true
                      : false
                  }
                />
                {validation.touched.emailId && validation.errors.emailId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.emailId}
                  </FormFeedback>
                ) : null}
              </div>
              <FormGroup className="my-4">
                <Label className="form-label">{props.t('Birth Date')}</Label>
                <InputGroup>
                  <Flatpickr
                    // disabled={!isEdit}

                    className="form-control d-block"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                    name="birthDate"
                    onChange={date => {
                      setBirthDate(new Date(date).toISOString()),
                        validation.setFieldValue(
                          "birthDate",
                          new Date(date).toISOString()
                        ),
                        console.log(new Date(date).toISOString())
                    }}
                    onBlur={validation.handleBlur}
                    value={birthDate || ""}
                  />
                </InputGroup>
              </FormGroup>
              <div className="my-4">
                <Label className="form-label">{props.t('Present Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="line1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line1 || ""}
                        invalid={
                          validation.touched.line1 && validation.errors.line1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line1 && validation.errors.line1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="line2"
                        className="form-control"
                        placeholder="Line2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line2 || ""}
                        invalid={
                          validation.touched.line2 && validation.errors.line2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line2 && validation.errors.line2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="state"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.state || ""}
                        invalid={
                          validation.touched.state && validation.errors.state
                            ? true
                            : false
                        }
                      />
                      {validation.touched.state && validation.errors.state ? (
                        <FormFeedback type="invalid">
                          {validation.errors.state}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="pincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pincode || ""}
                        invalid={
                          validation.touched.pincode &&
                            validation.errors.pincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.pincode &&
                        validation.errors.pincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="my-4">
                <Label className="form-label">{props.t('Permanent Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrline1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrline1 || ""}
                        invalid={
                          validation.touched.addrline1 &&
                            validation.errors.addrline1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrline1 &&
                        validation.errors.addrline1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrline1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrline2"
                        className="form-control"
                        placeholder="Line 2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrline2 || ""}
                        invalid={
                          validation.touched.addrline2 &&
                            validation.errors.addrline2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrline2 &&
                        validation.errors.addrline2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrline2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrstate"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrstate || ""}
                        invalid={
                          validation.touched.addrstate &&
                            validation.errors.addrstate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrstate &&
                        validation.errors.addrstate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrstate}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrpincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrpincode || ""}
                        invalid={
                          validation.touched.addrpincode &&
                            validation.errors.addrpincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrpincode &&
                        validation.errors.addrpincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrpincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>

              <div className=" my-5">
                <Row>
                  <Col lg={4} className="">
                    <Label className="form-label">{props.t('Upload Aadhar')}</Label>
                    <Input
                      type="file"
                      disabled={!isEdit}
                      id="aadhar"
                      name="aadhar"
                      accept="image/*"
                      onChange={changeHandler}
                    />
                  </Col>
                  <Col lg={2}>
                    <div className="">
                      {fileDataAadharURL ? (
                        // <a href={fileDataAadharURL} target="_blank">
                        <img
                          src={fileDataAadharURL}
                          alt="fileAadhar"
                          style={{ height: "80px", width: "80px" }}
                        />
                        // </a>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg={4} className="">
                    <Label className="form-label">{props.t('Upload Pan Card')}</Label>
                    <Input
                      type="file"
                      disabled={!isEdit}
                      name="pan"
                      id="Pan"
                      accept="image/*"
                      onChange={changeHandler}
                    />
                  </Col>
                  <Col lg={2}>
                    <div className="">
                      {fileDataPanURL ? (
                        // <a href={fileDataPanURL} target="_blank">
                        <img
                          src={fileDataPanURL}
                          alt="filePan"
                          style={{ height: "80px", width: "80px" }}
                        />
                        // </a>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>

            </div>
          )
        case 'CONDUCTOR':
          return (
            <div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Name')}</Label>
                <Input
                  disabled={!isEdit}
                  name="username"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  invalid={
                    validation.touched.username && validation.errors.username
                      ? true
                      : false
                  }
                />
                {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Business Name')}</Label>
                <Input
                  disabled={!isEdit}
                  name="businessName"
                  className="form-control"
                  placeholder="Enter Business Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.businessName || ""}
                  invalid={
                    validation.touched.businessName &&
                      validation.errors.businessName
                      ? true
                      : false
                  }
                />
                {validation.touched.businessName &&
                  validation.errors.businessName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.businessName}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="my-4">
                <Label>{props.t('Gender')}</Label>
                <Select
                  isSearchable={false}
                  disabled={!isEdit}
                  onChange={e => {
                    setGender(e.value), setSelectedGender(e), validation.setFieldValue("gender", e.value)
                  }}
                  options={options}
                  classNamePrefix="select2-selection"
                  onBlur={validation.handleBlur}
                  value={selectedGender}
                />
              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="tel"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber || ""}
                  invalid={
                    validation.touched.phoneNumber &&
                      validation.errors.phoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.phoneNumber &&
                  validation.errors.phoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Alternate Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="altPhoneNumber"
                  className="form-control"
                  placeholder="Enter Alternate Phone Number"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.altPhoneNumber || ""}
                  invalid={
                    validation.touched.altPhoneNumber &&
                      validation.errors.altPhoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.altPhoneNumber &&
                  validation.errors.altPhoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.altPhoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Email')}</Label>
                <Input
                  disabled={!isEdit}
                  name="emailId"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.emailId || ""}
                  invalid={
                    validation.touched.emailId && validation.errors.emailId
                      ? true
                      : false
                  }
                />
                {validation.touched.emailId && validation.errors.emailId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.emailId}
                  </FormFeedback>
                ) : null}
              </div>
              <FormGroup className="my-4">
                <Label className="form-label">{props.t('Birth Date')}</Label>
                <InputGroup>
                  <Flatpickr
                    // disabled={!isEdit}

                    className="form-control d-block"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                    name="birthDate"
                    onChange={date => {
                      setBirthDate(new Date(date).toISOString()),
                        validation.setFieldValue(
                          "birthDate",
                          new Date(date).toISOString()
                        ),
                        console.log(new Date(date).toISOString())
                    }}
                    onBlur={validation.handleBlur}
                    value={birthDate || ""}
                  />
                </InputGroup>
              </FormGroup>
              <div className="my-4">
                <Label className="form-label">{props.t('Present Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="line1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line1 || ""}
                        invalid={
                          validation.touched.line1 && validation.errors.line1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line1 && validation.errors.line1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="line2"
                        className="form-control"
                        placeholder="Line2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line2 || ""}
                        invalid={
                          validation.touched.line2 && validation.errors.line2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line2 && validation.errors.line2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="state"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.state || ""}
                        invalid={
                          validation.touched.state && validation.errors.state
                            ? true
                            : false
                        }
                      />
                      {validation.touched.state && validation.errors.state ? (
                        <FormFeedback type="invalid">
                          {validation.errors.state}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="pincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pincode || ""}
                        invalid={
                          validation.touched.pincode &&
                            validation.errors.pincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.pincode &&
                        validation.errors.pincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )
        case 'PARTNER':
          return (
            <div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Name')}</Label>
                <Input
                  disabled={!isEdit}
                  name="username"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  invalid={
                    validation.touched.username && validation.errors.username
                      ? true
                      : false
                  }
                />
                {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Business Name')}</Label>
                <Input
                  disabled={!isEdit}
                  name="businessName"
                  className="form-control"
                  placeholder="Enter Business Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.businessName || ""}
                  invalid={
                    validation.touched.businessName &&
                      validation.errors.businessName
                      ? true
                      : false
                  }
                />
                {validation.touched.businessName &&
                  validation.errors.businessName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.businessName}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="my-4">
                <Label>{props.t('Gender')}</Label>
                <Select
                  isSearchable={false}
                  disabled={!isEdit}
                  onChange={e => {
                    setGender(e.value), setSelectedGender(e), validation.setFieldValue("gender", e.value)
                  }}
                  options={options}
                  classNamePrefix="select2-selection"
                  onBlur={validation.handleBlur}
                  value={selectedGender}
                />
              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="tel"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber || ""}
                  invalid={
                    validation.touched.phoneNumber &&
                      validation.errors.phoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.phoneNumber &&
                  validation.errors.phoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Alternate Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="altPhoneNumber"
                  className="form-control"
                  placeholder="Enter Alternate Phone Number"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.altPhoneNumber || ""}
                  invalid={
                    validation.touched.altPhoneNumber &&
                      validation.errors.altPhoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.altPhoneNumber &&
                  validation.errors.altPhoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.altPhoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Email')}</Label>
                <Input
                  disabled={!isEdit}
                  name="emailId"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.emailId || ""}
                  invalid={
                    validation.touched.emailId && validation.errors.emailId
                      ? true
                      : false
                  }
                />
                {validation.touched.emailId && validation.errors.emailId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.emailId}
                  </FormFeedback>
                ) : null}
              </div>
              <FormGroup className="my-4">
                <Label className="form-label">{props.t('Birth Date')}</Label>
                <InputGroup>
                  <Flatpickr
                    // disabled={!isEdit}

                    className="form-control d-block"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                    name="birthDate"
                    onChange={date => {
                      setBirthDate(new Date(date).toISOString()),
                        validation.setFieldValue(
                          "birthDate",
                          new Date(date).toISOString()
                        ),
                        console.log(new Date(date).toISOString())
                    }}
                    onBlur={validation.handleBlur}
                    value={birthDate || ""}
                  />
                </InputGroup>
              </FormGroup>
              <div className="my-4">
                <Label className="form-label">{props.t('Present Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="line1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line1 || ""}
                        invalid={
                          validation.touched.line1 && validation.errors.line1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line1 && validation.errors.line1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="line2"
                        className="form-control"
                        placeholder="Line2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line2 || ""}
                        invalid={
                          validation.touched.line2 && validation.errors.line2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line2 && validation.errors.line2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="state"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.state || ""}
                        invalid={
                          validation.touched.state && validation.errors.state
                            ? true
                            : false
                        }
                      />
                      {validation.touched.state && validation.errors.state ? (
                        <FormFeedback type="invalid">
                          {validation.errors.state}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="pincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pincode || ""}
                        invalid={
                          validation.touched.pincode &&
                            validation.errors.pincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.pincode &&
                        validation.errors.pincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="my-4">
                <Label className="form-label">{props.t('GST Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="gstNo"
                  className="form-control"
                  placeholder="Enter Your GST Number"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.gstNo || ""}
                  invalid={
                    validation.touched.gstNo && validation.errors.gstNo
                      ? true
                      : false
                  }
                />
                {validation.touched.gstNo && validation.errors.gstNo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.gstNo}
                  </FormFeedback>
                ) : null}
              </div>

              <Row className="my-4">
                <Col lg={4} className="">
                  <Label className="form-label">{props.t('Upload GST Certificate')}</Label>
                  <Input
                    type="file"
                    disabled={!isEdit}
                    name="GST"
                    id="GST"
                    accept="image/*"
                    onChange={changeHandler}
                  />
                </Col>
                <Col lg={2}>
                  <div className="">
                    {fileDataGSTURL ? (
                      // <a href={fileDataGSTURL} target="_blank">
                      <img
                        src={fileDataGSTURL}
                        alt="fileGST"
                        style={{ height: "80px", width: "80px" }}
                      />
                      // </a>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          )
        case 'MANAGER':
          return (
            <div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Name')}</Label>
                <Input
                  disabled={!isEdit}
                  name="username"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  invalid={
                    validation.touched.username && validation.errors.username
                      ? true
                      : false
                  }
                />
                {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Business Name')}</Label>
                <Input
                  disabled={!isEdit}
                  name="businessName"
                  className="form-control"
                  placeholder="Enter Business Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.businessName || ""}
                  invalid={
                    validation.touched.businessName &&
                      validation.errors.businessName
                      ? true
                      : false
                  }
                />
                {validation.touched.businessName &&
                  validation.errors.businessName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.businessName}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="my-4">
                <Label>{props.t('Gender')}</Label>
                <Select
                  isSearchable={false}
                  disabled={!isEdit}
                  onChange={e => {
                    setGender(e.value), setSelectedGender(e), validation.setFieldValue("gender", e.value)
                  }}
                  options={options}
                  classNamePrefix="select2-selection"
                  onBlur={validation.handleBlur}
                  value={selectedGender}
                />
              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="tel"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber || ""}
                  invalid={
                    validation.touched.phoneNumber &&
                      validation.errors.phoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.phoneNumber &&
                  validation.errors.phoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Alternate Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="altPhoneNumber"
                  className="form-control"
                  placeholder="Enter Alternate Phone Number"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.altPhoneNumber || ""}
                  invalid={
                    validation.touched.altPhoneNumber &&
                      validation.errors.altPhoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.altPhoneNumber &&
                  validation.errors.altPhoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.altPhoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Email')}</Label>
                <Input
                  disabled={!isEdit}
                  name="emailId"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.emailId || ""}
                  invalid={
                    validation.touched.emailId && validation.errors.emailId
                      ? true
                      : false
                  }
                />
                {validation.touched.emailId && validation.errors.emailId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.emailId}
                  </FormFeedback>
                ) : null}
              </div>
              <FormGroup className="my-4">
                <Label className="form-label">{props.t('Birth Date')}</Label>
                <InputGroup>
                  <Flatpickr
                    // disabled={!isEdit}

                    className="form-control d-block"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                    name="birthDate"
                    onChange={date => {
                      setBirthDate(new Date(date).toISOString()),
                        validation.setFieldValue(
                          "birthDate",
                          new Date(date).toISOString()
                        ),
                        console.log(new Date(date).toISOString())
                    }}
                    onBlur={validation.handleBlur}
                    value={birthDate || ""}
                  />
                </InputGroup>
              </FormGroup>
              <div className="my-4">
                <Label className="form-label">{props.t('Present Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="line1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line1 || ""}
                        invalid={
                          validation.touched.line1 && validation.errors.line1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line1 && validation.errors.line1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="line2"
                        className="form-control"
                        placeholder="Line2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line2 || ""}
                        invalid={
                          validation.touched.line2 && validation.errors.line2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line2 && validation.errors.line2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="state"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.state || ""}
                        invalid={
                          validation.touched.state && validation.errors.state
                            ? true
                            : false
                        }
                      />
                      {validation.touched.state && validation.errors.state ? (
                        <FormFeedback type="invalid">
                          {validation.errors.state}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="pincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pincode || ""}
                        invalid={
                          validation.touched.pincode &&
                            validation.errors.pincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.pincode &&
                        validation.errors.pincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="my-4">
                <Label className="form-label">{props.t('Permanent Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrline1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrline1 || ""}
                        invalid={
                          validation.touched.addrline1 &&
                            validation.errors.addrline1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrline1 &&
                        validation.errors.addrline1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrline1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrline2"
                        className="form-control"
                        placeholder="Line 2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrline2 || ""}
                        invalid={
                          validation.touched.addrline2 &&
                            validation.errors.addrline2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrline2 &&
                        validation.errors.addrline2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrline2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrstate"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrstate || ""}
                        invalid={
                          validation.touched.addrstate &&
                            validation.errors.addrstate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrstate &&
                        validation.errors.addrstate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrstate}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrpincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrpincode || ""}
                        invalid={
                          validation.touched.addrpincode &&
                            validation.errors.addrpincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrpincode &&
                        validation.errors.addrpincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrpincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="my-4">
                <Label className="form-label">{props.t('GST Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="gstNo"
                  className="form-control"
                  placeholder="Enter Your GST Number"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.gstNo || ""}
                  invalid={
                    validation.touched.gstNo && validation.errors.gstNo
                      ? true
                      : false
                  }
                />
                {validation.touched.gstNo && validation.errors.gstNo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.gstNo}
                  </FormFeedback>
                ) : null}
              </div>

              <Row className="my-4">
                <Col lg={4} className="">
                  <Label className="form-label">{props.t('Upload GST Certificate')}</Label>
                  <Input
                    type="file"
                    disabled={!isEdit}
                    name="GST"
                    id="GST"
                    accept="image/*"
                    onChange={changeHandler}
                  />
                </Col>
                <Col lg={2}>
                  <div className="">
                    {fileDataGSTURL ? (
                      // <a href={fileDataGSTURL} target="_blank">
                      <img
                        src={fileDataGSTURL}
                        alt="fileGST"
                        style={{ height: "80px", width: "80px" }}
                      />
                      // </a>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          )
        case 'SITE_MANAGER':
          return (
            <div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Name')}</Label>
                <Input
                  disabled={!isEdit}
                  name="username"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  invalid={
                    validation.touched.username && validation.errors.username
                      ? true
                      : false
                  }
                />
                {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                ) : null}

              </div>

              <div className="my-4">
                <Label>{props.t('Gender')}</Label>
                <Select
                  isSearchable={false}
                  disabled={!isEdit}
                  onChange={e => {
                    setGender(e.value), setSelectedGender(e), validation.setFieldValue("gender", e.value)
                  }}
                  options={options}
                  classNamePrefix="select2-selection"
                  onBlur={validation.handleBlur}
                  value={selectedGender}
                />
              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="tel"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber || ""}
                  invalid={
                    validation.touched.phoneNumber &&
                      validation.errors.phoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.phoneNumber &&
                  validation.errors.phoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Alternate Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="altPhoneNumber"
                  className="form-control"
                  placeholder="Enter Alternate Phone Number"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.altPhoneNumber || ""}
                  invalid={
                    validation.touched.altPhoneNumber &&
                      validation.errors.altPhoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.altPhoneNumber &&
                  validation.errors.altPhoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.altPhoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Email')}</Label>
                <Input
                  disabled={!isEdit}
                  name="emailId"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.emailId || ""}
                  invalid={
                    validation.touched.emailId && validation.errors.emailId
                      ? true
                      : false
                  }
                />
                {validation.touched.emailId && validation.errors.emailId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.emailId}
                  </FormFeedback>
                ) : null}
              </div>
              <FormGroup className="my-4">
                <Label className="form-label">{props.t('Birth Date')}</Label>
                <InputGroup>
                  <Flatpickr
                    // disabled={!isEdit}

                    className="form-control d-block"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                    name="birthDate"
                    onChange={date => {
                      setBirthDate(new Date(date).toISOString()),
                        validation.setFieldValue(
                          "birthDate",
                          new Date(date).toISOString()
                        ),
                        console.log(new Date(date).toISOString())
                    }}
                    onBlur={validation.handleBlur}
                    value={birthDate || ""}
                  />
                </InputGroup>
              </FormGroup>
              <div className="my-4">
                <Label className="form-label">{props.t('Present Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="line1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line1 || ""}
                        invalid={
                          validation.touched.line1 && validation.errors.line1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line1 && validation.errors.line1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="line2"
                        className="form-control"
                        placeholder="Line2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line2 || ""}
                        invalid={
                          validation.touched.line2 && validation.errors.line2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line2 && validation.errors.line2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="state"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.state || ""}
                        invalid={
                          validation.touched.state && validation.errors.state
                            ? true
                            : false
                        }
                      />
                      {validation.touched.state && validation.errors.state ? (
                        <FormFeedback type="invalid">
                          {validation.errors.state}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="pincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pincode || ""}
                        invalid={
                          validation.touched.pincode &&
                            validation.errors.pincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.pincode &&
                        validation.errors.pincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="my-4">
                <Label className="form-label">{props.t('Permanent Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrline1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrline1 || ""}
                        invalid={
                          validation.touched.addrline1 &&
                            validation.errors.addrline1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrline1 &&
                        validation.errors.addrline1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrline1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrline2"
                        className="form-control"
                        placeholder="Line 2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrline2 || ""}
                        invalid={
                          validation.touched.addrline2 &&
                            validation.errors.addrline2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrline2 &&
                        validation.errors.addrline2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrline2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrstate"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrstate || ""}
                        invalid={
                          validation.touched.addrstate &&
                            validation.errors.addrstate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrstate &&
                        validation.errors.addrstate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrstate}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrpincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrpincode || ""}
                        invalid={
                          validation.touched.addrpincode &&
                            validation.errors.addrpincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrpincode &&
                        validation.errors.addrpincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrpincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )
        case 'BUSINESS_DEVELOPMENT_MANAGER':
          return (
            <div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Name')}</Label>
                <Input
                  disabled={!isEdit}
                  name="username"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  invalid={
                    validation.touched.username && validation.errors.username
                      ? true
                      : false
                  }
                />
                {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                ) : null}

              </div>

              <div className="form-group my-4">
                <Label className="form-label">{props.t('Business Name')}</Label>
                <Input
                  disabled={!isEdit}
                  name="businessName"
                  className="form-control"
                  placeholder="Enter Business Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.businessName || ""}
                  invalid={
                    validation.touched.businessName &&
                      validation.errors.businessName
                      ? true
                      : false
                  }
                />
                {validation.touched.businessName &&
                  validation.errors.businessName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.businessName}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="my-4">
                <Label>{props.t('Gender')}</Label>
                <Select
                  isSearchable={false}
                  disabled={!isEdit}
                  onChange={e => {
                    setGender(e.value), setSelectedGender(e), validation.setFieldValue("gender", e.value)
                  }}
                  options={options}
                  classNamePrefix="select2-selection"
                  onBlur={validation.handleBlur}
                  value={selectedGender}
                />
              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="tel"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber || ""}
                  invalid={
                    validation.touched.phoneNumber &&
                      validation.errors.phoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.phoneNumber &&
                  validation.errors.phoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Alternate Phone Number')}</Label>
                <Input
                  disabled={!isEdit}
                  name="altPhoneNumber"
                  className="form-control"
                  placeholder="Enter Alternate Phone Number"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.altPhoneNumber || ""}
                  invalid={
                    validation.touched.altPhoneNumber &&
                      validation.errors.altPhoneNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.altPhoneNumber &&
                  validation.errors.altPhoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.altPhoneNumber}
                  </FormFeedback>
                ) : null}

              </div>
              <div className="form-group my-4">
                <Label className="form-label">{props.t('Email')}</Label>
                <Input
                  disabled={!isEdit}
                  name="emailId"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.emailId || ""}
                  invalid={
                    validation.touched.emailId && validation.errors.emailId
                      ? true
                      : false
                  }
                />
                {validation.touched.emailId && validation.errors.emailId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.emailId}
                  </FormFeedback>
                ) : null}
              </div>
              <FormGroup className="my-4">
                <Label className="form-label">{props.t('Birth Date')}</Label>
                <InputGroup>
                  <Flatpickr
                    // disabled={!isEdit}

                    className="form-control d-block"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                    name="birthDate"
                    onChange={date => {
                      setBirthDate(new Date(date).toISOString()),
                        validation.setFieldValue(
                          "birthDate",
                          new Date(date).toISOString()
                        ),
                        console.log(new Date(date).toISOString())
                    }}
                    onBlur={validation.handleBlur}
                    value={birthDate || ""}
                  />
                </InputGroup>
              </FormGroup>
              <div className="my-4">
                <Label className="form-label">{props.t('Present Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="line1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line1 || ""}
                        invalid={
                          validation.touched.line1 && validation.errors.line1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line1 && validation.errors.line1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="line2"
                        className="form-control"
                        placeholder="Line2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.line2 || ""}
                        invalid={
                          validation.touched.line2 && validation.errors.line2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.line2 && validation.errors.line2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.line2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="state"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.state || ""}
                        invalid={
                          validation.touched.state && validation.errors.state
                            ? true
                            : false
                        }
                      />
                      {validation.touched.state && validation.errors.state ? (
                        <FormFeedback type="invalid">
                          {validation.errors.state}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="pincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pincode || ""}
                        invalid={
                          validation.touched.pincode &&
                            validation.errors.pincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.pincode &&
                        validation.errors.pincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="my-4">
                <Label className="form-label">{props.t('Permanent Address')}</Label>
                <Row>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 1</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrline1"
                        className="form-control"
                        placeholder="Line 1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrline1 || ""}
                        invalid={
                          validation.touched.addrline1 &&
                            validation.errors.addrline1
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrline1 &&
                        validation.errors.addrline1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrline1}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Line')} 2</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrline2"
                        className="form-control"
                        placeholder="Line 2"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrline2 || ""}
                        invalid={
                          validation.touched.addrline2 &&
                            validation.errors.addrline2
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrline2 &&
                        validation.errors.addrline2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrline2}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('State')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrstate"
                        className="form-control"
                        placeholder="Enter Your State"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrstate || ""}
                        invalid={
                          validation.touched.addrstate &&
                            validation.errors.addrstate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrstate &&
                        validation.errors.addrstate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrstate}
                        </FormFeedback>
                      ) : null}

                    </div>
                  </Col>
                  <Col lg={6} md={6} xs={6} sm={6}>
                    <div className="form-group">
                      <Label className="form-label">{props.t('Pincode')}</Label>
                      <Input
                        disabled={!isEdit}
                        name="addrpincode"
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addrpincode || ""}
                        invalid={
                          validation.touched.addrpincode &&
                            validation.errors.addrpincode
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addrpincode &&
                        validation.errors.addrpincode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addrpincode}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className=" my-5">
                <Row>
                  <Col lg={4} className="">
                    <Label className="form-label">{props.t('Upload Aadhar')}</Label>
                    <Input
                      type="file"
                      disabled={!isEdit}
                      id="aadhar"
                      name="aadhar"
                      accept="image/*"
                      onChange={changeHandler}
                    />
                  </Col>
                  <Col lg={2}>
                    <div className="">
                      {fileDataAadharURL ? (
                        // <a href={fileDataAadharURL} target="_blank">
                        <img
                          src={fileDataAadharURL}
                          alt="fileAadhar"
                          style={{ height: "80px", width: "80px" }}
                        />
                        // </a>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )
      }
    }
    catch (error) {
      console.log(error);
    }
  }
  const handleButtonClick = () => {
    const input = document.getElementById('imageUpload');
    input.click();
  };

  useEffect(() => {
    const func = async () => {
      if (props.viewUser) {
        setPhoneNumber(props.selectedUser?.phoneNumber)
        getUser(props.selectedUser?.phoneNumber)
      } else {
        const loggedInUser = JSON.parse(await localStorage.getItem("user"))

        setPhoneNumber(loggedInUser?.user?.phoneNumber)
        getUser(loggedInUser?.user?.phoneNumber)
      }
    }
    func()
  }, [])

  useEffect(() => {

  }, [isEdit])


  document.title = `${props.t("Profile")} | OneTKT`

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {!props?.viewUser && (
            <div>
              {/* Render Breadcrumb */}
              <Breadcrumb title="OneTKT" breadcrumbItem={`${props.t("Profile")}`} />
            </div>
          )}

          <Row>
            <Col lg="12">
              {errorMessage && errorMessage ? (
                <Alert color="danger">{errorMessage}</Alert>
              ) : null}
              {successMessage ? (
                <Alert color="success">{successMessage}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <div className="position-relative">
                        <img
                          src={profile ? profile : user?.avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                        <i
                          className="mdi mdi-pencil mdi-18px position-absolute"
                          style={{
                            left: "40%",
                            bottom: "-24px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleButtonClick()
                          }}
                        />
                      </div>
                      <input
                        type="file"
                        id='imageUpload'
                        accept="image/*"
                        name="profile"
                        style={{ display: "none" }}
                        onChange={e => {
                          changeHandler(e)
                        }}
                      />
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{user?.userName} (Id:{user?.userId})</h5>
                        <p className="mb-1">
                          {user?.phoneNumber} /{" "}
                          {user?.profile?.alternatePhoneNumber}
                        </p>
                        <p className="mb-0">{user?.role ? sentenceCase(user?.role) : user?.role}</p>
                        <p className="mb-0">{user?.profile?.gender}</p>
                        <p className="mb-0">{user?.profile?.businessName}</p>
                        <p className="mb-0">
                          {props.t('D.O.B.')} -{" "}
                          {formatToDisplayDate(user?.profile?.birthDate)}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">
            {props.t("Update Profile")}
            <i
              className="mx-3 mdi mdi-pencil"
              style={{ cursor: "pointer" }}
              onClick={() => setIsEdit(!isEdit)}
            />
          </h4>

          <Card className={`${!isEdit ? "bg-secondary" : ""}`}>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                {
                  fieldsToRender(user?.role)
                }
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Submit
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any
}

export default withTranslation()(UserProfile);
