import { callApi } from "helpers/api_helper"
import PropTypes from 'prop-types';
import { formatDate } from "helpers/Utils/formatTime"
import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
} from "reactstrap"
import { withTranslation } from "react-i18next";

const ViewBookingByBusModal = ({
  modalOpen,
  toggleModal,
  bookingReqList = [],
  getTickets,
  t
}) => {
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const handleCancel = async id => {
    try {
      // const error = await validate();
      // if (Object.keys(error).length > 0) {
      //     return false;
      // }
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/cancelBookings",
        data: {
          id,
        },
      }
      const response = await callApi(config)

      if (response.data.code === 200) {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        // setTicketList(response.data.data);
        toggleModal()
        await getTickets()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal} style={{ background: "#ECECEC" }}>
          <Label>{t('All Bookings')}</Label>
        </ModalHeader>
        <ModalBody style={{ background: "#ECECEC" }}>
          <Row>
            {bookingReqList.map(br => {
              const seats = Object.values(br.selectedSeats)
              const seatNumbers = seats.map(
                (item, i) =>
                  `${item.seatNumber} ${i !== seats.length - 1 ? ", " : ""}`
              )
              return (
                <Col xs={12} md={6} key={item._id}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                          <tbody>
                            {/* <tr>

                                                                <th scope="col">Ticket No.</th> <td>{br?.bookingRequestId}</td>
                                                            </tr> */}
                            <tr>
                              <th scope="col">{t('Bus Redg. No')}</th>
                              <td>{br?.bus?.busRegistrationNumber}</td>
                            </tr>
                            <tr>
                              <th scope="col">{t('Route')}</th>{" "}
                              <td>
                                {br?.route?.startPoint?.station} to <br />
                                {br?.route?.endPoint?.station}
                              </td>
                            </tr>
                            <tr>
                              <th scope="col">{t('Date')}</th>
                              <td>{formatDate(br?.createdAt)}</td>
                            </tr>
                            <tr>
                              <th scope="col">{t('Seat Numbers')}</th>
                              <td>{seatNumbers}</td>
                            </tr>
                            <tr className="mb-4">
                              <th scope="col">{t('Extra Seats')}</th>
                              <td colSpan={2}>{br?.extraSeats}</td>
                            </tr>
                            <tr className="mb-4">
                              <th scope="col">{t('Amount')}</th>
                              <td colSpan={2}>{br?.totalAmount}</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-flex justify-content-center align-items-center">
                          <Button
                            className="w-100 my-3"
                            size="md"
                            color="danger"
                            outline
                            onClick={() => handleCancel(br?._id)}
                          >
                            {t('Cancel')}
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          </Row>
          {/* <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th scope="col"> Partner</th>
                                    <th scope="col">Bus Redg. No</th>
                                    <th scope="col">Route</th>
                                    <th scope="col"> From</th>
                                    <th scope="col">To</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Total Amount</th>
                                    <th scope="col">Amount Received</th>
                                    <th scope="col">Amount Due</th>

                                    <th scope="col">Customer Phone Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bookingReqList?.map((bus, i) => {
                                        const route = `${bus?.route?.startPoint?.station} to ${bus?.route?.endPoint?.station}`;
                                        return <tr key={bus?._id}>

                                            <td>{bus?.bus?.busName}</td>
                                            <td>{bus?.bus?.busRegistrationNumber}</td>
                                            <td>{route}</td>
                                            <td>{bus?.route?.startPoint?.station}</td>
                                            <td>{bus?.route?.endPoint?.station}</td>
                                            <td>{formatDate(bus?.createdAt)}</td>
                                            <td>{bus?.totalAmount}</td>
                                            <td>{bus?.totalReceivedAmount}</td>
                                            <td>{bus?.totalReceivedAmount - bus?.totalAmount}</td>

                                            <td>{bus?.phoneNumber}</td>
                                        </tr>
                                    })}



                            </tbody>
                        </table>
                    </div> */}
        </ModalBody>
      </Modal>
    </div>
  )
}
ViewBookingByBusModal.propType = {
  t: PropTypes.any
}
export default withTranslation()(ViewBookingByBusModal);
