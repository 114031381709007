import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Container, Input, Label, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import Select from 'react-select';


import Breadcrumb from "../../components/Common/Breadcrumb";

const busOptions = [
    {
        label: 'Pradhan Travels',
        value: "PradhanTravels"
    },
    {
        label: 'Gurudas Travels',
        value: "GurudasTravels"
    },
    {
        label: 'Gondu Travels',
        value: "GonduTravels"
    },
];
const layerOptions = [
    {
        label: '1 + 1 / Upper / Sleeper',
        value: "1+1"
    },
    {
        label: '2 + 2 / Lower / Seater',
        value: "1+1"
    },
];
const SeatManagement = () => {
    document.title = "Seat-Management | OneTKT";

    return (


        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="OneTKT" breadcrumbItem="SeatManagement" />

                    <h4 className="card-title mb-4">Seat Management</h4>
                    <Card>
                        <CardBody>
                            <div className='text-end'>
                                <Button color='success' outline className='mx-1' onClick={() => setIsRouteModalOpen(true)}>Add a Route</Button>
                            </div>
                            <div className='my-2'>
                                <Label>Select Bus</Label>
                                <Select
                                    isSearchable={false}
                                    options={busOptions}
                                />
                            </div>

                            <div className='my-2'>
                                <Label>Select Layer</Label>
                                <Select
                                    isSearchable={false}
                                    options={layerOptions}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Container>

            </div>
        </React.Fragment>
    )
}

export default SeatManagement;