import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap"
import Select from "react-select"
import * as XLSX from "xlsx"
import Switch from "react-switch"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import DownloadPdf from "pages/Utility/DownloadPdf"

import Breadcrumb from "../../components/Common/Breadcrumb"
import { capitalCase, sentenceCase } from "change-case"
import { callApi } from "helpers/api_helper"
import ViewRouteModal from "pages/Modals/ViewRouteModal"
import AssignBusCrew from "./AssignBusCrew"
import BusLayout from "./BusLayout"
import EditBusModal from "pages/Modals/EditBusModal"
import AssignStaffModal from "pages/Modals/AssignStaffModal"
import DeactivateBusModal from "pages/Modals/DeactivateBusModal"
import Pagination from "helpers/Utils/Pagination"
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';

const busTypeOptions = [
  {
    label: "AC",
    value: "ac",
  },
  {
    label: "Non-AC",
    value: "non-ac",
  },
]
const layerOptions = [
  {
    label: "1 + 1",
    value: "1+1",
  },
  {
    label: "1 + 1 + 1",
    value: "1+1+1",
  },
  {
    label: "2 + 1",
    value: "2+1",
  },
  {
    label: "2 + 2",
    value: "2+2",
  },
  {
    label: "2 + 3",
    value: "2+3",
  },
  {
    label: "3 + 1",
    value: "3+1",
  },
  {
    label: "4 + 0",
    value: "4+0",
  },
]
const berthTypeOptions = [
  {
    label: "Lower",
    value: "lower",
  },
  {
    label: "Upper",
    value: "upper",
  },
]
const seatTypeOptions = [
  {
    label: "Seater",
    value: "seater",
  },
  {
    label: "Sleeper",
    value: "sleeper",
  },
  {
    label: "Seater + Sleeper",
    value: "seater+sleeper",
  },
]

const Buses = (props) => {
  const [acOrNonAc, setAcOrNonAc] = useState(null)
  const [seaterrGrid, setSeaterrGrid] = useState(null)
  const [sleeperGrid, setSleeperGrid] = useState(null)
  const [busName, setBusName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [alternateNumber, setAlternateNumber] = useState("")
  const [busRegistrationNumber, setBusRegistrationNumber] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [busList, setBusList] = useState([])
  const [userList, setUserList] = useState([])
  const [errors, setErrors] = useState({})
  const [isBusModalOpen, setIsBusModalOpen] = useState(false)
  const [isEditBusModal, setIsEditBusModal] = useState(false)
  const [isViewBusModalOpen, setIsViewBusModalOpen] = useState(false)
  const [isLayerModalOpen, setIsLayerModalOpen] = useState(false)
  const [isDeactivateBusModalOpen, setIsDeactivateBusModalOpen] =
    useState(false)
  const [switch1, setSwitch1] = useState(true)
  const [switch2, setSwitch2] = useState(true)

  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false)
  const [tableContent, setTableContent] = useState({})
  const [rows, setRows] = useState({})
  const [users, setUsers] = useState([])
  const [seatType, setSeatType] = useState("seater")
  const [layerList, setLayerList] = useState([])
  const [routeList, setRouteList] = useState([])
  const [selectedBus, setSelectedBus] = useState({})
  const [isStaffModalOpen, setIsStaffModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [roleOptions, setRoleOptions] = useState([])

  const [uploadedFiles, setUploadedFiles] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [newLinked, setNewLinked] = useState([])
  const [newLinkedLayer, setNewLinkedLayer] = useState([])
  const [pdfContent, setPdfContent] = useState()
  const [selectedPartner, setSelectedPartner] = useState()
  const [partnerOptions, setPartnerOptions] = useState([])
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [LoggedInuser, setLoggedInUser] = useState({})
  const [isAssignLoading, setIsAssignLoading] = useState(false)
  const pageSizes = [1, 2, 4, 8, 10, 20, 50, 100, 500, 1000]

  const startIndex = (currentPage - 1) * selectedPageSize
  const endIndex = currentPage * selectedPageSize

  const changePageSize = async size => {
    setCurrentPage(1)
    setSelectedPageSize(size)
    await getAllBuses(size, 1)
  }

  document.title = `${props.t("Buses")} | OneTKT`

  const resetFields = () => {
    setSelectedPartner(null)
  }
  const resetFormFields = () => {
    setSelectedPartner(null)
    setBusName("")
    setBusRegistrationNumber("")
    setPhoneNumber("")
    setAlternateNumber("")
    setAcOrNonAc(null)
    setUploadedFiles([])
  }

  const validate = () => {
    let error = {}
    if (LoggedInuser.user?.role !== "PARTNER") {
      if (!selectedPartner || !selectedPartner.value) {
        error.selectedPartner = "Partner Name Required!"
      }
    }
    if (!busName) {
      error.busName = "Bus Name Required!"
    }
    if (!busRegistrationNumber) {
      error.busRegistrationNumber = "Bus Registration Number Required!"
    }
    if (!phoneNumber) {
      error.phoneNumber = "Phone Number Required!"
    }
    if (!alternateNumber) {
      error.alternateNumber = "Alternate Number Required!"
    }
    if (!acOrNonAc) {
      error.acOrNonAc = "Please choose one option!"
    }
    setErrors(error)
    return error
  }

  const getAllRoles = async () => {
    const config = {
      method: "get",
      url: "roles/getRoleRoutes/getAllUserRoles",
    }
    const response = await callApi(config)

    if (response.data.code === 201) {
      const tempArr = response.data.data.map(item => {
        return {
          label: sentenceCase(item.roleName),
          value: item.roleName,
          data: item,
        }
      })

      setRoleOptions(
        tempArr.filter(
          item =>
            item.value === "SITE_MANAGER" ||
            item.value === "BUSINESS_DEVELOPMENT_MANAGER" ||
            item.value === "PARTNER"
        )
      )
    }
  }

  const getAllUsers = async () => {
    const config = {
      method: "post",
      url: "user/getUserRoutes/getAll",
    }
    const response = await callApi(config)
    if (response.data.code === 200) {
      setUserList(response.data.data)
      const userArr = response.data.data.map(item => {
        return {
          label: item.userName,
          value: item._id,
          data: item,
        }
      })
      setPartnerOptions(userArr.filter(item => item.data.role === "PARTNER"))
    }
  }

  const getAllBuses = async (size, tCurrentPage) => {
    try {
      const config = {
        method: "post",
        url: "bus/getBusRoutes/getAllActiveBuses",
        data: {
          pageSize: size,
          currentPage: tCurrentPage,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setBusList(response.data.data)
        setIsLoading(false)
        setTotalItemCount(response.data.totalCount)
        setTotalPage(Math.ceil(response.data.totalCount / size))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async () => {
    try {
      const error = await validate()
      if (Object.keys(error).length > 0) {
        return false
      }

      const config = {
        method: "post",
        url: "bus/getBusRoutes/addBus",
        data: {
          busName,
          busRegistrationNumber,
          acOrNonAc: acOrNonAc?.value,
          phoneNumber,
          alternateNumber,
          partner:
            LoggedInuser.user?.role === "PARTNER"
              ? LoggedInuser?.user?._id
              : selectedPartner?.value,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)

        setIsBusModalOpen(false)
        await getAllBuses()

        resetFormFields()
      } else {
        setSuccessMessage(null)
        setErrorMessage(response.data.message)
        setTimeout(() => {
          setErrorMessage(null)
        }, 2000)

        setIsBusModalOpen(false)
      }
    } catch (error) {
      console.log(error)
      setSuccessMessage(null)
      setErrorMessage("Something Went wrong!")
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      setIsBusModalOpen(false)
    }
  }

  function setSeatNumber(key, value) {
    let tempData = { ...tableContent }
    tempData[key].seatNumber = value
    setTableContent(tempData)
  }

  function setRowsFunction() {
    const rows_ = []

    for (let i = 0; i < 30; i++) {
      const cells = []

      for (let j = 0; j < 8; j++) {
        const key = `_${i}_${j}`
        let cellContent = null
        if (tableContent[key] && tableContent[key]?.seatType == "seater") {
          cellContent = tableContent[key] ? (
            <div className="d-flex flex-column justify-content-center align-items-center align-content-center">
              <i className="mdi mdi-seat-outline mdi-24px" />

              <input
                className="border rounded border-0 mb-2"
                value={tableContent[key]?.seatNumber}
                onChange={e => {
                  setSeatNumber(key, e.target.value)
                }}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                style={{ width: "50px", height: "20px", textAlign: "center" }}
              />
            </div>
          ) : null
        }
        if (tableContent[key] && tableContent[key]?.seatType == "sleeper") {
          cellContent = tableContent[key] ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <i className="mdi mdi-bed-outline mdi-24px" />
              <input
                className="border rounded border-0 mb-2"
                value={tableContent[key]?.seatNumber}
                onChange={e => {
                  setSeatNumber(key, e.target.value)
                }}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                style={{ width: "50px", height: "20px", textAlign: "center" }}
              />
            </div>
          ) : null
        }

        cells.push(
          <td
            onClick={() => {
              TableContentFunction(key)
            }}
            key={key}
            style={{
              width: "60px",
              height: "60px",
              border: "1px solid black",
              padding: 0,
            }}
          >
            {cellContent}
          </td>
        )
      }

      rows_.push(<tr key={i}>{cells}</tr>)
    }
    setRows(rows_)
  }
  function TableContentFunction(key) {
    if (Object.keys(tableContent).includes(key)) {
      let tempData = { ...tableContent }
      delete tempData[key]
      setTableContent(tempData)
    } else {
      setTableContent({
        ...tableContent,
        [key]: {
          seatType,
          seatNumber: (Object.keys(tableContent).length + 1).toString(),
        },
      })
    }
  }

  const getAllLayers = async () => {
    try {
      const config = {
        method: "post",
        url: "layer/getLayerRoutes/getAllLayers",
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setLayerList(response.data.data)
        // const tempArr = response.data.data.map(item => {
        //   if (item?.status === "ASSIGNED") {
        //     return item?._id
        //   }
        // });
        // setNewLinkedLayer(tempArr);
      }
    } catch (error) { }
  }

  const getAllRoutes = async () => {
    const config = {
      method: "post",
      url: "busRoutes/getBus_Routes/getAllRoutes",
    }
    const response = await callApi(config)
    if (response.data.code === 200) {
      setRouteList(response.data.data)
      // const tempArr = response.data.data.map(item => {
      //   if (item?.status === "ASSIGNED") {
      //     return item?._id
      //   }
      // })

      // setNewLinked(tempArr)
      // setIsRouteModalOpen(false);
    }
  }

  const handleAssignRoute = async item => {

    try {
      setIsAssignLoading(true)
      const config = {
        method: "post",
        url: "bus/getBusRoutes/assignRoute",
        data: {
          id: selectedBus?._id,
          route_id: item?._id,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        await getAllBuses()
        await getAllRoutes()
        // setIsRouteModalOpen(!isRouteModalOpen);
        setIsAssignLoading(false)
      } else {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setIsRouteModalOpen(!isRouteModalOpen)
        setIsAssignLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setSuccessMessage(null)
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      setIsRouteModalOpen(!isRouteModalOpen)
      setIsAssignLoading(false)
    }
  }

  const handleChange = async e => {

    const files = e.target.files
    const newUploads = [...uploadedFiles]
    for (let index = 0; index < files.length; index++) {
      const element = files[index]
      newUploads.push({
        url: URL.createObjectURL(element),
        name: element.name,
      })
    }
    setUploadedFiles(newUploads)
    // setUploadedFiles(files.map((item) => {
    //     return URL.createObjectURL(item);
    // }))
  }

  const handleAssignLayer = async item => {
    try {
      setIsAssignLoading(true)
      const config = {
        method: "post",
        url: "bus/getBusRoutes/assignLayer",
        data: {
          id: selectedBus?._id,
          layer_id: item?._id,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        await getAllBuses()
        await getAllUsers()
        await getAllLayers()
        // setIsLayerModalOpen(false);
        setIsAssignLoading(false)
      } else {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setIsLayerModalOpen(false)
        setIsAssignLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setSuccessMessage(null)
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      setIsLayerModalOpen(false)
      setIsAssignLoading(false)
    }
  }

  const handleDeleteLayer = async item => {
    try {
      setIsAssignLoading(true)
      const config = {
        method: "post",
        url: "bus/getBusRoutes/unlinkLayer",
        data: {
          id: selectedBus?._id,
          layer_id: item?._id,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        await getAllBuses()
        await getAllUsers()
        await getAllLayers()
        setIsAssignLoading(false)
        // setIsLayerModalOpen(false)
      } else {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setIsLayerModalOpen(false)
        setIsAssignLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setSuccessMessage(null)
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      setIsLayerModalOpen(false)
      setIsAssignLoading(false)
    }
  }

  const handleDeleteRoute = async item => {
    try {
      setIsAssignLoading(true)
      const config = {
        method: "post",
        url: "bus/getBusRoutes/unlinkRoute",
        data: {
          id: selectedBus?._id,
          route_id: item?._id,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        await getAllBuses()
        await getAllUsers()
        await getAllRoutes()
        setIsAssignLoading(false)
        // setIsRouteModalOpen(!isRouteModalOpen)
      } else {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setIsRouteModalOpen(!isRouteModalOpen)
        setIsAssignLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setSuccessMessage(null)
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      setIsRouteModalOpen(!isRouteModalOpen)
      setIsAssignLoading(false)
    }
  }

  const handleLinkedRoutes = (item, status) => {
    let a = newLinked
    if (status) {
      a.push(item?._id)
      handleAssignRoute(item)
    } else {
      let index = a.indexOf(item?._id)
      a.splice(index, 1)
      handleDeleteRoute(item)
    }
    setNewLinked([...a])
  }

  const handleLinkedLayers = (item, status) => {
    let a = newLinkedLayer
    if (status) {
      a.push(item?._id)
      handleAssignLayer(item)
    } else {
      let index = a.indexOf(item?._id)
      a.splice(index, 1)
      handleDeleteLayer(item)
    }
    setNewLinkedLayer([...a])
  }

  const handleLinkLayers = item => {
    setSelectedBus(item)
    setIsLayerModalOpen(true)
    let a = newLinkedLayer
    const x = layerList?.map(layer => {

      if (layer?.busId.includes(item?._id)) {
        return layer?._id
      }
    })

    setNewLinkedLayer(x.filter(layer => layer))
  }

  const handleLinkRoutes = item => {
    setIsRouteModalOpen(true)
    setSelectedBus(item)
    const x = routeList?.map(route => {

      if (route?.busId.includes(item?._id)) {
        return route?._id
      }
    })
    const y = routeList?.filter(route => route?.busId.includes(item?._id))
    const z = y.map(it => it._id)

    setNewLinked(x.filter(it => it))
  }

  const getPartnerStaffs = async (item) => {
    try {
      const config = {
        method: 'post',
        url: "bus/getBusRoutes/getPartnerStaffs",
        data: {
          id: item?._id,
        },
      }
      const response = await callApi(config);
      if (response.data.code === 200) {
        setUsers(response.data.data.partnerStaffs);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleAssignStaff = async item => {
    setSelectedBus(item)
    await getPartnerStaffs(item);
    setIsStaffModalOpen(true)

    // setUsers(item?.partnerStaffs)
  }

  const Linkedsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingLeft: 10,
        }}
      >
        {" "}
        {props.t('Link')}
      </div>
    )
  }

  const Unlinkedsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 10,
        }}
      >
        {" "}
        {props.t('UnLink')}
      </div>
    )
  }

  useEffect(() => {
    setRowsFunction()
  }, [tableContent])

  useEffect(() => {
    if (selectedBus?.layerId?.seatType === "SEATER") {
      setSeaterrGrid(selectedBus?.layerId?.availableSeats)
    } else {
      setSleeperGrid(selectedBus?.layerId?.availableSeats)
    }
    // const tempArr = layerList.filter(
    //   item => !selectedBus?.layerId?.includes(item._id)
    // )

    // setNewLinkedLayer(tempArr)
  }, [selectedBus])

  useEffect(() => {
    const func = async () => {
      await getAllLayers()
      await getAllRoutes()
      await getAllUsers()
      await getAllRoles()
    }
    func()
  }, [])

  useEffect(() => {
    setIsLoading(true)
    getAllBuses(selectedPageSize, currentPage)
  }, [currentPage])

  const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })
  const generatePDFContent = jsonData => (
    <Document>
      <Page style={styles.page} size="A2">
        <View style={styles.container}>
          <View style={styles.tableRow}>
            <Text style={styles.headingCell}>Bus Name</Text>
            <Text style={styles.headingCell}>Redg. No.</Text>
            <Text style={styles.headingCell}>AC / Non-AC</Text>
            <Text style={styles.headingCell}>Contact No</Text>
            <Text style={styles.headingCell}>Alternate No</Text>
          </View>
          {jsonData.map((item, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.valueCell}>{item?.busName}</Text>
                <Text style={styles.valueCell}>
                  {item?.busRegistrationNumber}
                </Text>
                <Text style={styles.valueCell}>
                  {item?.acOrNonAc.toUpperCase()}
                </Text>
                <Text style={styles.valueCell}>{item?.phoneNumber} </Text>
                <Text style={styles.valueCell}>{item?.alternateNumber} </Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )
  useEffect(() => {

    setPdfContent(generatePDFContent(busList))
  }, [busList])
  useEffect(() => {
    const fun = async () => {
      setLoggedInUser(JSON.parse(await localStorage.getItem("user")))
    }
    fun()
  }, [])

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="" color="danger" />
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="OneTKT" breadcrumbItem="Bus" />

            {errorMessage && errorMessage ? (
              <Alert color="primary">{errorMessage}</Alert>
            ) : null}
            {successMessage ? (
              <Alert color="success">{successMessage}</Alert>
            ) : null}

            <h4 className="card-title mb-4">{props.t('All Buses')}</h4>

            <Card>
              <CardBody>
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mx-1">
                    <Button
                      color="success"
                      outline
                      onClick={() => setIsBusModalOpen(true)}
                    >
                      {props.t('Add a Bus')}
                    </Button>
                  </div>
                  <div className="mx-1">
                    <Button color="success">
                      <DownloadPdf
                        className={`mdi mdi-download me-2`}
                        pdfContent={pdfContent}
                        fileName={"Buses"}
                      />
                    </Button>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr className="text-center">
                        <th>{props.t('Bus Name')}</th>
                        <th>{props.t('Redg. No.')}</th>
                        <th>{props.t('AC / Non-AC')}</th>
                        <th>{props.t('Contact No')}</th>
                        <th>{props.t('Alternate No')}</th>
                        <th>{props.t('Actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {busList?.map((item, index) => {
                        return (
                          <tr className="text-center" key={item._id}>
                            <td>{item?.busName}</td>
                            <td>{item?.busRegistrationNumber}</td>
                            <td>{item?.acOrNonAc.toUpperCase()}</td>
                            <td>{item?.phoneNumber}</td>
                            <td>{item?.alternateNumber}</td>
                            <td>
                              <ButtonGroup>
                                <Button
                                  color="primary"
                                  outline
                                  size="sm"
                                  // className="mx-1"
                                  onClick={() => {
                                    //   setIsEdit(!isEdit)
                                    setSelectedBus(item),
                                      setIsEditBusModal(true)
                                  }}
                                >
                                  <i
                                    className="mx-3 mdi mdi-pencil"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Button>

                                <Button
                                  color="primary"
                                  outline
                                  size="sm"
                                  // className="mx-1"
                                  onClick={() => {
                                    handleLinkLayers(item)
                                  }}
                                >
                                  {props.t('Link Layer')}
                                  {/* {item?.layerStatus === "ASSIGNED"
                                  ? "Linked Layer "
                                  : "Link Layer"} */}
                                </Button>
                                <Button
                                  color="primary"
                                  outline
                                  size="sm"
                                  // className="mx-1"
                                  onClick={() => {
                                    handleLinkRoutes(item)
                                  }}
                                >
                                  {" "}
                                  {props.t('Link Route')}
                                  {/* {item?.routeStatus === "ASSIGNED"
                                  ? "Linked Route"
                                  : "Link Route"} */}
                                </Button>
                                <Button
                                  color="primary"
                                  outline
                                  size="sm"
                                  // className="mx-1"
                                  onClick={() => {
                                    handleAssignStaff(item)
                                  }}
                                >
                                  {" "}
                                  {props.t('Assign Staff')}
                                  {/* {item?.staffStatus === "ASSIGNED"
                                  ? "Assigned Staff"
                                  : "Assign Staff"} */}
                                </Button>
                                <Button
                                  color="success"
                                  outline
                                  size="sm"
                                  // className="mx-1"
                                  onClick={() => {
                                    setIsViewBusModalOpen(true),
                                      setSelectedBus(item)
                                  }}
                                >
                                  {props.t('View Bus')}
                                </Button>
                                <Button
                                  color={item?.isActive ? "danger" : "success"}
                                  outline
                                  size="sm"
                                  // className="mx-1"
                                  onClick={() => {
                                    //   setIsEdit(!isEdit)
                                    setIsDeactivateBusModalOpen(true),
                                      setSelectedBus(item)
                                  }}
                                >
                                  {item?.isActive ? props.t("Deactivate") : props.t("Activate")}
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentPage={currentPage}
                  totalPage={totalPage}
                  onChangePage={i => setCurrentPage(i)}
                />
              </CardBody>
            </Card>

            {/* Add Bus Modal Start */}
            <Modal
              isOpen={isBusModalOpen}
              toggle={() => setIsBusModalOpen(!isBusModalOpen)}
              centered={true}
              onClosed={() => resetFormFields()}
            >
              <ModalHeader toggle={() => setIsBusModalOpen(!isBusModalOpen)}>
                <Label>{props.t('Add A Bus')}</Label>
              </ModalHeader>
              <ModalBody>
                {LoggedInuser.user?.role !== "PARTNER" && (
                  <div className="my-2">
                    <Label>{props.t('Select Partner')}</Label>
                    <Select
                      isSearchable={false}
                      onChange={e => {
                        setSelectedPartner(e)
                      }}
                      options={partnerOptions}
                      classNamePrefix="select2-selection"
                      value={selectedPartner}
                    />

                    {errors.selectedPartner && (
                      <p className="text-danger">{errors?.selectedPartner}</p>
                    )}
                  </div>
                )}

                <div className="my-2">
                  <Label>{props.t('Bus Name')}</Label>
                  <Input
                    placeholder="Bus Name"
                    value={busName}
                    onChange={e => setBusName(e.target.value)}
                  />
                  {errors.busName && (
                    <p className="text-danger">{errors?.busName}</p>
                  )}
                </div>
                <div className="my-2">
                  <Label>{props.t('Bus Registration Number')}</Label>
                  <Input
                    placeholder="Bus Registration Number"
                    value={busRegistrationNumber}
                    onChange={e => setBusRegistrationNumber(e.target.value)}
                  />
                  {errors.busRegistrationNumber && (
                    <p className="text-danger">
                      {errors?.busRegistrationNumber}
                    </p>
                  )}
                </div>
                <div className="my-2">
                  <Label>{props.t('Bus Contact Number')}</Label>
                  <Input
                    type="tel"
                    placeholder="Bus Contact Number"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                  />
                  {errors.phoneNumber && (
                    <p className="text-danger">{errors?.phoneNumber}</p>
                  )}
                </div>
                <div className="my-2">
                  <Label>{props.t('Alternate Contact Number')}</Label>
                  <Input
                    placeholder="Alternate Contact Number"
                    value={alternateNumber}
                    type="tel"
                    onChange={e => setAlternateNumber(e.target.value)}
                  />
                  {errors.alternateNumber && (
                    <p className="text-danger">{errors?.alternateNumber}</p>
                  )}
                </div>
                <div className="my-2">
                  <Label>{props.t('Upload Documents')}</Label>
                  <Input
                    type="file"
                    multiple
                    accept="image/*"
                    placeholder="Upload Documents"
                    onChange={handleChange}
                  />
                  <Row>
                    {uploadedFiles.length > 0
                      ? uploadedFiles.map((item, index) => {

                        return (
                          <Col xs={3} className="my-2" key={item?.url}>
                            <a
                              href={item?.url}
                              target="_blank"
                              key={`file${index}`}
                              className="d-flex justify-content-start align-items-center flex-column"
                            >
                              <img
                                src={item?.url}
                                alt={`file${index}`}
                                style={{ height: "80px", width: "80px" }}
                              />
                              <span>{item?.name}</span>
                            </a>
                          </Col>
                        )
                      })
                      : null}
                  </Row>
                </div>
                <div className="my-2">
                  <Label>{props.t('AC / Non-AC')}</Label>
                  <Select
                    isSearchable={false}
                    options={busTypeOptions}
                    placeholder="Choose Bus Type"
                    value={acOrNonAc}
                    onChange={e => setAcOrNonAc(e)}
                  />
                  {errors.acOrNonAc && (
                    <p className="text-danger">{errors?.acOrNonAc}</p>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    color="danger"
                    outline
                    className="mx-1"
                    onClick={() => {
                      setIsBusModalOpen(!isBusModalOpen)
                    }}
                  >
                    {props.t('Cancel')}
                  </Button>
                  <Button
                    color="primary"
                    outline
                    className="mx-1"
                    onClick={handleSubmit}
                  >
                    {props.t('Submit')}
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
            {/* Add Bus Modal End */}

            {/* Deactivate Bus Modal start */}
            <DeactivateBusModal
              modalOpen={isDeactivateBusModalOpen}
              toggleModal={() =>
                setIsDeactivateBusModalOpen(!isDeactivateBusModalOpen)
              }
              selectedBus={selectedBus}
              getAllBuses={getAllBuses}
            />
            {/* Deactivate Bus Modal End */}

            {/* Edit Bus Modal Start */}
            <EditBusModal
              modalOpen={isEditBusModal}
              toggleModal={() => setIsEditBusModal(!isEditBusModal)}
              selectedBus={selectedBus}
              getAllBuses={getAllBuses}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              busTypeOptions={busTypeOptions}
              options={partnerOptions}
            />
            {/* Edit Bus Modal End */}

            {/* ASSIGN ROUTE MODAL START */}
            <Modal
              isOpen={isRouteModalOpen}
              toggle={() => setIsRouteModalOpen(!isRouteModalOpen)}
              size="lg"
              centered={true}
            >
              <ModalHeader
                toggle={() => setIsRouteModalOpen(!isRouteModalOpen)}
              >
                <Label className="col-md-12">{props.t('Your Routes')}</Label>
              </ModalHeader>
              <ModalBody>
                <Table>
                  <thead>
                    <tr>
                      <th>{props.t('Start Point')}</th>
                      <th>{props.t('End Point')}</th>
                      <th>{props.t('Actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {routeList?.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>
                            {item?.startPoint?.station}{" "}
                            {item?.startPoint?.subStation}
                          </td>
                          <td>
                            {item?.endPoint?.station}{" "}
                            {item?.endPoint?.subStation}
                          </td>
                          <td>
                            <Switch
                              borderRadius={10}
                              uncheckedIcon={<Unlinkedsymbol />}
                              checkedIcon={<Linkedsymbol />}
                              onColor="#626ed4"
                              offColor="#ec4561"
                              height={29}
                              width={95}
                              outline={item?.status !== "ASSIGNED"}
                              onChange={e => {
                                setSwitch2(!switch2)
                                handleLinkedRoutes(item, e)
                              }}
                              disabled={isAssignLoading}
                              checked={newLinked.includes(item?._id)}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    color="danger"
                    outline
                    className="mx-1"
                    onClick={() => {
                      setIsRouteModalOpen(!isRouteModalOpen)
                      resetFields()
                    }}
                  >
                    {props.t('Close')}
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
            {/* ASSIGN ROUTE MODAL END */}

            {/* ASSIGN LAYER MODAL START */}
            <Modal
              isOpen={isLayerModalOpen}
              toggle={() => setIsLayerModalOpen(!isLayerModalOpen)}
              size="lg"
              centered={true}
            // isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}
            >
              <ModalHeader
                toggle={() => setIsLayerModalOpen(!isLayerModalOpen)}
              >
                <Label>{props.t('Your Layers')}</Label>
              </ModalHeader>
              <ModalBody>
                <div className="text-end"></div>

                <Table>
                  <thead>
                    <tr>
                      <th>{props.t('Grid Type')}</th>
                      <th>{props.t('Berth Type')}</th>
                      <th>{props.t('Seat Type')}</th>
                      <th>{props.t('Actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {layerList?.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>
                            {item?.gridType?.map(
                              (x, i) =>
                                `${x} ${i === item?.gridType?.length - 1 ? "" : ", "
                                }`
                            )}
                          </td>
                          <td>{sentenceCase(item?.berthType)}</td>
                          <td>{item?.seatType}</td>
                          <td>
                            <Switch
                              borderRadius={10}
                              uncheckedIcon={<Unlinkedsymbol />}
                              checkedIcon={<Linkedsymbol />}
                              onColor="#626ed4"
                              offColor="#ec4561"
                              height={26}
                              width={82}
                              outline={item?.status !== "ASSIGNED"}
                              onChange={e => {
                                setSwitch1(!switch1)
                                handleLinkedLayers(item, e)
                                // setIsLayerModalOpen(!isLayerModalOpen)
                              }}
                              disabled={isAssignLoading}
                              checked={newLinkedLayer.includes(item?._id)}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    color="danger"
                    outline
                    className="mx-1"
                    onClick={() => {
                      setIsLayerModalOpen(!isLayerModalOpen)
                      resetFields()
                    }}
                  >
                    {props.t('Close')}
                  </Button>
                  {/* <Button
                    color="primary"
                    outline
                    className="mx-1"
                    onClick={() => setIsLayerModalOpen(!isLayerModalOpen)}
                  >
                    Submit
                  </Button> */}
                </div>
              </ModalFooter>
            </Modal>
            {/* ASSIGN LAYER MODAL END */}

            {/* ASSIGN STAFF MODAL START */}
            <AssignStaffModal
              modalOpen={isStaffModalOpen}
              toggleModal={() => setIsStaffModalOpen(!isStaffModalOpen)}
              selectedBus={selectedBus}
              getAllBuses={getAllBuses}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              users={users}
              Linkedsymbol={Linkedsymbol}
              Unlinkedsymbol={Unlinkedsymbol}
              roleOptions={roleOptions}
              getAllUsers={getAllUsers}
            />
            {/* ASSIGN STAFF MODAL END */}

            {/* VIEW BUS MODAL START */}
            <Modal
              isOpen={isViewBusModalOpen}
              toggle={() => setIsViewBusModalOpen(!isViewBusModalOpen)}
              size="xl"
              centered={true}
            >
              <ModalHeader
                toggle={() => setIsViewBusModalOpen(!isViewBusModalOpen)}
              >
                <Label>{props.t('Bus Details')}</Label>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs={12} md={6}>
                    <Card>
                      <CardHeader>
                        <Label>{props.t('Bus')}</Label>
                      </CardHeader>
                      <CardBody>
                        <Table>
                          <thead>
                            <tr>
                              <th>{props.t('Name')}</th>
                              <th>{props.t('Details')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{props.t('Bus Name')}</td>
                              <td>{selectedBus?.busName}</td>
                            </tr>
                            <tr>
                              <td>{props.t('Registration No.')}</td>
                              <td>{selectedBus?.busRegistrationNumber}</td>
                            </tr>
                            <tr>
                              <td>{props.t('AC / Non AC')}</td>
                              <td>
                                {selectedBus?.acOrNonAc
                                  ? selectedBus?.acOrNonAc.toUpperCase()
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>{props.t('Contact No')}</td>
                              <td>
                                {selectedBus?.phoneNumber} /{" "}
                                {selectedBus?.alternateNumber}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs={12} md={6}>
                    <Card>
                      <CardHeader>
                        <Label>{props.t('Layers')}</Label>
                      </CardHeader>
                      <CardBody>
                        <Table>
                          <thead>
                            <tr>
                              <th>{props.t('Grid Type')}</th>
                              <th>{props.t('Berth Type')}</th>
                              <th>{props.t('Seat Type')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedBus?.layerId?.map(layer => (
                              <tr key={layer._id}>
                                <td>{layer?.gridType}</td>
                                <td>{layer?.berthType}</td>
                                <td>{layer?.seatType}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Card className="shadow">
                  <CardBody>
                    <Row>
                      <Col xs={12} md={2}>
                        {selectedBus?.uploadDocuments?.length > 0 ? (
                          <Row className="text-center">
                            {selectedBus?.uploadDocuments?.map((item, i) => {
                              return (
                                <div key={item?.url}>
                                  <Col
                                    xs={12}
                                    className="d-flex justify-content-center align-items-center flex-column"
                                  >
                                    <Label>Document {i + 1}</Label>
                                    <a href={item?.url} target="_blank">
                                      <img
                                        className="my-2"
                                        src={item?.url}
                                        alt="aadhar"
                                        style={{ height: "50%", width: "100%" }}
                                      />
                                    </a>
                                  </Col>
                                  <hr />
                                </div>
                              )
                            })}
                          </Row>
                        ) : (
                          <div className="">
                            <Label className="fw-bold">{props.t('Documents')}</Label>
                            <p>{props.t('No Documents Uploaded')}</p>
                          </div>
                        )}
                      </Col>
                      {sleeperGrid && (
                        <Col xs={12} md={5}>
                          <BusLayout
                            layerGrid={sleeperGrid}
                            alreadyBooked={
                              selectedBus?.layerId?.alreadyBookedSeats
                            }
                          />
                        </Col>
                      )}
                      {seaterrGrid && (
                        <Col xs={12} md={5}>
                          <BusLayout
                            layerGrid={seaterrGrid}
                            alreadyBooked={
                              selectedBus?.layerId?.alreadyBookedSeats
                            }
                          />
                        </Col>
                      )}
                      <Col xs={12} md={7}>
                        {selectedBus?.routeId && (
                          <Col xs={12} md={9}>
                            <ViewRouteModal
                              selectedRoute={selectedBus?.routeId}
                            />
                          </Col>
                        )}
                      </Col>
                    </Row>
                    <Row></Row>
                  </CardBody>
                </Card>
              </ModalBody>
              <ModalFooter>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    color="danger"
                    outline
                    className="mx-1"
                    onClick={() => {
                      setIsViewBusModalOpen(!isViewBusModalOpen)
                      resetFields()
                    }}
                  >
                    Close
                  </Button>
                  {/* <Button
                    color="primary"
                    outline
                    className="mx-1"
                    onClick={() => setIsViewBusModalOpen(!isViewBusModalOpen)}
                  >
                    Submit
                  </Button> */}
                </div>
              </ModalFooter>
            </Modal>
            {/* VIEW BUS MODAL END */}
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}
Buses.propType = {
  t: PropTypes.any
}
export default withTranslation()(Buses);
