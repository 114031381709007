import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
} from "reactstrap"

import CreatableSelect from "react-select/creatable"
import Select from "react-select"

import { callApi } from "helpers/api_helper"
import { withTranslation } from "react-i18next";

const UpdateRouteModal = ({
  setSuccessMessage,
  setErrorMessage,
  getAllRoutes,
  modalOpen,
  toggleModal,
  priceRangeOptions,
  selectedRoute,
  stationOptions,
  partnerOptions,
  t
}) => {
  const [startPoint, setStartPoint] = useState({ station: "" })
  const [endPoint, setEndPoint] = useState({ station: "" })
  const [ticketPriceRange, setTicketPriceRange] = useState(null)
  const [viaRoutes, setViaRoutes] = useState([{ station: "" }])
  const [selectedPartnerOptions, setselectedPartnerOptions] = useState([])
  const [LoggedInuser, setLoggedInUser] = useState({})
  const [errors, setErrors] = useState({})

  const validate = () => {
    const error = {}
    if (LoggedInuser.user?.role !== "PARTNER") {
      if (!selectedPartnerOptions?.value || selectedRoute?.partner) {
        error.selectedPartnerOptions = "please select a partner type"
      }
    }
    if (!startPoint) {
      error.startPoint = "Please select a Start Point."
    }

    if (!endPoint) {
      error.endPoint = "Please select an End Point."
    }

    if (!ticketPriceRange) {
      error.ticketPriceRange = "Please select a Ticket price range."
    }

    for (let i = 0; i < viaRoutes.length; i++) {
      if (!viaRoutes[i].station) {
        error[`viaRoute_${i}`] = `Please Enter Via Route `
      }
    }

    setErrors(error)
    return error
  }

  const handleSubmit = async () => {
    try {
      const error = await validate()
      if (Object.keys(error).length > 0) {
        return false
      }

      const config = {
        method: "post",
        url: "busRoutes/getBus_Routes/updateRoute",
        data: {
          partner:
            LoggedInuser.user?.role === "PARTNER"
              ? LoggedInuser?.user?._id
              : selectedPartnerOptions?.value,
          id: selectedRoute._id,
          startPoint,
          endPoint,
          viaRoute: viaRoutes,
          ticketPriceRange: ticketPriceRange?.value
            ? ticketPriceRange?.value
            : ticketPriceRange[0]?.value,
        },
      }

      // return false;
      const response = await callApi(config)

      if (response.data.code === 200) {
        await getAllRoutes()
        // setRouteList(newRoute);
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setSuccessMessage(response.data.message)
        toggleModal()
        // resetFields()
      } else {
        toggleModal()
        setTimeout(() => {
          setErrorMessage(null)
        }, 2000)
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      console.log(error)
      toggleModal()
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      setErrorMessage(error.message)
    }
  }

  const handleChange = async (e, index) => {
    e.preventDefault()
    const routes = [...viaRoutes]
    routes[index][e.target.name] = e.target.value
    setViaRoutes(routes)
  }

  //   const resetFields = () => {
  //     setStartPoint({})
  //     setViaRoutes([{ station: "" }])
  //     setEndPoint({})
  //     setTicketPriceRange(null)
  //   }
  useEffect(() => {
    const fun = async () => {
      setLoggedInUser(JSON.parse(await localStorage.getItem("user")))
    }
    fun()
  }, [])
  const addRoutes = () => {
    const newViaRoutes = [...viaRoutes, { station: "" }]
    setViaRoutes(newViaRoutes)
  }
  const deleteRoutes = i => {
    // if (i !== 0) {
    const newViaRoutes = [...viaRoutes]
    newViaRoutes.splice(i, 1)
    setViaRoutes(newViaRoutes)
    // }
  }
  useEffect(() => {

    if (Object.keys(selectedRoute).length > 0) {
      // const startStation = stationOptions.filter(
      //   item => item.value === selectedRoute?.startPoint?.station
      // )
      // const endStation = stationOptions.filter(
      //   item => item.value === selectedRoute?.endPoint?.station
      // )
      const selectedTicketPriceRange = priceRangeOptions.filter(
        item => item.value === selectedRoute?.ticketPriceRange
      )

      setEndPoint({
        ...endPoint,
        station: selectedRoute?.endPoint?.station,
      })
      setStartPoint({
        ...startPoint,
        station: selectedRoute?.startPoint?.station,
      })

      setViaRoutes(
        selectedRoute.viaRoute.length > 0
          ? selectedRoute.viaRoute
          : [{ station: "" }]
      )
      setTicketPriceRange(selectedTicketPriceRange)
      setselectedPartnerOptions(
        partnerOptions?.filter(item => item?.value === selectedRoute?.partner)
      )
    }
  }, [selectedRoute])

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggleModal} size="lg" centered={true}>
        <ModalHeader toggle={toggleModal}>
          <Label>{t('Update Route')}</Label>
        </ModalHeader>
        <ModalBody>
          <div className="">
            <Label className="font-size-16 mb-4">{t('Routes')}</Label>
            {LoggedInuser.user?.role !== "PARTNER" && (
              <div className="my-3 col-md-6">
                <Label>{t('Select Partner')}</Label>
                <Select
                  isSearchable={false}
                  // isMulti
                  options={partnerOptions}
                  value={selectedPartnerOptions}
                  // defaultValue={selectedGridType}
                  onChange={e => setselectedPartnerOptions(e)}
                />
                {errors.selectedPartnerOptions && (
                  <p className="text-danger">{errors.selectedPartnerOptions}</p>
                )}
              </div>
            )}
            <ol className="activity-checkout mb-0 px-4 mt-3">
              <li className="checkout-item">
                <div className="avatar-sm checkout-icon p-1">
                  <div className="avatar-title rounded-circle bg-success">
                    <i className="mdi mdi-bus text-white font-size-20"></i>
                  </div>
                </div>
                <div className="feed-item-list">
                  <Row>
                    <Col>
                      {/* <CreatableSelect */}
                      {/* onCreateOption={handleStartPointCreate} */}
                      <Input
                        placeholder="Start Point"
                        value={startPoint?.station}
                        onChange={e =>
                          setStartPoint({
                            ...startPoint,
                            station: e.target.value,
                          })
                        }
                      />
                      {errors.startPoint && (
                        <p className="text-danger">{errors.startPoint}</p>
                      )}
                    </Col>
                  </Row>
                </div>
              </li>

              {viaRoutes?.map((item, index) => {
                return (
                  <li className="checkout-item" key={item.station + index}>
                    <div className="avatar-sm checkout-icon p-2">
                      <div className="avatar-title rounded-circle bg-primary">
                        <i className="mdi mdi-bus text-white font-size-15"></i>
                      </div>
                    </div>
                    <div className="feed-item-list">
                      <div>
                        <div className="my-0">
                          <Row>
                            <Col xs={12} className="">
                              <div>
                                <Input
                                  name="station"
                                  placeholder="Station"
                                  defaultValue={item?.station}
                                  onChange={e => handleChange(e, index)}
                                />

                                {errors[`viaRoute_${index}`] && (
                                  <p className="text-danger">
                                    {errors[`viaRoute_${index}`]}
                                  </p>
                                )}

                                <div className="text-end">
                                  {index !== 0 && (
                                    <Button
                                      color="danger"
                                      outline
                                      className="my-1 mx-1"
                                      size="sm"
                                      onClick={() => deleteRoutes(index)}
                                    >
                                      <i className="mdi mdi-delete" />
                                    </Button>
                                  )}
                                </div>
                              </div>

                              <div className="text-end">
                                {(viaRoutes.length === 0 ||
                                  index === viaRoutes.length - 1) && (
                                    <div lg={3}>
                                      <Button
                                        color="success"
                                        outline
                                        className="my-1"
                                        onClick={addRoutes}
                                      >
                                        <i className="mdi mdi-plus" />
                                        {t('Add Via Route')}
                                      </Button>
                                    </div>
                                  )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })}

              <li className="checkout-item">
                <div className="avatar-sm checkout-icon p-1">
                  <div className="avatar-title rounded-circle bg-danger">
                    <i className="mdi mdi-bus text-white font-size-20"></i>
                  </div>
                </div>
                <div className="feed-item-list">
                  <Row>
                    <Col>
                      <Input
                        placeholder="Ending Point"
                        // onCreateOption={handleEndPointCreate}
                        value={endPoint?.station}
                        onChange={e =>
                          setEndPoint({ ...endPoint, station: e.target.value })
                        }
                      />
                      {errors.endPoint && (
                        <p className="text-danger">{errors.endPoint}</p>
                      )}
                    </Col>
                  </Row>
                </div>
              </li>
            </ol>
          </div>
          <div className="my-3 col-md-6">
            <Label>{t('Ticket Price Range')}</Label>
            <Select
              isSearchable={false}
              options={priceRangeOptions}
              value={ticketPriceRange}
              onChange={e => setTicketPriceRange(e)}
            />
            {errors.ticketPriceRange && (
              <p className="text-danger">{errors.ticketPriceRange}</p>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              color="danger"
              outline
              className="mx-1"
              onClick={() => {
                toggleModal()
              }}
            >
              {t('Close')}
            </Button>
            <Button
              color="primary"
              outline
              className="mx-1"
              onClick={() => handleSubmit()}
            >
              {t('Submit')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

UpdateRouteModal.propTypes = {
  t: PropTypes.any
}
export default withTranslation()(UpdateRouteModal);
