import React from "react";
import PropTypes from 'prop-types';
import { Row, Col, Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { withTranslation } from "react-i18next";

const DashboardTokenModal = ({ modalOpen, toggleModal, remainingDays, t }) => {
  // document.title = "Alert Email | One Ticket"
  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} centered size="md">
      <ModalBody>
        <Row className="">
          <Col md={12}>
            <table
              className=""
              style={{
                fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                boxSizing: "border-box",
                fontSize: "14px",
                width: "100%",
                backgroundColor: "transparent",
                margin: "0",
                color: "#000",
              }}
            // bgcolor="#f6f6f6"
            >
              <tbody>
                <tr
                  style={{
                    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                    boxSizing: "border-box",
                    fontSize: "14px",
                    margin: "0",
                  }}
                >
                  <td
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      verticalAlign: "top",
                      margin: "0",
                    }}
                    valign="top"
                  ></td>
                  <td
                    className="container"
                    width="600"
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      verticalAlign: "top",
                      display: "block",
                      maxWidth: "600px",
                      clear: "both",
                      margin: "0 auto",
                    }}
                    valign="top"
                  >
                    <div
                      className="content"
                      style={{
                        fontFamily:
                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        maxWidth: "600px",
                        display: "block",
                        margin: "0 auto",
                        padding: "20px",
                      }}
                    >
                      <table
                        className="main"
                        width="100%"
                        cellPadding="0"
                        cellSpacing="0"
                        style={{
                          fontFamily:
                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          borderRadius: "7px",
                          backgroundColor: "#fff",
                          margin: "0",
                          boxShadow: "0 0.75rem 1.5rem rgba(18,38,63,.03)",
                        }}
                      >
                        <tbody>
                          <tr
                            style={{
                              fontFamily:
                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                              boxSizing: "border-box",
                              fontSize: "14px",
                              margin: "0",
                            }}
                          >
                            <td
                              className="alert alert-warning"
                              style={{
                                fontFamily:
                                  "Helvetica Neue,Helvetica,Arial,sans-serif",
                                boxSizing: "border-box",
                                fontSize: "16px",
                                verticalAlign: "top",
                                color: "#ea553d",
                                fontWeight: "500",
                                textAlign: "center",
                                borderRadius: "3px 3px 0px 0px",
                                // backgroundColor: "#ea553d",
                                margin: "0",
                                padding: "20px",
                              }}
                              align="center"
                              valign="top"
                            >
                              {t("Warning: You're approaching your limit. Please upgrade.")}
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily:
                                "Helvetica Neue,Helvetica,Arial,sans-serif",
                              boxSizing: "border-box",
                              fontSize: "14px",
                              margin: "0",
                            }}
                          >
                            <td
                              className="content-wrap"
                              style={{
                                fontFamily:
                                  "Helvetica Neue,Helvetica,Arial,sans-serif",
                                boxSizing: "border-box",
                                fontSize: "14px",
                                verticalAlign: "top",
                                margin: "0",
                                padding: "20px",
                              }}
                              valign="top"
                            >
                              <table
                                width="100%"
                                cellPadding="0"
                                cellSpacing="0"
                                style={{
                                  fontFamily:
                                    "Helvetica Neue,Helvetica,Arial,sans-serif",
                                  boxSizing: "border-box",
                                  fontSize: "14px",
                                  margin: "0",
                                }}
                              >
                                <tbody>
                                  <tr
                                    style={{
                                      fontFamily:
                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                      boxSizing: "border-box",
                                      fontSize: "14px",
                                      margin: "0",
                                      textAlign: 'center'
                                    }}
                                  >
                                    <td
                                      className="content-block"
                                      style={{
                                        fontFamily:
                                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        verticalAlign: "top",
                                        margin: "0",
                                        padding: "0 0 20px",
                                      }}
                                      valign="top"
                                    >
                                      You have{" "}
                                      <strong
                                        style={{
                                          fontFamily:
                                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                                          boxSizing: "border-box",
                                          fontSize: "14px",
                                          margin: "0",
                                        }}
                                      >
                                        <span
                                          style={{
                                            backgroundColor:
                                              remainingDays > 7
                                                ? "#02a499"
                                                : "#ea553d",
                                            color: "#ffffff",
                                            padding: "5px 8px",
                                            fontSize: "12px",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          {remainingDays} {t('Days')}
                                        </span>
                                      </strong>{" "}
                                      {t('remaining')}.
                                    </td>
                                  </tr>
                                  <tr
                                    style={{
                                      fontFamily:
                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                      boxSizing: "border-box",
                                      fontSize: "14px",
                                      margin: "0",
                                    }}
                                  >
                                    <td
                                      className="content-block"
                                      style={{
                                        fontFamily:
                                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        verticalAlign: "top",
                                        margin: "0",
                                        padding: "0 0 20px",
                                      }}
                                      valign="top"
                                    >
                                      {/* {remainingTokens < 50
                                          ? " "
                                          : "Recharge now to get more tokens."} */}
                                    </td>
                                  </tr>
                                  <tr
                                    style={{
                                      fontFamily:
                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                      boxSizing: "border-box",
                                      fontSize: "14px",
                                      margin: "0",
                                    }}
                                  >
                                    <td
                                      className="content-block"
                                      style={{
                                        fontFamily:
                                          "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        verticalAlign: "top",
                                        margin: "0",
                                        padding: "0 0 20px",
                                        textAlign: 'center'
                                      }}
                                      valign="top"
                                    >
                                      <Link
                                        to="/rechargeTickets"
                                        className="btn-primary"
                                        style={{
                                          fontFamily:
                                            "Helvetica Neue,Helvetica,Arial,sans-serif",
                                          boxSizing: "border-box",
                                          fontSize: "14px",
                                          color: "#FFF",
                                          textDecoration: "none",
                                          lineHeight: "2em",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                          cursor: "pointer",
                                          display: "inline-block",
                                          borderRadius: "5px",
                                          textTransform: "capitalize",
                                          backgroundColor: "#02a499",
                                          margin: "0",
                                          borderColor: "#02a499",
                                          borderStyle: "solid",
                                          borderWidth: "8px 16px",
                                        }}
                                      >
                                        {t('Recharge token')}
                                      </Link>
                                    </td>
                                  </tr>

                                  <tr
                                    style={{
                                      fontFamily:
                                        "Helvetica Neue,Helvetica,Arial,sans-serif",
                                      boxSizing: "border-box",
                                      fontSize: "14px",
                                      margin: "0",
                                    }}
                                  ></tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
DashboardTokenModal.propType = {
  t: PropTypes.any
}
export default withTranslation()(DashboardTokenModal)
