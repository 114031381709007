import UserProfile from 'pages/Authentication/user-profile';
import React, { useEffect } from 'react';
import { Button, ButtonGroup, Card, CardBody, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';

function formatToDisplayDate(date) {
    // let date = new Date(dateString);
    // let day = ('0' + date.getDate()).slice(-2);
    // let month = ('0' + (date.getMonth() + 1)).slice(-2);
    // let year = date.getFullYear();
    // let hours = ('0' + date.getHours()).slice(-2);
    // let minutes = ('0' + date.getMinutes()).slice(-2);
    // return `${day}/${month}/${year}`;
    if (date) {
        let temp = date.split('T');
        let newDate = temp[0];
        let newTime = temp[1];

        newDate = newDate.split('-').reverse().join('/');

        return newDate
    } else {
        return ""
    }
}

const ViewUserDetails = ({ modalOpen, toggleModal, selectedUser, }) => {
    useEffect(() => {

    }, [selectedUser])

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                toggle={toggleModal}
                centered
                size='xl'
            >
                <ModalHeader toggle={toggleModal}>
                    <Label>User Details</Label>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <UserProfile viewUser={true} selectedUser={selectedUser} />
                        {/* <Row>
                            <Col xs={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="mx-3">
                                                <div className=''>
                                                    <img
                                                        src={selectedUser?.avatar}
                                                        alt=""
                                                        className="avatar-md rounded-circle img-thumbnail"

                                                    />

                                                </div>
                                            </div>
                                            <div className="align-self-center flex-1">
                                                <div className="text-muted">
                                                    <h5>{selectedUser?.userName}</h5>
                                                    <p className="mb-1">{selectedUser?.phoneNumber} / {selectedUser?.profile?.alternatePhoneNumber}</p>
                                                    <p className="mb-0">{selectedUser?.role}</p>
                                                    <p className="mb-0">{selectedUser?.profile?.gender}</p>
                                                    <p className="mb-0">{selectedUser?.profile?.businessName}</p>
                                                    <p className="mb-0">D.O.B. - {formatToDisplayDate(selectedUser?.profile?.birthDate)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>User Name</td>
                                            <td>{selectedUser?.userName}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row> */}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <Button color='danger' outline onClick={toggleModal}>Close</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ViewUserDetails;