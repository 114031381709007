import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";


import { callApi } from 'helpers/api_helper';
import { withTranslation } from 'react-i18next';

const NewStaffModal = ({ modalOpen, toggleModal, roleOptions, getAllUsers, setErrorMessage, setSuccessMessage, t }) => {

    const [name, setname] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [role, setRole] = useState("");
    const [data, setData] = useState([]);
    const [selectedRole, setSelectedRole] = useState();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            userName: name || '',
            phoneNumber: phoneNumber || '',
            role: role || '',
        },
        validationSchema: Yup.object({
            userName: Yup.string().required("Please Enter Your User Name"),
            phoneNumber: Yup.string().required("Please Enter Your Phone Number"),
            // role: Yup.string().required("Please Enter Your Role"),
        }),
        onSubmit: (values, { resetForm }) => { // Add resetForm as the second argument
            handleSubmit(values);
            resetForm(); // Reset the form after submission
        }
    });


    const handleSubmit = async (values) => {
        try {
            const config = {
                method: 'post',
                url: "user/getUserRoutes/createUser",
                data: {
                    userName: values.userName,
                    phoneNumber: values.phoneNumber,
                    role: selectedRole.value,
                }
            }

            const response = await callApi(config);
            if (response.data.code === 200) {
                toggleModal();
                setErrorMessage(null);
                setSuccessMessage(response.data.message);
                setTimeout(() => {
                    setSuccessMessage(null);

                }, 2000);
                await getAllUsers();
            }
            else {
                setSuccessMessage(null);
                setErrorMessage(response.data.message);
                setTimeout(() => {
                    setErrorMessage(null);
                }, 2000);
                toggleModal();
            }

        } catch (error) {
            setSuccessMessage(null);
            setErrorMessage(error.message);
            setTimeout(() => {
                setErrorMessage(null);
            }, 2000);
            toggleModal();
        }
    }

    const handleEditSubmit = async (values) => {
        try {
            const config = {
                method: 'post',
                url: "user/getUserRoutes/updateUser",
                data: {
                    userName: values.userName,
                    phoneNumber: values.phoneNumber,
                    role: selectedRole.value,
                }
            }

            const response = await callApi(config);
            if (response.data.code === 200) {
                toggleModal();
                setTimeout(() => {
                    setSuccessMessage(null);

                }, 2000);
                setSuccessMessage(response.data.message);
                await getAllUsers();
            }
            else {
                setSuccessMessage(null);
                setErrorMessage(response.data.message);
                setTimeout(() => {
                    setErrorMessage(null);
                }, 2000);
                toggleModal();
            }

        } catch (error) {
            setSuccessMessage(null);
            setErrorMessage(error.message);
            setTimeout(() => {
                setErrorMessage(null);
            }, 2000);
            toggleModal();
        }
    }

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                toggle={toggleModal}
                backdrop="static"
                size="md"
                centered={true}
                onClosed={() => validation.resetForm()}
            >
                <ModalHeader toggle={toggleModal}>
                    {t('Add New Staff')}
                </ModalHeader>
                <ModalBody>

                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="form-group my-3">
                            <Label className="form-label">{t('User Name')}</Label>
                            <Input
                                name="userName"
                                className="form-control"
                                placeholder="Enter User Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.userName || ""}
                                invalid={
                                    validation.touched.userName && validation.errors.userName ? true : false
                                }
                            />
                            {validation.touched.userName && validation.errors.userName ? (
                                <FormFeedback type="invalid">{validation.errors.userName}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="form-group my-3">
                            <Label className="form-label">{t('Phone Number')}</Label>
                            <Input
                                name="phoneNumber"
                                className="form-control"
                                placeholder="Enter Phone Number"
                                type="tel"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phoneNumber || ""}
                                invalid={
                                    validation.touched.phoneNumber && validation.errors.phoneNumber ? true : false
                                }
                            />
                            {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                                <FormFeedback type="invalid">{validation.errors.phoneNumber}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="my-2">
                            <Label>{t('Role')}</Label>
                            <Select
                                isSearchable={false}
                                onChange={(e) => { setSelectedRole(e) }}
                                options={roleOptions}
                                classNamePrefix="select2-selection"
                                onBlur={validation.handleBlur}
                                defaultValue={selectedRole}
                            />
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-4">
                            <Button outline color="danger" className='mx-2' onClick={toggleModal}>
                                {t('Close')}
                            </Button>
                            <Button type="submit" color="success">
                                {t('Add Staff')}
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    )
}
NewStaffModal.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    t: PropTypes.any,

}
export default withTranslation()(NewStaffModal);