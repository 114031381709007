import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
import * as XLSX from "xlsx"

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input,
  CardTitle,
} from "reactstrap"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import { Bar } from "react-chartjs-2"
import Select from "react-select"
import DownloadPdf from "pages/Utility/DownloadPdf"
import { withTranslation } from "react-i18next"

const options = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "Today",
    value: 1,
  },
  {
    label: "Last 7 Days",
    value: 7,
  },
  {
    label: "Last 30 Days",
    value: 30,
  },
  {
    label: "Last 366 Days",
    value: 366,
  },
]

function formatToDisplayDate(date) {
  // let date = new Date(dateString);
  // let day = ('0' + date.getDate()).slice(-2);
  // let month = ('0' + (date.getMonth() + 1)).slice(-2);
  // let year = date.getFullYear();
  // let hours = ('0' + date.getHours()).slice(-2);
  // let minutes = ('0' + date.getMinutes()).slice(-2);
  // return `${day}/${month}/${year}`;
  if (date) {
    let temp = date.split("T")
    let newDate = temp[0]
    let newTime = temp[1]

    newDate = newDate.split("-").reverse().join("/")

    return newDate
  } else {
    return ""
  }
}

const DashboardModal = ({
  modalOpen,
  toggleModal,
  bookedTicketsList,
  getAllBookedTickets,
  t
}) => {
  const [label, setLabels] = useState([])
  const [datas, setDatas] = useState([])
  const [selectedDifference, setSelectedDifference] = useState();
  const [pdfContent, setPdfContent] = useState()

  const data = {
    labels: label, //["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Sales Analytics",
        backgroundColor: "#02a499",
        borderColor: "#02a499",
        borderWidth: 1,
        hoverBackgroundColor: "#02a499",
        hoverBorderColor: "#02a499",
        data: datas, //[65, 59, 81, 45, 56, 80, 50, 20]
      },
    ],
  }

  const option = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    tootlbar: {
      show: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex]
          var meta = dataset._meta[Object.keys(dataset._meta)[0]]
          var total = meta.total
          var currentValue = dataset.data[tooltipItem.index]
          var percentage = parseFloat(((currentValue / total) * 100).toFixed(1))
          return currentValue //+ ' (' + percentage + '%)';
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index]
        },
      },
    },
  }
  const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })

  const generatePDFContent = (jsonData = []) => (
    <Document>
      <Page style={styles.page} size="A2">
        <View style={styles.container}>
          <View style={styles.tableRow}>
            <Text style={styles.headingCell}>Bus Name</Text>
            <Text style={styles.headingCell}>Bus Redg. No.</Text>
            <Text style={styles.headingCell}>Tickets Sold</Text>
            <Text style={styles.headingCell}>Occupancy(in %)</Text>
          </View>
          {jsonData.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.valueCell}>{item?.bus[0]?.busName}</Text>
              <Text style={styles.valueCell}>
                {item?.bus[0]?.busRegistrationNumber}
              </Text>
              <Text style={styles.valueCell}>{item?.ticketsBooked}</Text>
              <Text style={styles.valueCell}>{item?.occupancy} %</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )

  useEffect(() => {

    if (bookedTicketsList.length > 0) {
      setLabels(bookedTicketsList?.map(item => item?.bus[0]?.busName))
      setDatas(bookedTicketsList?.map(item => item?.occupancy))
    }
    setPdfContent(generatePDFContent(bookedTicketsList))
  }, [bookedTicketsList])

  const handleSelect = async e => {
    try {
      setSelectedDifference(e)
      await getAllBookedTickets(e.value)
    } catch (error) {
      console.log({ error })
    }
  }

  const handleDownload = async () => {
    try {
      const data = bookedTicketsList?.map(item => {
        return {
          "Bus Registration Number": item?.bus[0]?.busRegistrationNumber,
          "Tickets Sold": item?.ticketsBooked,
          "Occupancy(In %)": item?.occupancy,
        }
      })
      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "OccupancyList.xlsx")
      }
    } catch (error) {
      console.log("error => ", error)
    }
  }

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggleModal} centered size="lg">
        <ModalHeader toggle={toggleModal}>
          <Label>{t('Occupancy Details')}</Label>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="text-center">
              <Row>
                <Col xs={0} md={6}></Col>
                <Col xs={6} md={3}>
                  {/* <label>Sort By</label> */}
                  <Select
                    isSearchable={false}
                    // className="form-control"
                    placeholder="Select to sort"
                    classNamePrefix="select2-selection"
                    options={options}
                    value={selectedDifference}
                    onChange={e => handleSelect(e)}
                  />
                </Col>
                <Col xs={6} md={3} className="mb-3">
                  <Button color="success">
                    <DownloadPdf
                      className={`mdi mdi-download me-2`}
                      pdfContent={pdfContent}
                      fileName={'Occupancy'}

                    />
                  </Button>
                </Col>
              </Row>
              <div className="table-responsive mb-4">
                {bookedTicketsList.length === 0 ? (
                  <p>{t("No Records Found")}</p>
                ) : (
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">{t('Bus Name')}.</th>
                        <th scope="col">{t('Bus Redg. No.')}</th>
                        <th scope="col">{t('Tickets Sold')}</th>
                        <th scope="col">{t('Occupancy')}(in %)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookedTicketsList?.map(item => {
                        return (
                          <tr key={item._id}>
                            <td>{item?.bus[0]?.busName}</td>
                            <td>{item?.bus[0]?.busRegistrationNumber}</td>
                            <td>
                              <div>{item?.ticketsBooked}</div>
                            </td>
                            <td>{item?.occupancy} %</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            {/* <Card>
                            <CardBody>
                                <h4 className="card-title mb-4">Occupancy Chart</h4>
                                <Bar width={600} height={245} data={data} options={option} />
                            </CardBody>
                        </Card> */}
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}
DashboardModal.propType = {
  t: PropTypes.any
}
export default withTranslation()(DashboardModal)
