import { callApi } from 'helpers/api_helper';
import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup, Card, CardBody, Col, Container, Input, Label, Modal, Row, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Breadcrumb from "../../components/Common/Breadcrumb";
import CancelBusDatePicker from './CancelBusDatePicker';

const CancelBus = () => {
    const [error, setError] = useState({});
    const [busList, setBusList] = useState([]);
    const [busRegistrationNumber, setBusRegistrationNumber] = useState('');
    const [cancelModalOpen, setCancelModalOpen] = useState(false);

    document.title = 'Cancel Bus | OneTKT';

    const validate = () => {
        const error = {};
        if (!busRegistrationNumber) {
            error.busRegistrationNumber = 'Enter a valid Registration Number!'
        }
        setError(error);
        return error;
    }

    const handleSearch = async () => {
        try {
            const error = await validate();
            if (Object.keys(error).length > 0) {
                return false;
            }

            const config = {
                method: 'post',
                url: 'bus/getBusRoutes/searchByResgistrationNumber',
                data: {
                    busRegistrationNumber
                }
            }
            const response = await callApi(config);
            if (response.data.code === 200) {
                setBusList(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="OneTKT" breadcrumbItem="CancelBus" />

                    <Row>
                        <Col xs={12} lg={4}>
                            <Card>
                                <CardBody>
                                    <Label>Bus Registration Number</Label>
                                    <Row>
                                        <Col xs={8}>
                                            <Input
                                                value={busRegistrationNumber}
                                                onChange={(e) => setBusRegistrationNumber(e.target.value)}
                                            />
                                            {
                                                error.busRegistrationNumber && <p className='text-danger'>{error.busRegistrationNumber}</p>
                                            }
                                        </Col>
                                        <Col xs={4}>
                                            <Button color='primary' outline onClick={handleSearch}>Search</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={4}>

                            {
                                Object.keys(busList).length > 0 &&
                                <Card>
                                    <CardBody>
                                        <div>
                                            <h4>Results</h4>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-centered table-nowrap mb-0">
                                                <thead>
                                                    <tr className='text-center'>
                                                        <th>Redg. No.</th>

                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='text-center'>
                                                        <td>{busList?.busRegistrationNumber}</td>

                                                        <td>
                                                            <ButtonGroup>
                                                                <Button color='danger' outline size='sm' className='mx-1' onClick={() => setCancelModalOpen(true)}>
                                                                    Cancel
                                                                </Button>

                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            }
                        </Col>

                    </Row>
                </Container>
            </div>
            <Modal
                isOpen={cancelModalOpen}
                toggle={() => setCancelModalOpen(!cancelModalOpen)}
                centered
            >
                <ModalHeader toggle={() => setCancelModalOpen(!cancelModalOpen)}>
                    <Label>Cancel Bus</Label>
                </ModalHeader>
                <ModalBody>
                    <CancelBusDatePicker />
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <Button color='danger' outline onClick={() => setCancelModalOpen(!cancelModalOpen)}>Cancel</Button>
                        <Button color='success' outline onClick={() => setCancelModalOpen(!cancelModalOpen)}>Submit</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default CancelBus