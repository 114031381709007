import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { useNavigate } from "react-router-dom"
import { callApi } from "helpers/api_helper"
import { Card, CardBody, Col, Container, Button, Label, Row } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import moment from "moment"
import PieChart from "./PieChart"
import Select from "react-select"
import * as XLSX from "xlsx"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import DownloadPdf from "pages/Utility/DownloadPdf"
import { withTranslation } from "react-i18next";

const options = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "Runway for 7 Days",
    value: 7,
  },
  {
    label: "Runway for 15 Days",
    value: 15,
  },
  {
    label: "Runway for 30 Days",
    value: 30,
  },
  {
    label: "More than 30 Days",
    value: 30,
  },
]

const ExhaustingCoins = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [userList, setUserList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(5)
  const [totalPage, setTotalPage] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [data, setData] = useState([])
  const [labels, setLabels] = useState([])
  const [selectedDifference, setSelectedDifference] = useState()
  const [pdfContent, setPdfContent] = useState()

  const pageSizes = [1, 2, 4, 8, 10, 20, 50, 100, 500, 1000]

  const startIndex = (currentPage - 1) * selectedPageSize
  const endIndex = currentPage * selectedPageSize

  const navigate = useNavigate()

  const changePageSize = async size => {
    setCurrentPage(1)
    setSelectedPageSize(size)
    await fetchUsers(size, 1)
  }

  const fetchUsers = async (size, tCurrentPage) => {
    try {
      const config = {
        method: "post",
        url: "user/getUserRoutes/exhaustingCoins",
        data: {
          pageSize: size,
          currentPage: tCurrentPage,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        const today = moment()
        const tempArr = response.data.data.map(item => {
          const tempDate = moment(
            item?.bookingRequests[0]?.createdAt,
            "DD/MM/YYYY"
          )
          const differenceInDays = tempDate.diff(today, "days")
        })
        setUserList(response.data.data)
        setIsLoading(false)
        setTotalItemCount(response.data.totalCount)
        setTotalPage(Math.ceil(response.data.totalCount / size))
      }
    } catch (error) {
      console.log({ errorMessage: error })
    }
  }
  const fetchFilteredUsers = async (size, tCurrentPage, value) => {
    try {
      const config = {
        method: "post",
        url: "user/getUserRoutes/filterUserByBookingDaysExhausting",
        data: {
          pageSize: size,
          currentPage: tCurrentPage,
          runwayDays: value,
        },
      }
      if (value === 0) {
        await fetchUsers()
      } else {
        const response = await callApi(config)
        if (response.data.code === 200) {
          setUserList(response.data.data)
          setIsLoading(false)
          setTotalItemCount(response.data.totalCount)
          setTotalPage(Math.ceil(response.data.totalCount / size))
        }
      }
    } catch (error) {
      console.log({ errorMessage: error })
    }
  }

  const getAllPieChartDetails = async (size, tCurrentPage) => {
    const config = {
      method: "post",
      url: "user/getUserRoutes/runwaysDataForPieChart",
      data: {
        pageSize: size,
        currentPage: tCurrentPage,
      },
    }
    const response = await callApi(config)
    if (response.data.code === 200) {
      // setIsLoading(false);
      const tempVar = response?.data?.data
      setData([
        tempVar.runwaysFor7Days,
        tempVar.runwaysFor15Days,
        tempVar.runwaysFor30Days,
        tempVar.runwaysForMore30Days,
      ])
      setLabels([
        props.t("Runways For 7 Days"),
        props.t("Runways For 15 Days"),
        props.t("Runways For 30 Days"),
        props.t("Runways For More 30 Days"),
      ])
    } else {
      navigate("/login")
    }
  }

  const handleSelect = async e => {
    try {
      setSelectedDifference(e)
      await fetchFilteredUsers(selectedPageSize, currentPage, e.value)
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    getAllPieChartDetails()
    fetchUsers(selectedPageSize, currentPage)
  }, [])

  const handleDownload = async () => {
    try {
      if (userList.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(userList)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "userList.xlsx")
      }
    } catch (error) {
      console.log("error => ", error)
    }
  }

  const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })
  const generatePDFContent = jsonData => (
    <Document>
      <Page style={styles.page} size="A2">
        <View style={styles.container}>
          <View style={styles.tableRow}>
            <Text style={styles.headingCell}>User ID</Text>
            <Text style={styles.headingCell}>Business Name</Text>
            <Text style={styles.headingCell}>Partner Phone Number</Text>
            <Text style={styles.headingCell}>Token Left </Text>
          </View>
          {jsonData.map((user, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.valueCell}>{user?.user[0]?.userId}</Text>
                <Text style={styles.valueCell}>{user?.user[0]?.userName} </Text>
                <Text style={styles.valueCell}>
                  {user?.user[0]?.phoneNumber}
                </Text>
                <Text style={styles.valueCell}>
                  {user?.user[0]?.userTokens}
                </Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )
  useEffect(() => {

    setPdfContent(generatePDFContent(userList))
  }, [userList])

  document.title = "Exhausting Coins | OneTKT"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="OneTKT" breadcrumbItem="Exhausting Coins" />

          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h4 className="card-title mb-4">{props.t('Exhausting Coins')}</h4>
                </Col>

                <Col></Col>
                <Col xs={12} md={4} className="mb-2">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Label>{props.t('Sort By')}</Label>
                      <Select
                        isSearchable={false}
                        options={options}
                        value={selectedDifference}
                        onChange={e => handleSelect(e)}
                      />
                    </div>
                    <div
                      className="mx-1"
                      style={{ marginTop: 24, marginLeft: 8 }}
                    >
                      <Button color="success">
                        <DownloadPdf
                          className={`mdi mdi-download me-2`}
                          pdfContent={pdfContent}
                          fileName={'ExhaustingCoins'}

                        />
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr className="text-center">
                      <th scope="col"> {props.t('User ID')}</th>
                      <th scope="col">{props.t('Business Name')}</th>
                      <th scope="col">{props.t('Partner Phone Number')}</th>
                      {/* <th scope="col">Daily Tokens Consumption</th> */}
                      <th scope="col">
                        {props.t('Token Left')}{" "}
                        <span style={{ cursor: "pointer" }}>
                          <i className="fas fa-sort" />
                        </span>
                      </th>
                      {/* <th scope="col">Runway for</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {userList?.map(user => {
                      return (
                        <tr className="text-center" key={user._id}>
                          <th scope="row">{user?.user[0]?.userId}</th>
                          <td>
                            <div>{user?.user[0]?.userName}</div>
                          </td>
                          <td>{user?.user[0]?.phoneNumber}</td>
                          {/* <td>94</td> */}
                          <td>
                            <span className="badge bg-success">
                              {user?.user[0]?.userTokens}
                            </span>
                          </td>
                          {/* <td>Runway for {user?.difference} days</td> */}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <PieChart data={data} labels={labels} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

ExhaustingCoins.propType = {
  t: PropTypes.any
}
export default withTranslation()(ExhaustingCoins);
