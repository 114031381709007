import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Mousetrap from "mousetrap"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Label,
  Input,
  Spinner,
  Alert,
} from "reactstrap"

// Redux
import { Link, useNavigate } from "react-router-dom"

import Select from "react-select"

// import images
import onetkt from "../../assets/images/OneTktblk.png"
import { callApi } from "helpers/api_helper"
import OtpInput from "react-otp-input"
import "./ResponsiveOTPInput.css"

const languageOptions = [
  {
    label: "English",
    value: "ENGLISH",
  },
  {
    label: "Hindi",
    value: "HINDI",
  },
  {
    label: "Odia",
    value: "ODIA",
  },
  {
    label: "Russia",
    value: "RUSSIA",
  },
  {
    label: "Germany",
    value: "GERMANY",
  },
  {
    label: "Chinese",
    value: "CHINESE",
  },
]

const Login = props => {
  const navigate = useNavigate()

  const [userLogin, setUserLogin] = useState([])
  const [mpinCreated, setMpinCreated] = useState(false)
  // const [isLoginOrOtpOrMpinOrLanguage, setIsLoginOrOtpOrMpinOrLanguage] = useState("login");
  const [isLoginOrOtpOrMpinOrLanguage, setIsLoginOrOtpOrMpinOrLanguage] =
    useState("login")
  const [phoneNumber, setPhoneNumber] = useState("")

  const [Otp, setOtp] = useState("")
  const [Mpin, setMpin] = useState("")
  const [newMpin, setNewMpin] = useState("")
  const [confirmMpin, setConfirmMpin] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [errors, setErrors] = useState({})
  const [btnLoading, setBtnLoading] = useState(false)
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState()

  const [error, setError] = useState("")
  const GenerateOtp = async () => {
    const otpConfig = {
      method: "post",
      url: "user/getUserRoutes/generateOtp",
      data: {
        phoneNumber,
      },
    }
    const otpResponse = await callApi(otpConfig)
    return otpResponse
  }
  const ValidateOtp = async () => {
    const config = {
      method: "post",
      url: "user/getUserRoutes/ValidateOtp",
      data: {
        phoneNumber: phoneNumber,
        otp: Otp,
      },
    }
    const response = await callApi(config)

    return response
  }
  const validateForm = () => {
    if (!selectedLanguage) {
      setError("Please select a language.")
      return false
    }
    setError("")
    return true
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (validateForm()) {

    }
  }
  const handleClick = async () => {
    try {
      setBtnLoading(true)
      const error = await validate()

      if (Object.keys(error).length > 0) {
        setBtnLoading(false)
        return false
      }

      const config = {
        method: "post",
        url: "user/getUserRoutes/getUserByPhoneNumber",
        data: {
          phoneNumber,
        },
      }

      const response = await callApi(config)

      if (response.data.code === 200) {
        const { isMPINOk } = response.data.data

        // const otpResponse = await callApi(otpConfig);

        if (!isMPINOk) {
          const otpResponse = await GenerateOtp()

          setBtnLoading(false)
          if (otpResponse.data.code === 200) {
            setSuccessMessage(otpResponse.data.message)
            setTimeout(() => {
              setSuccessMessage(null)
            }, 2000)
            setErrorMessage(null)
            setIsLoginOrOtpOrMpinOrLanguage("Otp")
          } else {
            setSuccessMessage(null)
            setErrorMessage(otpResponse.data.message)
          }
        } else {
          setIsLoginOrOtpOrMpinOrLanguage("Mpin")
        }
      } else {
        setErrorMessage(response.data.message)
        setSuccessMessage(null)
        setTimeout(() => {
          setErrorMessage(null)
        }, 2000)
        setBtnLoading(false)
      }
    } catch (error) {
      setBtnLoading(false)
    }
  }

  const handleOtpSubmit = async () => {
    try {
      const error = await validate()

      if (Object.keys(error).length > 0) {
        return false
      }
      const response = await ValidateOtp()

      if (response.data.code == 200) {
        setIsLoginOrOtpOrMpinOrLanguage("newMpin")
        // navigate('/dashboard')
      } else {
        setSuccessMessage(null)
        setErrorMessage(response.data.message)
      }
    } catch (error) { }
  }

  const handleMpinSubmit = async () => {
    try {
      const error = await validate()

      if (Object.keys(error).length > 0) {
        return false
      }

      const Mpinconfig = {
        method: "post",
        url: "user/getUserRoutes/ValidateMPIN",
        data: {
          phoneNumber: phoneNumber,
          MPIN: Mpin,
        },
      }
      const response = await callApi(Mpinconfig)
      if (response.data.code === 200) {
        await localStorage.setItem(
          "user",
          JSON.stringify({
            token: response.data.data.token,
            user: response.data.data.userData,
          })
        )
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        })
        setErrorMessage(null)
        setIsLoginOrOtpOrMpinOrLanguage("login")
        // navigate("/dashboard")
        window.open('/dashboard', '_self');
      } else {
        setSuccessMessage(null)
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
    }
  }

  const handleNewMpin = async () => {
    try {
      const error = await validate()

      if (Object.keys(error).length > 0) {
        return false
      }
      if (newMpin === confirmMpin) {
        const config = {
          method: "post",
          url: "user/getUserRoutes/generateMPIN",
          data: {
            phoneNumber: phoneNumber,
            MPIN: newMpin,
          },
        }

        // return false
        const response = await callApi(config)
        if (response.data.code === 200) {
          await localStorage.setItem(
            "user",
            JSON.stringify({
              token: response.data.data.token,
              user: response.data.data.userData,
            })
          )
          setSuccessMessage(response.data.message)
          setErrorMessage(null)
          setTimeout(() => {
            setSuccessMessage(null)
          }, 2000)
          // setIsLoginOrOtpOrMpinOrLanguage('login');
          setIsLoginOrOtpOrMpinOrLanguage("language")
          if (!response.data.data.userData.isProfileOk) {
            // navigate('/profile');
          } else {

            // navigate("/dashboard");
            window.open('/dashboard', '_self');

          }
        } else {
          setSuccessMessage(null)
          setErrorMessage(response.data.message)
          setTimeout(() => {
            setErrorMessage(null)
          }, 2000)
        }
      } else {
        setErrors({
          ...errors,
          confirmMpin: "New MPIN And Confirm MPIN Must be same",
        })
      }
    } catch (error) {
      console.log(error)
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
    }
  }

  const handleLanguage = async () => {
    try {
      const error = await validate()

      if (Object.keys(error).length > 0) {
        return false
      }

      const Languageconfig = {
        method: "post",
        url: "user/getUserRoutes/changeTheLang",
        data: {
          phoneNumber: phoneNumber,
          language: selectedLanguage.value,
        },
      }
      const response = await callApi(Languageconfig)
      if (response.data.code === 200) {
        const user = JSON.parse(await localStorage.getItem("user"))
        const updatedUser = { ...user.user, language: selectedLanguage.value }
        // await localStorage.setItem('user', JSON.stringify({ token: user.token, user: updatedUser }));

        setSuccessMessage(response.data.message)
        setErrorMessage(null)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setIsLoginOrOtpOrMpinOrLanguage("language")
        navigate("/profile")
      } else {
        setSuccessMessage(null)
        setErrorMessage(response.data.message)
        setTimeout(() => {
          setErrorMessage(null)
        }, 2000)
      }
    } catch (error) {
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
    }
  }

  const validate = () => {
    let error = {}

    if (!phoneNumber) {
      error.phoneNumber = "Phone Number is required!"
    } else if (phoneNumber.length !== 10) {
      error.phoneNumber = "Enter a valid PhoneNumber!"
    }
    if (isLoginOrOtpOrMpinOrLanguage === "Otp") {
      if (!Otp) {
        error.Otp = "Otp is required!"
      } else if (Otp.length !== 4) {
        error.Otp = "Enter a valid Otp!"
      }
    }
    if (isLoginOrOtpOrMpinOrLanguage === "Mpin") {
      if (!Mpin) {
        error.Mpin = "Mpin is required!"
      } else if (Mpin.length !== 4) {
        error.Mpin = "Mpin must be of 4 characters!"
      }
    }
    if (isLoginOrOtpOrMpinOrLanguage === "Otp1") {
      if (!Otp) {
        error.Otp = "Otp is required!"
      } else if (Otp.length !== 4) {
        error.Otp = "Enter a valid Otp!"
      }
    }

    if (isLoginOrOtpOrMpinOrLanguage === "forgetmpin") {
      if (!newMpin) {
        error.newMpin = "new Mpin is required!"
      } else if (newMpin.length !== 4) {
        error.newMpin = "Mpin must be of 4 characters!"
      }
      if (!confirmMpin) {
        error.confirmMpin = "Confirm Mpin is required!"
      } else if (confirmMpin.length !== 4) {
        error.confirmMpin = "Confirm Mpin must be of 4 characters!"
      } else if (newMpin !== confirmMpin) {
        error.confirmMpin = "Mpin and  confirmMpin  must be same"
      }

      // if (!(selectedLanguage)) {
      //   error.selectedLanguage = "Language  is required!";
      // }
    }
    setErrors(error)

    return error
  }
  const handelMpin = async () => {
    try {
      const error = await validate()

      if (Object.keys(error).length > 0) {
        return false
      }
      if (newMpin === confirmMpin) {
        const config = {
          method: "post",
          url: "user/getUserRoutes/forgotMPIN",
          data: {
            phoneNumber: phoneNumber,
            MPIN: newMpin,
          },
        }


        const response = await callApi(config)
        if (response.data.code === 200) {
          setSuccessMessage(response.data.message)
          setErrorMessage(null)
          setTimeout(() => {
            setSuccessMessage(null)
          }, 2000)
          setTimeout(() => {
            setIsLoginOrOtpOrMpinOrLanguage("Mpin")
          }, 2000)
        } else {
          setSuccessMessage(null)
          setErrorMessage(response.data.message)
          setTimeout(() => {
            setErrorMessage(null)
          }, 2000)
        }
      }
    } catch (error) {
      console.log(error)

      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
    }
  }
  const handelforgetMpin = async () => {
    try {
      const otpResponse = await GenerateOtp()


      if (otpResponse.data.code === 200) {
        setSuccessMessage(otpResponse.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setErrorMessage(null)
        setIsLoginOrOtpOrMpinOrLanguage("Otp1")
      } else {
        setSuccessMessage(null)
        setErrorMessage(otpResponse.data.message)
      }
    } catch (error) {
      setBtnLoading(false)
    }
  }
  const handleforgetOtpSubmit = async () => {
    try {
      const error = await validate()

      if (Object.keys(error).length > 0) {
        return false
      }

      const response = await ValidateOtp()

      if (response.data.code == 200) {
        setIsLoginOrOtpOrMpinOrLanguage("forgetmpin")
        // navigate('/dashboard')
      } else {
        setSuccessMessage(null)
        setErrorMessage(response.data.message)
      }
    } catch (error) { }
  }

  useEffect(() => {
    const nonNumericKeys = [
      "backspace",
      "tab",
      "enter",
      "shift",
      "ctrl",
      "alt",
      "capslock",
      "escape",
      "pageup",
      "pagedown",
      "end",
      "home",
      "insert",
      "delete",
      "arrowup",
      "arrowdown",
      "arrowleft",
      "arrowright",
    ]

    function handleKeyDown(event) {
      if (event.key && event.key.length === 1 && isNaN(parseInt(event.key))) {
        event.preventDefault()
      }
    }

    const mousetrap = new Mousetrap()
    mousetrap.bind(Object.keys(Mousetrap.prototype), (event, key) => {
      if (nonNumericKeys.includes(key.toLowerCase())) {
        handleKeyDown(event)
      }
    })

    return () => {
      mousetrap.reset()
    }
  }, [])

  const customStyles = {
    menu: provided => ({
      ...provided,
      maxHeight: "150px",
      overflowY: "auto",
    }),
  }

  document.title = "Login | OneTKT"
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center flex-column">
            <Col md={8} lg={6} xl={4}>
              <Card className="">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 mt-4">Welcome !</h5>
                    <p className="text-white-50">
                      Sign in to continue to OneTKT.
                    </p>
                  </div>
                </div>

                <CardBody>
                  <div
                    className=" text-center"
                    style={{ backgroundColor: "white" }}
                  >
                    <img src={onetkt} height="35" alt="One Deta" />
                  </div>
                  <div className="p-0">
                    {errorMessage && errorMessage ? (
                      <Alert color="danger">{errorMessage}</Alert>
                    ) : null}
                    {successMessage ? (
                      <Alert color="success">{successMessage}</Alert>
                    ) : null}

                    {isLoginOrOtpOrMpinOrLanguage === "login" && (
                      <div className="mt-4">
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="phoneNumber">
                            Phone Number
                          </Label>
                          <Input
                            name="phoneNumber"
                            className="form-control"
                            placeholder="Enter Phone Number"
                            type="tel"
                            id="phoneNumber"
                            onChange={e => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                          />
                          {errors.phoneNumber && (
                            <p className="text-danger">{errors.phoneNumber}</p>
                          )}
                        </div>

                        <div className="mb-3 d-flex justify-content-end align-items-center">
                          <div className="text-end">
                            <button
                              disabled={btnLoading}
                              className="btn btn-primary w-md waves-effect waves-light"
                              onClick={handleClick}
                            >
                              {btnLoading ? (
                                <Spinner
                                  size="sm"
                                  className="m-0 p-0"
                                  color="secondary"
                                />
                              ) : (
                                "Continue"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {isLoginOrOtpOrMpinOrLanguage === "newMpin" && (
                      <div className="mt-4">
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="newMpin">
                            New MPIN
                          </Label>

                          <div className="otp-container">
                            <OtpInput
                              value={newMpin}
                              id="newMpin"
                              isInputNum="tel"
                              shouldAutoFocus={true}
                              onChange={mpin => setNewMpin(mpin)}
                              numInputs={4}
                              separator={<span>-</span>}
                              containerStyle="text-center"
                              inputStyle={{
                                height: "40px",
                                width: "40px",
                                margin: "5px",
                              }}
                            />
                          </div>
                          {errors.newMpin && (
                            <p className="text-danger">{errors.newMpin}</p>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="confirmMpin">
                            Confirm MPIN
                          </Label>

                          <div className="otp-container">
                            <OtpInput
                              value={confirmMpin}
                              id="confirmMpin"
                              isInputNum="tel"
                              onChange={mpin => setConfirmMpin(mpin)}
                              numInputs={4}
                              separator={<span>-</span>}
                              containerStyle="text-center"
                              inputStyle={{
                                height: "40px",
                                width: "40px",
                                margin: "5px",
                              }}
                            />
                            {errors.confirmMpin && (
                              <p className="text-danger">
                                {errors.confirmMpin}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 d-flex justify-content-end align-items-center">
                          <div className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              onClick={handleNewMpin}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {isLoginOrOtpOrMpinOrLanguage === "language" && (
                      <div className="mt-4">
                        <form onSubmit={handleSubmit}>
                          <div className="mb-4">
                            <Label className="form-label" htmlFor="language">
                              Select Language
                            </Label>
                            <Select
                              isSearchable={false}
                              styles={customStyles}
                              classNamePrefix="react-select"
                              options={languageOptions}
                              value={selectedLanguage}
                              onChange={language =>
                                setSelectedLanguage(language)
                              }
                            />
                            {error && <p className="error">{error}</p>}
                          </div>
                          <div className="mb-3 d-flex justify-content-end align-items-center">
                            <div className="text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                onClick={handleLanguage}
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}

                    {isLoginOrOtpOrMpinOrLanguage === "Otp" && (
                      <div className="mt-4">
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="Otp">
                            OTP
                          </Label>
                          <div className="otp-container">
                            <OtpInput
                              value={Otp}
                              id="Otp"
                              isInputNum="tel"
                              shouldAutoFocus={true}
                              onChange={otp => setOtp(otp)}
                              numInputs={4}
                              separator={<span>-</span>}
                              containerStyle="text-center"
                              inputStyle={{
                                height: "40px",
                                width: "40px",
                                margin: "5px",
                              }}
                            />
                            {errors.Otp && (
                              <p className="text-danger">{errors.Otp}</p>
                            )}
                          </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end align-items-center">
                          <div className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              onClick={handleOtpSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {isLoginOrOtpOrMpinOrLanguage === "Mpin" && (
                      <div className="mt-4">
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="Mpin">
                            MPIN
                          </Label>
                          <div className="otp-container">
                            <OtpInput
                              className=""
                              value={Mpin}
                              name="Mpin"
                              id="Mpin"
                              isInputNum="tel"
                              shouldAutoFocus={true}
                              onChange={mpin => setMpin(mpin)}
                              numInputs={4}
                              separator={<span>-</span>}
                              containerStyle="text-center "
                              inputStyle={{
                                height: "40px",
                                width: "40px",
                                margin: "5px",
                              }}
                            />
                            {errors.Mpin && (
                              <p className="text-danger">{errors.Mpin}</p>
                            )}
                          </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end align-items-center">
                          <div className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              onClick={() => {
                                handleMpinSubmit(),
                                  setIsLanguageModalOpen(!isLanguageModalOpen)
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>

                        <div className="mt-2 mb-0 row">
                          <div className="col-12 mt-4">
                            <p
                              onClick={handelforgetMpin}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="mdi mdi-lock"></i> Forgot your MPIN?
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {isLoginOrOtpOrMpinOrLanguage === "Otp1" && (
                      <div className="mt-4">
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="Otp">
                            OTP
                          </Label>
                          <div className="otp-container">
                            <OtpInput
                              value={Otp}
                              id="Otp"
                              isInputNum="tel"
                              shouldAutoFocus={true}
                              onChange={otp => setOtp(otp)}
                              numInputs={4}
                              separator={<span>-</span>}
                              containerStyle="text-center"
                              inputStyle={{
                                height: "40px",
                                width: "40px",
                                margin: "5px",
                              }}
                            />
                            {errors.Otp && (
                              <p className="text-danger">{errors.Otp}</p>
                            )}
                          </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end align-items-center">
                          <div className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              onClick={handleforgetOtpSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {isLoginOrOtpOrMpinOrLanguage === "forgetmpin" && (
                      <div className="mt-4">
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="newMpin">
                            New MPIN
                          </Label>

                          <div className="otp-container">
                            <OtpInput
                              value={newMpin}
                              id="newMpin"
                              isInputNum="tel"
                              shouldAutoFocus={true}
                              onChange={mpin => setNewMpin(mpin)}
                              numInputs={4}
                              separator={<span>-</span>}
                              containerStyle="text-center"
                              inputStyle={{
                                height: "40px",
                                width: "40px",
                                margin: "5px",
                              }}
                            />
                            {errors.newMpin && (
                              <p className="text-danger">{errors.newMpin}</p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="confirmMpin">
                            Confirm MPIN
                          </Label>

                          <div className="otp-container">
                            <OtpInput
                              value={confirmMpin}
                              id="confirmMpin"
                              isInputNum="tel"
                              onChange={mpin => setConfirmMpin(mpin)}
                              numInputs={4}
                              separator={<span>-</span>}
                              containerStyle="text-center"
                              inputStyle={{
                                height: "40px",
                                width: "40px",
                                margin: "5px",
                              }}
                            />
                            {errors.confirmMpin && (
                              <p className="text-danger">
                                {errors.confirmMpin}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 d-flex justify-content-end align-items-center">
                          <div className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              onClick={handelMpin}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="mt-5 text-center">
            {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link
                    to="/register"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
            <p>© 2023 OneTKT, Developed by OneDeta</p>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Login

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
