
import React, { useState } from 'react'
import { Button, Spinner } from 'reactstrap';
import jsPDF from 'jspdf';
import phonePayLogo from '../../assets/images/PhonePe-Logo.wine.png';
import moment from 'moment';
import { formatDate } from 'helpers/Utils/formatTime';

const Invoice = (data) => {
    const [downloadLoading, setDownloadLoading] = useState(false);

    const generateReceiptPDF = (txn) => {
        let data = txn?.data;
        const doc = new jsPDF();

        doc.setFontSize(12);

        var lineSpacing = {
            NormalSpacing: 12,
        };

        const imgData = 'https://onetkt.onedeta.com/static/media/PhonePe-Logo.wine.6a5f7e9bcfb97f25b7bf.png'

        doc.setFont('bold');
        doc.setFontSize(18);
        doc.setTextColor(0, 0, 0);
        doc.text(`TicketCo Solutions Private Limited`, 20, 30);

        doc.setFont("Times", "Roman");
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(`4th Floor, DLF Cybercity`, 20, 40);
        doc.text(`Chandaka Industrial Estate, Patia`, 20, 45);
        doc.text(`Bhubaneswar, Odisha 751024`, 20, 50);

        doc.setFontSize(18);
        doc.text(`Invoice`, 140, 30);

        doc.setFontSize(10);
        doc.text(`Gst :`, 140, 40);
        doc.text(`21AAJCT2090F1ZM`, 150, 40);
        doc.setFontSize(10);
        doc.text(`Subsription Date:`, 140, 45);
        doc.text(`${moment().format('DD.MM.YYYY')}`, 170, 45);
        doc.setFontSize(10);
        doc.text(`Bill To :`, 140, 50);
        doc.text((data.user.userName), 155, 50);

        doc.setDrawColor(29, 31, 34);
        doc.setLineWidth(0.5);
        doc.line(10, 55, 200, 55);

        // doc.setDrawColor(123, 142, 239);
        doc.setDrawColor(29, 31, 34);
        doc.setLineWidth(10);
        doc.line(15, 90, 195, 90);

        doc.setFont("Times", "Roman")
        doc.setFontSize(12);
        doc.setTextColor(255, 255, 255);
        doc.text(`Item Description`, 30, 92)
        doc.text(`Qty`, 90, 92)
        doc.text(`Amount`, 165, 92)

        doc.setDrawColor(192, 192, 192);
        doc.setLineWidth(10);
        doc.line(15, 100, 195, 100);

        doc.setFont("Times", "Roman")
        doc.setFontSize(11);
        doc.setTextColor(0, 0, 0);
        doc.text(`Tokens`, 32, 102);
        doc.text(`${data?.tokens}`, 90, 102);
        doc.text(`Rs. ${data?.amount} /-`, 167, 102);

        doc.setFontSize(12);
        doc.text(`Sub Total :`, 130, 120);
        doc.setFont('bold');
        doc.text(`Rs. ${data?.amount} /-`, 167, 120)
        doc.setFontSize(12);
        doc.text(`GST(18%) :`, 130, 130);
        doc.setFont('bold');
        doc.text(`Rs. ${data?.gst} /-`, 167, 130)

        doc.setFontSize(12);
        doc.text(`Total :`, 130, 140);
        doc.setFont('bold');
        doc.text(`Rs. ${data?.amount + data?.gst} /-`, 167, 140);

        doc.setDrawColor(29, 31, 34);
        doc.setLineWidth(0.5);
        doc.line(10, 145, 200, 145);

        doc.setFont("Times", "Roman");
        doc.setFontSize(9);
        // doc.text(`Powered by `, 75, 150)
        // doc.addImage(imgData, 'JPEG', 100, 150, 105, 150);
        doc.save('Txn.pdf');
        setDownloadLoading(false);

    };

    return (
        <>
            {' '}
            <Button
                outline
                color='success'
                disabled={downloadLoading}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDownloadLoading(true);
                    generateReceiptPDF(data);
                }}
            >
                {
                    downloadLoading ? <div
                        style={{

                        }}
                    >
                        <Spinner className="" color="danger" />
                    </div>
                        :
                        <i className="ion ion-md-download"></i>
                }
            </Button>
        </>
    );
}

export default Invoice;
