import React from 'react'
import PropTypes from 'prop-types';
import { callApi } from 'helpers/api_helper'
import { withTranslation } from 'react-i18next'
import { Button, ButtonGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

const DeactivateBusModal = ({ modalOpen, toggleModal, selectedBus, getAllBuses, t }) => {
    const handleSubmit = async () => {
        try {
            const config = {
                method: 'post',
                url: 'bus/getBusRoutes/updateActiveOrInActive',
                data: {
                    id: selectedBus._id,
                    isActive: selectedBus?.isActive ? false : true
                }
            }
            const response = await callApi(config);
            if (response.data.code === 200) {
                getAllBuses();
                toggleModal();
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div>
            <Modal
                isOpen={modalOpen}
                toggle={toggleModal}
            >
                <ModalHeader toggle={toggleModal}>
                    <Label>{selectedBus?.isActive ? t('Deactivate') : t("Activate")} Bus</Label>
                </ModalHeader>
                <ModalBody className='text-center'>
                    <Label>Are you sure to {selectedBus?.isActive ? 'Deactivate' : "Activate"} the bus ?</Label>
                    <div>
                        <ButtonGroup>
                            <Button color='danger' outline onClick={toggleModal}>{t('Cancel')}</Button>
                            <Button color='primary' outline onClick={handleSubmit}>{t('Confirm')}</Button>
                        </ButtonGroup>

                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}
DeactivateBusModal.propType = {
    t: PropTypes.any
}
export default withTranslation()(DeactivateBusModal);