import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
export const API_URL = "https://be.oneticket.teceads.co.in/api/v1/"
// export const PORTAL_URL = "https://oneticket.teceads.co.in/"
// const API_URL = "http://192.168.1.8:4301/api/v1/"
// const API_URL = "http://localhost:4301/api/v1/"
// export const PORTAL_URL = "http://localhost:4300"
export const PORTAL_URL = "https://onetkt.onedeta.com"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export const callApi = async apiCallData => {
  var data = { ...apiCallData?.data, api_key: `registeruser` }
  const fetchToken = await localStorage.getItem("user")
  let token = ""

  if (fetchToken) {
    token = JSON.parse(fetchToken)?.token
  }
  // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6eyJhdmF0YXIiOiJodHRwczovL3d3dy53M3NjaG9vbHMuY29tL2hvd3RvL2ltZ19hdmF0YXIyLnBuZyIsImlzTVBJTk9rIjpmYWxzZSwiaXNQcm9maWxlT2siOmZhbHNlLCJfaWQiOiI2NDAwNzhhZGFiODkwNjYzY2NlOTIyZDkiLCJwaG9uZU51bWJlciI6ODMyODkzNjg5NiwidXNlck5hbWUiOiJCaWJodXRpIEJodXNoYW4gR3VydWRhcyIsInJvbGUiOiJTVVBFUl9BRE1JTiIsImNyZWF0ZWRBdCI6IjIwMjMtMDMtMDJUMTA6MjE6MzMuOTY0WiIsInVwZGF0ZWRBdCI6IjIwMjMtMDMtMDJUMTE6NDY6MDguOTA5WiIsIl9fdiI6MCwiTVBJTiI6IiQyYiQxMCRCc3lxaHRLNjgwQ2lzcmJJS1QzbUpldk94VzZJaldrS0xoWWRRVUU0S0lWak5SWWZhZi5TQyJ9LCJpYXQiOjE2Nzc4MjY5MzUsImV4cCI6MTY3Nzk5OTczNX0.1_ZvKTtEyD39dFD0R-vTMQxeWS6tOUB37YFLWrcEiwk' //await getToken();
  let config = {
    method: apiCallData.method,
    url: `${API_URL}${apiCallData.url}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
    data: data,
  }
  let response = await axios(config)
  return response
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
