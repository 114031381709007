import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Select from "react-select"
import { generatePDFContent } from "pages/Utility/GetPDFContent"
import DownloadPdf from "pages/Utility/DownloadPdf"
import { withTranslation } from "react-i18next";

const options = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "Today",
    value: 1,
  },
  {
    label: "Last 7 Days",
    value: 7,
  },
  {
    label: "Last 30 Days",
    value: 30,
  },
  {
    label: "Last 1 Year",
    value: 366,
  },
]

function formatToDisplayDate(date) {
  // let date = new Date(dateString);
  // let day = ('0' + date.getDate()).slice(-2);
  // let month = ('0' + (date.getMonth() + 1)).slice(-2);
  // let year = date.getFullYear();
  // let hours = ('0' + date.getHours()).slice(-2);
  // let minutes = ('0' + date.getMinutes()).slice(-2);
  // return `${day}/${month}/${year}`;
  if (date) {
    let temp = date.split("T")
    let newDate = temp[0]
    let newTime = temp[1]

    newDate = newDate.split("-").reverse().join("/")

    return newDate
  } else {
    return ""
  }
}

const DashboardModal = ({
  modalOpen,
  toggleModal,
  bookedTicketsList,
  getAllBookedTickets,
  t
}) => {
  const [label, setLabels] = useState([])
  const [datas, setDatas] = useState([])
  const [pdfContent, setPdfContent] = useState()
  const [selectedDifference, setSelectedDifference] = useState()

  const data = {
    labels: label, //["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Sales Analytics",
        backgroundColor: "#02a499",
        borderColor: "#02a499",
        borderWidth: 1,
        hoverBackgroundColor: "#02a499",
        hoverBorderColor: "#02a499",
        data: datas, //[65, 59, 81, 45, 56, 80, 50, 20]
      },
    ],
  }

  const option = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            // max: 100
          },
        },
      ],
    },
    tootlbar: {
      show: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex]
          var meta = dataset._meta[Object.keys(dataset._meta)[0]]
          var total = meta.total
          var currentValue = dataset.data[tooltipItem.index]
          var percentage = parseFloat(((currentValue / total) * 100).toFixed(1))
          return currentValue //+ ' (' + percentage + '%)';
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index]
        },
      },
    },
  }

  const handleSelect = async e => {
    try {
      setSelectedDifference(e)
      await getAllBookedTickets(e.value)
    } catch (error) {
      console.log({ error })
    }
  }

  // const handleDownload = () => {

  //   const pdfContent = generatePDFContent(bookedTicketsList);
  //   const htmlString = `
  //     <html>
  //       <head>
  //         <title>PDF Preview</title>
  //       </head>
  //       <body>
  //         <div>${pdfContent}</div>
  //       </body>
  //     </html>
  //   `;

  //   const blob = new Blob([htmlString], { type: 'application/pdf' });
  //   const blobUrl = URL.createObjectURL(blob);

  //   const link = document.createElement('a');
  //   link.href = blobUrl;
  //   link.download = 'data.pdf';
  //   link.click();

  //   URL.revokeObjectURL(blobUrl);
  // }

  useEffect(() => {
    if (bookedTicketsList.length > 0) {
      setLabels(bookedTicketsList?.map(item => item?.bus[0]?.busName))
      setDatas(bookedTicketsList?.map(item => item?.ticketsBooked))
    }
    setPdfContent(generatePDFContent(bookedTicketsList))
  }, [bookedTicketsList])
  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggleModal} centered size="lg">
        <ModalHeader toggle={toggleModal}>
          <Label>{t('Booked Ticket Details')}</Label>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="text-center">
              {/* <div className="text-end mb-3"> */}
              <Row>
                <Col xs={0} md={6}></Col>
                <Col xs={6} md={3}>
                  {/* <label>Sort By</label> */}
                  <Select
                    isSearchable={false}
                    // className="form-control"
                    placeholder="Select to sort"
                    classNamePrefix="select2-selection"
                    options={options}
                    value={selectedDifference}
                    onChange={e => handleSelect(e)}


                  />
                </Col>
                <Col xs={6} md={3} className="mb-3">
                  <Button color="success">
                    <DownloadPdf
                      className={`mdi mdi-download me-2`}
                      pdfContent={pdfContent}
                      fileName={'BookedTickets'}

                    />
                  </Button>
                </Col>
              </Row>
              <div className="table-responsive mb-4">
                {bookedTicketsList.length === 0 ? (
                  <p>{t('No Records Found')}</p>
                ) : (
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">{t('Partner Name')}</th>
                        <th scope="col">{t('Bus Redg. No.')}</th>
                        <th scope="col">{t('Tickets Sold')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookedTicketsList?.map(item => {
                        return (
                          <tr key={item._id}>
                            <td>{item?.bus[0]?.busName}</td>
                            <th scope="row">
                              {item?.bus[0]?.busRegistrationNumber}
                            </th>
                            <td>
                              <div>{item?.ticketsBooked}</div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            {/* <Card>
              <CardBody>
                <h4 className="card-title mb-4">Ticket Booking Chart</h4>
                <Bar width={600} height={245} data={data} options={option} />
              </CardBody>
            </Card> */}
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}
DashboardModal.propType = {
  t: PropTypes.any
}
export default withTranslation()(DashboardModal)

