import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { Card, CardBody, Container, Button, Spinner } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { callApi } from "helpers/api_helper"
import DownloadPdf from "pages/Utility/DownloadPdf"
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer"

import Invoice from "./Invoice"
import Pagination from "helpers/Utils/Pagination"
import { withTranslation } from "react-i18next"
import { propTypes } from "react-bootstrap-editable"

const ViewPayments = (props) => {
  const [datas, setdata] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [pdfContent, setPdfContent] = useState()
  const [Data, setData] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)

  const viewData = async () => {
    const config = {
      method: "post",
      url: "subscription/getSubscriptionRoutes/getAllSubscriptionTxn",
      data: {
        currentPage,
        pageSize: selectedPageSize,
      },
    }

    const response = await callApi(config)
    a
    setdata(response.data.data)
    if (response.data.code === 200) {
      setIsLoading(false)
      setData(response.data.data)
      setTotalItemCount(response.data.total)
      setTotalPage(Math.ceil(response.data.total / selectedPageSize))
    }
  }

  const generatePDFContent = jsonData => (
    <Document>
      <Page style={styles.page} size="A2">
        <View style={styles.container}>
          <View style={styles.tableRow}>
            <Text style={styles.headingCell}>Subscription Id</Text>
            <Text style={styles.headingCell}>Token</Text>
            <Text style={styles.headingCell}>Date</Text>
            <Text style={styles.headingCell}>Amount</Text>
            <Text style={styles.headingCell}>Status</Text>
          </View>
          {jsonData.map((payment, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.valueCell}>
                  {payment.subscriptionRequestId}
                </Text>
                <Text style={styles.valueCell}>{payment.tokens} </Text>
                <Text style={styles.valueCell}>
                  {payment.createdAt
                    .slice(0, 10)
                    .toString()
                    .split("-")
                    .reverse()
                    .join("/")}
                </Text>
                <Text style={styles.valueCell}>{payment.amount}</Text>
                <Text style={styles.valueCell}>{payment.status}</Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )

  useEffect(() => {
    setPdfContent(generatePDFContent(Data))
  }, [Data])

  useEffect(() => {
    setTimeout(() => {
      viewData()
    }, 2000)

    setIsLoading(true)
  }, [currentPage, selectedPageSize])

  const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })

  document.title = "View Payments | OneTKT"

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="" color="danger" />
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="OneTKT" breadcrumbItem="View Payments" />

            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <h4 className="card-title mb-4">{props.t('Results')}</h4>

                  <div className="mx-1">
                    <Button color="success">
                      <DownloadPdf
                        className={`mdi mdi-download `}
                        pdfContent={pdfContent}
                        fileName={"viewPayment"}
                      />
                    </Button>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">{props.t('Subscription Id')}</th>
                        <th scope="col">{props.t('Token')}</th>
                        <th scope="col">{props.t('Date')}</th>
                        <th scope="col">{props.t('Amount')}</th>
                        <th scope="col">{props.t('Status')}</th>
                        <th scope="col">{props.t('Action')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr></tr>
                      {datas.map(data => (
                        <tr key={data._id}>
                          <td>{data.subscriptionRequestId}</td>
                          <td>{data.tokens}</td>
                          <td>
                            {data.createdAt
                              .slice(0, 10)
                              .toString()
                              .split("-")
                              .reverse()
                              .join("/")}
                          </td>
                          <td>{data.amount}</td>
                          <td>
                            <span
                              className={
                                data.status === "SUCCESS"
                                  ? "badge bg-success"
                                  : data.status === "pending"
                                    ? "badge bg-warning"
                                    : data.status === "cancel"
                                      ? "badge bg-danger"
                                      : ""
                              }
                            >
                              {data.status}
                            </span>
                          </td>
                          <td>
                            <div color="success">
                              <Invoice data={data} />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              onChangePage={i => setCurrentPage(i)}
            />
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}
ViewPayments.propType = {
  t: PropTypes.any
}
export default withTranslation()(ViewPayments);
