import React from 'react';

const SeaterIcon = ({ seatNo, isSelected = false, isBooked = false }) => {


  const selectedStyle = {}


  if (isSelected) {
    selectedStyle.boxShadow = "rgba(0, 0, 0, 0.35) 0px 5px 15px"
    selectedStyle.borderColor = "#02A499"
    // selectedStyle.borderWidth = '2px'

  }

  if (isBooked) {
    // selectedStyle.boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
    selectedStyle.borderColor = "#EC4561"
    // selectedStyle.borderWidth='3px'
  }


  return (
    <>
      <div className='m-1' style={{ position: "relative", padding: '10px 0px', height: '35px', width: '30px', border: '2px solid black', borderRadius: '4px', ...selectedStyle }}>
        <div style={{ position: "absolute", marginLeft: "-5px", height: '25px', width: '36px', border: `2px solid black`, borderRadius: '3px', ...selectedStyle }}>
          <strong style={{ "fontSize": "12px" }}>{seatNo}</strong>
        </div>
      </div>

    </>


  )
}

export default SeaterIcon;