import * as XLSX from "xlsx"
import { sentenceCase } from "change-case"
import { callApi } from "helpers/api_helper"
import Pagination from "helpers/Utils/Pagination";
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Select from "react-select"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import BusSeatLayout from "./BusLayout"
import { useNavigate } from "react-router-dom"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer"
import DownloadPdf from "pages/Utility/DownloadPdf"
import { withTranslation } from "react-i18next"

const berthTypeOptions = [
  {
    label: "Lower",
    value: "LOWER",
  },
  {
    label: "Upper",
    value: "UPPER",
  },
]
const seatTypeOptions = [
  {
    label: "Seater",
    value: "SEATER",
  },
  {
    label: "Sleeper",
    value: "SLEEPER",
  },
  // {
  //   label: "Seater + Sleeper",
  //   value: "SEATER+SLEEPER",
  // },
]
const gridOptions = [
  {
    label: "1 + 1",
    value: "1+1",
  },
  {
    label: "1 + 1 + 1",
    value: "1+1+1",
  },
  {
    label: "2 + 1",
    value: "2+1",
  },
  {
    label: "2 + 2",
    value: "2+2",
  },
  {
    label: "2 + 3",
    value: "2+3",
  },
  {
    label: "3 + 1",
    value: "3+1",
  },
  {
    label: "4 + 0",
    value: "4+0",
  },
]

const Layer = (props) => {
  const initialLayers = {
    gridType: "1 + 1",
    seatType: "Seater",
    berthType: "Lower",
  }
  const [isLoading, setIsLoading] = useState(false)
  const [isSeatModalOpen, setIsSeatModalOpen] = useState(false)
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [tableContent, setTableContent] = useState({})
  const [rows, setRows] = useState([])
  const [seatType, setSeatType] = useState("seater")
  const [busLayoutFunctionState, setBusLayoutFunctionState] = useState(<></>)
  const [alreadyBooked, setAlreadyBooked] = useState({})

  const [isLayerModalOpen, setIsLayerModalOpen] = useState(false)
  const [layerList, setLayerList] = useState([])
  const [selectedBerthType, setSelectedBerthType] = useState()
  const [selectedSeatType, setSelectedSeatType] = useState()
  const [selectedGridType, setSelectedGridType] = useState()
  const [selectedLayer, setSelectedLayer] = useState({})
  const [idx, setIdx] = useState(null)
  const [selectedSeats, setSelectedSeats] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [pdfContent, setPdfContent] = useState()
  const [errors, setErrors] = useState("")
  const [selectedPartnerOptions, setselectedPartnerOptions] = useState([])
  const [partnerOptions, setPartnerOptions] = useState([])
  const [LoggedInuser, setLoggedInUser] = useState({})

  const pageSizes = [1, 2, 4, 8, 10, 20, 50, 100, 500, 1000]

  const startIndex = (currentPage - 1) * selectedPageSize
  const endIndex = currentPage * selectedPageSize

  const navigate = useNavigate()

  const validate = () => {
    setErrors({})
    const error = {}
    if (LoggedInuser.user?.role !== "PARTNER") {
      if (!selectedPartnerOptions || !selectedPartnerOptions.value) {
        error.selectedPartnerOptions = "Partner Name Required!"
      }
    }
    if (!selectedGridType?.value) {
      error.selectedGridType = "Please select a Grid Type."
    }
    if (!selectedBerthType?.value) {
      error.selectedBerthType = "Please select a Berth Type."
    }
    if (!selectedSeatType?.value) {
      error.selectedSeatType = "Please select a Seat."
    }

    setErrors(error)
    return error
  }

  const resetFields = () => {
    setSelectedBerthType(null)
    setselectedPartnerOptions(null)
    setSelectedSeatType(null)
    setSelectedGridType(null)
    setBusLayoutFunctionState(<></>)
    setTableContent({})
  }

  const changePageSize = async size => {
    setCurrentPage(1)
    setSelectedPageSize(size)
    await getAllLayers(size, 1)
  }

  function setSeatNumber(key, value) {
    let tempData = { ...tableContent }
    tempData[key].seatNumber = value
    setTableContent(tempData)
  }

  function setRowsFunction() {
    const rows_ = []

    for (let i = 0; i < 15; i++) {
      const cells = []

      for (let j = 0; j < 7; j++) {
        const key = `_${i}_${j}`
        let cellContent = null
        if (tableContent[key] && tableContent[key]?.seatType == "seater") {
          cellContent = tableContent[key] ? (
            <div className="d-flex flex-column justify-content-center align-items-center align-content-center bg-success">
              <i className="mdi mdi-seat-outline mdi-18px text-white" />

              <input
                className="border rounded border-0 mb-2"
                value={tableContent[key]?.seatNumber}
                onChange={e => {
                  setSeatNumber(key, e.target.value)
                }}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                style={{ width: "35px", height: "20px", textAlign: "center" }}
              />
            </div>
          ) : null
        }
        if (tableContent[key] && tableContent[key]?.seatType == "sleeper") {
          cellContent = tableContent[key] ? (
            <div className="d-flex flex-column justify-content-center align-items-center align-content-center bg-success">
              <i className="mdi mdi-bed-outline mdi-18px text-white" />
              <input
                className="border rounded border-0 mb-2"
                value={tableContent[key]?.seatNumber}
                onChange={e => {
                  setSeatNumber(key, e.target.value)
                }}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                style={{ width: "35px", height: "20px", textAlign: "center" }}
              />
            </div>
          ) : null
        }

        cells.push(
          <td
            onClick={() => {
              TableContentFunction(key)
            }}
            key={key}
            style={{
              width: "40px",
              height: "40px",
              border: "1px solid black",
              padding: 0,
            }}
          >
            {cellContent}
          </td>
        )
      }

      rows_.push(<tr key={i}>{cells}</tr>)
    }
    setRows(rows_)
  }

  function TableContentFunction(key) {
    if (Object.keys(tableContent).includes(key)) {
      let tempData = { ...tableContent }
      delete tempData[key]
      setTableContent(tempData)
    } else {
      setTableContent({
        ...tableContent,
        [key]: {
          seatType,
          seatNumber: (Object.keys(tableContent).length + 1).toString(),
        },
      })
    }
  }

  const handleSubmit = async () => {
    const newLayer = [...layerList]
    try {
      if (Object.keys(selectedLayer).length > 0) {
        console.log(
          "first",
          newLayer[idx],
          idx,
          selectedSeatType,
          selectedBerthType,
          selectedGridType,
          selectedPartnerOptions
        )
        newLayer[idx] = {
          seatType: selectedSeatType?.value,
          berthType: selectedBerthType?.label,
          gridType: selectedGridType?.label,
          availableSeats: tableContent,
          partner: selectedPartnerOptions?.value,
        }
        const config = {
          method: "post",
          url: "layer/getLayerRoutes/updateLayer",
          data: {
            id: selectedLayer?._id,
            seatType: selectedSeatType?.value,
            berthType: selectedBerthType?.value,
            gridType: selectedGridType?.value,
            availableSeats: tableContent,
            partner: selectedPartnerOptions?.value,
          },
        }
        const response = await callApi(config)
        if (response.data.code === 200) {
          // setLayerList(response.data.data);
          await getAllLayers()
          setIsSeatModalOpen(!isSeatModalOpen)
          setSelectedLayer({})
          resetFields()
        }
      } else {
        // newLayer.push({
        //     seatType : selectedSeatType?.label,
        //     berthType : selectedBerthType?.label,
        //     gridType : selectedGridType?.label
        // });
        const config = {
          method: "post",
          url: "layer/getLayerRoutes/addLayer",
          data: {
            seatType: selectedSeatType?.value,
            berthType: selectedBerthType?.value,
            gridType: selectedGridType?.value,
            availableSeats: tableContent,
            partner:
              LoggedInuser.user?.role === "PARTNER"
                ? LoggedInuser?.user?._id
                : selectedPartnerOptions?.value,
          },
        }

        // return false;
        const response = await callApi(config)
        if (response.data.code === 200) {
          // setLayerList(response.data.data);
          await getAllLayers()
          setIsSeatModalOpen(!isSeatModalOpen)
          setSelectedLayer({})
          resetFields()
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const getAllLayers = async (size, tCurrentPage) => {
    try {
      const config = {
        method: "post",
        url: "layer/getLayerRoutes/getAllLayers",
        data: {
          pageSize: size,
          currentPage: tCurrentPage,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setLayerList(response.data.data)
        setIsLoading(false)
        setTotalItemCount(response.data.totalCount)
        setTotalPage(Math.ceil(response.data.totalCount / size))
      } else {
        navigate("/login")
      }
    } catch (error) { }
  }

  const handleDelete = async item => {
    // const newLayerList = layerList.filter((item, i) => i !== index);
    // setLayerList(newLayerList);
    try {
      const config = {
        method: "post",
        url: "layer/getLayerRoutes/deleteLayer",
        data: {
          id: item._id,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        // setLayerList(response.data.data);
        await getAllLayers(selectedPageSize, 1)
        setSelectedLayer({})
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const handleContinue = async () => {
    const error = await validate()
    if (Object.keys(error).length > 0) {
      return false
    }

    setIsSeatModalOpen(true)
    setIsLayerModalOpen(!isLayerModalOpen)
    // setIsLayerModalOpen(!isLayerModalOpen);
  }

  const handleClick = (i, index) => {
    const newSeats = [...selectedSeats]

    const index1 = newSeats.indexOf(`(${i},${index})`)

    if (newSeats.includes(`(${i},${index})`)) {
      newSeats.splice(index1, 1) // 2nd parameter means remove one item only
    } else {
      newSeats.push(`(${i},${index})`)
    }
    // const rc = newSeats.map((item,x) => {

    // })
    setSelectedSeats(newSeats)

  }

  const handleDownload = async () => {
    try {
      const data = layerList?.map(item => {
        return {
          "Grid Type": item?.gridType[0],
          "Berth Type": sentenceCase(item?.berthType),
          "Seat Type": item?.seatType,
        }
      })
      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "LayerList.xlsx")
      }
    } catch (error) {
      console.log("error => ", error)
    }
  }

  useEffect(() => {
    setRowsFunction()

    setBusLayoutFunctionState(
      <BusSeatLayout
        layerGrid={tableContent}
        setSelectedSeats={setSelectedSeats}
      />
    )
  }, [tableContent])

  useEffect(() => {

    setSelectedBerthType(
      berthTypeOptions.filter(
        item => item?.value === selectedLayer.berthType
      )[0]
    )
    setSelectedSeatType(
      seatTypeOptions.filter(item => item?.value === selectedLayer.seatType)[0]
    )
    setSelectedGridType(
      gridOptions.filter(item => item?.value === selectedLayer.gridType)[0]
    )
  }, [selectedLayer])

  useEffect(() => {
    setIsLoading(true)
    getAllLayers(selectedPageSize, currentPage)
  }, [currentPage])
  useEffect(() => {
    const fun = async () => {
      setLoggedInUser(JSON.parse(await localStorage.getItem("user")))
    }
    fun()
  }, [])

  const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })

  const generatePDFContent = jsonData => (
    <Document>
      <Page style={styles.page} size="A2">
        <View style={styles.container}>
          <View style={styles.tableRow}>
            <Text style={styles.headingCell}>Grid Type</Text>
            <Text style={styles.headingCell}>Berth Type</Text>
            <Text style={styles.headingCell}>Seat Type</Text>
          </View>
          {jsonData.map((item, index) => {
            const grids = item?.gridType?.map(
              (x, i) => `${x} ${i === item?.gridType?.length - 1 ? "" : ", "}`
            )

            return (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.valueCell}>{grids?.map(grid => grid)}</Text>
                <Text style={styles.valueCell}>
                  {sentenceCase(item?.berthType)}
                </Text>
                <Text style={styles.valueCell}>{item?.seatType}</Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )

  useEffect(() => {

    setPdfContent(generatePDFContent(layerList))
  }, [layerList])

  document.title = `${props.t("Layers")}| OneTKT`



  useEffect(() => {
    getAllUsers()
  }, [])

  const getAllUsers = async () => {
    const config = {
      method: "post",
      url: "user/getUserRoutes/getAll",
    }
    const response = await callApi(config)
    if (response.data.code === 200) {
      const userArr = response.data.data.map(item => {
        return {
          label: item.userName,
          value: item._id,
          data: item,
        }
      })

      setPartnerOptions(userArr.filter(item => item.data.role === "PARTNER"))
    }
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="" color="danger" />
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="OneTKT" breadcrumbItem="Layers" />

            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="card-title mb-4">{props.t('Layers')}</h4>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mx-1">
                      <Button
                        color="primary"
                        outline
                        className="mx-1"
                        onClick={() => setIsLayerModalOpen(true)}
                      >
                        {props.t('Add Layer')}
                      </Button>
                    </div>

                    <div className="mx-1">
                      <Button color="success">
                        <DownloadPdf
                          className={`mdi mdi-download me-2`}
                          pdfContent={pdfContent}
                          fileName={"Layers"}
                        />
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th>{props.t('Grid Type')}</th>
                        <th>{props.t('Berth Type')}</th>
                        <th>{props.t('Seat Type')}</th>
                        <th>{props.t('Actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {layerList?.map((item, index) => {
                        const grids = item?.gridType?.map(
                          (x, i) =>
                            `${x} ${i === item?.gridType?.length - 1 ? "" : ", "
                            }`
                        )
                        return (
                          <tr key={item?._id}>
                            <td>{grids?.map(grid => grid)}</td>
                            <td>{sentenceCase(item?.berthType)}</td>
                            <td>{item?.seatType}</td>
                            <td>
                              <ButtonGroup>
                                {/* <Button
                                  className="mx-1"
                                  color="success"
                                  outline
                                  onClick={() => {
                                    setIsLayerModalOpen(true),
                                      setSelectedLayer(item),
                                      setIdx(index)
                                  }}
                                >
                                  <i className="mdi mdi-pencil mdi-12px" />
                                </Button> */}
                                <Button
                                  className="mx-1"
                                  color="primary"
                                  outline
                                  onClick={() => {
                                    setIsViewModalOpen(true),
                                      setSelectedLayer(item),
                                      setAlreadyBooked(item?.alreadyBookedSeats)
                                  }}
                                >
                                  {props.t('View Layout')}
                                </Button>
                                <Button
                                  className="mx-1"
                                  color="danger"
                                  outline
                                  onClick={() => {
                                    setSelectedLayer(item), handleDelete(item)
                                  }}
                                >
                                  <i className="mdi mdi-delete mdi-12px" />
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
                <Pagination
                  currentPage={currentPage}
                  totalPage={totalPage}
                  onChangePage={i => setCurrentPage(i)}
                />
              </CardBody>
            </Card>
          </Container>

          {/* Seat Arrangement start */}

          <Modal
            isOpen={isSeatModalOpen}
            toggle={() => setIsSeatModalOpen(!isSeatModalOpen)}
            size="lg"
            centered={true}
            onClosed={resetFields}
          >
            <ModalHeader toggle={() => setIsSeatModalOpen(!isSeatModalOpen)}>
              <Label>{props.t('Seat Management')}</Label>
            </ModalHeader>
            <ModalBody>
              <div
                style={{
                  height: "500px",
                  overflowY: "scroll",
                }}
              >
                <Row>
                  <Col xs={12} md={10} lg={6}>
                    <div className="my-3 text-center">
                      <Button
                        className="mx-2 btn btn-success"
                        disabled={seatType === "seater"}
                        onClick={() => {
                          setSeatType("seater")
                        }}
                      >
                        <i className="mdi mdi-seat" /> {props.t('Seat Selection')}
                      </Button>
                      <Button
                        className="mx-2 btn btn-success"
                        disabled={seatType === "sleeper"}
                        onClick={() => {
                          setSeatType("sleeper")
                        }}
                      >
                        <i className="mdi mdi-bed" /> {props.t('Sleeper Selection')}
                      </Button>
                    </div>
                    <div className="m-2 d-flex justify-content-center align-items-center">
                      <table className="text-center">
                        <tbody>{rows}</tbody>
                      </table>
                    </div>
                  </Col>
                  <Col xs={12} md={10} lg={6}>
                    <div className="my-3 d-flex justify-content-center align-items-center">
                      {busLayoutFunctionState}
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  color="danger"
                  outline
                  className="mx-1"
                  onClick={() => {
                    setIsSeatModalOpen(!isSeatModalOpen)
                  }}
                >
                  {props.t('Cancel')}
                </Button>
                <Button
                  color="primary"
                  outline
                  className="mx-1"
                  onClick={() => handleSubmit()}
                >
                  {props.t('Save')}
                </Button>
              </div>
            </ModalFooter>
          </Modal>
          {/* Seat Arrangement End */}

          {/* Add Layer Modal Start */}
          <Modal
            isOpen={isLayerModalOpen}
            toggle={() => setIsLayerModalOpen(!isLayerModalOpen)}
            size="md"
            centered={true}
          >
            <ModalHeader toggle={() => setIsLayerModalOpen(!isLayerModalOpen)}>
              <Label>{props.t('Add A Layer')}</Label>
            </ModalHeader>
            <ModalBody>
              {LoggedInuser.user?.role !== "PARTNER" && (
                <div className="my-2">
                  <Label>{props.t('Select Partner')}</Label>
                  <Select
                    isSearchable={false}
                    // isMulti
                    options={partnerOptions}
                    value={selectedPartnerOptions}
                    // defaultValue={selectedGridType}
                    onChange={e => setselectedPartnerOptions(e)}
                  />
                  {errors.selectedPartnerOptions && (
                    <p className="text-danger">
                      {errors.selectedPartnerOptions}
                    </p>
                  )}
                </div>
              )}
              <div className="my-2">
                <Label>{props.t('Select Grid Type')}</Label>
                <Select
                  isSearchable={false}
                  // isMulti
                  options={gridOptions}
                  value={selectedGridType}
                  // defaultValue={selectedGridType}
                  onChange={e => setSelectedGridType(e)}
                />
                {errors.selectedGridType && (
                  <p className="text-danger">{errors.selectedGridType}</p>
                )}
              </div>
              <div className="my-2">
                <Label>{props.t('Select Berth Type')}</Label>
                <Select
                  isSearchable={false}
                  options={berthTypeOptions}
                  value={selectedBerthType}
                  onChange={e => setSelectedBerthType(e)}
                />
                {errors.selectedBerthType && (
                  <p className="text-danger">{errors.selectedBerthType}</p>
                )}
              </div>
              <div className="my-2">
                <Label>{props.t('Select Seat Type')}</Label>
                <Select
                  isSearchable={false}
                  options={seatTypeOptions}
                  value={selectedSeatType}
                  onChange={e => setSelectedSeatType(e)}
                />
                {errors.selectedSeatType && (
                  <p className="text-danger">{errors.selectedSeatType}</p>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  color="danger"
                  outline
                  className="mx-1"
                  onClick={() => {
                    setIsLayerModalOpen(!isLayerModalOpen)
                  }}
                >
                  {props.t('Cancel')}
                </Button>
                <Button
                  color="primary"
                  outline
                  className="mx-1"
                  onClick={handleContinue}
                >
                  {props.t('Continue')}
                </Button>{" "}
              </div>
            </ModalFooter>
          </Modal>
          {/* Add Layer Modal End */}

          {/* View Layer Modal Start */}
          <Modal
            isOpen={isViewModalOpen}
            toggle={() => setIsViewModalOpen(!isViewModalOpen)}
            size="md"
            centered={true}
          >
            <ModalHeader toggle={() => setIsViewModalOpen(!isViewModalOpen)}>
              <Label>{props.t('View Layer')}</Label>
            </ModalHeader>
            <ModalBody>
              <Table>
                <thead>
                  <tr>
                    <th>{props.t('Grid Type')}</th>
                    <th>{props.t('Berth Type')}</th>
                    <th>{props.t('Seat Type')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{selectedLayer?.gridType}</td>
                    <td>{selectedLayer?.berthType}</td>
                    <td>{selectedLayer?.seatType}</td>
                  </tr>
                </tbody>
              </Table>
              <Row>
                <Col xs={1}></Col>
                <Col xs={10}>
                  <BusSeatLayout
                    layerGrid={selectedLayer?.availableSeats}
                    setSelectedSeats={setSelectedSeats}
                    alreadyBooked={alreadyBooked}
                  />
                </Col>
                <Col xs={1}></Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  color="danger"
                  outline
                  className="mx-1"
                  onClick={() => {
                    setIsViewModalOpen(!isViewModalOpen), resetFields()
                  }}
                >
                  {props.t('Cancel')}
                </Button>
              </div>
            </ModalFooter>
          </Modal>
          {/* Layer Modal End */}
        </div>
      )}
    </React.Fragment>
  )
}
Layer.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any,
}
export default withTranslation()(Layer);
