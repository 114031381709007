import * as React from 'react';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { CalendarPickerSkeleton } from '@mui/x-date-pickers/CalendarPickerSkeleton';
import { useEffect } from 'react';
import { MobileDatePicker, StaticDatePicker } from '@mui/x-date-pickers';
// import { useStylesBookingForm, useStylesBookingFormUserFee } from "./useStyles.js";
import { Label } from 'reactstrap';
// import { callApi } from '../apiHandlers/callApi.js';
import { makeStyles } from '@mui/styles';
function getRandomNumber(min, max) {
    return Math.round(Math.random() * (max - min) + min);
}

/**
 * Mimic fetch with abort controller https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 * ⚠️ No IE11 support
 */

const useStylesBookingFormUserFee = makeStyles({
    root: {

        '& .MuiPickersDay-root:not(.Mui-disabled)': {
            border: '3px solid green'
        },
        '& .MuiPickersDay-root': {
            fontWeight: 'bold',
            fontSize: '14px',
        },

        '& .MuiPickersToolbar-content ': {
            display: 'none'
        },
        // '& .MuiPickersDay-root:focus' : {
        //     // background : '#7D5A50',
        //     // border: '3px solid #7D5A50',
        //     // color : 'white !important'
        // },
        '& .MuiPickersDay-root:disabled': {
            border: '2px solid #FF8080 !important',
        }

    }
})


function fakeFetch(date, { signal }) {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
            const daysInMonth = date.daysInMonth();
            const daysToHighlight = [1, 2, 3].map(() => getRandomNumber(1, daysInMonth));

            resolve({ daysToHighlight });
        }, 500);

        signal.onabort = () => {
            clearTimeout(timeout);
            reject(new DOMException('aborted', 'AbortError'));
        };
    });
}

const initialValue = dayjs();


export default function CancelBusDatePicker({ }) {

    const requestAbortController = React.useRef(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [disableDates, setDisableDates] = React.useState([]);
    const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);
    // const [open,setOpen] = React.useState(false)

    // useEffect(()=>{
    //     if(ele != null){
    //         setOpen(true)
    //     }
    // },[ele])
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        let selectedDate = `${year}/${month}/${day}`;
        if (!disableDates?.includes(selectedDate)) {
            setDisableDates([...disableDates, selectedDate]);
        } else {
            let array = [...disableDates]
            const index = array.indexOf(selectedDate);
            if (index !== -1) {
                array.splice(index, 1);
                setDisableDates([...array])

            }
        }
    }
    const [ancEl, setAncEl] = React.useState(undefined)
    const fetchHighlightedDays = (date) => {
        const controller = new AbortController();
        fakeFetch(date, {
            signal: controller.signal,
        })
            .then(({ daysToHighlight }) => {

                // setHighlightedDays(daysToHighlight);
                setIsLoading(false);
            })
            .catch((error) => {
                // ignore the error if it's caused by `controller.abort`
                if (error.name !== 'AbortError') {
                    throw error;
                }
            });

        requestAbortController.current = controller;
    };

    React.useEffect(() => {
        fetchHighlightedDays(initialValue);
        // abort request on unmount
        return () => requestAbortController.current?.abort();
    }, []);

    const handleMonthChange = (date) => {
        // if (requestAbortController.current) {
        //     // make sure that you are aborting useless requests
        //     // because it is possible to switch between months pretty quickly
        //     requestAbortController.current.abort();
        //     monthChange(mandapId)
        // }

        setIsLoading(true);
        setHighlightedDays([]);
        // monthChange(date)
        fetchHighlightedDays(date);


    };

    const disableBookedDays = (date) => {
        const convertedIntoDateObject = disableDates?.map((bookedDate) => {
            return new Date(bookedDate).getTime();
        });

        return (convertedIntoDateObject?.includes(new Date(date).getTime()));
    };

    const classes = useStylesBookingFormUserFee();









    return (

        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Label>
                Click On Date To Stop Collection
            </Label>
            <StaticDatePicker
                classes={classes}
                className='mt-1 d-block w-100'
                toolbarPlaceholder=""
                openTo="day"
                loading={isLoading}
                onMonthChange={handleMonthChange}
                // renderLoading={() => <CalendarPickerSkeleton />}
                shouldDisableDate={disableBookedDays}
                disablePast
                showToolbar={false}
                label=" "
                // value={defaultDate}
                onChange={(newValue) => {
                    formatDate(newValue?.$d);

                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
}