import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { useNavigate } from "react-router-dom"
import * as XLSX from "xlsx"
import { callApi } from "helpers/api_helper"
import ViewRouteModal from "pages/Modals/ViewRouteModal";
import Breadcrumb from "../../components/Common/Breadcrumb"
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Pagination from "helpers/Utils/Pagination"
import "./Routes.css"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer"
import DownloadPdf from "pages/Utility/DownloadPdf"
import UpdateRouteModal from "pages/Modals/UpdateRouteModal"
import AddRouteModel from "pages/Modals/AdduserRoute"
import { withTranslation } from "react-i18next"

const priceRangeOptions = [
  {
    label: (
      <span>
        <i className="fas fa-rupee-sign"></i> 10 - 100
      </span>
    ),
    value: "10-100",
  },
  {
    label: (
      <span>
        <i className="fas fa-rupee-sign"></i> 100 - 500
      </span>
    ),
    value: "100-500",
  },
  {
    label: (
      <span>
        <i className="fas fa-rupee-sign"></i> 500 - 1000
      </span>
    ),
    value: "500-1000",
  },
  {
    label: (
      <span>
        <i className="fas fa-rupee-sign"></i> 1000 - 1500
      </span>
    ),
    value: "1000-1500",
  },
  {
    label: (
      <span>
        <i className="fas fa-rupee-sign"></i> 1500 - 2000
      </span>
    ),
    value: "1500-2000",
  },
]
const Routes = (props) => {
  const initialRoutes = [
    {
      startPoint: {
        station: "Bhubaneswar",

        time: "12 : 10 PM",
      },
      endPoint: {
        station: "Berhampur",

        time: "04 : 10 PM",
      },

      viaRoutes: [
        {
          station: "Khordha",
        },
        {
          station: "Balugaon",
        },
      ],
    },
  ]
  const [pdfContent, setPdfContent] = useState()

  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false)
  const [isEditRouteModalOpen, setIsEditRouteModalOpen] = useState(false)
  const [partnerOptions, setPartnerOptions] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [startPoint, setStartPoint] = useState({ station: "", time: "" })
  const [endPoint, setEndPoint] = useState({ station: "", time: "" })
  const [viewRouteModal, setViewRouteModal] = useState(false)
  const [routeList, setRouteList] = useState([initialRoutes])
  const [selectedRoute, setSelectedRoute] = useState({})
  const [stationOptions, setStationOptions] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const [viaRoutes, setViaRoutes] = useState([{ station: "" }])
  const [idx, setIdx] = useState(null)
  const [ticketPriceRange, setTicketPriceRange] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [errors, setErrors] = useState("")

  const navigate = useNavigate()

  const pageSizes = [1, 2, 4, 8, 10, 20, 50, 100, 500, 1000]

  const startIndex = (currentPage - 1) * selectedPageSize
  const endIndex = currentPage * selectedPageSize

  const resetFields = () => {
    setStartPoint({})
    setViaRoutes([{ station: "" }])
    setEndPoint({})
    setTicketPriceRange(null)
  }

  const getAllRoutes = async (size, tCurrentPage) => {
    try {
      const config = {
        method: "post",
        url: "busRoutes/getBus_Routes/getAllRoutes",
        data: {
          pageSize: size,
          currentPage: tCurrentPage,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        const result = []
        for (let i = 0; i < response.data.data.length; i += 2) {
          result.push([response.data.data[i], response.data.data[i + 1]])
        }

        setRouteList(result)
        setTotalItemCount(response.data.totalCount)
        setTotalPage(Math.ceil(response.data.totalCount / size))
        setIsLoading(false)
      } else {
        navigate("/login")
      }
    } catch (error) {
      console.log({ error })
    }
  }

  // const getAllCities = async (size, tCurrentPage) => {
  //   try {
  //     const config = {
  //       method: "post",
  //       url: "https://countriesnow.space/api/v0.1/countries/state/cities",
  //       data: {
  //         country: "India",
  //         state: "Odisha",
  //       },
  //     }
  //     const response = await axios(config)
  //     if (!response.data.error) {
  //       setStationOptions(
  //         response.data.data.map(item => {
  //           return {
  //             label: item,
  //             value: item,
  //           }
  //         })
  //       )
  //     }
  //   } catch (error) {
  //     console.log({ error })
  //   }
  // }

  const handleDelete = async item => {
    try {
      const config = {
        method: "post",
        url: "busRoutes/getBus_Routes/deleteRoute",
        data: {
          id: item._id,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        // setLayerList(response.data.data);
        await getAllRoutes(selectedPageSize, 1)
        setSelectedRoute({})
      }
    } catch (error) {
      console.log({ error })
    }
    const newRouteList = routeList.filter((item, i) => i !== index)
    setRouteList(newRouteList)
  }

  const createOption = label => ({
    label,
    value: label.replace(/\W/g, ""),
  })

  const handleStartPointCreate = inputValue => {
    const newOption = createOption(inputValue)
    setStationOptions(prev => [...prev, newOption])
    setStartPoint({ ...startPoint, station: newOption })
  }
  const handleEndPointCreate = inputValue => {
    const newOption = createOption(inputValue)
    setStationOptions(prev => [...prev, newOption])
    setEndPoint({ ...endPoint, station: newOption })
  }

  const handleDownload = async () => {
    try {
      const data = routeList?.map(item => {
        let vias = ""
        for (let index = 0; index < item?.viaRoute?.length; index++) {
          const element = item?.viaRoute[index]
          vias = `${vias} , ${element.station}`
        }
        return {
          "Start Point": item?.startPoint?.station,
          "End Point": item?.endPoint?.station,
          // "Seat Type": item?.seatType,
          vias: vias,
        }
      })
      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "RouteList.xlsx")
      }
    } catch (error) {
      console.log("error => ", error)
    }
  }

  const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })

  const getAllUsers = async () => {
    const config = {
      method: "post",
      url: "user/getUserRoutes/getAll",
    }
    const response = await callApi(config)
    if (response.data.code === 200) {
      const userArr = response.data.data.map(item => {
        return {
          label: item.userName,
          value: item._id,
          data: item,
        }
      })

      setPartnerOptions(userArr.filter(item => item.data.role === "PARTNER"))
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getAllRoutes(selectedPageSize, currentPage)
  }, [currentPage])

  useEffect(() => {
    // getAllCities()
    getAllUsers()
  }, [])

  const generatePDFContent = jsonData => {
    const data = jsonData.flat(1)

    return (
      <Document>
        <Page style={styles.page} size="A2">
          <View style={styles.container}>
            <View style={styles.tableRow}>
              <Text style={styles.headingCell}>Start Point</Text>
              <Text style={styles.headingCell}>End Point</Text>
            </View>
            {data.map((item2, index) => {
              return (
                <View key={index} style={styles.tableRow}>
                  <Text style={styles.valueCell}>
                    {item2?.startPoint?.station}
                  </Text>
                  <Text style={styles.valueCell}>
                    {item2?.endPoint?.station}
                  </Text>
                </View>
              )
            })}
          </View>
        </Page>
      </Document>
    )
  }

  const handleViewModal = async item => {
    setSelectedRoute(item)
    setViewRouteModal(true)
  }

  useEffect(() => {

    setPdfContent(generatePDFContent(routeList))
  }, [routeList])

  document.title = `${props.t("Routes")} | OneTKT`

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="" color="danger" />
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="OneTKT" breadcrumbItem="Routes" />
            {errorMessage && errorMessage ? (
              <Alert color="danger">{errorMessage}</Alert>
            ) : null}
            {successMessage ? (
              <Alert color="success">{successMessage}</Alert>
            ) : null}
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="card-title mb-4">{props.t('Routes')}</h4>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mx-1">
                      <Button
                        color="primary"
                        outline
                        className="mx-1"
                        onClick={() => {
                          setIsRouteModalOpen(true), resetFields()
                        }}
                      >
                        {props.t('Add Route')}
                      </Button>
                    </div>
                    <div className="mx-1">
                      <Button color="success">
                        <DownloadPdf
                          className={`mdi mdi-download me-2`}
                          pdfContent={pdfContent}
                          fileName={"Routes"}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%", overflowX: "scroll" }}>
                  <Row className="my-2">
                    <Col xs={4} className="fw-bold">
                      {props.t('Start Point')}
                    </Col>
                    <Col xs={4} className="fw-bold">
                      {props.t('End Point')}
                    </Col>
                    <Col xs={4} className="fw-bold">
                      {props.t('Actions')}
                    </Col>
                  </Row>
                  <hr />
                  {routeList?.map((item, index) => {
                    return (
                      <Row className="card routes" key={item._id}>
                        {item.map((item2, i) => {
                          return (
                            <Row
                              className="my-1"
                              key={item2?.startPoint?.station + i}
                            >
                              <Col xs={4}>{item2?.startPoint?.station}</Col>
                              <Col xs={4}>{item2?.endPoint?.station}</Col>
                              <Col xs={4}>
                                <ButtonGroup>
                                  <Button
                                    className="mx-1 "
                                    color="success"
                                    outline
                                    onClick={() => {
                                      setSelectedRoute(item2),
                                        setIsEditRouteModalOpen(true),
                                        setIdx(index)
                                    }}
                                  >
                                    <i className="mdi mdi-pencil mdi-12px" />
                                  </Button>
                                  <Button
                                    className="mx-1 "
                                    color="success"
                                    outline
                                    onClick={() => {
                                      handleViewModal(item2)
                                    }}
                                  >
                                    {props.t('View Route')}
                                  </Button>
                                  <Button
                                    className="mx-1"
                                    color="danger"
                                    outline
                                    onClick={() => handleDelete(item2)}
                                  >
                                    <i className="mdi mdi-delete mdi-12px" />
                                  </Button>
                                </ButtonGroup>
                              </Col>
                            </Row>
                          )
                        })}
                      </Row>
                    )
                  })}
                </div>

                <Pagination
                  currentPage={currentPage}
                  totalPage={totalPage}
                  onChangePage={i => setCurrentPage(i)}
                />
              </CardBody>
            </Card>
          </Container>

          {/* Route Modal Start */}
          <AddRouteModel
            AddModelopen={isRouteModalOpen}
            toggleAddModel={() => setIsRouteModalOpen(!isRouteModalOpen)}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            getAllRoutes={getAllRoutes}
            selectedRoute={selectedRoute}
            priceRangeOptions={priceRangeOptions}
            stationOptions={stationOptions}
            partnerOptions={partnerOptions}
          />

          <UpdateRouteModal
            modalOpen={isEditRouteModalOpen}
            toggleModal={() => setIsEditRouteModalOpen(!isEditRouteModalOpen)}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            getAllRoutes={getAllRoutes}
            selectedRoute={selectedRoute}
            priceRangeOptions={priceRangeOptions}
            stationOptions={stationOptions}
            partnerOptions={partnerOptions}
          />
          {/* Route Modal End */}

          {/* VIEW ROUTE MODAL START */}
          <Modal
            isOpen={viewRouteModal}
            toggle={() => setViewRouteModal(!viewRouteModal)}
            size="md"
            centered={true}
          >
            <ModalHeader toggle={() => setViewRouteModal(!viewRouteModal)}>
              <Label>{props.t('View Routes')}</Label>
            </ModalHeader>
            <ModalBody>
              <ViewRouteModal selectedRoute={[selectedRoute]} />
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  color="danger"
                  outline
                  className="mx-1"
                  onClick={() => setViewRouteModal(!viewRouteModal)}
                >
                  {props.t('Close')}
                </Button>
              </div>
            </ModalFooter>
          </Modal>

          {/* VIEW ROUTE MODAL END */}
        </div>
      )}
    </React.Fragment>
  )
}
Routes.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any,
}
export default withTranslation()(Routes);
