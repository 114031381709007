
import AWS from 'aws-sdk';

export async function Upload(file) {
    try {
        AWS.config.update({
            endpoint: new AWS.Endpoint('blr1.digitaloceanspaces.com'), // e.g., 'nyc3.digitaloceanspaces.com'
            accessKeyId: 'DO002LEBDZP7CX647E47',
            secretAccessKey: '+7A2cDpxBhzPAo9OY+TCvLNLeVFzQInTY/dKZzBd+tc',
            signatureVersion: 'v4',
            region: 'ap-south-1',
        });

        const s3 = new AWS.S3();

        const params = {
            Bucket: 'esevabemc',
            Key: file.name,
            Body: file,
            ContentType: 'application/png',
            ACL: 'public-read',
        };
        const uploadedObject = await s3.upload(params).promise();
        const imageUrl = uploadedObject.Location;

        console.log({ imageUrl })
        return imageUrl;
    } catch (error) {
        console.log(error);
    }
}

export default Upload;