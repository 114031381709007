import { callApi } from 'helpers/api_helper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Input } from 'reactstrap';
import CheckOut from './CheckOutPage';



let currentDateTimeString = new Date().toString()
let dateTimeParts = currentDateTimeString.split(' ')
let requiredDateFormat = dateTimeParts[2] + " " + dateTimeParts[1] + " " + dateTimeParts[3]

const BuyTokenModal = ({ modalOpen, toggleModal, getSubscription, setErrorMessage, setSuccessMessage, t }) => {
    const usename = JSON.parse(localStorage.getItem('user'))?.user?.userName

    const [amount, setAmount] = useState('');
    const [gst, setGst] = useState('');
    const [tokens, setTokens] = useState(0);
    const [message, setMessage] = useState('');
    const [username, setUsername] = useState(usename);
    const [errors, setErrors] = useState({});
    const [checkOutModalOpen, setCheckOutModalOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);


    const validate = () => {
        const error = {};
        if (!amount) {
            error.amount = 'Enter a valid amount!'
        }
        setErrors(error);
        return error;
    }
    // const handleSubmit = async () => {
    //     try {
    //         const error = await validate();
    //         if (Object.keys(error).length > 0) {
    //             return false
    //         }
    //         const config = {
    //             method: 'post',
    //             url: "subscription/getSubscriptionRoutes/addSubscription",
    //             data: {
    //                 amount,
    //                 tokens
    //             }
    //         };
    //         const response = await callApi(config);
    //      
    //         if (response.data.code === 200) {
    //             setErrorMessage(null);
    //             setSuccessMessage(response.data.message);
    //             setTimeout(() => {
    //                 setSuccessMessage(null);
    //             }, 2000);
    //             await getSubscription()
    //             toggleModal();
    //         }
    //         else {
    //             setSuccessMessage(null);
    //             setErrorMessage(response.data.message);
    //             setTimeout(() => {
    //                 setErrorMessage(null);
    //             }, 2000);
    //             toggleModal();
    //         }
    //     } catch (error) {
    //         console.log({ error });
    //         setSuccessMessage(null);
    //         setErrorMessage(error.message);
    //         setTimeout(() => {
    //             setErrorMessage(null);
    //         }, 2000);
    //         toggleModal();
    //     }
    // }

    useEffect(() => {
        const func = async () => {
            const usename = JSON.parse(await localStorage.getItem('user'))?.user?.userName

            setUsername(usename)
        }
        func()
    }, [])

    const resetFields = () => {
        setAmount('');
    }

    const handleSubmit = async () => {
        try {
            setErrors({});
            const error = await validate();

            if (Object.keys(error).length > 0) {
                return false
            }
            const config = {
                method: 'post',
                url: "pg/payment/phonePay",
                data: {
                    subscriptionRequestId: orderDetails?._id//"8328936896",
                },
            };
            const response = await callApi(config);

            if (response.data.code === 200) {
                setErrorMessage(null);
                setSuccessMessage(response.data.message);

                await localStorage.setItem('payment', JSON.stringify({ merchantId: response.data.data.data.merchantId, merchantTransactionId: response.data.data.data.merchantTransactionId }));

                setTimeout(() => {
                    setSuccessMessage(null);
                }, 2000);
                window.location.href = (response?.data?.data?.data?.instrumentResponse?.redirectInfo?.url)

                toggleModal();
            }
            else {
                setSuccessMessage(null);
                setErrorMessage(response.data.message);
                setTimeout(() => {
                    setErrorMessage(null);
                }, 2000);
                toggleModal();
            }
        } catch (error) {
            console.log({ error });
            setSuccessMessage(null);
            setErrorMessage(error.message);
            setTimeout(() => {
                setErrorMessage(null);
            }, 2000);
            toggleModal();
        }
    }

    const handleCheckoutModal = async () => {
        try {
            await handleCreateOrder();
        } catch (error) {
            console.log({ error });
            setSuccessMessage(null);
            setErrorMessage(error.message);
            setTimeout(() => {
                setErrorMessage(null);
            }, 2000);
        }
    }
    const handleCreateOrder = async () => {
        try {
            setErrors({});
            const error = await validate();
            if (Object.keys(error).length > 0) {
                return false
            }
            const user = JSON.parse(await localStorage.getItem('user'))?.user;
            const config = {
                method: 'post',
                url: "subscription/getSubscriptionRoutes/createOrder",
                data: {
                    tokens: tokens,
                    amount: amount,
                    mobileNumber: user.phoneNumber,//"8328936896",
                },
            };
            const response = await callApi(config);

            if (response.data.code === 200) {
                setOrderDetails(response.data.data)
                setErrorMessage(null);
                setSuccessMessage(response.data.message);
                setTimeout(() => {
                    setSuccessMessage(null);
                }, 2000);
                setCheckOutModalOpen(true);
                toggleModal(true);
                // resetFields();
            }
            else {
                setSuccessMessage(null);
                setErrorMessage(response.data.message);
                setTimeout(() => {
                    setErrorMessage(null);
                }, 2000);
                toggleModal();
                resetFields();
            }
        } catch (error) {
            console.log(error);
            setSuccessMessage(null);
            setErrorMessage(error.message);
            setTimeout(() => {
                setErrorMessage(null);
            }, 2000);
            toggleModal();
            resetFields();
        }
    }

    useEffect(() => {
        if (amount && Number(amount) > 0) {
            setTokens(amount ? Math.floor(Number(amount) / 2) : 0)
            setMessage(`For Rs. ${amount}, ${amount ? Math.floor(Number(amount) / 2) : 0} tokens will be available.`);
        }
    }, [amount])

    return (


        <div>

            <CheckOut
                modalOpen={checkOutModalOpen}
                requiredDateFormat={requiredDateFormat}
                handleSubmit={handleSubmit}
                orderDetails={orderDetails}
                toggleModal={() =>
                    setCheckOutModalOpen(
                        !checkOutModalOpen
                    )
                }

            />
            <Modal
                isOpen={modalOpen}
                toggle={toggleModal}
                centered
                size='md'
            >
                <ModalHeader toggle={() => { toggleModal(), setAmount('') }}>
                    <Label>{t('Buy Token')}</Label>
                </ModalHeader>
                <ModalBody>
                    <div className='container'>
                        <div className='p-4 text-center' >
                            <div>
                                <Input type='tel' placeholder='Please enter amount' value={amount} onChange={(e) => setAmount(Number(e.target.value))} />
                                {
                                    errors?.amount && <p className='text-danger'>{errors?.amount}</p>
                                }
                            </div>
                            <div className='my-3'>
                                <Row>
                                    <Col xs={3}>
                                        <Button className='' size='md' onClick={() => setAmount(Number(amount) + 5000)}>+5000</Button>
                                    </Col>
                                    <Col xs={3}>
                                        <Button className='' size='md' onClick={() => setAmount(Number(amount) + 1000)}>+1000</Button>
                                    </Col>
                                    <Col xs={3}>
                                        <Button className='' size='md' onClick={() => { setAmount(Number(amount) + 500) }}>+500</Button>
                                    </Col>
                                    <Col xs={3}>
                                        <Button className='' size='md' onClick={() => { setAmount(Number(amount) + 200) }}>+200</Button>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                {
                                    message &&
                                    <p className='text-primary'>{message}</p>
                                }
                            </div>
                            <div>
                                <Button
                                    className="btn btn-primary btn-lg waves-effect waves-light w-100"
                                    color='success'
                                    onClick={() => {
                                        handleCheckoutModal()
                                    }}
                                >
                                    <strong>{t('Buy')} {tokens} {t('Tokens')}</strong>
                                </Button>

                            </div>
                        </div>
                    </div>
                </ModalBody>

            </Modal>
        </div>
    )
}
BuyTokenModal.propType = {
    t: PropTypes.any
}
export default withTranslation()(BuyTokenModal);