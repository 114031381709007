import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })

export const generatePDFContent = (jsonData) => (
    <Document>
        <Page style={styles.page} size="A2">
            <View style={styles.container}>
                <View style={styles.tableRow}>
                    <Text style={styles.headingCell}>Partner Name</Text>
                    <Text style={styles.headingCell}>Bus Redg. No.</Text>
                    <Text style={styles.headingCell}>Tickets Sold</Text>
                </View>
                {jsonData.map((item, index) => (
                    <View key={index} style={styles.tableRow}>
                        <Text style={styles.valueCell}>{item?.bus[0]?.busName}</Text>
                        <Text style={styles.valueCell}>{item?.bus[0]?.busRegistrationNumber}</Text>
                        <Text style={styles.valueCell}>{item?.ticketsBooked}</Text>
                    </View>
                ))}
            </View>
        </Page>
    </Document>
);