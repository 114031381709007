import moment from "moment";

export function currentTime(time) {
    const timeArr = time.split(':');

    let hh = timeArr[0];
    let mm = timeArr[0];
    let session = "AM";

    if (hh == 0) {
        hh = 12;
    }
    if (hh > 12) {
        hh = hh - 12;
        session = "PM";
    }

    hh = (hh < 10) ? "0" + hh : hh;
    mm = (mm < 10) ? "0" + mm : mm;

    let formattedTime = hh + ":" + mm + " " + session;
    return formattedTime;

}
export const formatDate = (date) => {
    return moment(date)?.format('DD/MM/YYYY');
}