/* eslint-disable react/no-danger */
// import React from 'react';
import React, { useState, useEffect } from 'react';

import {
    Row, Col, CardBody, Button,
    Modal,
    ModalBody,
} from 'reactstrap';
import {
    MDBCol,
    MDBContainer,
    MDBRow,
} from "mdb-react-ui-kit";
// import Breadcrumb from 'containers/navs/Breadcrumb';
// import { Separator, Colxx } from 'components/common/CustomBootstrap';
// import IntlMessages from 'helpers/IntlMessages';

import phonePayLogo from '../../assets/images/PhonePe-Logo.wine.png';
const CheckOut = ({ modalOpen, orderDetails,
    toggleModal, handleSubmit, requiredDateFormat }) => {
    const [billTo, setBillTo] = useState('');


    useEffect(() => {
        const func = async () => {
            const user = JSON.parse(await localStorage.getItem('user'))?.user;
            setBillTo(user?.userName);
        }
        func()
    }, [])


    return (
        <>
            <Modal isOpen={modalOpen} toggle={toggleModal} centered size="lg">
                {/* <ModalHeader toggle={toggleModal}>
                    <Label>Invoice</Label>
                </ModalHeader> */}
                <ModalBody>


                    <CardBody>
                        <MDBContainer className="">
                            {/* <Row className="mb-5">
                            <Col xxs="12"> */}
                            <div>

                                <div className='text-center'>
                                    <p style={{ fontSize: "18px", fontWeight: 800, fontSize: '20px' }}>Payment Invoice</p>
                                </div>
                                <Row>

                                    <Col className='mt-4'>

                                        <div>

                                            <h2 className="my-2" style={{ fontSize: "16px", fontWeight: 800 }}>
                                                TicketCo Solutions Private Limited
                                            </h2>
                                            <p style={{ marginBottom: 0 }}>4th Floor, DLF Cybercity</p>
                                            <p style={{ marginBottom: 0 }}>Chandaka Industrial Estate, Patia</p>
                                            <p style={{ marginBottom: 0 }}>Bhubaneswar, Odisha 751024</p>
                                            <p style={{ marginBottom: 0 }}>India</p>
                                            <p style={{ marginBottom: 0 }}>Gst- <strong>21AAJCT2090F1ZM</strong></p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col style={{ display: 'flex', flexWrap: "wrap", justifyContent: "flex-start", paddingTop: "20px" }}>
                                        <div>
                                            <p style={{ fontWeight: 800, fontSize: '14px', marginBottom: 0 }}>Bill To :</p>
                                        </div>
                                        <div style={{ marginLeft: "6px" }}>
                                            <p style={{ marginBottom: 0 }}>{billTo}</p>
                                        </div>
                                    </Col>
                                    <Col style={{ display: 'flex', flexWrap: "wrap", justifyContent: "flex-end", paddingTop: "20px" }}>
                                        <div>
                                            <p style={{ fontWeight: 800, fontSize: '14px', marginBottom: 0 }}>Subscription Date :</p>
                                        </div>
                                        <div style={{ marginLeft: "6px" }}>
                                            <p style={{ marginBottom: 0 }}>{requiredDateFormat}</p>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="table-responsive" style={{ paddingTop: "20px" }}>
                                    <table className="table table-hover table-centered table-nowrap mb-0">
                                        <thead >
                                            <tr style={{ backgroundColor: "grey", color: "white" }}>
                                                <th scope="col"> Item Description</th>
                                                <th scope="col">Rate</th>
                                                <th scope="col">Amount</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>Tokens</td>
                                                <td>{orderDetails?.tokens}</td>
                                                <td>{orderDetails?.amount}</td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ display: "flex", justifyContent: 'flex-end', marginTop: "12px" }}>
                                    <div style={{ margingRight: "40px" }}>
                                        <p style={{ fontSize: "14px" }}>Sub Total:</p>
                                        <p style={{ fontSize: "14px" }}>GST(18%):</p>
                                        <p style={{ fontSize: "14px" }}>TOTAL:</p>
                                    </div>
                                    <div style={{ marginLeft: '12px' }}>
                                        <p style={{ fontWeight: 600, fontSize: "14px" }}>Rs.{orderDetails?.amount}</p>
                                        <p style={{ fontWeight: 600, fontSize: "14px" }}>Rs.{orderDetails?.gst}</p>
                                        <p style={{ fontWeight: 600, fontSize: "14px" }}>Rs.{orderDetails?.amount + orderDetails?.gst}</p>
                                    </div>
                                </div>

                                <MDBRow className="text-black">
                                    <MDBCol xl="12">
                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>

                                            <div>
                                                <Button
                                                    className="btn btn-primary btn-lg waves-effect waves-light w-100"
                                                    color='danger'
                                                    onClick={toggleModal}
                                                >
                                                    <strong>Cancel</strong>
                                                </Button>

                                            </div>
                                            <div>
                                                <Button
                                                    className="btn btn-primary btn-lg waves-effect waves-light w-100"
                                                    color='success'
                                                    onClick={() => handleSubmit()}
                                                >
                                                    <strong>Proceed</strong>
                                                </Button>

                                            </div>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <div className="text-center" style={{ marginTop: "90px", display: "flex", justifyContent: "center", alignContent: "center" }}>
                                    <div style={{ marginTop: 22 }}>
                                        <p className='m-0'>Powered By</p>
                                    </div>
                                    <div>
                                        <p><img className='m-0' src={phonePayLogo} alt='phonePayLogo' style={{ height: '65px', width: "100px" }} /></p>
                                    </div>

                                </div>

                            </div>

                        </MDBContainer>

                    </CardBody>
                </ModalBody>
            </Modal>
        </>
    );
};

export default CheckOut;
