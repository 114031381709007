import { callApi } from "helpers/api_helper";
import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Button, ButtonGroup, Label, Row, Col,
  CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Container
} from "reactstrap";
import phonePayLogo from '../../assets/images/PhonePe-Logo.wine.png';

import html2pdf from 'html2pdf.js';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";

import successImage from '../../assets/images/successImage.gif';
import failureImage from '../../assets/images/failure_payment.gif';
// import phonePayLogo from '../../assets/images/PhonePe-Logo.wine.png';
let currentDateTimeString = new Date().toString()
let dateTimeParts = currentDateTimeString.split(' ')
let requiredDateFormat = dateTimeParts[2] + " " + dateTimeParts[1] + " " + dateTimeParts[3]
function formatToDisplayDate(date) {
  if (date) {
    let temp = date.NOW.split('T');
    let newDate = temp[0];
    let newTime = temp[1];

    newDate = newDate.split('-').reverse().join('/');

    return newDate
  } else {
    return ""
  }
}


const RedirectUrl = () => {
  const usename = JSON.parse(localStorage.getItem('user'))?.user?.userName

  // const [modalOpen, setModalOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [gst, setGst] = useState();
  const [gstNumber, setGstNumber] = useState();
  const [tokens, setTokens] = useState();
  const [username, setUsername] = useState(usename);
  const [user, setUser] = useState({});
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [pincode, setPincode] = useState('');
  const [state, setState] = useState('');
  const [status, setStatus] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();



  const navigate = useNavigate();
  // useEffect(() => {
  //   window.location.reload();
  // }, [])

  useEffect(() => {
    // Handle the payment status when component mounts
  }, []);

  //   useEffect(() => {
  //     setGstNumber(Math.ceil(amount * 18 / 100))
  // }, [amount])

  useEffect(() => {
    const func = async () => {
      const search = await searchParams.get('subscriptionRequestId');
      const user = JSON.parse(await localStorage.getItem('user'))?.user;
      const usename = user?.userName;
      const gstNumber = user?.profile?.GSTNumber;
      const line1 = user?.profile?.address1?.line1;
      const line2 = user?.profile?.address1?.line2;
      const pincode = user?.profile?.address1?.pincode;
      const state = user?.profile?.address1?.state;
      //   const gstNumber = JSON.parse(await localStorage.getItem('user'))?.user?.profile?.GSTNumber
      // setGstNumber(gstNumber)
      setLine1(line1);
      setLine2(line2);
      setPincode(pincode);
      setState(state);
      setGstNumber(gstNumber);
      setUsername(usename);
      await handlePaymentStatus(search);
    }
    func();
  }, [])

  const handlePaymentStatus = async (search) => {
    try {
      // const { merchantId, merchantTransactionId } = JSON.parse(await localStorage.getItem('payment'));
      const config = {
        url: 'pg/payment/redirect-url',
        method: 'post',
        data: {
          subscriptionRequestId: search
        }

      }
      const response = await callApi(config);


      if (response?.data?.code === 200) {

        // Payment status received successfully

        // setModalOpen(true); // Show the success modal
        const { subscriptionRequest } = response?.data?.data
        setAmount(subscriptionRequest.amount ? subscriptionRequest.amount : 0);
        setGst(subscriptionRequest?.gst);
        setTokens(subscriptionRequest?.tokens);
        setUser(subscriptionRequest?.user)


      } else {
        setStatus(true);
        // Payment status failed to be received
        // You can display a failure message to the user here
      }
    } catch (error) {
      // Handle any errors that occurred during the POST request
      console.log(error);
      // You can display an error message to the user here
    }
  };

  const contentRef = useRef(null);

  const generatePDF = () => {
    const content = contentRef.current;
    const opt = {
      margin: [0, 0],
      filename: 'invoice.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().from(content).set(opt).save();
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div>
          {
            status ?
              <img src={failureImage} alt={`${status}`} />
              :
              <div>

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                  <Button color="success" className={`mdi mdi-download me-2`}
                    onClick={generatePDF}>Download PDF</Button>
                </div>

                <CardBody style={{ marginTop: "20px" }}>
                  <MDBContainer className="" ref={contentRef}>
                    <div>
                      <div className='text-center'>
                        <p style={{ fontSize: "20px", fontWeight: 800, marginTop: "20px" }}>Payment Invoice</p>
                      </div>
                      <Row>

                        <Col className='mt-4'>
                          <div>

                            <h2 className="my-1" style={{ fontSize: "20px", fontWeight: 800 }}>
                              TicketCo Solutions Pvt.Ltd.
                            </h2>
                            {/* <h2 style={{ fontSize: "20px", fontWeight: 800 }}>
                                                          Private Limited
                                                      </h2> */}
                            <p style={{ marginBottom: 0 }}>4th Floor, DLF Cybercity</p>
                            {/* <p style={{ marginBottom: 0 }}>Sayed Ashraf Ali</p> */}
                            <p style={{ marginBottom: 0 }}>Chandaka Industrial Estate, Patia</p>
                            <p style={{ marginBottom: 0 }}>Bhubaneswar, Odisha 751024</p>
                            <p style={{ marginBottom: 0 }}>India</p>
                            <p style={{ marginBottom: 0 }}>Gst: <strong>21AAJCT2090F1ZM</strong></p>

                          </div>
                        </Col>
                        {/* <Col className='mt-2 text-end'>
      
      
                        <div style={{marginTop:"20px"}}>
                          <h2 className="my-1" style={{ fontWeight: 800, fontSize: '20px'}}>Vendor Address :</h2>
                          <p style={{ marginBottom: 0 }}>Pradhan Travels</p>
                          <p style={{ marginBottom: 0 }}>GST: <strong>{gstNumber}</strong></p>
                          <p style={{ marginBottom: 0 }}>{line1}, {line2}</p>
                          <p style={{ marginBottom: 0 }}>{state} - {pincode}</p>
                          <p style={{ marginBottom: 0 }}>India</p>
                        </div>
                      </Col> */}
                      </Row>
                      <Row>
                        <Col style={{ display: 'flex', flexWrap: "wrap", justifyContent: "flex-start", paddingTop: "20px" }}>
                          <div>
                            <p style={{ fontWeight: 800, fontSize: '14px', marginBottom: 0 }}>Bill To :</p>
                          </div>
                          <div style={{ marginLeft: "6px" }}>
                            <p style={{ marginBottom: 0 }}>{user?.userName}</p>
                          </div>
                        </Col>
                        <Col style={{ display: 'flex', flexWrap: "wrap", justifyContent: "flex-end", paddingTop: "20px" }}>
                          <div>
                            <p style={{ fontWeight: 800, fontSize: '14px', marginBottom: 0 }}>Subscription Date :</p>
                          </div>
                          <div style={{ marginLeft: "6px" }}>
                            <p style={{ marginBottom: 0 }}>{requiredDateFormat}</p>
                          </div>
                        </Col>
                      </Row>

                      <div className="table-responsive" style={{ paddingTop: "20px" }}>
                        <table className="table table-hover table-centered table-nowrap mb-0">
                          <thead >
                            <tr style={{ backgroundColor: "grey", color: "white" }}>
                              <th scope="col"> Item Description</th>
                              <th scope="col">Qty</th>
                              {/* <th scope="col">Rate</th> */}
                              <th scope="col">Amount</th>

                            </tr>
                          </thead>
                          <tbody>

                            <tr>
                              <td>Tokens</td>
                              <td>{tokens ? tokens : "-"}</td>
                              {/* <td>2</td> */}
                              <td>Rs. {amount}</td>

                            </tr>

                          </tbody>
                        </table>
                      </div>
                      <div style={{ display: "flex", justifyContent: 'flex-end', marginTop: "12px" }}>
                        <div style={{ margingRight: "40px" }}>
                          <p style={{ fontSize: "14px" }}>Sub Total:</p>
                          <p style={{ fontSize: "14px" }}>GST(18%):</p>
                          <p style={{ fontSize: "14px" }}>TOTAL:</p>
                        </div>
                        <div style={{ marginLeft: '12px' }}>
                          <p style={{ fontWeight: 600, fontSize: "14px" }}> Rs.{amount}
                          </p>
                          <p style={{ fontWeight: 600, fontSize: "14px" }}>Rs. {gst}</p>
                          <p style={{ fontWeight: 600, fontSize: "14px" }}>Rs. {amount + gst}</p>
                        </div>


                      </div>


                      <div className="text-center" style={{ marginTop: "90px", display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <div style={{ marginTop: 22 }}>

                          <p>Powered By<img className='m-0' src={phonePayLogo} alt='phonePayLogo' style={{ height: '65px', width: "100px" }} /></p>

                        </div>


                      </div>
                    </div>

                  </MDBContainer>

                </CardBody>


              </div>
          }


        </div >
      </Container >
    </div >
  );
};





export default RedirectUrl;
