import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import servicesIcon2 from "../../assets/images/services-icon/02.png";
import servicesIcon3 from "../../assets/images/services-icon/tokenization.png";
import servicesIcon4 from "../../assets/images/services-icon/requirement.png";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  InputGroup
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';



const Token = (props) => {
  const [data_attr, setdata_attr] = useState(0);
  const [token, setToken] = useState(null);
  document.title = `${props.t("Tokens")} | OneTKT`;
  return (

    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className='mt-4'>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <div className='d-flex allign-content-center justify-content-center' style={{ "height": "300px", "overflow": "hidden" }}>
                    <img height={"300px"} src='https://thumbs.dreamstime.com/b/sample-ribbon-banner-sign-153372980.jpg'></img>

                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Tokens Remaining
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      1,685{" "}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Avg Daily Requirement
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      500{" "}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Token Expires In
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      4 Days{" "}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div>
                  </div>
                </CardBody>
                <Button
                  className="btn btn-primary btn-lg waves-effect waves-light"
                  color='success'> <strong>Buy Tokens</strong> </Button>
              </Card>
            </Col>

            <Col xs={12} md={8}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Latest Transaction</h4>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col"> #Id</th>
                          <th scope="col">Token</th>
                          <th scope="col">Date</th>
                          <th scope="col">Amount</th>
                          <th scope="col" colSpan="2">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">14256</th>
                          <td>
                            <div>

                              Philip Smead
                            </div>
                          </td>
                          <td>15/1/2018</td>
                          <td>Rs. 94</td>
                          <td>
                            <span className="badge bg-success">
                              Delivered
                            </span>
                          </td>

                        </tr>
                        <tr>
                          <th scope="row">14257</th>
                          <td>
                            <div>

                              Brent Shipley
                            </div>
                          </td>
                          <td>16/1/2019</td>
                          <td>Rs. 112</td>
                          <td>
                            <span className="badge bg-warning">Pending</span>
                          </td>

                        </tr>
                        <tr>
                          <th scope="row">14258</th>
                          <td>
                            <div>

                              Robert Sitton
                            </div>
                          </td>
                          <td>17/1/2019</td>
                          <td>Rs. 116</td>
                          <td>
                            <span className="badge bg-success">
                              Delivered
                            </span>
                          </td>

                        </tr>
                        <tr>
                          <th scope="row">14259</th>
                          <td>
                            <div>

                              Alberto Jackson
                            </div>
                          </td>
                          <td>18/1/2019</td>
                          <td>Rs. 109</td>
                          <td>
                            <span className="badge bg-danger">Cancel</span>
                          </td>

                        </tr>
                        <tr>
                          <th scope="row">14260</th>
                          <td>
                            <div>

                              David Sanchez
                            </div>
                          </td>
                          <td>19/1/2019</td>
                          <td>Rs. 120</td>
                          <td>
                            <span className="badge bg-success">
                              Delivered
                            </span>
                          </td>

                        </tr>

                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>



  )
}

Token.propTypes = {
  t: PropTypes.any
}
export default withTranslation()(Token);