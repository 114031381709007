import React from "react";
import PropTypes from 'prop-types';

import { useEffect } from "react"
import {
    Button,
    Col,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap"
import { withTranslation } from "react-i18next";

const ViewRouteModal = ({ selectedRoute, t }) => {
    useEffect(() => {

    }, [selectedRoute])
    return (
        <div>
            <div className="">
                <h5 className="font-size-16 mb-4">{t('Routes')}</h5>

                <Row>
                    {
                        selectedRoute?.map((item) => {
                            return (
                                <Col xs={12} md={6} key={item?._id}>
                                    <ol className="activity-checkout mb-0 px-4 mt-3">
                                        <li className="checkout-item">
                                            <div className="avatar-sm checkout-icon p-1">
                                                <div className="avatar-title rounded-circle bg-success">
                                                    <i className="mdi mdi-bus text-white font-size-20"></i>
                                                </div>
                                            </div>
                                            <div className="feed-item-list">
                                                <div>
                                                    <h5 className="font-size-16 py-2">{item?.startPoint?.station}</h5>
                                                </div>

                                            </div>
                                        </li>
                                        {
                                            item?.viaRoute?.map((item1, i) => {
                                                return (
                                                    <li key={item1.station + i} className="checkout-item">
                                                        <div className="avatar-sm checkout-icon p-2">
                                                            <div className="avatar-title rounded-circle bg-primary">
                                                                <i className="mdi mdi-bus text-white font-size-15"></i>
                                                            </div>
                                                        </div>
                                                        <div className="feed-item-list">
                                                            <div>
                                                                <h5 className=" py-2" style={{ fontSize: '14px' }}>{item1?.station}</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }

                                        <li className="checkout-item">
                                            <div className="avatar-sm checkout-icon p-1">
                                                <div className="avatar-title rounded-circle bg-danger">
                                                    <i className="mdi mdi-bus text-white font-size-20"></i>
                                                </div>
                                            </div>
                                            <div className="feed-item-list">

                                                <h5 className="font-size-16 py-2">{item?.endPoint?.station}</h5>
                                            </div>
                                        </li>

                                    </ol>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </div>
    )
}
ViewRouteModal.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(ViewRouteModal);
