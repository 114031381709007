import React from 'react';

const SleeperIcon = ({ seatNo, isSelected = false, isBooked = false }) => {


  const selectedStyle = {}


  if (isSelected) {
    selectedStyle.boxShadow = "rgba(0, 0, 0, 0.35) 0px 5px 15px"
    selectedStyle.borderColor = "#02A499"
    selectedStyle.borderWidth = '2px'

  }

  if (isBooked) {
    // selectedStyle.boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
    selectedStyle.borderColor = "#EC4561"
    // selectedStyle.borderWidth='3px'
  }


  return (
    <div className='d-flex justify-content-center flex-column m-1' style={{ padding: '10px 0px', height: '45px', width: '35px', border: '2px solid black', borderRadius: '4px', ...selectedStyle }}>
      <div className='d-flex justify-content-around align-items-center'>

        <div style={{ width: '25px', height: "7px", border: '2px solid black', borderRadius: '3px', ...selectedStyle }}></div>
      </div>
      <div className='d-flex mt-1 justify-content-around align-items-center flex-column my-2'>
        <strong style={{ "fontSize": "12px" }}>{seatNo}</strong>
      </div>
      {/* <div className='d-flex justify-content-center flex-column m-1' style={{padding : '10px 0px',height : '55px', width:'35px' ,border : '2px solid black', borderRadius:'4px'}}>
         <div className='d-flex justify-content-around align-items-center'>
         
             <div style={{width : '25px',height: "7px",border : '2px solid black', borderRadius:'3px'}}></div>
         </div>
         <div className='d-flex justify-content-around align-items-center flex-column my-2'> 
             <strong style={{"fontSize":"10px"}}>{seatNo}</strong>
          </div>
         </div> */}
    </div>

  )
}

export default SleeperIcon;