import React from "react";
const { default: ReactApexChart } = require("react-apexcharts");

class SemiCircularChart extends React.Component {
    constructor(props) {
        super(props);

        const percent = this.props.percent ? (this.props.percent * 100).toFixed() : 0
        console.log({ liveRunningBusPercentage: percent });
        this.state = {

            series: [percent],
            options: {
                chart: {
                    type: 'radialBar',
                    offsetY: -20,
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#1bf100",  /* fallback for old browsers */
                            // background: '-webkit-linear-gradient(to right, #FF0000, #FFF200, #1E9600)', /* Chrome 10-25, Safari 5.1-6 */
                            // background: 'linear-gradient(to right, #FF0000, #FFF200, #1E9600)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: -2,
                                fontSize: '12px'
                            }
                        }
                    }
                },
                grid: {
                    padding: {
                        top: 10
                    },
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        shadeIntensity: 0.4,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 53, 91]
                    },
                },
                labels: ['Average Results'],
            },


        };
    }



    render() {
        return (


            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
            </div>


        );
    }
}

export default SemiCircularChart;