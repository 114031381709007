import PropTypes from "prop-types"
import { sentenceCase } from "change-case"
import React, { useState, useEffect } from "react"
import * as XLSX from "xlsx"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Table,
  ButtonGroup,
  Spinner,
} from "reactstrap";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

import DownloadPdf from "pages/Utility/DownloadPdf"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// actions
import { callApi } from "helpers/api_helper"
import ViewUserDetails from "pages/Modals/ViewUserDetails"
import Pagination from "helpers/Utils/Pagination"
import { useNavigate } from "react-router-dom"
import EditPartner from "pages/Modals/EditPartner"
import CreatePartner from "pages/Modals/CreatePartner"
import { withTranslation } from "react-i18next"

const AddUser = props => {
  const [name, setname] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [role, setRole] = useState("")
  const [idx, setidx] = useState(1)
  const [data, setData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [roleOptions, setRoleOptions] = useState([])
  const [selectedRole, setSelectedRole] = useState()
  const [selectedUser, setSelectedUser] = useState()

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [pdfContent, setPdfContent] = useState()

  const pageSizes = [2, 4, 8, 10, 20, 50, 100, 500, 1000]

  const startIndex = (currentPage - 1) * selectedPageSize
  const endIndex = currentPage * selectedPageSize

  const navigate = useNavigate()

  const changePageSize = async size => {
    setCurrentPage(1)
    setSelectedPageSize(size)
    await getAllUsers(size, 1)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userName: name || "",
      phoneNumber: phoneNumber || "",
      role: role || "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter Your User Name"),
      phoneNumber: Yup.string().required("Please Enter Your Phone Number"),
      // role: Yup.string().required("Please Enter Your Role"),
    }),
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  const handleSubmit = async values => {
    try {
      const config = {
        method: "post",
        url: "user/getUserRoutes/createUser",
        data: {
          userName: values.userName,
          phoneNumber: values.phoneNumber,
          role: selectedRole.value,
        },
      }

      const response = await callApi(config)
      if (response.data.code === 200) {
        setIsModalOpen(false)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setSuccessMessage(response.data.message)
        await getAllUsers()
      } else {
        setTimeout(() => {
          setErrorMessage(null)
        }, 2000)
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      setErrorMessage(error.message)
    }
  }

  const handleDelete = async phoneNumber => {
    try {
      const config = {
        method: "post",
        url: "user/getUserRoutes/deleteUserByPhoneNumber",
        data: {
          phoneNumber,
        },
      }

      const response = await callApi(config)
      if (response.data.code === 200) {
        setIsModalOpen(false)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setSuccessMessage(response.data.message)
        await getAllUsers()
      } else {
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const getAllRoles = async () => {
    const config = {
      method: "get",
      url: "roles/getRoleRoutes/getAllUserRoles",
    }
    const response = await callApi(config)

    if (response.data.code === 201) {
      const tempArr = response.data.data.map(item => {
        if (item.roleName === "PARTNER") {

          setSelectedRole({
            label: sentenceCase(item.roleName),
            value: item.roleName,
            data: item,
          })
          // setFieldValue('role', item.roleName)
        }
        return {
          label: sentenceCase(item.roleName),
          value: item.roleName,
          data: item,
        }
      })

      setRoleOptions(tempArr)
    }
  }

  const getAllUsers = async (size, tCurrentPage) => {
    const config = {
      method: "post",
      url: "user/getUserRoutes/getAllPartners",
      data: {
        pageSize: size,
        currentPage: tCurrentPage,
      },
    }
    const response = await callApi(config)
    if (response.data.code === 200) {
      setIsLoading(false)
      // const tempArr = response.data.data.filter((item) => item?.role === 'PARTNER')
      setData(response.data.data)
      setTotalItemCount(response.data.data.length)
      setTotalPage(Math.ceil(response.data.data.length / size))
    } else {
      navigate("/login")
    }
  }

  const handleDownload = async () => {
    try {
      // const data = bookedTicketsList?.map((item) => {
      //   return {
      //     "Partner Name": item?.bus[0]?.busName,
      //     "Bus Registration Number": item?.bus[0]?.busRegistrationNumber,
      //     "Tickets Sold": item?.ticketsBooked,
      //   }
      // })
      if (data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "PartnerList.xlsx")
      }
    } catch (error) {
      console.log("error => ", error)
    }
  }

  const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })
  const generatePDFContent = jsonData => (
    <Document>
      <Page style={styles.page} size="A2">
        <View style={styles.container}>
          <View style={styles.tableRow}>
            <Text style={styles.headingCell}>User ID</Text>
            <Text style={styles.headingCell}>User Name</Text>
            <Text style={styles.headingCell}>Business Name</Text>
            <Text style={styles.headingCell}>Phone Number</Text>
            <Text style={styles.headingCell}>Profile Status</Text>
            <Text style={styles.headingCell}>MPIN Status</Text>
          </View>
          {jsonData.map((user, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.valueCell}>{user?.userId}</Text>
                <Text style={styles.valueCell}>{user?.userName}</Text>
                <Text style={styles.valueCell}>
                  {user?.profile?.businessName
                    ? user?.profile?.businessName
                    : "-"}
                </Text>
                <Text style={styles.valueCell}>{user?.phoneNumber}</Text>
                <Text style={styles.valueCell}>
                  {user?.isProfileOk ? "Updated" : "Not Updated"}
                </Text>
                <Text style={styles.valueCell}>
                  {user?.isMPINOk ? "Updated" : "Not Updated"}
                </Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )

  useEffect(() => {


    setPdfContent(generatePDFContent(data))
  }, [data])

  useEffect(() => {
    getAllRoles()
  }, [])

  useEffect(() => {
    setIsLoading(true)
    getAllUsers(selectedPageSize, currentPage)
  }, [currentPage])

  const setUser = user => {
    setSelectedUser(user)
    setPhoneNumber(user?.phoneNumber)
    setname(user?.userName)
    setRole(roleOptions?.filter(item => item.value === user?.userName))
  }

  const resetFormValues = () => {
    validation.setValues({
      userName: "", // Reset to initial values
      phoneNumber: "", // Reset to initial values
      role: "", // Reset to initial values
      idx: "", // Reset to initial values
    })
  }

  document.title = "Add User | OneTKT"
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="" color="danger" />
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Dashboard" breadcrumbItem="Partners" />

            <Row>
              <Col lg="12">
                {errorMessage && errorMessage ? (
                  <Alert color="danger">{errorMessage}</Alert>
                ) : null}
                {successMessage ? (
                  <Alert color="success">{successMessage}</Alert>
                ) : null}

                <ViewUserDetails
                  modalOpen={isViewModalOpen}
                  toggleModal={() => setIsViewModalOpen(!isViewModalOpen)}
                  selectedUser={selectedUser}
                />
                <Card>
                  <CardBody>
                    <div className="text-end mb-3 d-flex justify-content-center align-items-center">
                      <div className="mx-1">
                        <Button
                          color="primary"
                          onClick={() => setIsModalOpen(true)}
                        >
                          <i className={`ion ion-ios-person-add me-2`}></i>
                          {props.t('Add New User')}
                        </Button>
                      </div>
                      <div className="mx-1">
                        <Button color="success">
                          <DownloadPdf
                            className={`mdi mdi-download me-2`}
                            pdfContent={pdfContent}
                            fileName={"Partners"}
                          />
                        </Button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <Table>
                        <thead>
                          <tr>
                            <th>{props.t('User ID')}</th>
                            <th>{props.t('User Name')}</th>
                            <th>{props.t('Business Name')}</th>
                            <th>{props.t('Phone Number')}</th>
                            <th>{props.t('Profile Status')}</th>
                            <th>{props.t('MPIN Status')}</th>
                            <th>{props.t('Actions')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map(user => {
                            return (
                              <tr key={user?.userId}>
                                <td>{user?.userId}</td>
                                <td>{user?.userName}</td>
                                <td>
                                  {user?.profile?.businessName
                                    ? user?.profile?.businessName
                                    : "-"}
                                </td>
                                <td>{user?.phoneNumber}</td>
                                <td>
                                  {user?.isProfileOk
                                    ? "Updated"
                                    : "Not Updated"}
                                </td>
                                <td>
                                  {user?.isMPINOk ? "Updated" : "Not Updated"}
                                </td>
                                <td>
                                  <ButtonGroup>
                                    <Button
                                      outline
                                      color="primary"
                                      style={{ pointerEvents: "all" }}
                                      onClick={() => {
                                        setUser(user), setIsEditModalOpen(true)
                                      }}
                                    >
                                      {props.t('Edit')}
                                    </Button>
                                    <Button
                                      outline
                                      color="primary"
                                      style={{ pointerEvents: "all" }}
                                      onClick={() => {
                                        setUser(user),
                                          // setSelectedUser(user),
                                          setIsViewModalOpen(true)
                                      }}
                                    >
                                      {props.t('View Details')}
                                    </Button>
                                    <Button
                                      outline
                                      color="danger"
                                      style={{ pointerEvents: "all" }}
                                      onClick={() =>
                                        handleDelete(user?.phoneNumber)
                                      }
                                    >
                                      {props.t('Delete')}
                                    </Button>
                                  </ButtonGroup>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                    {/* <MDBDataTable responsive striped bordered data={data} /> */}
                    <Pagination
                      currentPage={currentPage}
                      totalPage={totalPage}
                      onChangePage={i => setCurrentPage(i)}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <h4 className="card-title mb-4">Add New User</h4> */}

            {/* Create Partner Modal Start*/}
            <CreatePartner
              modalOpen={isModalOpen}
              toggleModal={() => setIsModalOpen(!isModalOpen)}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              roleOptions={roleOptions}
              getAllUsers={getAllUsers}
            />
            {/* Edit Partner Modal Start*/}
            <EditPartner
              selectedUser={selectedUser}
              modalOpen={isEditModalOpen}
              toggleModal={() => setIsEditModalOpen(!isEditModalOpen)}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              roleOptions={roleOptions}
              getAllUsers={getAllUsers}
            />
            {/* Edit Partner Modal End*/}
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

AddUser.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(AddUser);
