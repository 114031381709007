import React, { useState, useEffect } from "react"
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
  InputGroup,
  UncontrolledDropdown,
  Spinner,
} from "reactstrap"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import Select from "react-select"

import DownloadPdf from "pages/Utility/DownloadPdf"
import { callApi } from "helpers/api_helper"
import { formatDate } from "helpers/Utils/formatTime"
import * as XLSX from "xlsx"

import Pagination from "helpers/Utils/Pagination"
import "./MyBooking.css"
import { withTranslation } from "react-i18next"

const options = [
  {
    label: "All",
    value: 0,
  },
  {
    label: "Last 7 Days",
    value: 7,
  },
  {
    label: "Last 30 Days",
    value: 30,
  },
  {
    label: "More than 30 Days",
    value: 366,
  },
]

const bookings = [
  {
    partner: "Biswakarma Travels",
    registrationNo: "OD02H1234",
    busName: "Rathore Express",
    route: "Cuttack to Puri",
    from: "Cuttack",
    to: "Puri",
    date: "2023-05-02",
    totalAmount: 550,
    amountReceived: 500,
    amountDue: 50,
    phoneNumber: "9856524785",
    conductorName: "Pradeep Kumar",
    conductorPhoneNumber: "8765432190",
    driverName: "Rajesh Behera",
    driverPhoneNumber: "7896543210",
  },
  {
    partner: "Shree Jagannath Travels",
    registrationNo: "OD02H1234",
    busName: "Puri Express",
    route: "Bhubaneswar to Puri",
    from: "Bhubaneswar",
    to: "Puri",
    date: "2023-05-03",
    totalAmount: 350,
    amountReceived: 300,
    amountDue: 50,
    phoneNumber: "9856532190",
    conductorName: "Rakesh Sahoo",
    conductorPhoneNumber: "9876543210",
    driverName: "Suresh Nayak",
    driverPhoneNumber: "8765432190",
  },
  {
    partner: "Maa Tara Travels",
    registrationNo: "OD02H1234",
    busName: "Tara Express",
    route: "Bhubaneswar to Balasore",
    from: "Bhubaneswar",
    to: "Balasore",
    date: "2023-05-04",
    totalAmount: 800,
    amountReceived: 700,
    amountDue: 100,
    phoneNumber: "9776543210",
    conductorName: "Sanjay Behera",
    conductorPhoneNumber: "7896543210",
    driverName: "Bikash Sahoo",
    driverPhoneNumber: "8765432190",
  },
  {
    partner: "Maa Mangala Travels",
    registrationNo: "OD02H1234",
    busName: "Mangala Express",
    route: "Cuttack to Bhadrak",
    from: "Cuttack",
    to: "Bhadrak",
    date: "2023-05-05",
    totalAmount: 400,
    amountReceived: 350,
    amountDue: 50,
    phoneNumber: "9856512345",
    conductorName: "Srikant Das",
    conductorPhoneNumber: "8765432190",
    driverName: "Sudhir Mohanty",
    driverPhoneNumber: "7896543210",
  },
  {
    partner: "Bharat Express",
    registrationNo: "OD02H1234",
    busName: "Bharat Deluxe",
    route: "Bhubaneswar to Rourkela",
    from: "Bhubaneswar",
    to: "Rourkela",
    date: "2023-05-06",
    totalAmount: 1500,
    amountReceived: 1400,
    amountDue: 100,
    phoneNumber: "9776543210",
    conductorName: "Manoj Kumar",
    conductorPhoneNumber: "7896543210",
    driverName: "Debasish Nayak",
    driverPhoneNumber: "8765432190",
  },
  {
    partner: "Biraja Travels",
    registrationNo: "OD02H1234",
    busName: "Biraja Express",
    route: "Bhubaneswar to Cuttack",
    from: "Bhubaneswar",
    to: "Cuttack",
    date: "2023-04-01",
    totalAmount: 200,
    amountReceived: 200,
    amountDue: 0,
    phoneNumber: "9876543210",
    conductorName: "Prakash Sahu",
    conductorPhoneNumber: "8765432109",
    driverName: "Rakesh Kumar",
    driverPhoneNumber: "7654321098",
  },
  {
    partner: "Maa Tarini Travels",
    registrationNo: "OD02H1234",
    busName: "Maa Tarini Deluxe",
    route: "Bhubaneswar to Rourkela",
    from: "Bhubaneswar",
    to: "Rourkela",
    date: "2023-04-02",
    totalAmount: 500,
    amountReceived: 400,
    amountDue: 100,
    phoneNumber: "8765432109",
    conductorName: "Santosh Mohanty",
    conductorPhoneNumber: "7654321098",
    driverName: "Hari Prasad",
    driverPhoneNumber: "6543210987",
  },
  {
    partner: "Sri Jagannath Travels",
    registrationNo: "OD02H1234",
    busName: "Jagannath AC",
    route: "Puri to Kolkata",
    from: "Puri",
    to: "Kolkata",
    date: "2023-04-03",
    totalAmount: 800,
    amountReceived: 800,
    amountDue: 0,
    phoneNumber: "7654321098",
    conductorName: "Sudhir Kumar",
    conductorPhoneNumber: "6543210987",
    driverName: "Dilip Singh",
    driverPhoneNumber: "5432109876",
  },
  {
    partner: "Ravi Travels",
    registrationNo: "OD02H1234",
    busName: "Ravi Deluxe",
    route: "Bhubaneswar to Sambalpur",
    from: "Bhubaneswar",
    to: "Sambalpur",
    date: "2023-04-04",
    totalAmount: 700,
    amountReceived: 700,
    amountDue: 0,
    phoneNumber: "6543210987",
    conductorName: "Ganesh Sahu",
    conductorPhoneNumber: "5432109876",
    driverName: "Rajesh Prasad",
    driverPhoneNumber: "4321098765",
  },
  {
    partner: "Sree Ganesh Travels",
    registrationNo: "OD02H1234",
    busName: "Sree Ganesh AC",
    route: "Cuttack to Kolkata",
    from: "Cuttack",
    to: "Kolkata",
    date: "2023-04-05",
    totalAmount: 900,
    amountReceived: 900,
    amountDue: 0,
    phoneNumber: "5432109876",
    conductorName: "Alok Sahu",
    conductorPhoneNumber: "4321098765",
    driverName: "Suresh Kumar",
    driverPhoneNumber: "3210987654",
  },
  {
    partner: "OSRTC",
    registrationNo: "OD02H1234",
    busName: "OSRTC Express",
    route: "Bhubaneswar to Cuttack",
    from: "Bhubaneswar",
    to: "Cuttack",
    date: "2023-04-01",
    totalAmount: 200,
    amountReceived: 200,
    amountDue: 0,
    phoneNumber: "9876543210",
    conductorName: "Prakash Sahu",
    conductorPhoneNumber: "8765432109",
    driverName: "Rakesh Kumar",
    driverPhoneNumber: "7654321098",
  },
  {
    partner: "OSRTC",
    registrationNo: "OD02H1234",
    busName: "OSRTC Deluxe",
    route: "Bhubaneswar to Rourkela",
    from: "Bhubaneswar",
    to: "Rourkela",
    date: "2023-04-02",
    totalAmount: 500,
    amountReceived: 400,
    amountDue: 100,
    phoneNumber: "8765432109",
    conductorName: "Santosh Mohanty",
    conductorPhoneNumber: "7654321098",
    driverName: "Hari Prasad",
    driverPhoneNumber: "6543210987",
  },
  {
    partner: "OSRTC",
    registrationNo: "OD02H1234",
    busName: "OSRTC AC",
    route: "Puri to Kolkata",
    from: "Puri",
    to: "Kolkata",
    date: "2023-04-03",
    totalAmount: 800,
    amountReceived: 800,
    amountDue: 0,
    phoneNumber: "7654321098",
    conductorName: "Sudhir Kumar",
    conductorPhoneNumber: "6543210987",
    driverName: "Dilip Singh",
    driverPhoneNumber: "5432109876",
  },
]
const MyBooking = (props) => {
  const [data_attr, setdata_attr] = useState(0)
  const [token, setToken] = useState(null)
  const [bookingReqList, setBookingReqList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [pdfContent, setPdfContent] = useState()
  const [selectedDifference, setSelectedDifference] = useState()
  const [search, setSearch] = useState("")
  const [query, setQuery] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  const pageSizes = [2, 4, 8, 10, 20, 50, 100, 500, 1000]

  const startIndex = (currentPage - 1) * selectedPageSize
  const endIndex = currentPage * selectedPageSize

  const changePageSize = async size => {
    setCurrentPage(1)
    setSelectedPageSize(size)
    await getAllBookingRequests(size, 1)
  }

  const navigate = useNavigate()

  const getAllBookingRequests = async (size, tCurrentPage) => {
    try {
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/getAllBookingRequest",
        data: {
          pageSize: size,
          currentPage: tCurrentPage,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setBookingReqList(response.data.data)
        setIsLoading(false)
        setTotalItemCount(response.data.total)
        setTotalPage(Math.ceil(response.data.total / size))
      } else {
        navigate("/login")
      }
    } catch (error) {
      console.log({ error })
    }
  }

  // const handleFilter = async e => {
  //   try {
  //     setSelectedDifference(e)
  //     await getAllBookingRequests(e.value)
  //   } catch (error) {
  //     console.log({ error })
  //   }
  // }

  // const handleSearch = async(e)=>{
  //   setSearch(e.target.value)

  //   searchBookings(e.target.value)
  // }
  //

  const handleSearch = async searchQuery => {
    try {
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/searchBooking",
        data: {
          searchQuery,
        },
      }
      c
      const response = await callApi(config)
      if (response.data.code === 200) {
        setBookingReqList(response.data.data.searchDetails)
        setIsLoading(false)
        setTotalItemCount(response.data.total)
        setTotalPage(Math.ceil(response.data.total / size))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilter = async item => {

    try {
      setSelectedDifference(item)
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/filterBookingsByDay",
        data: {
          filter: item,
          api_key: "registeruser",
          pageSize: selectedPageSize,
          currentPage: currentPage,
        },
      }
      const response = await callApi(config)
      if (response?.data?.code === 200) {
        setBookingReqList(response?.data?.data)
        setIsLoading(false)
        setTotalItemCount(response?.data?.total)
        setTotalPage(Math.ceil(response?.data?.total / size))
        setSelectedDifference()
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const handleDownload = async () => {
    try {
      if (bookingReqList.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(bookingReqList)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "BookingReqList.xlsx")
      }
    } catch (error) {
      console.log("error => ", error)
    }
  }

  document.title = `${props.t("My Booking")} | OneTKT`

  const styles = StyleSheet.create({
    page: {
      padding: "5px",

      width: "100%",
    },
    container: {
      border: "3px solid black",
      flex: 1,
      flexDirection: "column",
      padding: 10,
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#000",
      paddingBottom: 5,
      marginBottom: 5,
      width: "100%",
    },
    headingCell: {
      flex: 1,
      fontWeight: "bold",
      padding: "0 6px",
      fontSize: "15px",
      textAlign: "center",
    },
    valueCell: {
      flex: 1,
      padding: "0 6px",
      fontSize: "12px",
      textAlign: "center",
    },
  })
  const generatePDFContent = jsonData => (
    <Document>
      <Page style={styles.page} size="A2">
        <View style={styles.container}>
          <View style={styles.tableRow}>
            <Text style={styles.headingCell}>Partner Name</Text>
            <Text style={styles.headingCell}>Bus Redg. No.</Text>
            <Text style={styles.headingCell}>Route</Text>
            <Text style={styles.headingCell}>From</Text>
            <Text style={styles.headingCell}>To</Text>
            <Text style={styles.headingCell}>Date</Text>
            <Text style={styles.headingCell}>Total Amount</Text>
            <Text style={styles.headingCell}>Amount Received</Text>
            <Text style={styles.headingCell}>Amount Due</Text>
            <Text style={styles.headingCell}>Customer Phone Number</Text>
          </View>
          {jsonData?.map((bus, index) => {
            const route = `${bus?.route?.startPoint?.station} to ${bus?.route?.endPoint?.station}`

            return (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.valueCell}>{bus?.busName}</Text>
                <Text style={styles.valueCell}>
                  {bus?.busRegistrationNumber}
                </Text>
                <Text style={styles.valueCell}>{route}</Text>
                <Text style={styles.valueCell}>
                  {bus?.route?.startPoint?.station}
                </Text>
                <Text style={styles.valueCell}>
                  {bus?.route?.endPoint?.station}
                </Text>
                <Text style={styles.valueCell}>
                  {formatDate(bus?.createdAt)}
                </Text>
                <Text style={styles.valueCell}>{bus?.totalAmount}</Text>
                <Text style={styles.valueCell}>{bus?.totalReceivedAmount}</Text>
                <Text style={styles.valueCell}>
                  {bus?.totalReceivedAmount - bus?.totalAmount}
                </Text>
                <Text style={styles.valueCell}>{bus?.phoneNumber}</Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )
  useEffect(() => {

    setPdfContent(generatePDFContent(bookingReqList))
  }, [bookingReqList])

  useEffect(() => {
    const callFunc = async () => {
      setIsLoading(true)
      await getAllBookingRequests(selectedPageSize, currentPage)
    }
    callFunc()
  }, [currentPage])
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="" color="danger" />
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            <div className="mt-4">
              <Card>
                <CardBody>
                  <div className="mb-4">
                    <Row>
                      <Col xs={12} lg={5} className=" my-2">
                        <h4 className="text-start w-100 card-title">
                          {props.t('Bookings')}
                        </h4>
                      </Col>
                      <Col xs={12} lg={7} className="my-1 ">
                        <div className="w-100 d-flex justify-content-center align-items-center my-booking">
                          <div className="text-start w-100 my-2">
                            <Select
                              isSearchable={false}
                              className="w-100"
                              placeholder="Select to sort"
                              classNamePrefix="select2-selection"
                              options={options}
                              onChange={e => handleFilter(e.value)}
                            />
                          </div>
                          <InputGroup className="m-1 w-100">
                            <span className="input-group-append">
                              <span
                                className="input-group-text"
                                style={{ padding: "10px" }}
                              >
                                <i className="ion ion-ios-search" />
                              </span>
                            </span>
                            <input
                              className="form-control"
                              name="keyword"
                              id="search"
                              placeholder="search by Bus No/Ph"
                              value={query}
                              type="tel"
                              onChange={e => {
                                setQuery(e.target.value),
                                  handleSearch(e.target.value)
                              }}
                            />
                          </InputGroup>
                          <div className="m-1 w-100 downloadButton">
                            <Button color="success" className="w-md-100">
                              <DownloadPdf
                                className={`mdi mdi-download me-2`}
                                pdfContent={pdfContent}
                                fileName={"Bookings"}
                              />
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                    ></div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0 mt-5">
                      <thead>
                        <tr>
                          <th scope="col">{props.t('Partner')}</th>
                          <th scope="col">{props.t('Bus Redg. No.')}</th>
                          <th scope="col">{props.t('Route')}</th>
                          <th scope="col">{props.t('From')}</th>
                          <th scope="col">{props.t('To')}</th>
                          <th scope="col">{props.t('Date')}</th>
                          <th scope="col">{props.t('Total Amount')}</th>
                          <th scope="col">{props.t('Amount Received')}</th>
                          <th scope="col">{props.t('Amount Due')}</th>

                          <th scope="col">{props.t('Customer Phone Number')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingReqList?.map((bus, i) => {
                          const route = bus?.route
                            ? `${bus?.route[0]?.startPoint?.station} to ${bus?.route[0]?.endPoint?.station}`
                            : "-"
                          return (
                            <tr className="text-center" key={bus._id}>
                              <td>{bus?.bus?.busName}</td>
                              <td>{bus?.bus?.busRegistrationNumber}</td>
                              <td>{route}</td>
                              <td>
                                {bus?.route[0]?.startPoint?.station
                                  ? bus?.route[0]?.startPoint?.station
                                  : "-"}
                              </td>
                              <td>
                                {bus?.route[0]?.endPoint?.station
                                  ? bus?.route[0]?.endPoint?.station
                                  : "-"}
                              </td>
                              <td>{formatDate(bus?.createdAt)}</td>
                              <td>{bus?.totalAmount}</td>
                              <td>{bus?.totalReceivedAmount}</td>
                              <td>
                                {bus?.totalReceivedAmount - bus?.totalAmount}
                              </td>

                              <td>{bus?.phoneNumber}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <Pagination
                      currentPage={currentPage}
                      totalPage={totalPage}
                      onChangePage={i => setCurrentPage(i)}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}
MyBooking.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any
}
export default withTranslation()(MyBooking);
