import PropTypes from "prop-types";
import React, { useEffect, useCallback, useRef, useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link, useLocation } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const [role, setRole] = useState('');
  const [user, setUser] = useState({});

  const location = useLocation();
  const ref = useRef();
  const path = location.pathname;

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    const getRole = async () => {
      const user = JSON.parse(await localStorage.getItem('user'));
      setUser(user);
      setRole(user.user.role);
    }
    getRole();
  }, [])


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 992) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  // Function to handle click on the parent container
  const handleParentClick = (e) => {
    // Check if the clicked element is a link
    if (e.target.tagName === "A") {
      tToggle(); // Call the tToggle function
    }
  };
  // ["SUPER_ADMIN", "ADMIN", "SYSTEM_ADMINISTRATOR", "OPERATION_COORDINATOR", "BUSINESS_DEVELOPMENT_MANAGER", "", "PARTNER", "MANAGER", "CONDUCTOR"]
  const renderSideBar = (userRole) => {
    switch (userRole) {
      case 'SUPER_ADMIN':
        return (
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu" onClick={handleParentClick}>
                <li className="menu-title">{props.t("Inventory")} </li>

                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("Dashboards")}</span>
                  </Link>

                </li>
                <li>
                  <Link to="/profile" className="waves-effect">
                    <i className="mdi mdi-face"></i>
                    <span>{props.t("My Profile")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-ticket-confirmation-outline"></i>
                    <span>{props.t("Booking")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="/booking">{props.t("Book Now")}</Link>
                    </li> */}
                    <li>
                      <Link to="/myBooking">{props.t("View Bookings")} </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/cancelTicket" className=" waves-effect">
                    <i className="mdi mdi-cancel"></i>
                    <span>{props.t("Cancel Ticket")}</span>
                  </Link>

                </li>

                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ion ion-ios-person-add"></i>
                    <span>{props.t("Manage Users")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/addUser">{props.t("Partners")}</Link>
                    </li>
                    <li>
                      <Link to="/addStaff">{props.t("Team")} </Link>
                    </li>
                    <li>
                      <Link to="/addPartnerStaff">{props.t("My Staffs")}</Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-bus" />
                    <span>{props.t("Manage Bus")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/layers" className="waves-effect">
                        <span>{props.t("Layers")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/routes" className="waves-effect">
                        <span>{props.t("Routes")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/buses" className="waves-effect">
                        <span>{props.t("Bus")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link to="https://parivahan.gov.in/parivahan//en/content/vehicle-related-services" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Pay Vehicle Tax")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="https://rechargezap.in/fastag" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Recharge Fastag")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Tokens")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="/rechargeTickets" className="waves-effect">
                        <span>{props.t("Recharge Tokens")}</span>
                      </Link>
                    </li> */}

                    <li>
                      <Link to="/viewPayments" className="waves-effect">
                        <span>{props.t("View Payments")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-chart-bar"></i>
                    <span>{props.t("Analytics")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/exhaustingCoins" className="waves-effect">
                        <span>{props.t("Exhausting Coins")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/idlePartner" className="waves-effect">
                        <span>{props.t("Idle Partner")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/roles" className="waves-effect">
                    <i className="ion ion-ios-person-add"></i>
                    <span>{props.t("Roles")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/logout" className="waves-effect">
                    <i className="mdi mdi-logout"></i>
                    <span>{props.t("Logout")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        )
      case 'ADMIN':
        return (
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu" onClick={handleParentClick}>
                <li className="menu-title">{props.t("Inventory")} </li>

                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("Dashboards")}</span>
                  </Link>

                </li>
                <li>
                  <Link to="/profile" className="waves-effect">
                    <i className="mdi mdi-face"></i>
                    <span>{props.t("My Profile")}</span>
                  </Link>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-ticket-confirmation-outline"></i>
                    <span>{props.t("Booking")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="/booking">{props.t("Book Now")}</Link>
                    </li> */}
                    <li>
                      <Link to="/myBooking">{props.t("View Bookings")} </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/cancelTicket" className=" waves-effect">
                    <i className="mdi mdi-cancel"></i>
                    <span>{props.t("Cancel Ticket")}</span>
                  </Link>

                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ion ion-ios-person-add"></i>
                    <span>{props.t("Manage Users")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/addUser">{props.t("Partners")}</Link>
                    </li>
                    <li>
                      <Link to="/addStaff">{props.t("Team")} </Link>
                    </li>
                    <li>
                      <Link to="/addPartnerStaff">{props.t("My Staffs")}</Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-bus" />
                    <span>{props.t("Manage Bus")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/layers" className="waves-effect">
                        <span>{props.t("Layers")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/routes" className="waves-effect">
                        <span>{props.t("Routes")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/buses" className="waves-effect">
                        <span>{props.t("Bus")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link to="https://parivahan.gov.in/parivahan//en/content/vehicle-related-services" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Pay Vehicle Tax")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="https://rechargezap.in/fastag" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Recharge Fastag")}</span>
                  </Link>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Tokens")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="/rechargeTickets" className="waves-effect">
                        <span>{props.t("Recharge Tokens")}</span>
                      </Link>
                    </li> */}

                    <li>
                      <Link to="/viewPayments" className="waves-effect">
                        <span>{props.t("View Payments")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-chart-bar"></i>
                    <span>{props.t("Analytics")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/exhaustingCoins" className="waves-effect">
                        <span>{props.t("Exhausting Coins")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/idlePartner" className="waves-effect">
                        <span>{props.t("Idle Partner")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/roles" className="waves-effect">
                    <i className="ion ion-ios-person-add"></i>
                    <span>{props.t("Roles")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/logout" className="waves-effect">
                    <i className="mdi mdi-logout"></i>
                    <span>{props.t("Logout")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        )
      case 'SITE_MANAGER':
        return (
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu" onClick={handleParentClick}>
                <li className="menu-title">{props.t("Inventory")} </li>

                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("Dashboards")}</span>
                  </Link>

                </li>
                <li>
                  <Link to="/profile" className="waves-effect">
                    <i className="mdi mdi-face"></i>
                    <span>{props.t("My Profile")}</span>
                  </Link>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-ticket-confirmation-outline"></i>
                    <span>{props.t("Booking")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="/booking">{props.t("Book Now")}</Link>
                    </li> */}
                    <li>
                      <Link to="/myBooking">{props.t("View Bookings")} </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/cancelTicket" className=" waves-effect">
                    <i className="mdi mdi-cancel"></i>
                    <span>{props.t("Cancel Ticket")}</span>
                  </Link>

                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ion ion-ios-person-add"></i>
                    <span>{props.t("Manage Users")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/addUser">{props.t("Partners")}</Link>
                    </li>
                    {/* <li>
                      <Link to="/addStaff">{props.t("Team")} </Link>
                    </li> */}
                    <li>
                      <Link to="/addPartnerStaff">{props.t("My Staffs")}</Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-bus" />
                    <span>{props.t("Manage Bus")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/layers" className="waves-effect">
                        <span>{props.t("Layers")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/routes" className="waves-effect">
                        <span>{props.t("Routes")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/buses" className="waves-effect">
                        <span>{props.t("Bus")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                {/* <li>
                  <Link to="https://parivahan.gov.in/parivahan//en/content/vehicle-related-services" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Pay Vehicle Tax")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="https://rechargezap.in/fastag" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Recharge Fastag")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Tokens")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="/rechargeTickets" className="waves-effect">
                        <span>{props.t("Recharge Tokens")}</span>
                      </Link>
                    </li> */}

                    <li>
                      <Link to="/viewPayments" className="waves-effect">
                        <span>{props.t("View Payments")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-chart-bar"></i>
                    <span>{props.t("Analytics")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/exhaustingCoins" className="waves-effect">
                        <span>{props.t("Exhausting Coins")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/idlePartner" className="waves-effect">
                        <span>{props.t("Idle Partner")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/logout" className="waves-effect">
                    <i className="mdi mdi-logout"></i>
                    <span>{props.t("Logout")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        )
      case 'PARTNER':
        return (
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu" onClick={handleParentClick}>
                <li className="menu-title">{props.t("Inventory")} </li>

                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("Dashboards")}</span>
                  </Link>

                </li>
                <li>
                  <Link to="/profile" className="waves-effect">
                    <i className="mdi mdi-face"></i>
                    <span>{props.t("My Profile")}</span>
                  </Link>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-ticket-confirmation-outline"></i>
                    <span>{props.t("Booking")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/booking">{props.t("Book Now")}</Link>
                    </li>
                    <li>
                      <Link to="/myBooking">{props.t("View Bookings")} </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/cancelTicket" className=" waves-effect">
                    <i className="mdi mdi-cancel"></i>
                    <span>{props.t("Cancel Ticket")}</span>
                  </Link>

                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ion ion-ios-person-add"></i>
                    <span>{props.t("Manage Users")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">

                    <li>
                      <Link to="/addPartnerStaff">{props.t("My Staffs")}</Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-bus" />
                    <span>{props.t("Manage Bus")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/layers" className="waves-effect">
                        <span>{props.t("Layers")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/routes" className="waves-effect">
                        <span>{props.t("Routes")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/buses" className="waves-effect">
                        <span>{props.t("Bus")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link to="https://parivahan.gov.in/parivahan//en/content/vehicle-related-services" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Pay Vehicle Tax")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="https://rechargezap.in/fastag" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Recharge Fastag")}</span>
                  </Link>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Tokens")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/rechargeTickets" className="waves-effect">
                        <span>{props.t("Recharge Tokens")}</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/viewPayments" className="waves-effect">
                        <span>{props.t("View Payments")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>

                <li>
                  <Link to="/logout" className="waves-effect">
                    <i className="mdi mdi-logout"></i>
                    <span>{props.t("Logout")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        )
      case 'MANAGER':
        return (
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu" onClick={handleParentClick}>
                <li className="menu-title">{props.t("Inventory")} </li>

                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("Dashboards")}</span>
                  </Link>

                </li>
                <li>
                  <Link to="/profile" className="waves-effect">
                    <i className="mdi mdi-face"></i>
                    <span>{props.t("My Profile")}</span>
                  </Link>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-ticket-confirmation-outline"></i>
                    <span>{props.t("Booking")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/booking">{props.t("Book Now")}</Link>
                    </li>
                    <li>
                      <Link to="/myBooking">{props.t("View Bookings")} </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/cancelTicket" className=" waves-effect">
                    <i className="mdi mdi-cancel"></i>
                    <span>{props.t("Cancel Ticket")}</span>
                  </Link>

                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ion ion-ios-person-add"></i>
                    <span>{props.t("Manage Users")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">

                    <li>
                      <Link to="/addPartnerStaff">{props.t("My Staffs")}</Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-bus" />
                    <span>{props.t("Manage Bus")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/layers" className="waves-effect">
                        <span>{props.t("Layers")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/routes" className="waves-effect">
                        <span>{props.t("Routes")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/buses" className="waves-effect">
                        <span>{props.t("Bus")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link to="https://parivahan.gov.in/parivahan//en/content/vehicle-related-services" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Pay Vehicle Tax")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="https://rechargezap.in/fastag" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Recharge Fastag")}</span>
                  </Link>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Tokens")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/rechargeTickets" className="waves-effect">
                        <span>{props.t("Recharge Tokens")}</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/viewPayments" className="waves-effect">
                        <span>{props.t("View Payments")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link to="/logout" className="waves-effect">
                    <i className="mdi mdi-logout"></i>
                    <span>{props.t("Logout")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        )
      case 'CONDUCTOR':
        return (
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu" onClick={handleParentClick}>
                <li className="menu-title">{props.t("Inventory")} </li>

                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("Dashboards")}</span>
                  </Link>

                </li>
                <li>
                  <Link to="/profile" className="waves-effect">
                    <i className="mdi mdi-face"></i>
                    <span>{props.t("My Profile")}</span>
                  </Link>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-ticket-confirmation-outline"></i>
                    <span>{props.t("Booking")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/booking">{props.t("Book Now")}</Link>
                    </li>
                    <li>
                      <Link to="/myBooking">{props.t("View Bookings")} </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/cancelTicket" className=" waves-effect">
                    <i className="mdi mdi-cancel"></i>
                    <span>{props.t("Cancel Ticket")}</span>
                  </Link>

                </li>


                <li>
                  <Link to="/logout" className="waves-effect">
                    <i className="mdi mdi-logout"></i>
                    <span>{props.t("Logout")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        )
      case 'BUSINESS_DEVELOPMENT_MANAGER':
        return (
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu" onClick={handleParentClick}>
                <li className="menu-title">{props.t("Inventory")} </li>

                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("Dashboards")}</span>
                  </Link>

                </li>
                <li>
                  <Link to="/profile" className="waves-effect">
                    <i className="mdi mdi-face"></i>
                    <span>{props.t("My Profile")}</span>
                  </Link>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-ticket-confirmation-outline"></i>
                    <span>{props.t("Booking")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="/booking">{props.t("Book Now")}</Link>
                    </li> */}
                    <li>
                      <Link to="/myBooking">{props.t("View Bookings")} </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/cancelTicket" className=" waves-effect">
                    <i className="mdi mdi-cancel"></i>
                    <span>{props.t("Cancel Ticket")}</span>
                  </Link>

                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ion ion-ios-person-add"></i>
                    <span>{props.t("Manage Users")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/addUser">{props.t("Partners")}</Link>
                    </li>
                    {/* <li>
                      <Link to="/addStaff">{props.t("Team")} </Link>
                    </li> */}
                    <li>
                      <Link to="/addPartnerStaff">{props.t("My Staffs")}</Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-bus" />
                    <span>{props.t("Manage Bus")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/layers" className="waves-effect">
                        <span>{props.t("Layers")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/routes" className="waves-effect">
                        <span>{props.t("Routes")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/buses" className="waves-effect">
                        <span>{props.t("Bus")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Tokens")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">

                    <li>
                      <Link to="/viewPayments" className="waves-effect">
                        <span>{props.t("View Payments")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-chart-bar"></i>
                    <span>{props.t("Analytics")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/exhaustingCoins" className="waves-effect">
                        <span>{props.t("Exhausting Coins")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/idlePartner" className="waves-effect">
                        <span>{props.t("Idle Partner")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/logout" className="waves-effect">
                    <i className="mdi mdi-logout"></i>
                    <span>{props.t("Logout")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        )

      default:
        return (
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu" onClick={handleParentClick}>
                <li className="menu-title">{props.t("Inventory")} </li>

                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{props.t("Dashboards")}</span>
                  </Link>

                </li>
                <li>
                  <Link to="/profile" className="waves-effect">
                    <i className="mdi mdi-face"></i>
                    <span>{props.t("My Profile")}</span>
                  </Link>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="mdi mdi-ticket-confirmation-outline"></i>
                    <span>{props.t("Booking")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/booking">{props.t("Book Now")}</Link>
                    </li>
                    <li>
                      <Link to="/myBooking">{props.t("View Bookings")} </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/cancelTicket" className=" waves-effect">
                    <i className="mdi mdi-cancel"></i>
                    <span>{props.t("Cancel Ticket")}</span>
                  </Link>

                </li>


                {/* <li>
                  <Link to="https://parivahan.gov.in/parivahan//en/content/vehicle-related-services" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Pay Vehicle Tax")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="https://rechargezap.in/fastag" target='_blank' className="waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Recharge Fastag")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="fas fa-rupee-sign"></i>
                    <span>{props.t("Tokens")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">

                    <li>
                      <Link to="/viewPayments" className="waves-effect">
                        <span>{props.t("View Payments")}</span>
                      </Link>
                    </li>

                  </ul>
                </li>

                <li>
                  <Link to="/logout" className="waves-effect">
                    <i className="mdi mdi-logout"></i>
                    <span>{props.t("Logout")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        )
    }
  }

  return (
    <React.Fragment>
      {renderSideBar(role)}
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
