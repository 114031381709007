import React from "react"
import { PDFDownloadLink } from "@react-pdf/renderer"

const DownloadPdf = ({ pdfContent, fileName }) => {
  return (
    <div>
      <div>
        <div>
          <PDFDownloadLink
            document={pdfContent}
            fileName={`${fileName}.pdf`}
            style={{ color: "#fff" }}
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : <i className="ion ion-md-download"></i>
            }
          </PDFDownloadLink>
        </div>
      </div>
    </div>
  )
}

export default DownloadPdf
