import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { callApi } from "helpers/api_helper"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import NewStaffModal from "./NewStaffModal"
import Switch from "react-switch"
import { withTranslation } from "react-i18next";

const AssignStaffModal = ({
  modalOpen,
  toggleModal,
  getAllUsers,
  selectedBus,
  getAllBuses,
  setErrorMessage,
  setSuccessMessage,
  roleOptions,
  Linkedsymbol,
  Unlinkedsymbol,
  t
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [switch1, setSwitch1] = useState(true);
  const [newLinked, setNewLinked] = useState([]);

  const [updatedUsers, setUpdatedUsers] = useState([]);
  const [isAssignLoading, setIsAssignLoading] = useState(false);

  const handleAssignStaff = async (item, status) => {
    try {
      setSwitch1(!switch1);
      let a = newLinked;
      if (status) {
        a.push(item?._id);
        handleAssign(item);
      } else {
        let index = a.indexOf(item?._id);
        a.splice(index, 1);
        handleDeleteStaff(item);
      }
      setNewLinked([...a]);

    } catch (error) {
      console.log({ error })
      setSuccessMessage(null)
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      toggleModal()
    }
  }



  const handleAssign = async (item) => {
    try {
      setIsAssignLoading(true);

      const config = {
        method: "post",
        url: "bus/getBusRoutes/assignStaff",
        data: {
          bus_id: selectedBus?._id,
          user_id: item?._id,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        await getAllBuses()
        await getAllUsers()
        toggleModal();
        setIsAssignLoading(false);
      } else {
        toggleModal();
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000);
        setIsAssignLoading(false);
      }
    } catch (error) {
      console.log({ error });
      setSuccessMessage(null)
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      toggleModal();
      setIsAssignLoading(false);
    }
  }

  const handleDeleteStaff = async item => {
    try {
      setIsAssignLoading(true);
      const config = {
        method: "post",
        url: "bus/getBusRoutes/unlinkStaff",
        data: {
          bus_id: selectedBus?._id,
          user_id: item?._id,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        toggleModal();
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        await getAllBuses()
        await getAllUsers();
        setIsAssignLoading(false);
      } else {
        toggleModal();
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        toggleModal();
        setIsAssignLoading(false);
      }
    } catch (error) {
      console.log({ error });
      toggleModal();
      setSuccessMessage(null)
      setErrorMessage(error.message)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      toggleModal();
      setIsAssignLoading(false);
    }
  }


  useEffect(() => {
    if (selectedBus) {
      // const newUsers = users?.filter((item) => selectedBus?.partner?.partnerStaffs?.includes(item._id));
      setUpdatedUsers(selectedBus?.partnerStaffs);

    }
  }, [selectedBus])

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggleModal} size="lg" centered={true}>
        <ModalHeader toggle={toggleModal}>
          <Label>
            {t('Staff List')}
            {/* <Button
              className="m-2"
              color="success"
              outline
              size="sm"
              onClick={() => setIsModalOpen(true)}
            >
              + New Staff
            </Button> */}
          </Label>
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <div className="table-responsive">
                {
                  updatedUsers?.length > 0 ?
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>{t('User ID')}</th>
                          <th>{t('User Name')}</th>
                          <th>{t('Phone Number')}</th>
                          <th>{t('Actions')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {updatedUsers?.map((item, i) => {
                          return (
                            <tr key={item?._id}>
                              <td>{item?.userId}</td>
                              <td>{item?.userName}</td>
                              <td>{item?.phoneNumber}</td>
                              <td>
                                <Switch
                                  borderRadius={10}
                                  uncheckedIcon={<Unlinkedsymbol />}
                                  checkedIcon={<Linkedsymbol />}
                                  onColor="#626ed4"
                                  offColor="#ec4561"
                                  height={26}
                                  width={82}
                                  onChange={e => {
                                    handleAssignStaff(item, e)
                                  }}
                                  disabled={isAssignLoading}
                                  checked={item?.busId.includes(selectedBus?._id)}
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    :
                    <p className="text-center fw-bold">{t('No users Found!')}</p>
                }
              </div>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              color="danger"
              outline
              className="mx-1"
              onClick={toggleModal}
            >
              {t('Close')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <NewStaffModal
        modalOpen={isModalOpen}
        toggleModal={() => setIsModalOpen(!isModalOpen)}
        getAllUsers={getAllUsers}
        roleOptions={roleOptions}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      />
    </div>
  )
}

AssignStaffModal.propType = {
  t: PropTypes.any
}
export default withTranslation()(AssignStaffModal);
