// import PropTypes from 'prop-types';
import React, { useEffect } from "react";
// import withRouter from 'components/Common/withRouter';

// import { logoutUser } from "../../store/actions";
//redux
import { useNavigate } from 'react-router-dom';

const Logout = props => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    navigate('/login');

    // dispatch(logoutUser(navigate));

  }, [navigate]);

  return <></>;
};



export default Logout;
