import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { capitalCase } from 'change-case';
import { callApi } from 'helpers/api_helper';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { Card, CardBody, Col, Container, Label, Row, Table } from 'reactstrap';
import Breadcrumb from "../../components/Common/Breadcrumb";

const Roles = (props) => {
    const [roleList, setRoleList] = useState([]);
    const [apiList, setApiList] = useState([]);
    const [selectedRole, setSelectedRole] = useState();
    const [selectedApiList, setSelectedApiList] = useState([]);
    const fetchRoleList = async () => {
        try {
            let createRoleData = {
                method: 'get',
                url: 'roles/getRoleRoutes/getAllRoles'
            }
            let response = await callApi(createRoleData)
            var temp = []
            response.data.data.map((x, i) => {
                temp.push({
                    label: capitalCase(x.roleName),
                    value: x.roleName,
                    key: i
                })
                setRoleList(temp)
            })
        } catch (error) {
            console.log(error);
        }

    }
    const getRoleById = async (value) => {
        try {
            let roleData = {
                method: 'post',
                url: 'roles/getRoleRoutes/getRoleById',
                data: {
                    roleName: value
                }
            }
            let response = await callApi(roleData)
            // setAllowedApiForRole(response.data.data[0].roleApi)
            setSelectedApiList(response.data.data[0].roleApi)
        } catch (err) {
            console.log(err)
        }
    }
    const apiClickHandler = (e) => {
        let newApi = e.target.name
        var flag = false
        for (let i = 0; i < selectedApiList.length; i++) {
            if (newApi === selectedApiList[i]) {
                const mainArr = [...selectedApiList]
                const firstPart = mainArr.slice(0, i)
                const secondPart = mainArr.slice(i + 1, mainArr.length)
                setSelectedApiList([...firstPart, ...secondPart])
                flag = true

                break
            }
        }
        if (!flag) {
            setSelectedApiList((e) => [...e, newApi])
        }
    }

    const fetchApiList = async () => {
        let createApiData = {
            method: 'get',
            url: 'roles/getRoleRoutes/getAllApi',
        }
        let response = await callApi(createApiData)

        if (response.data.code == 200) {
            let tempArr = []
            for (let i = 0; i < response.data.data[0].endpoints.length; i++) {
                tempArr.push(response.data.data[0].endpoints[i].path)
            }
            setApiList(tempArr)
        }
    }

    const setRoleApiFunction = async () => {
        let createRoleData = {
            method: 'post',
            url: 'roles/getRoleRoutes/setRole',
            data: {
                roleName: selectedRole.value,
                roleApi: selectedApiList
            }
        }
        let response = await callApi(createRoleData)
        await fetchApiList()

    }

    useEffect(() => {
        fetchRoleList();
        fetchApiList();
    }, [])

    useEffect(() => {
        setRoleApiFunction();
    }, [selectedApiList])
    document.title = 'Roles | OneTKT'
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="OneTKT" breadcrumbItem="Role" />

                    <h4 className="card-title mb-4">{props.t('Set Role')}</h4>

                    <Card>
                        <CardBody>

                            <div className="my-2">
                                <Label>{props.t('Select A Role')}</Label>
                                <Select
                                    isSearchable={false}
                                    onChange={(e) => { setSelectedRole(e), getRoleById(e.value) }}
                                    options={roleList}
                                    classNamePrefix="select2-selection"
                                    value={selectedRole}

                                />

                            </div>
                        </CardBody>
                    </Card>
                    {(selectedRole != null) ?
                        <Row className="mb-5">
                            <Col xxs="12">
                                <Card className="mb-4">
                                    <CardBody>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>{props.t('Api No.')}</th>
                                                    <th>{props.t('API')}</th>
                                                    <th>{props.t('CheckBox')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {apiList.map((apiName, i) => {
                                                    return (
                                                        <tr key={apiName}>
                                                            <th scope="row">{i + 1}</th>
                                                            <td>{apiName}</td>
                                                            <td><input type='checkbox'
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {

                                                                        apiClickHandler(e)
                                                                    }
                                                                }}
                                                                name={apiName} onClick={(e) => { apiClickHandler(e), console.log('first') }} checked={selectedApiList.includes(apiName)} /></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : <p>{props.t('Select Role To show Apis')}</p>}
                </Container>
            </div>
        </React.Fragment>
    )
}

Roles.propTypes = {
    t: PropTypes.any
}
export default withTranslation()(Roles);