import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Button } from "reactstrap"
import { withTranslation } from "react-i18next"

const Breadcrumb = ({
  t, breadcrumbItem, maintitle,
  title }) => {
  const [setting_Menu, setsetting_Menu] = useState(false)

  return (
    <Row className="align-items-center">
      <Col sm={6}>
        <div className="page-title-box">
          <h4 className="font-size-18">{t(breadcrumbItem)}</h4>
          <ol className="breadcrumb mb-0">
            {
              (t(maintitle)) ?
                <>
                  <BreadcrumbItem>
                    <Link to="/#">{t(maintitle)}</Link>
                  </BreadcrumbItem>
                </> : ''
            }
            <BreadcrumbItem>
              <Link to="/#">{t(title)}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {t(breadcrumbItem)}
            </BreadcrumbItem>
          </ol>
        </div>
      </Col>
      <Col sm={6}>
        <div className="float-end d-none d-md-block">

          {/* <Dropdown
            isOpen={setting_Menu}
            toggle={() => {
              setsetting_Menu(!setting_Menu)
            }}
          >
            <DropdownToggle color="primary" className="btn btn-primary dropdown-toggle waves-effect waves-light">
              <i className="mdi mdi-cog me-2"></i> {props.title}
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem tag="a" href="#">Action</DropdownItem>
              <DropdownItem tag="a" href="#">Another action</DropdownItem>
              <DropdownItem tag="a" href="#">Something else here</DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag="a" href="#">Separated link</DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any
}

export default withTranslation()(Breadcrumb)
