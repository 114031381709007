import React, { useState, useEffect } from "react"
import Select from "react-select"
import PropTypes from "prop-types"

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom";
// import { useGoogleTranslate } from 'react-google-translator';

// import "chartist/dist/scss/chartist.scss"
import DashboardSavingsModal from "pages/Modals/DashboardSavingsModal"
import DashboardOccupancyModal from "pages/Modals/DashboardOccupancyModal"
import DashboardRevenueModal from "pages/Modals/DashboardRevenueModal"
import DashboardTicketBookedModal from "pages/Modals/DashboardTicketBookedModal"
import DashboardCancellationModal from "pages/Modals/DashboardCancellationModal"
import DashboardCoinsModal from "pages/Modals/DashboardCoinsModal"
import DocumentModal from "pages/Modals/DocumentModal"
import { capitalCase } from "change-case"
import { callApi } from "helpers/api_helper"
import LiveBuses from "./Components/LiveBuses"
import moment from "moment"
import DashboardTokenModal from "pages/Modals/DashboardTokenModal"
import SemiCircularChart from "pages/Utility/SemiCircularChart"
import CountdownTimer from "pages/Authentication/CountDownTimer"
import { filter } from "lodash"
import { withTranslation } from "react-i18next";
//i18n
// import { withTranslation } from "react-i18next";

function getSalutation() {
  const currentTime = new Date()
  const currentHour = currentTime.getHours()

  let salutation

  if (currentHour < 12) {
    salutation = "Good Morning"
  } else if (currentHour < 18) {
    salutation = "Good Afternoon"
  } else {
    salutation = "Good Evening"
  }

  return `${salutation}`
}


const Dashboard = props => {
  const [menu, setMenu] = useState(false);
  const [user, setUser] = useState(null)
  const [isDashboardModalOpen, setIsDashboardModalOpen] = useState(false)
  const [isDashboardTokenModalOpen, setIsDashboardTokenModalOpen] =
    useState(false)
  const [isDashboardOccupancyModalOpen, setIsDashboardOccupancyModalOpen] =
    useState(false)

  const [isDashboardRevenueModalOpen, setIsDashboardRevenueModalOpen] =
    useState(false)
  const [
    isDashboardTicketBookedModalOpen,
    setIsDashboardTicketBookedModalOpen,
  ] = useState(false)
  const [
    isDashboardCancellationModalOpen,
    setIsDashboardCancellationModalOpen,
  ] = useState(false)
  const [isDashboardCoinModalOpen, setIsDashboardCoinModalOpen] =
    useState(false)

  const [documentModalOpen, setDocumentModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedDashboard, setSelectedDashboard] = useState({})
  const [busList, setBusList] = useState([])
  const [liveBusList, setLiveBusList] = useState([])
  const [bookedTicketsList, setBookedTicketsList] = useState([])
  const [bookedCoins, setBookedCoins] = useState([])
  const [busCancelledBooking, setBusCancelledBooking] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPageSize, setSelectedPageSize] = useState(5)
  const [totalPage, setTotalPage] = useState(0)
  const [totalItemCount, setTotalItemCount] = useState(0)
  const [totalTicketCancelled, setTotalTicketCancelled] = useState(0)
  const [totalTicketBooked, setTotalTicketBooked] = useState(0)
  const [totalCoins, setTotalCoins] = useState(0)
  const [last30DaysSubscriptions, setLast30DaysSubscriptions] = useState(0)
  const [totalActiveBus, setTotalActiveBus] = useState(0)
  const [totalLiveBus, setTotalLiveBus] = useState(0)
  const [liveRunningBuPercentage, setLiveRunningBuPercentage] = useState(0)
  const [totalPartners, setTotalPartners] = useState(0)
  const [bookedTicketsCounts, setBookedTicketsCounts] = useState({})
  const [bookedTicketsDayWise, setBookedTicketsDayWise] = useState({})
  const [todayTicketList, setTodayTicketList] = useState([])
  const [sevenDaysTicketList, setSevenDaysTicketList] = useState([])
  const [thirtyDaysTicketList, setThirtyDaysTicketList] = useState([])
  const [onYearTicketList, setOneYearTicketList] = useState([])
  const [remainingTokens, setRemainingTokens] = useState([])
  const [remainingDays, setRemainingDays] = useState([])
  const [time, setTime] = useState(null)
  const [timeMessage, setTimeMessage] = useState("")
  const [subscriptionData, setSubscriptionData] = useState({})
  const [totalSaving, setTotalSaving] = useState(0)
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [bookingRequests, setBookingRequests] = useState([])

  const pageSizes = [2, 4, 8, 10, 20, 50, 100, 500, 1000]

  const startIndex = (currentPage - 1) * selectedPageSize
  const endIndex = currentPage * selectedPageSize

  const getAllLiveBuses = async (size, tCurrentPage) => {
    try {
      const config = {
        method: "post",
        url: "bus/getBusRoutes/liveBuses",
        data: {
          pageSize: size,
          currentPage: tCurrentPage,
        },
      }

      const response = await callApi(config)
      if (response.data.code === 200) {
        const newBusList = response.data.data.map(item => {
          const totalSeats = Object.keys(item?.layer[0]?.availableSeats).length
          const bookedSeats = item?.ticketBooked
          const occ = (bookedSeats / totalSeats) * 100
          return {
            ...item,
            occupancy: `${occ.toFixed(2)}%`,
          }
        })
        setLiveBusList(newBusList)
        // setLiveBusList(response.data.data);
        // setTotalItemCount(response.data.total);
        // setTotalPage(Math.ceil(response.data.total / size));
        setTotalLiveBus(response.data.total)
        let counter = 0
        const arr = response.data.data.map(item => {
          return {
            bus: item.bus[0].busName,
            route: item?.route[0]._id,
          }
        })
        const uniqueRecords = findUniqueRecords(arr)

        // setTotalActiveBus(uniqueRecords.length);
      }
    } catch (error) {
      console.log({ error })
    }
  }
  function findUniqueRecords(data) {
    const uniqueRecords = []

    data.forEach(record => {
      const isUnique = !uniqueRecords.some(
        existingRecord =>
          JSON.stringify(existingRecord) === JSON.stringify(record)
      )

      if (isUnique) {
        uniqueRecords.push(record)
      }
    })

    return uniqueRecords
  }
  const getLast30DaysSubscriptions = async () => {
    try {
      const config = {
        method: "post",
        url: "subscription/getSubscriptionRoutes/getLast30DaysSubscriptions",
      }

      const response = await callApi(config)
      if (response.data.code === 200) {
        setLast30DaysSubscriptions(response.data.total)
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const getAllBuses = async () => {
    try {
      const config = {

        method: "post",
        url: "bus/getBusRoutes/getAllActiveBuses",

      }
      const response = await callApi(config)
      if (response.data.code === 200) {

        setTotalActiveBus(response?.data?.data.filter((item) => item.isActive === true).length)

        setBusList(
          response.data.data.map(item => {
            return {
              label: `${item.busRegistrationNumber}/${item.busName}`,
              value: item._id,
              data: item,
            }
          })
        )
      }
    } catch (error) {
      console.log(error)
    }
  }



  const getAllUsers = async (size, tCurrentPage) => {
    const config = {
      method: "post",
      url: "user/getUserRoutes/getAllPartners",
      data: {
        pageSize: size,
        currentPage: tCurrentPage,
      },
    }
    const response = await callApi(config)
    if (response.data.code === 200) {
      // setIsLoading(false);
      // const tempArr = response.data.data.filter((item) => item?.role === 'PARTNER')
      setTotalPartners(response?.data?.totalCount)
    } else {
      navigate("/login")
    }
  }

  const getAllBookedTickets = async filter => {
    try {
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/getAllBookingsByBus",
        data: {
          filter,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        const newBookedList = []
        for (let index = 0; index < response.data.data.length; index++) {
          const element = response.data.data[index]
          if (element?.layer[0]?.availableSeats) {
            const totalSeats = Object.keys(
              element?.layer[0]?.availableSeats
            ).length
            const bookedSeats = element?.ticketsBooked
            const occ = (bookedSeats / totalSeats) * 100

            newBookedList.push({
              ...element,
              occupancy: occ.toFixed(2),
            })

          }
        }
        setBookedTicketsList(newBookedList)
        let tickets = 0
        for (let index = 0; index < response.data.data.length; index++) {
          const element = response.data.data[index]
          tickets += element.ticketsBooked
        }
        setTotalTicketBooked(tickets)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllBookedCoins = async filter => {
    try {
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/coinUsedAgainstEachBus",
        data: {
          filter,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setBookedCoins(response.data.data)
        // setTotalCoins(response?.data?.data[0]?.subscriptions[0]?.tokens ? response?.data?.data[0]?.subscriptions[0]?.tokens : 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllCancelledBooking = async filter => {
    try {
      const cancelledBooking = {
        method: "post",
        url: "bus/getBookingRequestRoutes/getAllCancelledBookingsBybus",
        data: {
          filter,
        },
      }
      const response = await callApi(cancelledBooking)
      if (response?.data?.code === 200) {
        let final = []
        let a = response?.data?.data

        // let totalCancelledTickets = 0
        // let finalCancellationDate = a[a.length - 1]
        for (let i = 0; i < a.length; i++) {
          const element = a[i]
          const cancelledBookings = element?.bookingRequests
          let finalCancelledDate = cancelledBookings[
            cancelledBookings?.length - 1
          ].updatedAt
            .toString()
            .split("T")[0]
          let totalCancelledAmountForEachBus = 0
          cancelledBookings?.map(
            x => (totalCancelledAmountForEachBus += parseInt(x?.totalAmount))
          )
          final.push({
            ticketsCancelled: element?.ticketCancelled,
            ticketCancelledAmount: totalCancelledAmountForEachBus,
            finalCancellationDate: finalCancelledDate,
            busRegistrationNumber: element?.bus[0]?.busRegistrationNumber,
            busName: element?.bus[0]?.busName,
          })
        }
        setBusCancelledBooking(final)
        setTotalTicketCancelled(
          response?.data?.data[0]?.ticketCancelled
            ? response?.data?.data[0]?.ticketCancelled
            : 0
        )
      } else {
        navigate("/login")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancelledData = async () => {
    await getAllCancelledBooking()
    setIsDashboardCancellationModalOpen(true)
  }

  const getAllBookingRequests = async () => {
    try {
      const currentDate = moment()
      const todayList = []
      const sevenDaysList = []
      const thirtyDaysList = []
      const oneYearList = []

      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/getAllBookingRequest",
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setBookingRequests(response.data.data)
        let totalSavings = 0
        let totalRevenues = 0
        for (let index = 0; index < response.data.data.length; index++) {
          const element = response.data.data[index]
          totalSavings =
            totalSavings + (element?.savings ? element?.savings : 0)
          totalRevenues =
            totalRevenues +
            (element?.totalAmount ? Number(element?.totalAmount) : 0)
        }

        setTotalSaving(totalSavings)
        setTotalRevenue(totalRevenues)
        response?.data?.data?.map(item => {
          const daysDiff = currentDate.diff(moment(item?.date), "days")
          if (daysDiff === 0) {
            todayList.push(item)
          } else if (daysDiff > 0 && daysDiff <= 7) {
            sevenDaysList.push(item)
          } else if (daysDiff > 7 && daysDiff <= 30) {
            thirtyDaysList.push(item)
          } else if (daysDiff > 30 && daysDiff <= 365) {
            oneYearList.push(item)
          }
        })
        setTodayTicketList(todayList)
        setSevenDaysTicketList(sevenDaysList)
        setThirtyDaysTicketList(thirtyDaysList)
        setOneYearTicketList(oneYearList)
      } else {
        navigate("/login")
      }
      setBookedTicketsCounts({
        today: todayList?.length,
        "7days": sevenDaysList?.length,
        "30days": thirtyDaysList?.length,
        "365days": oneYearList?.length,
      })
      setBookedTicketsDayWise({
        today: todayList,
        "7days": sevenDaysList,
        "30days": thirtyDaysList,
        "365days": oneYearList,
      })
    } catch (error) {
      console.log({ error })
    }
  }

  const getAllFilteredBookingRequests = async filter => {
    try {
      const currentDate = moment()
      const todayList = []
      const sevenDaysList = []
      const thirtyDaysList = []
      const oneYearList = []

      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/filterAllBookings",
        data: {
          filter,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setBookingRequests(response.data.data)
        let totalSavings = 0
        let totalRevenues = 0
        for (let index = 0; index < response.data.data.length; index++) {
          const element = response.data.data[index]
          totalSavings =
            totalSavings + (element?.savings ? element?.savings : 0)
          totalRevenues =
            totalRevenues +
            (element?.totalAmount ? Number(element?.totalAmount) : 0)
        }

        setTotalSaving(totalSavings)
        setTotalRevenue(totalRevenues)
        response?.data?.data?.map(item => {
          const daysDiff = currentDate.diff(moment(item?.date), "days")
          if (daysDiff === 0) {
            todayList.push(item)
          } else if (daysDiff > 0 && daysDiff <= 7) {
            sevenDaysList.push(item)
          } else if (daysDiff > 7 && daysDiff <= 30) {
            thirtyDaysList.push(item)
          } else if (daysDiff > 30 && daysDiff <= 365) {
            oneYearList.push(item)
          }
        })
        setTodayTicketList(todayList)
        setSevenDaysTicketList(sevenDaysList)
        setThirtyDaysTicketList(thirtyDaysList)
        setOneYearTicketList(oneYearList)
      } else {
        navigate("/login")
      }
      setBookedTicketsCounts({
        today: todayList?.length,
        "7days": sevenDaysList?.length,
        "30days": thirtyDaysList?.length,
        "365days": oneYearList?.length,
      })
      setBookedTicketsDayWise({
        today: todayList,
        "7days": sevenDaysList,
        "30days": thirtyDaysList,
        "365days": oneYearList,
      })
    } catch (error) {
      console.log({ error })
    }
  }

  const toggle = () => {
    setMenu(!menu)
  }

  const navigate = useNavigate()

  // const getAllSubscriptions = async () => {
  //   try {
  //     const config = {
  //       method: "post",
  //       url: "subscription/getSubscriptionRoutes/getSubscriptionByUserId",
  //     }

  //     const response = await callApi(config)
  //     if (response.data.code === 200) {
  //       setRemainingTokens(response?.data?.data?.tokens)
  //     }
  //   } catch (error) {
  //     console.log({ error })
  //   }
  // }

  const getAllRemainingDays = async () => {
    try {
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/dailyAverage",
      }

      const response = await callApi(config)
      if (response.data.code === 200) {
        setRemainingDays(response?.data?.Days)
        await localStorage.setItem(
          "average",
          JSON.stringify({ average: response.data.average })
        )
        if (response?.data?.Days < 7) {
          setIsDashboardTokenModalOpen(true)
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const getSubscription = async () => {
    try {
      const config = {
        method: "post",
        url: "subscription/getSubscriptionRoutes/getSubscriptionByUserId",
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setSubscriptionData(response.data.data)
        setIsLoading(false)
      } else {
        navigate("/login")
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const getSubscriptionByRole = async () => {
    try {
      const config = {
        method: "post",
        url: "subscription/getSubscriptionRoutes/getSubscriptionByRole",
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setTotalCoins(response.data.data.tokens)
        setIsLoading(false)
      }
    } catch (error) {
      console.log({ error })
    }
  }

  function convertToRupees(number) {
    if (isNaN(number)) {
      return 0
    } else if (number >= 10000000) {
      return (number / 10000000).toFixed(2) + " Cr"
    } else if (number >= 100000) {
      return (number / 100000).toFixed(2) + " L"
    } else if (number >= 1000) {
      return (number / 1000).toFixed(2) + " K"
    } else {
      return number
    }
  }

  const analytics = role => {
    try {
      switch (role) {
        case "SUPER_ADMIN":
          return (
            <Row>
              {
                // user?.role !== 'CONDUCTOR' &&

                <Col xs={6} md={4}>
                  <Card
                    // onClick={() => setIsDashboardModalOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <div className="text-center">
                        <SemiCircularChart percent={liveRunningBuPercentage} />
                        <p className="text-muted mb-0">{props.t('Live Buses')}</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              }
              {
                // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&
                <>
                  <Col xs={6} md={4}>
                    <Card
                      // onClick={() => setIsDashboardTicketBookedModalOpen(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <SemiCircularChart
                          percent={last30DaysSubscriptions / 100}
                        />
                        <div className="text-center">
                          <p className="text-muted mb-0">{props.t('Monthly')} RF</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              }
              <Col xs={12} md={4}>
                <Row>
                  {
                    // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&

                    <Col xs={6} lg={12}>
                      <Card
                        // onClick={async () => await handleCancelledData()}
                        style={{ cursor: "pointer" }}
                      >
                        <CardBody>
                          <div className="text-center">
                            <h4 className="text-primary">{totalPartners}</h4>
                            <p className="text-muted mb-0">{props.t('Partners')}</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                  <Col xs={6} lg={12}>
                    <Card
                      // onClick={async () => await handleCancelledData()}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <div className="text-center">
                          <h4 className="text-primary">{totalActiveBus}</h4>
                          <p className="text-muted mb-0">{props.t('Inventory')}</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )
        case "BUSINESS_DEVELOPMENT_MANAGER":
          return (
            <Row>
              {
                // user?.role !== 'CONDUCTOR' &&

                <Col xs={6} md={4}>
                  <Card
                    // onClick={() => setIsDashboardModalOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <div className="text-center">
                        <SemiCircularChart percent={liveRunningBuPercentage} />
                        <p className="text-muted mb-0">{props.t('Live Buses')}</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              }
              {
                // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&
                <>
                  <Col xs={6} md={4}>
                    <Card
                      // onClick={() => setIsDashboardTicketBookedModalOpen(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <SemiCircularChart
                          percent={last30DaysSubscriptions / 100}
                        />
                        <div className="text-center">
                          <p className="text-muted mb-0">{props.t('Monthly')} RF</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              }
              <Col xs={12} md={4}>
                <Row>
                  {
                    // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&

                    <Col xs={6} lg={12}>
                      <Card
                        // onClick={async () => await handleCancelledData()}
                        style={{ cursor: "pointer" }}
                      >
                        <CardBody>
                          <div className="text-center">
                            <h4 className="text-primary">{totalPartners}</h4>
                            <p className="text-muted mb-0">{props.t('Partners')}</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                  <Col xs={6} lg={12}>
                    <Card
                      // onClick={async () => await handleCancelledData()}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <div className="text-center">
                          <h4 className="text-primary">{totalActiveBus}</h4>
                          <p className="text-muted mb-0">{props.t('Inventory')}</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )
        case "ADMIN":
          return (
            <Row>
              {
                // user?.role !== 'CONDUCTOR' &&

                <Col xs={6} md={4}>
                  <Card
                    // onClick={() => setIsDashboardModalOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <div className="text-center">
                        <SemiCircularChart percent={liveRunningBuPercentage} />
                        <p className="text-muted mb-0">{props.t('Live Buses')}</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              }
              {
                // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&
                <>
                  <Col xs={6} md={4}>
                    <Card
                      // onClick={() => setIsDashboardTicketBookedModalOpen(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <SemiCircularChart
                          percent={last30DaysSubscriptions / 100}
                        />
                        <div className="text-center">
                          <p className="text-muted mb-0">{props.t('Monthly')} RF</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              }
              <Col xs={12} md={4}>
                <Row>
                  {
                    // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&

                    <Col xs={6} lg={12}>
                      <Card
                        // onClick={async () => await handleCancelledData()}
                        style={{ cursor: "pointer" }}
                      >
                        <CardBody>
                          <div className="text-center">
                            <h4 className="text-primary">{totalPartners}</h4>
                            <p className="text-muted mb-0">{props.t('Partners')}</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                  <Col xs={6} lg={12}>
                    <Card
                      // onClick={async () => await handleCancelledData()}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <div className="text-center">
                          <h4 className="text-primary">{totalActiveBus}</h4>
                          <p className="text-muted mb-0">{props.t('Inventory')}</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )
        case "SYSTEM_ADMINISTRATOR":
          return (
            <Row>
              {
                // user?.role !== 'CONDUCTOR' &&

                <Col xs={6} md={4}>
                  <Card
                    // onClick={() => setIsDashboardModalOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <div className="text-center">
                        <SemiCircularChart percent={liveRunningBuPercentage} />
                        <p className="text-muted mb-0">{props.t('Live Buses')}</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              }
              {
                // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&
                <>
                  <Col xs={6} md={4}>
                    <Card
                      // onClick={() => setIsDashboardTicketBookedModalOpen(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <SemiCircularChart
                          percent={last30DaysSubscriptions / 100}
                        />
                        <div className="text-center">
                          <p className="text-muted mb-0">{props.t('Monthly')} RF</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              }
              <Col xs={12} md={4}>
                <Row>
                  {
                    // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&

                    <Col xs={6} lg={12}>
                      <Card
                        // onClick={async () => await handleCancelledData()}
                        style={{ cursor: "pointer" }}
                      >
                        <CardBody>
                          <div className="text-center">
                            <h4 className="text-primary">{totalPartners}</h4>
                            <p className="text-muted mb-0">{props.t('Partners')}</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                  <Col xs={6} lg={12}>
                    <Card
                      // onClick={async () => await handleCancelledData()}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <div className="text-center">
                          <h4 className="text-primary">{totalActiveBus}</h4>
                          <p className="text-muted mb-0">{props.t('Inventory')}</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )
        case "OPERATION_COORDINATOR":
          return (
            <Row>
              {
                // user?.role !== 'CONDUCTOR' &&

                <Col xs={6} md={4}>
                  <Card
                    // onClick={() => setIsDashboardModalOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <div className="text-center">
                        <SemiCircularChart percent={liveRunningBuPercentage} />
                        <p className="text-muted mb-0">{props.t('Live Buses')}</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              }
              {
                // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&
                <>
                  <Col xs={6} md={4}>
                    <Card
                      // onClick={() => setIsDashboardTicketBookedModalOpen(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <SemiCircularChart
                          percent={last30DaysSubscriptions / 100}
                        />
                        <div className="text-center">
                          <p className="text-muted mb-0">{props.t('Monthly')} RF</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              }
              <Col xs={12} md={4}>
                <Row>
                  {
                    // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&

                    <Col xs={6} lg={12}>
                      <Card
                        // onClick={async () => await handleCancelledData()}
                        style={{ cursor: "pointer" }}
                      >
                        <CardBody>
                          <div className="text-center">
                            <h4 className="text-primary">{totalPartners}</h4>
                            <p className="text-muted mb-0">{props.t('Partners')}</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                  <Col xs={6} lg={12}>
                    <Card
                      // onClick={async () => await handleCancelledData()}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <div className="text-center">
                          <h4 className="text-primary">{totalActiveBus}</h4>
                          <p className="text-muted mb-0">{props.t('Inventory')}</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )
        case "SITE_MANAGER":
          return (
            <Row>
              {
                // user?.role !== 'CONDUCTOR' &&

                <Col xs={6} md={4}>
                  <Card
                    // onClick={() => setIsDashboardModalOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <div className="text-center">
                        <SemiCircularChart percent={liveRunningBuPercentage} />
                        <p className="text-muted mb-0">{props.t('Live Buses')}</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              }
              {
                // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&
                <>
                  <Col xs={6} md={4}>
                    <Card
                      // onClick={() => setIsDashboardTicketBookedModalOpen(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <SemiCircularChart
                          percent={last30DaysSubscriptions / 100}
                        />
                        <div className="text-center">
                          <p className="text-muted mb-0">{props.t('Monthly')} RF</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              }
              <Col xs={12} md={4}>
                <Row>
                  {
                    // user.role !== 'PARTNER' || user.role !== 'MANAGER' || user.role !== 'CONDUCTOR' &&

                    <Col xs={6} lg={12}>
                      <Card
                        // onClick={async () => await handleCancelledData()}
                        style={{ cursor: "pointer" }}
                      >
                        <CardBody>
                          <div className="text-center">
                            <h4 className="text-primary">{totalPartners}</h4>
                            <p className="text-muted mb-0">{props.t('Partners')}</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                  <Col xs={6} lg={12}>
                    <Card
                      // onClick={async () => await handleCancelledData()}
                      style={{ cursor: "pointer" }}
                    >
                      <CardBody>
                        <div className="text-center">
                          <h4 className="text-primary">{totalActiveBus}</h4>
                          <p className="text-muted mb-0">{props.t('Inventory')}</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )

        default:
          break
      }
    } catch (error) {
      console.log(error)
    }
  }

  const buttonsToRender = role => {
    switch (role) {
      case "SUPER_ADMIN":
        return (
          <Row>
            {/* <Col
              xl={4}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/booking")}
            >
              <Link to="/booking" className="text-white-50">
                <Button
                  className="btn my-1 w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {props.t('Book Now')}
                    <i className="mdi mdi-ticket-confirmation-outline h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col> */}
            <Col
              xl={6}
              md={6}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/cancelTicket")}
            >
              <Link to="/cancelTicket" className="text-white-50">
                <Button
                  className="btn my-1 w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Cancel Ticket')} <i className="mdi mdi-cancel h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
            <Col
              xl={6}
              md={6}
              style={{ cursor: "pointer" }}
              onClick={() => setDocumentModalOpen(true)}
            >
              <Link to="#" className="text-white-50">
                <Button
                  className="btn my-1 mb-3 btn w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Documents')}{" "}
                    <i className="mdi mdi-file-document-multiple-outline h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
          </Row>
        )
      case "ADMIN":
        return (
          <Row>
            {/* <Col
              xl={4}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/booking")}
            >
              <Link to="/booking" className="text-white-50">
                <Button
                  className="btn my-1 w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Book Now')}
                    <i className="mdi mdi-ticket-confirmation-outline h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col> */}
            <Col
              xl={6}
              md={6}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/cancelTicket")}
            >
              <Link to="/cancelTicket" className="text-white-50">
                <Button
                  className="btn my-1 w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Cancel Ticket')} <i className="mdi mdi-cancel h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
            <Col
              xl={6}
              md={6}
              style={{ cursor: "pointer" }}
              onClick={() => setDocumentModalOpen(true)}
            >
              <Link to="#" className="text-white-50">
                <Button
                  className="btn my-1 mb-3 btn w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Documents')}{" "}
                    <i className="mdi mdi-file-document-multiple-outline h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
          </Row>
        )
      case "MANAGER":
        return (
          <Row>
            <Col
              xl={4}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/booking")}
            >
              <Link to="/booking" className="text-white-50">
                <Button
                  className="btn my-1 w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Book Now')}
                    <i className="mdi mdi-ticket-confirmation-outline h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
            <Col
              xl={4}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/cancelTicket")}
            >
              <Link to="/cancelTicket" className="text-white-50">
                <Button
                  className="btn my-1 w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Cancel Ticket')} <i className="mdi mdi-cancel h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
            <Col
              xl={4}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() => setDocumentModalOpen(true)}
            >
              <Link to="#" className="text-white-50">
                <Button
                  className="btn my-1 mb-3 btn w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Documents')}{" "}
                    <i className="mdi mdi-file-document-multiple-outline h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
          </Row>
        )
      case "PARTNER":
        return (
          <Row>
            <Col
              xl={4}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/booking")}
            >
              <Link to="/booking" className="text-white-50">
                <Button
                  className="btn my-1 w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Book Now')}
                    <i className="mdi mdi-ticket-confirmation-outline h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
            <Col
              xl={4}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/cancelTicket")}
            >
              <Link to="/cancelTicket" className="text-white-50">
                <Button
                  className="btn my-1 w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Cancel Ticket')} <i className="mdi mdi-cancel h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
            <Col
              xl={4}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() => setDocumentModalOpen(true)}
            >
              <Link to="#" className="text-white-50">
                <Button
                  className="btn my-1 mb-3 btn w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Documents')}{" "}
                    <i className="mdi mdi-file-document-multiple-outline h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
          </Row>
        )
      case "CONDUCTOR":
        return (
          <Row>
            <Col
              xl={4}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/booking")}
            >
              <Link to="/booking" className="text-white-50">
                <Button
                  className="btn my-1 w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Book Now')}
                    <i className="mdi mdi-ticket-confirmation-outline h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
            <Col
              xl={4}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/cancelTicket")}
            >
              <Link to="/cancelTicket" className="text-white-50">
                <Button
                  className="btn my-1 w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Cancel Ticket')} <i className="mdi mdi-cancel h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
            <Col
              xl={4}
              md={4}
              style={{ cursor: "pointer" }}
              onClick={() => setDocumentModalOpen(true)}
            >
              <Link to="#" className="text-white-50">
                <Button
                  className="btn my-1 mb-3 btn w-100 btn-primary btn-lg waves-effect waves-light"
                  color="success"
                >
                  <strong>
                    {" "}
                    {props.t('Documents')}{" "}
                    <i className="mdi mdi-file-document-multiple-outline h5"></i>
                  </strong>
                </Button>
              </Link>
            </Col>
          </Row>
        )
      default:
        return ""
    }
  }
  const todaysLiveBuses = (role, liveBusList) => {
    switch (role) {
      case "SUPER_ADMIN":
        return <LiveBuses liveBusList={liveBusList} />
      case "ADMIN":
        return <LiveBuses liveBusList={liveBusList} />
      case "BUSINESS_DEVELOPMENT_MANAGER":
        return <LiveBuses liveBusList={liveBusList} />
      case "SITE_MANAGER":
        return <LiveBuses liveBusList={liveBusList} />
    }
  }

  useEffect(() => {

    totalActiveBus !== 0
      ? setLiveRunningBuPercentage((totalLiveBus / totalActiveBus).toFixed(2))
      : setLiveRunningBuPercentage(0)
  }, [totalLiveBus, totalActiveBus])

  useEffect(() => {
    const func = async () => {

      setIsLoading(true)
      const userDetails = JSON.parse(await localStorage.getItem("user"))
      if (!userDetails && !userDetails.token) {
        navigate("/login")
      }
      setUser(userDetails.user)
      await getAllCancelledBooking()
      await getAllLiveBuses()
      await getAllBookedTickets()
      await getAllBookedCoins()
      await getLast30DaysSubscriptions()
      await getAllBuses()
      await getAllUsers()
      await getAllBookingRequests()
      await getSubscription()
      await getSubscriptionByRole()

      setIsLoading(false);

    }
    func()
  }, [])


  useEffect(() => {
    if (user && (user.role === "PARTNER" || user.role === "MANAGER")) {
      getAllRemainingDays()
    }
  }, [user])

  document.title = `${props.t("Dashboard")} | One Ticket`

  const translatedComponent = <div>
    {isLoading ? (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner className="" color="danger" />
      </div>
    ) : (
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            {user?._id &&
              user?.subscriptionId &&
              !user?.subscriptionId?.isActive && (
                <div className="d-flex justify-content-center align-items-center w-100">
                  <CountdownTimer
                    targetDate={user?.subscriptionId?.createdAt}
                    userId={user?._id}
                  />
                </div>
              )}
            <Row className="align-items-center">
              <Col md={8} className="my-3">
                <h6 className="page-title">
                  {props.t(getSalutation())}, {user?.userName}
                </h6>
                {props.t('Welcome To OneTKT')} !
              </Col>
              {/* <Col className="float-end w-100">
                  {
                    (user?.role !== 'PARTNER' && user?.role !== 'CONDUCTOR' && user?.role !== 'MANAGER') &&
                    <Select
                      isSearchable={false}
                      placeholder="Search"
                      className=""
                      options={partnerOptions}
                    />
                  }
                </Col> */}
            </Row>
          </div>

          <Row>
            <Col xs={6} md={2}>
              <Card
                onClick={() => setIsDashboardModalOpen(true)}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <div className="text-center">
                    <h4 className="text-primary">
                      <i className="fas fa-rupee-sign"></i>{" "}
                      {convertToRupees(totalSaving)}
                    </h4>
                    <p className="text-muted mb-0">{props.t('Savings')}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs={6} md={2}>
              <Card
                onClick={() => setIsDashboardTicketBookedModalOpen(true)}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <div className="text-center">
                    <h4 className="text-primary">{totalTicketBooked}</h4>
                    <p className="text-muted mb-0">{props.t('Tickets')}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs={6} md={2}>
              <Card
                onClick={async () => await handleCancelledData()}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <div className="text-center">
                    <h4 className="text-primary">{totalTicketCancelled}</h4>
                    <p className="text-muted mb-0">{props.t('Cancelled')}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs={6} md={2}>
              <Card
                onClick={() => setIsDashboardOccupancyModalOpen(true)}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <div className="text-center">
                    <h4 className="text-primary">
                      {bookedTicketsList[bookedTicketsList.length - 1]?.occupancy
                        ? bookedTicketsList[bookedTicketsList.length - 1]?.occupancy
                        : 0}{" "}
                      %
                    </h4>
                    <p className="text-muted mb-0">{props.t('Occupancy')}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs={6} md={2}>
              <Card
                onClick={() => setIsDashboardRevenueModalOpen(true)}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <div className="text-center">
                    <h4 className="text-primary">
                      <i className="fas fa-rupee-sign"></i>{" "}
                      {convertToRupees(totalRevenue)}
                    </h4>
                    <p className="text-muted mb-0">{props.t('Revenue')}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs={6} md={2}>
              <Card
                onClick={() => setIsDashboardCoinModalOpen(true)}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <div className="text-center">
                    <h4 className="text-primary">
                      {convertToRupees(totalCoins)}
                    </h4>
                    <p className="text-muted mb-0">{props.t('Coins')}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {buttonsToRender(user?.role)}

          {analytics(user?.role)}
          {todaysLiveBuses(user?.role, liveBusList)}

          <DocumentModal
            modalOpen={documentModalOpen}
            toggleModal={() => setDocumentModalOpen(!documentModalOpen)}
            busOptions={busList}
          />

          <DashboardOccupancyModal
            modalOpen={isDashboardOccupancyModalOpen}
            toggleModal={() =>
              setIsDashboardOccupancyModalOpen(!isDashboardOccupancyModalOpen)
            }
            bookedTicketsList={bookedTicketsList}
            getAllBookedTickets={getAllBookedTickets}
          />
          <DashboardRevenueModal
            modalOpen={isDashboardRevenueModalOpen}
            toggleModal={() =>
              setIsDashboardRevenueModalOpen(!isDashboardRevenueModalOpen)
            }
            revenue={bookingRequests}
            getAllFilteredBookingRequests={getAllFilteredBookingRequests}
          />
          <DashboardSavingsModal
            modalOpen={isDashboardModalOpen}
            toggleModal={() => setIsDashboardModalOpen(!isDashboardModalOpen)}
            savings={bookingRequests}
            getAllFilteredBookingRequests={getAllFilteredBookingRequests}
          />
          <DashboardTokenModal
            modalOpen={isDashboardTokenModalOpen}
            toggleModal={() =>
              setIsDashboardTokenModalOpen(!isDashboardTokenModalOpen)
            }
            // remainingTokens={remainingTokens}
            remainingDays={remainingDays}
            // getAllSubscriptions={getAllSubscriptions}
            getAllRemainingDays={getAllRemainingDays}
          />
          <DashboardTicketBookedModal
            modalOpen={isDashboardTicketBookedModalOpen}
            toggleModal={() =>
              setIsDashboardTicketBookedModalOpen(
                !isDashboardTicketBookedModalOpen
              )
            }
            selectedDashboard={selectedDashboard}
            bookedTicketsList={bookedTicketsList}
            getAllBookedTickets={getAllBookedTickets}
          />
          <DashboardCancellationModal
            modalOpen={isDashboardCancellationModalOpen}
            toggleModal={() =>
              setIsDashboardCancellationModalOpen(
                !isDashboardCancellationModalOpen
              )
            }
            getAllCancelledBooking={getAllCancelledBooking}
            cancelledTickets={busCancelledBooking}
            selectedDashboard={selectedDashboard}
          />
          <DashboardCoinsModal
            modalOpen={isDashboardCoinModalOpen}
            toggleModal={() =>
              setIsDashboardCoinModalOpen(!isDashboardCoinModalOpen)
            }
            bookedCoins={bookedCoins}
            selectedDashboard={selectedDashboard}
            getAllBookedCoins={getAllBookedCoins}
          />
        </Container>
      </div>
    )}
  </div>


  return (
    <div className="">
      {
        translatedComponent
      }
    </div>

  )
}

Dashboard.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any
}

export default withTranslation()(Dashboard);
