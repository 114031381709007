import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom"
import { callApi } from "helpers/api_helper"
import { formatDate } from "helpers/Utils/formatTime"
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"


const CancelTicket = (props) => {
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [ticketList, setTicketList] = useState([])
  const [phoneNumber, setPhoneNumber] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const navigate = useNavigate();

  document.title = `${props.t('Cancel Ticket')} | OneTKT`

  const validate = () => {
    const error = {}
    if (!phoneNumber) {
      error.phoneNumber = "Enter a valid Phone Number!"
    } else if (phoneNumber.length !== 10) {
      error.phoneNumber = "Phone Number must be of 10 digits!"
    }
    setError(error)
    return error
  }

  const getTickets = async () => {
    try {
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/getAllBookingRequestForCancel",
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setIsLoading(false)
        setTicketList(response.data.data)
      } else {
        navigate("/login")
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const handleSearch = async () => {
    try {
      const error = await validate()
      if (Object.keys(error).length > 0) {
        return false
      }
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/searchBookingRequestByPhoneNumber",
        data: {
          phoneNumber,
        },
      }
      const response = await callApi(config)
      if (response.data.code === 200) {
        setTicketList(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleCancel = async id => {
    try {
      // const error = await validate();
      // if (Object.keys(error).length > 0) {
      //     return false;
      // }
      const config = {
        method: "post",
        url: "bus/getBookingRequestRoutes/cancelBookings",
        data: {
          id,
        },
      }
      const response = await callApi(config)

      if (response.data.code === 200) {
        setErrorMessage(null)
        setSuccessMessage(response.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        // setTicketList(response.data.data);
        await getTickets()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getTickets()
  }, [])

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="" color="danger" />
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="OneTKT" breadcrumbItem="Cancel Ticket" />
            {errorMessage && errorMessage ? (
              <Alert color="danger">{errorMessage}</Alert>
            ) : null}
            {successMessage ? (
              <Alert color="success">{successMessage}</Alert>
            ) : null}

            <Row>
              <Col xs={12} lg={4}>
                <Card>
                  <CardBody>
                    <Label>{props.t('Phone Number')}</Label>
                    <Row>
                      <Col xs={8}>
                        <Input
                          value={phoneNumber}
                          type="tel"
                          onChange={e => setPhoneNumber(e.target.value)}
                        />
                        {error.phoneNumber && (
                          <p className="text-danger">{error.phoneNumber}</p>
                        )}
                      </Col>
                      <Col xs={4}>
                        <Button
                          className="w-100"
                          color="primary"
                          outline
                          onClick={handleSearch}
                        > <i className="ion ion-ios-search p-1"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                {ticketList?.map((ticket, i) => {
                  const seats = Object.values(ticket.selectedSeats)
                  const seatNumbers = seats.map(
                    (item, i) =>
                      `${item.seatNumber} ${i !== seats.length - 1 ? ", " : ""}`
                  )
                  return (
                    <>
                      <Card>
                        <CardBody>
                          <div className="table-responsive">
                            <table className="table table-hover table-centered table-nowrap mb-0">
                              <tbody>
                                <tr>
                                  <th scope="col">{props.t('Bus Redg. No.')}</th>
                                  <td>{ticket?.bus?.busRegistrationNumber}</td>
                                </tr>
                                <tr>
                                  <th scope="col">{props.t('Route')}</th>{" "}
                                  <td>
                                    {ticket?.route?.startPoint?.station} to{" "}
                                    {ticket?.route?.endPoint?.station}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="col">{props.t('Date')}</th>
                                  <td>{formatDate(ticket?.createdAt)}</td>
                                </tr>
                                <tr>
                                  <th scope="col">{props.t('Seat Numbers')}</th>
                                  <td>{seatNumbers}</td>
                                </tr>
                                <tr className="mb-4">
                                  <td colSpan={2}>
                                    <Button
                                      className="w-100"
                                      color="danger"
                                      outline
                                      onClick={() => handleCancel(ticket._id)}
                                    >
                                      {props.t('Cancel')}
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </>
                  )
                })}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}
CancelTicket.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any
}
export default withTranslation()(CancelTicket);
