import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Button, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";

import { callApi } from "helpers/api_helper";
import { withTranslation } from "react-i18next";

const EditPartner = ({
  modalOpen,
  toggleModal,
  selectedUser,
  roleOptions,
  setSuccessMessage,
  setErrorMessage,
  getAllUsers,
  t
}) => {
  const [userName, setUserName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [selectedRole, setSelectedRole] = useState()
  const [errors, setErrors] = useState({})

  const resetFormValues = async () => {
    setUserName(selectedUser.userName)
    setPhoneNumber(String(selectedUser.phoneNumber))
    setSelectedRole(
      roleOptions.filter(item => selectedUser.role === item.value)
    )
  }

  const validate = async () => {
    try {
      const error = {}
      setErrors({})
      if (!userName) {
        error.userName = "Please Enter the User Name!"
      } else if (!phoneNumber) {
        error.phoneNumber = "Please Enter the Phone Number!"
      } else if (isNaN(phoneNumber)) {
        error.phoneNumber = "Please Enter a valid Phone Number!"
      } else if (phoneNumber.length !== 10) {
        error.phoneNumber = "Please Enter a valid Phone Number!"
      }
      setErrors(error)
      return error
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async () => {
    try {
      const error = await validate()
      if (Object.keys(error).length > 0) {
        return false
      }
      const config = {
        method: "post",
        url: "user/getUserRoutes/updateUser",
        data: {
          userName: userName,
          phoneNumber: phoneNumber,
          role: selectedRole.value ? selectedRole.value : selectedUser.role,
          userId: selectedUser?._id,
        },
      }

      const response = await callApi(config)
      if (response.data.code === 200) {
        toggleModal()
        setTimeout(() => {
          setSuccessMessage(null)
        }, 2000)
        setSuccessMessage(response.data.message)
        await getAllUsers()
      } else {
        setTimeout(() => {
          setErrorMessage(null)
        }, 2000)
        setErrorMessage(response.data.message)
      }
    } catch (error) {
      console.log(error)
      setTimeout(() => {
        setErrorMessage(null)
      }, 2000)
      setErrorMessage(error.message)
    }
  }

  useEffect(() => {

    if (selectedUser) {
      setUserName(selectedUser.userName)
      setPhoneNumber(String(selectedUser.phoneNumber))
      setSelectedRole(
        roleOptions.filter(item => selectedUser.role === item.value)
      )
    }
  }, [selectedUser])
  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={() => {
          toggleModal(), resetFormValues()
        }}
        backdrop="static"
        size="lg"
        centered={true}
      >
        <ModalHeader
          toggle={() => {
            toggleModal(), resetFormValues()
          }}
        >
          {t('Update User')}
        </ModalHeader>
        <ModalBody>
          <div className="form-group my-3">
            <Label className="form-label">{t('User Name')}</Label>
            <Input
              name="userName"
              className={`form-control ${errors.userName && "border border-danger"
                }`}
              placeholder="Enter User Name"
              type="text"
              onChange={e => {
                setUserName(e.target.value)
              }}
              value={userName}
            />
            {errors.userName && (
              <p className="text-danger">{errors.userName}</p>
            )}
          </div>
          <div className="form-group my-3">
            <Label className="form-label">{t('Phone Number')}</Label>
            <Input
              name="phoneNumber"
              className={`form-control ${errors.phoneNumber && "border border-danger"
                }`}
              placeholder="Enter Phone Number"
              type="tel"
              onChange={e => {
                setPhoneNumber(e.target.value)
              }}
              value={phoneNumber}
            />
            {errors.phoneNumber && (
              <p className="text-danger">{errors.phoneNumber}</p>
            )}
          </div>
          <div className="my-2">
            <Label>{t('Role')}</Label>
            <Select
              isSearchable={false}
              onChange={e => {
                setSelectedRole(e)
              }}
              options={roleOptions}
              classNamePrefix="select2-selection"
              value={selectedRole}
              isDisabled={true}
            />
          </div>
          <div className="d-flex justify-content-end align-items-center mt-4">
            <Button
              outline
              color="danger"
              className="mx-2"
              onClick={() => {
                toggleModal(), resetFormValues()
              }}
            >
              {t('Cancel')}
            </Button>
            <Button onClick={handleSubmit} color="success">
              {t('Update User')}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}
EditPartner.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any,
}
export default withTranslation()(EditPartner);
